// @flow strict

import React from 'react'

import Icon from 'components/Icon'

import { IconWrapperStyle } from './style'

const TimelineContainerIcon = (): React$Node => (
  <div className={IconWrapperStyle}>
    <Icon icon="CONTAINER" />
  </div>
)

export default TimelineContainerIcon
