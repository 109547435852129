// @flow strict

import * as React from 'react'
import { useCallback, useMemo } from 'react'

import type { Scalars } from '@graphql/server/flow'

import TooltipIcon from 'components/Form/FormTooltip/TooltipIcon'
import { Tooltip } from 'components/Tooltip'
import useUser from 'hooks/useUser'
import { useChat } from 'modules/chat/hooks/useChat'
import { isSameMinute } from 'utils/date'

import type { Messages } from '../../hooks/reducer'
import ChatMessage from '../ChatMessage'

import { BarStyle, ProgressBarStyle } from './style'

type Props = {
  messages: Messages,
}

const ChatMessages = ({ messages }: Props): React.Node => {
  const { user } = useUser()

  const { dispatch } = useChat()

  const onDeleteClick = useCallback(
    (messageGroupId: ?Scalars['ID'], messageId: Scalars['ID']) => {
      dispatch({
        type: 'SET_DELETE_MODE',
        payload: {
          ...(messageGroupId ? { messageGroupId } : {}),
          messageId,
        },
      })
    },
    [dispatch]
  )

  const onEditClick = useCallback(
    (messageGroupId: ?Scalars['ID'], messageId: Scalars['ID'], content: string) => {
      dispatch({
        type: 'SET_EDIT_MODE',
        payload: {
          ...(messageGroupId ? { messageGroupId } : {}),
          messageId,
          content,
        },
      })
    },
    [dispatch]
  )

  const updatedMessages = useMemo(() => {
    return messages.filter((message) => message.id)
  }, [messages])

  return (
    <>
      {updatedMessages.map((message, idx) => {
        const isMyMessage = message.createdBy?.id === user.id ?? ''

        const shouldShowTime =
          idx === updatedMessages.length - 1 ||
          // $FlowFixMe
          !isSameMinute(message.createdAt, updatedMessages?.[idx + 1].createdAt)

        if (message.__typename === 'Message')
          return (
            <ChatMessage
              key={message.id}
              isMyMessage={isMyMessage}
              message={message}
              showAvatar={
                idx === 0 || updatedMessages[idx - 1].createdBy?.id !== message.createdBy?.id
              }
              showTime={shouldShowTime}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )

        if (message.__typename === 'FileProgressMessage')
          return (
            <div className={ProgressBarStyle}>
              <div className={BarStyle(message.progress)} />
            </div>
          )

        if (message.__typename === 'FileUnsuccessfulMessage')
          return (
            <div style={{ display: 'flow-root' }}>
              <Tooltip message={message.error}>
                <TooltipIcon
                  type="error"
                  hasInfo={false}
                  iconProps={{ size: '2x' }}
                  style={{ margin: '2rem 3rem', float: 'right' }}
                />
              </Tooltip>
            </div>
          )
        return <></>
      })}
    </>
  )
}

export default ChatMessages
