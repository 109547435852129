// @flow strict
import { cloneDeep, set } from 'lodash'
import { Container } from 'unstated'

import type { DocumentFragment } from '@graphql/server/flow'

import { isEquals } from 'utils/fp'

type ShipmentFilesFormState = {|
  files: DocumentFragment[],
  hasCalledFilesApiYet: boolean,
|}

const initValues: ShipmentFilesFormState = {
  files: ([]: DocumentFragment[]),
  hasCalledFilesApiYet: false,
}

export default class ShipmentFilesContainer extends Container<ShipmentFilesFormState> {
  state: ShipmentFilesFormState = initValues

  originalValues: ShipmentFilesFormState = initValues

  isDirty: () => boolean = () => !isEquals(this.state, this.originalValues)

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
    this.setState(this.originalValues)
  }

  setFieldValue: <P: $Keys<ShipmentFilesFormState>>(
    path: P,
    value: ShipmentFilesFormState[P]
  ) => void = <P: $Keys<ShipmentFilesFormState>>(path: P, value: ShipmentFilesFormState[P]) => {
    if (path === 'files' && typeof value !== 'boolean') {
      const newFiles = [...value]
      newFiles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

      this.setState((prevState: ShipmentFilesFormState): ShipmentFilesFormState =>
        set(cloneDeep(prevState), path, newFiles)
      )
    } else {
      this.setState((prevState: ShipmentFilesFormState): ShipmentFilesFormState =>
        set(cloneDeep(prevState), path, value)
      )
    }
  }

  initDetailValues: (
    files: ShipmentFilesFormState['files'],
    hasCalledFilesApiYet: ShipmentFilesFormState['hasCalledFilesApiYet']
  ) => void = (files, hasCalledFilesApiYet: boolean = false) => {
    files.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

    this.setState({
      files,
      hasCalledFilesApiYet,
    })
    this.originalValues = { files, hasCalledFilesApiYet }
  }
}
