/* eslint-disable react-hooks/rules-of-hooks */
// disable hooks on this file due to that plugin think that `useWith` is a hook

import {
  compose as compose2,
  either,
  equals,
  identity,
  is,
  isNil,
  lensIndex,
  lensProp,
  map,
  path,
  pathOr,
  pick,
  range,
  set,
  split,
  take,
  useWith,
} from 'ramda'

import { Path, PathValue } from '@types'

export {
  clone,
  pipe,
  when,
  reject,
  isEmpty,
  omit,
  pick,
  head,
  contains,
  flatten,
  compose,
  uniq,
} from 'ramda'
/**
 * See if an object (val) is an instance of the supplied constructor. This function will check up the inheritance chain, if any.
 */
export const isDataType = is

/**
 * Returns true if its arguments are equivalent, false otherwise. Handles cyclical data structures.
 */
export const isEquals = equals

/**
 * Returns the first n elements of the given list
 */
export const takeItems = take

/**
 *  Returns a list of numbers from from (inclusive) to to (exclusive).
 */
export const inRange = range

export const isEither = either

export const mapOver = map

/**
 * Return value from object with path
 */
export const getByPath: <S, P extends Path<S>>(_path: P, subject: S) => PathValue<S, P> = useWith(
  path,
  [split('.')]
)

export const pickByProps = pick

/* Checks if the input value is null or undefined. */
export const isNullOrUndefined = isNil

/**
 * Return value from object with path, return default value if undefined
 */
export const getByPathWithDefault: <D, S, P extends Path<S>>(
  defaultValue: D,
  _path: P,
  subject: S
) => NonNullable<PathValue<S, P>> | D = useWith(pathOr, [identity, split('.')])

export const isValuable = (val: any) => val != null
export const isValuables = (...arr: Array<any>) => arr.every((val) => val != null)

// given a path, set the variable to a property in an object in an array
// returns a deep copy of the subject
export const setIn = (propPath: string, value: any, subject: any): any => {
  const lens = compose2(
    ...propPath
      .split('.')
      .map((key) =>
        !Number.isNaN(parseFloat(key)) ? lensIndex(parseFloat(key)) : lensProp(key as never)
      )
  )

  return set(lens, value, subject)
}

/**
 * given a property path string, update the value in path
 * @param object entity object (Shipment, Order, Batch, etc..)
 * @param newValue new follower
 * @param propertyPath 0.followers.1
 */
export const updateValueInObject = ({
  object,
  newValue,
  propertyPath,
}: {
  object: any
  newValue: any
  propertyPath: string
}) => {
  const stack = propertyPath.split('.')

  while (stack.length > 1) {
    const key = stack.shift()
    if (key) {
      object = object[key]
    }
  }

  const key = stack.shift()
  if (key && object[key]) {
    object[key] = newValue
  }
}

export const arrayToObject = (inputArray: { [k: string]: string }[], keyField: string) =>
  // @ts-ignore // TODO
  inputArray.reduce((obj, item) => ({ ...obj, [item[keyField]]: item }), {})
