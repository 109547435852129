// @flow
import { initValues as containerInitValues } from '../modules/container/form/containers/info'
import type { ContainerInfoFormState } from '../modules/container/form/containers/info'

import { injectUid } from './id'

/**
 * generate a container
 */
export const generateContainer = (): {|
  ...ContainerInfoFormState,
  id: string,
  batches: empty[],
|} =>
  injectUid({
    ...containerInitValues,
    batches: [],
  })

export default generateContainer

// Returns max volume (in m^3) based on container type
export const getMaxVolume = (containerType?: ?string): null | number => {
  switch (containerType) {
    case '22G1': {
      return 33
    }
    case '42G1': {
      return 67
    }
    case '45G1': {
      return 76
    }
    case '22R1': {
      return 27.9
    }
    case '42R1': {
      return 56.1
    }
    default: {
      return null
    }
  }
}
