// @flow
import Downshift from 'downshift'
import matchSorter from 'match-sorter'
import * as React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import type { Scalars, TagEntityType, TagFragment } from '@graphql/server/flow'
import { isForbidden, isNotFound } from '@utils/data'

import ConfirmDialog from 'components/Dialog/ConfirmDialog'
import { DefaultStyle } from 'components/Form'
import TagSelectOptions from 'components/Form/Inputs/Styles/TagSelectOptions'
import Icon from 'components/Icon'
import Tag from 'components/Tag'
import TagListProvider from 'providers/TagListProvider'

import {
  DroppableWrapperStyle,
  InputStyle,
  RemoveStyle,
  SelectionWrapperStyle,
  WrapperStyle,
} from './style'

type Props = {|
  width?: string,
  prohibitedValues?: TagFragment[],
  editable?: {
    set: boolean,
    remove: boolean,
  },
  connectionIds: $ElementType<Scalars, 'ID'[]>,
  organizationIds?: string[],
  ownedBy?: Object,
  tagType: TagEntityType,
  name: string,
  id?: string,
  values: TagFragment[],
  disabled?: boolean,
  type?: string,
  placeholder?: string,
  onChange?: Function,
  onClickRemove: (tag: Object) => void,
  shouldConfirmRemove?: (tag: Object) => boolean,
  confirmRemoveDialogMessage?: React.Node,
  onBlur?: Function,
  onFocus?: Function,
|}

type State = {
  focused: boolean,
  confirmationDialogIsOpen: boolean,
}

const defaultProps = {
  disabled: false,
  editable: {
    set: false,
    remove: false,
  },
  width: '400px',
}

export default class TagsInput extends React.Component<Props, State> {
  inputWrapperRef: {| current: null | HTMLDivElement |} = React.createRef<HTMLDivElement>()

  inputRef: {| current: null | HTMLInputElement |} = React.createRef<HTMLInputElement>()

  static defaultProps: {|
    disabled: boolean,
    editable: {| remove: boolean, set: boolean |},
    width: string,
  |} = defaultProps

  state: State = {
    focused: false,
    confirmationDialogIsOpen: false,
  }

  componentDidUpdate() {
    const { focused } = this.state
    if (focused && this.inputWrapperRef.current) {
      this.inputWrapperRef.current.scrollLeft = this.inputWrapperRef.current.scrollWidth
    }
  }

  handleChange: (tags: TagFragment[]) => void = (tags: TagFragment[]) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(tags)
    }
  }

  handleBlur: () => void = () => {
    const { name, onBlur } = this.props
    if (onBlur) {
      onBlur(name, true)
    }
  }

  handleAdd: (tag: TagFragment) => void = (tag: TagFragment) => {
    const { values } = this.props

    if (values) {
      this.handleChange([...values, tag])
    } else {
      this.handleChange([tag])
    }
  }

  handleRemove: (tag: TagFragment) => void = (tag: TagFragment) => {
    const { values } = this.props

    if (values) this.handleChange(values.filter((t) => t.id !== tag.id))
  }

  handleDownshiftChange: (selectedItem: ?TagFragment) => void = (selectedItem: ?TagFragment) => {
    const { values } = this.props

    if (selectedItem) {
      if (values && values.map((t) => t.id).includes(selectedItem.id)) {
        this.handleRemove(selectedItem)
      } else {
        this.handleAdd(selectedItem)
      }
    }
  }

  handleStateChange: (any) => void = ({ isOpen }: Object) => {
    if (isOpen === false) {
      this.handleBlur()
    }
  }

  stateReducer: (state: any, changes: any) => any = (state: Object, changes: Object) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: true,
          inputValue: '',
        }
      default:
        return changes
    }
  }

  computeFilteredTags: (tags: TagFragment[], input: ?string) => TagFragment[] = (
    tags: TagFragment[],
    input: ?string
  ): TagFragment[] => {
    return matchSorter(tags, input || '', {
      keys: ['name'],
    })
  }

  handleConfirmationDialogOpen: () => void = () => {
    this.setState((prev) => ({ ...prev, confirmationDialogIsOpen: true }))
  }

  handleConfirmationDialogClose: () => void = () => {
    this.setState((prev) => ({ ...prev, confirmationDialogIsOpen: false }))
  }

  handleInputFocus: () => void = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
    this.setState((prev) => ({ ...prev, focused: true }))
  }

  handleInputBlur: () => void = () => {
    this.setState((prev) => ({ ...prev, focused: false }))
    this.handleBlur()
  }

  render(): React.Node {
    const {
      connectionIds,
      organizationIds,
      editable,
      width,
      tagType,
      disabled,
      values,
      name,
      id,
      onClickRemove,
      shouldConfirmRemove,
      confirmRemoveDialogMessage,
      prohibitedValues,
    } = this.props
    const { focused, confirmationDialogIsOpen } = this.state

    return editable?.set ? (
      <Downshift
        itemToString={(i) => (i ? i.id : '')}
        selectedItem={null}
        onChange={this.handleDownshiftChange}
        onStateChange={this.handleStateChange}
        stateReducer={this.stateReducer}
        labelId={`${name}TagInputs`}
        onOuterClick={this.handleInputBlur}
      >
        {({
          getInputProps,
          getItemProps,
          openMenu,
          isOpen,
          inputValue,
          highlightedIndex,
          clearSelection,
          reset,
        }) => (
          <div className={WrapperStyle(focused, !!disabled, !!editable)}>
            <DefaultStyle isFocused={focused} width={width}>
              <div className={SelectionWrapperStyle}>
                <div
                  ref={this.inputWrapperRef}
                  role="presentation"
                  {...(width !== undefined && { className: InputStyle(width) })}
                  onClick={() => {
                    this.handleInputFocus()
                    openMenu()
                  }}
                >
                  <DragDropContext
                    onDragEnd={(result: any) => {
                      if (!result.destination) {
                        return
                      }
                      const sourceIndex = result.source.index
                      const destinationIndex = result.destination.index

                      const reorderedColumns = [...values]
                      const [removed] = reorderedColumns.splice(sourceIndex, 1)
                      reorderedColumns.splice(destinationIndex, 0, removed)

                      this.handleChange(reorderedColumns)
                    }}
                  >
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(dropProvided) => (
                        <div
                          ref={dropProvided.innerRef}
                          {...dropProvided.droppableProps}
                          className={DroppableWrapperStyle}
                        >
                          {(values ?? [])
                            .filter((item) => !isForbidden(item) && !isNotFound(item))
                            .map((tag, index) => (
                              <>
                                <Draggable key={tag.id} draggableId={tag.id} index={index}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Tag
                                        tag={tag}
                                        suffix={
                                          editable.remove && (
                                            <button
                                              type="button"
                                              className={RemoveStyle}
                                              onClick={(event) => {
                                                event.stopPropagation()
                                                if (shouldConfirmRemove && shouldConfirmRemove(tag))
                                                  this.handleConfirmationDialogOpen()
                                                else onClickRemove(tag)
                                              }}
                                            >
                                              <Icon icon="CLEAR" />
                                            </button>
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                                {shouldConfirmRemove && confirmRemoveDialogMessage && (
                                  <ConfirmDialog
                                    isOpen={confirmationDialogIsOpen}
                                    onRequestClose={this.handleConfirmationDialogClose}
                                    onCancel={this.handleConfirmationDialogClose}
                                    onConfirm={() => {
                                      onClickRemove(tag)
                                      this.handleConfirmationDialogClose()
                                    }}
                                    message={confirmRemoveDialogMessage}
                                  />
                                )}
                              </>
                            ))}
                          {dropProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <input
                    {...getInputProps({
                      ref: (ref) => {
                        this.inputRef = ref
                      },
                      type: 'text',
                      spellCheck: false,
                      disabled,
                      onKeyDown: (e) => {
                        switch (e.key) {
                          case 'Backspace':
                            if (!inputValue && values && values.length > 0 && !e.repeat) {
                              this.handleRemove(values[values.length - 1])
                            }
                            break
                          default:
                        }
                      },
                      onFocus: () => {
                        this.handleInputFocus()
                        openMenu()
                      },
                      onBlur: () => {
                        this.handleInputBlur()
                        reset()
                        clearSelection()
                      },
                      ...(id ? { id } : {}),
                    })}
                  />
                </div>
                {isOpen && (
                  <TagListProvider
                    queryString={inputValue}
                    tagType={tagType}
                    connectionIds={connectionIds}
                    organizationIds={organizationIds}
                  >
                    {({ data: tags, onScroll }) => {
                      return (
                        <TagSelectOptions
                          getItemProps={getItemProps}
                          items={this.computeFilteredTags(tags ?? [], inputValue).filter((tag) =>
                            prohibitedValues
                              ? !prohibitedValues.some((badTag) => tag.id === badTag.id)
                              : true
                          )}
                          onScroll={(scrollTop, event) => {
                            onScroll({ event })
                          }}
                          selectedItems={values}
                          highlightedIndex={highlightedIndex}
                          itemToString={(item) => (item ? item.description || item.name : '')}
                          itemToValue={(item) => (item ? item.description : '')}
                          width={width}
                          align="left"
                        />
                      )
                    }}
                  </TagListProvider>
                )}
              </div>
            </DefaultStyle>
          </div>
        )}
      </Downshift>
    ) : (
      <div className={SelectionWrapperStyle}>
        <div {...(width !== undefined && { className: InputStyle(width) })}>
          {values &&
            (values || [])
              .filter((item) => !isForbidden(item) && !isNotFound(item))
              .map((tag) => (
                <>
                  <Tag
                    key={tag.id}
                    tag={tag}
                    suffix={
                      editable?.remove && (
                        <button
                          type="button"
                          className={RemoveStyle}
                          onClick={(event) => {
                            event.stopPropagation()
                            if (shouldConfirmRemove && shouldConfirmRemove(tag))
                              this.handleConfirmationDialogOpen()
                            else onClickRemove(tag)
                          }}
                        >
                          <Icon icon="CLEAR" />
                        </button>
                      )
                    }
                  />
                  {shouldConfirmRemove && confirmRemoveDialogMessage && (
                    <ConfirmDialog
                      isOpen={confirmationDialogIsOpen}
                      onRequestClose={this.handleConfirmationDialogClose}
                      onCancel={this.handleConfirmationDialogClose}
                      onConfirm={() => {
                        onClickRemove(tag)
                        this.handleConfirmationDialogClose()
                      }}
                      message={confirmRemoveDialogMessage}
                    />
                  )}
                </>
              ))}
        </div>
      </div>
    )
  }
}
