// @flow
import * as React from 'react'

import {
  FieldItemWrapperStyle,
  LabelTooltipWrapperStyle,
  SubLabelStyle,
  TooltipAbsoluteWrapperStyle,
} from './style'

type Props = {
  vertical?: boolean,
  verticalGap?: string,
  tooltip?: React.Node,
  label?: React.Node,
  subLabel?: React.Node,
  tooltipTop?: boolean,
  input?: React.Node,
}

const FieldItem = ({
  vertical = false,
  verticalGap = '5px',
  tooltip,
  label,
  subLabel,
  tooltipTop = false,
  input,
  ...rest
}: Props): React.Element<'div'> => (
  <div {...rest} className={FieldItemWrapperStyle(vertical, verticalGap)}>
    {tooltip && label ? (
      <div className={LabelTooltipWrapperStyle}>
        <div className={TooltipAbsoluteWrapperStyle(tooltipTop)}>{tooltip}</div>
        {label}
        {subLabel && <span className={SubLabelStyle}>{subLabel}</span>}
      </div>
    ) : (
      <>
        {tooltip && <div className={TooltipAbsoluteWrapperStyle}>{tooltip}</div>}
        {label}
      </>
    )}
    {input}
  </div>
)

export default FieldItem
