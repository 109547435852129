// @flow

import type {
  AutoTrackingTransportType,
  PortInput,
  TransportType,
  UsersOrganizationFragment,
  PortFragment,
  VoyageFragment,
  VoyageSummaryFragment,
  Port,
  MinimalOrganizationFragment,
} from '@graphql/server/flow'

import type { VoyageIcon } from 'modules/shipment/type.js.flow'
import { getByPathWithDefault, isDataType, isNullOrUndefined } from 'utils/fp'

import type { ShipmentInfoFormState } from './form/containers/info'

export function getCarrierAutoTrackingTransportType({
  carrierInput,
}: {|
  carrierInput: { [key: AutoTrackingTransportType]: string },
|}): AutoTrackingTransportType {
  // $FlowFixMe Flow errors .find / .includes
  return Object.keys(carrierInput).find((key) => carrierInput[(key: string)])
}

export const getPortType = ({ port }: {| port?: $Shape<Port> |}): $Keys<PortInput> =>
  // $FlowFixMe Flow errors .find / .includes
  Object.keys(port).find(
    (key) =>
      key !== '__typename' &&
      port?.[(key: string)] &&
      !key.includes('Name') &&
      !key.includes('name') &&
      !key.includes('code') &&
      !key.includes('transportType')
  )

export const getSomePortCode = ({ port }: { port: PortFragment | PortInput }): string =>
  port[getPortType({ port })] || ''

export const getPortTransportationType = ({
  portType,
}: {
  portType: $Keys<PortInput>,
}): TransportType => {
  switch (portType) {
    case 'seaport':
      return 'Sea'
    case 'airport':
      return 'Air'
    case 'road':
      return 'Road'
    case 'rail':
      return 'Rail'
    case 'dryport':
      return 'Dry'
    default:
      return 'Sea'
  }
}

export const getTransportationTypePort = ({
  transportType,
}: {
  transportType: TransportType,
}): $Keys<PortInput> => {
  switch (transportType) {
    case 'Sea':
      return 'seaport'
    case 'Air':
      return 'airport'
    case 'Road':
      return 'road'
    case 'Rail':
      return 'rail'
    case 'Dry':
      return 'dryport'
    default:
      return 'seaport'
  }
}

export const getTransportationTypeIcon = ({
  transportType,
}: {
  transportType: TransportType | AutoTrackingTransportType,
}): VoyageIcon => {
  switch (transportType) {
    case 'Sea':
      return 'SHIPMENT'
    case 'Air':
      return 'PLANE'
    case 'Road':
      return 'ROAD'
    case 'Rail':
      return 'RAIL'
    case 'Dry':
      return 'DRY'
    case 'Courier':
      return 'COURIER'
    default:
      return 'UNKNOWN'
  }
}

export const getPortIcon = ({ portType }: { portType: $Keys<PortInput> }): VoyageIcon =>
  getTransportationTypeIcon({ transportType: getPortTransportationType({ portType }) })

export const getVoyageIcon = ({
  voyage,
}: {
  voyage:
    | {| departurePort: PortFragment | PortInput, arrivalPort: PortFragment | PortInput |}
    | VoyageFragment
    | VoyageSummaryFragment,
}): VoyageIcon => {
  if (!voyage.departurePort) return 'UNKNOWN'
  if (!voyage.arrivalPort) return 'UNKNOWN'
  const departurePortType = getPortType({ port: voyage.departurePort })
  // $FlowIssue: Somehow thinks that the voyage is mutated after the above operation
  const arrivalPortType = getPortType({ port: voyage.arrivalPort })

  if (!departurePortType || !arrivalPortType) return 'UNKNOWN'

  const leftIcon: VoyageIcon = getPortIcon({ portType: departurePortType })
  const rightIcon: VoyageIcon = getPortIcon({ portType: arrivalPortType })

  if (leftIcon === 'UNKNOWN' || rightIcon === 'UNKNOWN') return 'UNKNOWN'
  if (leftIcon === rightIcon && (leftIcon === 'SHIPMENT' || leftIcon === 'PLANE')) return leftIcon
  return 'LAND'
}

export const getShipmentSummary = (
  shipment: Object
): {|
  batchesOfActiveOrder: any,
  batchesOfArchivedOrder: number,
  totalBatches: any | number,
|} => {
  const totalBatches = shipment.batches ? shipment.batches.length : 0
  const batchesOfActiveOrder = (shipment.batches || []).reduce(
    (total, { orderItem }) =>
      orderItem && orderItem.order && orderItem.order.archived ? total : total + 1,
    0
  )

  return {
    totalBatches,
    batchesOfActiveOrder,
    batchesOfArchivedOrder: totalBatches - batchesOfActiveOrder,
  }
}

export const BATCHES_POOL = 'Batches_Pool'

export const isFocusedBatchesPool = (selected: string | number | null): boolean =>
  selected === BATCHES_POOL

export const isFocusedContainerCard = (selected: string | number | null): boolean =>
  isDataType(Number, selected)

export const getBatchesByContainerId = (batches: Object[], containerId: ?string): Object[] => {
  if (!containerId) return batches

  return batches
    .slice(0)
    .filter((batch) => getByPathWithDefault(null, 'container.id', batch) === containerId)
}

export const getBatchesInPool = (batches: Object[]): Object[] =>
  batches.filter((batch) => isNullOrUndefined(batch.container))

export const getAgreedArrivalDates = (containers: Object[]): Object[] =>
  containers
    .map(({ warehouseArrivalAgreedDate }) => warehouseArrivalAgreedDate)
    .filter(Boolean)
    .map((item) => new Date(item))

export const getActualArrivalDates = (containers: Object[]): Object[] =>
  containers
    .map(({ warehouseArrivalActualDate }) => warehouseArrivalActualDate)
    .filter(Boolean)
    .map((item) => new Date(item))

export const numAgreedArrivalDateApproved = (containers: Object[]): number =>
  containers
    .map(({ warehouseArrivalAgreedDateApprovedBy }) => warehouseArrivalAgreedDateApprovedBy)
    .filter(Boolean).length

export const numActualArrivalDateApproved = (containers: Object[]): number =>
  containers
    .map(({ warehouseArrivalActualDateApprovedBy }) => warehouseArrivalActualDateApprovedBy)
    .filter(Boolean).length

export const getRelatedPartners = ({
  organization,
  info,
}: {
  organization?: UsersOrganizationFragment,
  info: Partial<ShipmentInfoFormState>,
}): MinimalOrganizationFragment[] =>
  [info.importer, info.exporter, ...(info.forwarders ?? []), ...(info.organizations ?? [])]
    // Filter nulls
    .filter(Boolean)
    // Filter bad requests
    .flatMap((relatedOrg) => (relatedOrg.__typename === 'Organization' ? relatedOrg : []))
    // Filter duplicates
    .filter(
      (relatedOrg1, i, relatedOrgs) =>
        relatedOrgs.findIndex(
          (relatedOrg2) => relatedOrg1.id && relatedOrg2.id && relatedOrg2.id === relatedOrg1.id
        ) === i
    )
    // Filter user's organization
    .filter((relatedOrg) =>
      !organization ? true : !!relatedOrg.id && relatedOrg.id !== organization.id
    )
