// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'
import { useIntl } from 'react-intl'

import type {
  File,
  OrderItemsByIDsDocumentsQuery,
  OrderItemsByIDsDocumentsQueryVariables,
  OrdersByIDsDocumentsQuery,
  OrdersByIDsDocumentsQueryVariables,
  ProductProvidersByIDsDocumentsQuery,
  ProductProvidersByIDsDocumentsQueryVariables,
  ShipmentsByIDsDocumentsQuery,
  ShipmentsByIDsDocumentsQueryVariables,
  MessageGroup,
} from '@graphql/server/flow'
import { getDocumentQuery, getDocumentsFromQueryData } from '@utils/document'

import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import FormDialog from 'components/Dialog/FormDialog'
import { DocumentsUpload } from 'components/Form'
import { formatFilesToArray } from 'utils/file'

import { ParentDialogStyle } from '../../../../style'

type TData =
  | OrdersByIDsDocumentsQuery['ordersByIDs']
  | OrderItemsByIDsDocumentsQuery['orderItemsByIDs']
  | ProductProvidersByIDsDocumentsQuery['productProvidersByIDs']
  | ShipmentsByIDsDocumentsQuery['shipmentsByIDs']

type TVariables =
  | OrdersByIDsDocumentsQueryVariables
  | OrderItemsByIDsDocumentsQueryVariables
  | ProductProvidersByIDsDocumentsQueryVariables
  | ShipmentsByIDsDocumentsQueryVariables

type Props = {
  entityType: 'Order' | 'OrderItem' | 'Shipment' | 'ProductProvider',
  parent: Object,
  files: File | File[],
  isDialogOpen: boolean,
  isLoading?: boolean,
  onCancel: Function,
  onRequestClose: Function,
  onSave: Function,
}

const ParentDocumentTypeDialog = ({
  entityType,
  parent,
  files,
  isDialogOpen,
  isLoading = false,
  onCancel,
  onRequestClose,
  onSave,
}: Props): React.Node => {
  const intl = useIntl()
  const [dialogFiles, setDialogFiles] = React.useState(formatFilesToArray(files))
  const [messageGroupsValue, setMessageGroupsValue] = React.useState<MessageGroup[]>([])

  React.useEffect(() => {
    const [firstType] = getFileTypesByEntity(entityType, intl)

    setDialogFiles((_files) => {
      return _files.map((_file) => ({
        ..._file,
        type: firstType.value,
      }))
    })
  }, [entityType, intl])

  useQuery<TData, TVariables>(getDocumentQuery(entityType), {
    variables: {
      ids: [parent.id],
    },
    onCompleted: (newData) => {
      const { files: existingFiles, messageGroups } = getDocumentsFromQueryData(entityType, newData)
      setDialogFiles((df) => [...df, ...existingFiles])
      setMessageGroupsValue(messageGroups)
    },
  })

  return (
    <FormDialog
      isOpen={isDialogOpen}
      width="880px"
      onRequestClose={onRequestClose}
      onCancel={onCancel}
      isLoading={isLoading}
      noPadding
      className={ParentDialogStyle}
      onSave={() => onSave(dialogFiles)}
    >
      <DocumentsUpload
        files={dialogFiles}
        messageGroups={messageGroupsValue}
        isInDialog
        entity={entityType}
        onSave={(updatedFiles) => setDialogFiles(updatedFiles)}
        canDownload
        canChangeType
      />
    </FormDialog>
  )
}

export default ParentDocumentTypeDialog
