// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { Label } from 'components/Form'

import { ProgressStyle, UploadPlaceholderStyle } from './style'

type Props = {
  progress: number,
  height?: string,
}

export default function UploadPlaceholder({
  progress,
  height = '185px',
}: Props): React.Element<'div'> {
  return (
    <div className={UploadPlaceholderStyle(height)}>
      <div className={ProgressStyle}>{`${progress}%`}</div>

      <Label align="center">
        <FormattedMessage
          id="components.UploadPlaceholder.uploading"
          defaultMessage="Uploading..."
        />
      </Label>
    </div>
  )
}
