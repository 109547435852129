// @flow strict
import * as React from 'react'
import { cx } from 'react-emotion'

import { GridCenteredStyle, GridColumnWrapperStyle } from './style'

type Props = {
  children: React.Node,
  gap?: string,
  centered?: boolean,
  maxWidth?: string,
  className?: string,
}

function GridColumn({
  children,
  gap = '20px',
  maxWidth,
  centered,
  className,
  ...rest
}: Props): React.Element<'div'> {
  return (
    <div
      className={cx(
        GridColumnWrapperStyle(gap, maxWidth),
        { [GridCenteredStyle]: centered },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export default GridColumn
