// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Icon'

import LabelIcon from '../index'

export default function OrderLabelIcon(): React.Node {
  return (
    <LabelIcon color="ORDER">
      <FormattedMessage id="modules.RelationMap.label.order" defaultMessage="Order" />{' '}
      <Icon icon="ORDER" />
    </LabelIcon>
  )
}
