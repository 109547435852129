// @flow

import * as React from 'react'

import { AvatarStyle } from './style'

type Props = {
  name: string,
  size?: number,
}

// add image url later on
const Avatar = ({ name, size = 30 }: Props): React.Element<'div'> => {
  return <div className={AvatarStyle(size)}>{name ? name[0] : 'Z'}</div>
}

export default Avatar
