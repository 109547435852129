// @flow
import { css } from 'react-emotion'

import { colors, layout, scrollbars } from 'styles/common'

export const TimelineWrapperStyle: any = css`
  ${layout.VERTICAL};
  background-color: ${colors.WHITE};
  height: 100%;
  width: 100%;
`

export const ListWrapperStyle: any = css`
  ${scrollbars.MAIN};
  ${layout.VERTICAL};
  ${layout.CENTER};
  overflow-x: hidden;
  overflow-y: overlay;
  width: 100%;
  flex: 1;
`

export const CommentInputWrapperStyle: any = css`
  padding: 0 100px;
`
