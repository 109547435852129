// @flow

// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'

import imageFragment from '@graphql/client/common/fragment.image.graphql'
import badRequestFragment from '@graphql/client/errors/fragment.badRequest.graphql'
import forbiddenFragment from '@graphql/client/errors/fragment.forbidden.graphql'

const fileUploadMutation: any = gql`
  mutation fileUpload($file: Upload!, $input: FileInput!) {
    fileUpload(file: $file, input: $input) {
      ...Image
      ...BadRequest
      ...Forbidden
    }
  }

  ${imageFragment}
  ${badRequestFragment}
  ${forbiddenFragment}
`

export default fileUploadMutation
