// @flow
import { css } from 'react-emotion'

export const HorizontalPortsWrapperStyle: string = css`
  display: flex;
  width: 100%;
`

export const PortNameWrapperStyle: string = css`
  display: flex;
  flex: 1;
  justify-content: center;
  min-width: 95px;
`

export const WarehouseNameWrapperStyle: string = css`
  display: flex;
  flex: 1.89;
  justify-content: center;
`

export const BlankSpaceStyle: string = css`
  flex: 1;
  min-width: 95px;
`
