// @flow
import * as React from 'react'

import Icon from 'components/Icon'

import { IconStyle } from './style'

type OptionalProps = {
  disabled: boolean,
  readOnly: boolean,
  selectable: boolean,
  selected: boolean,
  invert: boolean,
  flatten: boolean,
}

type Props = OptionalProps & {
  icon: string,
  preferIcon: boolean,
  color: string,
  onClick: () => void,
}

const defaultProps = {
  disabled: false,
  readOnly: false,
  selectable: false,
  selected: false,
  invert: false,
  flatten: false,
}

const getIcon = (disabled, selectable, selected, icon, preferIcon: boolean) => {
  if (disabled) return 'DISABLED'
  if (preferIcon === true) return icon
  if (selectable && selected) return 'CHECKED'
  if (selectable && !selected) return 'UNCHECKED'
  return icon
}

// $FlowFixMe it is an open issue on flow https://github.com/facebook/flow/issues/6103
const CornerIcon: React$AbstractComponent<Props, HTMLButtonElement> = React.forwardRef(
  (
    {
      icon,
      preferIcon,
      color,
      disabled,
      readOnly,
      selectable,
      selected,
      onClick,
      invert,
      flatten,
    }: Props,
    ref
  ) => {
    const iconToShow = getIcon(disabled, selectable, selected, icon, preferIcon)

    return (
      <button
        className={IconStyle(color, disabled, readOnly, invert, flatten)}
        type="button"
        onClick={onClick}
        ref={ref}
      >
        <Icon icon={iconToShow} />
      </button>
    )
  }
)

// $FlowFixMe: ignore for now until flow fix this issue with forwardRef
CornerIcon.defaultProps = defaultProps

export default CornerIcon
