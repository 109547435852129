// @flow

import * as React from 'react'
import { useDrop } from 'react-dnd'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import type { FilePayload } from '@graphql/server/flow'
import { isForbidden } from '@utils/data'

// TODO: Use dynamic external/internal permissions
// eslint-disable-next-line no-unused-vars
import { BaseButton } from 'components/Buttons'
import { CardAction, DocumentCard } from 'components/Cards'
import { Label } from 'components/Form'
import FormattedNumber from 'components/FormattedNumber'
import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import SlideView from 'components/SlideView'
import UploadPlaceholder from 'components/UploadPlaceholder'
// TODO: Use dynamic external/internal permissions
// eslint-disable-next-line no-unused-vars
import { useHasPermissions, useViewerHasPermissions } from 'contexts/Permissions'
import DocumentFormSlideView from 'modules/document/index.formSlideView'
import type { UploadingFile } from 'types'
// TODO: Use dynamic external/internal permissions
// eslint-disable-next-line no-unused-vars
import entityHasConnection from 'utils/entityHasConnection'
import { canViewFile } from 'utils/file'
import { uuid } from 'utils/id'

import DocumentDragWrapper from './DocumentDragWrapper'
import DocumentsSelector from './DocumentsSelector'
import {
  AddDocumentButtonIconStyle,
  AddDocumentButtonLabelStyle,
  AddDocumentButtonWrapperStyle,
  DocumentTypeAreaBodyStyle,
  DocumentTypeAreaHeaderStyle,
  DocumentTypeAreaWrapperStyle,
} from './style'

type Props = {|
  entityType: string,
  entityOwnedById: ?string,
  entityConnectionById: ?string,
  type: { value: string, label: React$Node },
  types: string[],
  allFiles: FilePayload[],
  files: FilePayload | UploadingFile[],
  fileErrors: { [fileID: string]: string },
  filesForHighlight: FilePayload[],
  areaHighlight: boolean,
  scrollOnFileHighlight: boolean,
  onSave: Function,
  onUpload: Function,
  canUpload: boolean,
  canAddOrphan: boolean,
  canViewForm: boolean,
  canDownload: boolean,
  canChangeType: boolean,
  canDelete: boolean,
  isMultiSelect: boolean,
  selectedFiles: Object,
  onDocumentClicked: (file: Object) => void,
|}

const DocumentTypeArea = ({
  entityType,
  // TODO: Use dynamic external/internal permissions
  // eslint-disable-next-line no-unused-vars
  entityOwnedById,
  // TODO: Use dynamic external/internal permissions
  // eslint-disable-next-line no-unused-vars
  entityConnectionById,
  type,
  types,
  allFiles,
  files,
  fileErrors,
  areaHighlight,
  filesForHighlight,
  scrollOnFileHighlight,
  onSave,
  onUpload,
  canUpload,
  canAddOrphan,
  canViewForm,
  canDownload,
  canChangeType,
  canDelete,
  isMultiSelect,
  selectedFiles,
  onDocumentClicked,
}: Props): React.Node => {
  // TODO: Use dynamic external/internal permissions
  // const hasPermissions = useHasPermissions(
  //   entityOwnedById,
  //   entityConnectionById,
  //   entityHasConnection(entityType)
  // )
  // $FlowFixMe: flow cant convert string to literal https://github.com/facebook/flow/issues/2639
  const hasPermissions = useViewerHasPermissions()

  const canView = canViewFile(hasPermissions, type.value, entityType)
  const otherTypes = types.filter((t) => t !== type)
  const labelRef = React.useRef<HTMLDivElement | null>(null)

  const [{ isDraggedOver, canDrop }, dropRef] = useDrop({
    accept: otherTypes,
    canDrop: (item) => {
      return canUpload && item.type !== type.value
    },
    drop: (item) => ({ ...item, type: type.value }),
    collect: (monitor) => ({
      isDraggedOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const fileToScrollTo = React.useMemo(() => {
    if (scrollOnFileHighlight) {
      const fileHighlightIds = filesForHighlight
        .map((file) => (file.id ? file?.id : ''))
        .filter(Boolean)
      return files.find((file) => (file.id ? fileHighlightIds.includes(file.id) : false))
    }

    return {}
  }, [filesForHighlight, files, scrollOnFileHighlight])

  return (
    <Dropzone onDrop={onUpload}>
      {({ getRootProps: dropZoneProps, isDragActive: isDraggingFilesOver }) => (
        <div {...dropZoneProps()}>
          <div
            className={DocumentTypeAreaWrapperStyle({
              isDraggedOver:
                (isDraggedOver && canDrop && canView) ||
                (canView && canUpload && isDraggingFilesOver),
              highlight: areaHighlight,
            })}
            ref={dropRef}
          >
            <div ref={labelRef} className={DocumentTypeAreaHeaderStyle(files.length > 0)}>
              <Label>
                {type.label}
                {' ('}
                <FormattedNumber value={files.length} />)
              </Label>

              <GridRow gap="5px">
                {canView && canAddOrphan && (
                  <BooleanValue>
                    {({ value: documentsSelectorIsOpen, set: setDocumentsSelectorIsOpen }) => (
                      <>
                        <BaseButton
                          label={
                            <FormattedMessage
                              id="modules.Documents.selectDocument"
                              defaultMessage="Select Documents"
                            />
                          }
                          icon="ADD"
                          onClick={() => setDocumentsSelectorIsOpen(true)}
                          textColor="WHITE"
                          hoverTextColor="WHITE"
                          backgroundColor="GRAY_LIGHT"
                          hoverBackgroundColor="GRAY"
                        />

                        <SlideView
                          isOpen={documentsSelectorIsOpen}
                          onRequestClose={() => setDocumentsSelectorIsOpen(false)}
                          shouldConfirm={() => {
                            const button = document.getElementById('saveButtonOnSelectDocuments')
                            return button
                          }}
                        >
                          <DocumentsSelector
                            onCancel={() => setDocumentsSelectorIsOpen(false)}
                            onSelect={(newSelectedFiles) => {
                              onSave([
                                ...files,
                                ...newSelectedFiles.map((file) => ({
                                  ...file,
                                  type: type.value,
                                  entity: { __typename: entityType },
                                })),
                              ])
                              setDocumentsSelectorIsOpen(false)
                            }}
                            alreadyAddedDocuments={allFiles}
                          />
                        </SlideView>
                      </>
                    )}
                  </BooleanValue>
                )}

                {canUpload && canView && (
                  <label className={AddDocumentButtonWrapperStyle}>
                    <div className={AddDocumentButtonLabelStyle}>
                      <FormattedMessage
                        id="documents.button.uploadDocuments"
                        defaultMessage="Upload Documents"
                      />
                    </div>
                    <div className={AddDocumentButtonIconStyle}>
                      <Icon icon="UPLOAD" />
                    </div>
                    <input type="file" accept="*" hidden multiple value="" onChange={onUpload} />
                  </label>
                )}
              </GridRow>
            </div>
            {canView && files.length > 0 && (
              <div className={DocumentTypeAreaBodyStyle}>
                {files
                  .filter((file) => !isForbidden(file))
                  .map((file) =>
                    file.uploading ? (
                      <UploadPlaceholder
                        progress={file.progress ?? 0}
                        height="134px"
                        key={file.id}
                      />
                    ) : (
                      <BooleanValue key={file.id || uuid()}>
                        {({ value: documentFormIsOpen, set: setDocumentFormIsOpen }) => (
                          <>
                            <DocumentDragWrapper
                              item={{
                                id: file.id || '',
                                type: type.value,
                              }}
                              canChangeType={canChangeType}
                              onChangeType={(item) => {
                                onSave(
                                  files.map((f) =>
                                    f.id === item.id ? { ...f, type: item.type } : f
                                  ),
                                  item
                                )
                              }}
                            >
                              <DocumentCard
                                file={file}
                                error={fileErrors[file.id ?? '']}
                                hideParentInfo
                                downloadable={canDownload}
                                highlight={filesForHighlight.some(
                                  (fileToHighlight) =>
                                    fileToHighlight.id && file.id && fileToHighlight.id === file.id
                                )}
                                scrollIntoView={
                                  fileToScrollTo?.id && file.id && fileToScrollTo?.id === file.id
                                }
                                onClick={(evt) => {
                                  evt.stopPropagation()
                                  if (isMultiSelect) {
                                    onDocumentClicked(file)
                                  } else if (canViewForm) {
                                    setDocumentFormIsOpen(true)
                                  }
                                }}
                                selectable={isMultiSelect}
                                selected={file.id ? selectedFiles[file.id] : false}
                                showActionsOnHover
                                actions={[
                                  canDelete && (
                                    <CardAction
                                      icon="REMOVE_ALT"
                                      hoverColor="RED"
                                      onClick={(evt) => {
                                        evt.stopPropagation()
                                        onSave(
                                          files.filter(
                                            (item) => item.id && file.id && item.id !== file.id
                                          )
                                        )
                                      }}
                                    />
                                  ),
                                ].filter(Boolean)}
                              />
                            </DocumentDragWrapper>

                            <SlideView
                              isOpen={documentFormIsOpen}
                              onRequestClose={() => setDocumentFormIsOpen(false)}
                              shouldConfirm={() => {
                                const button = document.getElementById('document_form_save_button')
                                return button
                              }}
                            >
                              <DocumentFormSlideView
                                // $FlowFixMe: Temporary value
                                isNew={file.isNew}
                                // $FlowFixMe: Adds uploading file exemption
                                file={file}
                                onSave={(updatedFile) => {
                                  onSave(
                                    files.map((f) => (f.id === updatedFile.id ? updatedFile : f))
                                  )
                                  setDocumentFormIsOpen(false)
                                }}
                              />
                            </SlideView>
                          </>
                        )}
                      </BooleanValue>
                    )
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default DocumentTypeArea
