import { css } from 'react-emotion'

import { connectionColors, borderRadiuses, colors, fontSizes } from '@styles/common'
import { ConnectionColor } from '@types'

export const AvatarStyle = ({ color }: { color: ConnectionColor }): string => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.BLACK};
  width: 15px;
  height: 15px;
  border-radius: ${borderRadiuses.CIRCLE};
  font-size: ${fontSizes.TINY};
  background-color: ${connectionColors[color]};
  user-select: none;
`

export default AvatarStyle
