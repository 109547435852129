// @flow strict
import { useLazyQuery } from '@apollo/client'
import { useCallback, useRef, useState } from 'react'

import customFieldsQuery from '@graphql/client/metadata/query.customFields.graphql'
import type {
  CustomizableEntityType,
  FieldDefinitionFragment,
  CustomFieldsQuery,
  CustomFieldsQueryVariables,
  Scalars,
} from '@graphql/server/flow'
import { CustomizableEntityTypeValues } from '@graphql/server/flow'

export type FieldDefinitionsMemo = {|
  [CustomizableEntityType]: {|
    fieldDefinitions: FieldDefinitionFragment[],
    page: number,
    hasMore: boolean,
  |},
|}

const perPage = 10

export default function useCustomFieldsLazy(): {
  data: FieldDefinitionsMemo,
  loadMore: ({
    entityType: CustomizableEntityType,
    excludeIds?: Scalars['ID'][],
    onCompleted?: (res: FieldDefinitionsMemo) => void,
  }) => void,
  loading: boolean,
} {
  const currentEntityRef = useRef<CustomizableEntityType>('Shipment')
  const isFetchingRef = useRef<boolean>(false)

  const [data, setData] = useState<FieldDefinitionsMemo>(
    Object.keys(CustomizableEntityTypeValues).reduce(
      (memo, val) => ({
        ...memo,
        [(val: string)]: { fieldDefinitions: [], page: 0, hasMore: true },
      }),
      {}
    )
  )

  const onCompleted = (res: CustomFieldsQuery): FieldDefinitionsMemo => {
    isFetchingRef.current = false
    const { nodes, page, totalPage } = res.customFields
    const fieldDefinitions = nodes.flatMap((n) => (n.__typename === 'FieldDefinition' ? n : []))
    const hasMore = page < totalPage

    const isPureEntityType = fieldDefinitions.every(
      (fd) => fd.entityType === fieldDefinitions[0].entityType
    )
    if (!isPureEntityType) {
      throw new Error('This hook should be used with only one entity type per request')
    }

    let ret: FieldDefinitionsMemo = {}

    setData((prev) => {
      ret = {
        ...prev,
        [(currentEntityRef.current: string)]: {
          fieldDefinitions,
          page,
          hasMore,
        },
      }
      return ret
    })

    return ret
  }

  const [loadCustomFields, { loading }] = useLazyQuery<
    CustomFieldsQuery,
    CustomFieldsQueryVariables
  >(customFieldsQuery, {
    onCompleted,
  })

  const loadMore = useCallback(
    ({
      entityType,
      excludeIds,
      onCompleted: onCompletedCallback,
    }: {
      entityType: CustomizableEntityType,
      excludeIds?: Scalars['ID'][],
      onCompleted?: (res: FieldDefinitionsMemo) => void,
    }) => {
      if (data[entityType].hasMore) {
        currentEntityRef.current = entityType
        isFetchingRef.current = true
        loadCustomFields({
          variables: {
            filterBy: {
              entityTypes: [entityType],
              excludeIds,
            },
            page: (data[entityType].page ?? 0) + 1,
            perPage,
          },
          onCompleted: (res: CustomFieldsQuery) => {
            if (onCompletedCallback) {
              onCompletedCallback(onCompleted(res))
            } else {
              onCompleted(res)
            }
          },
        })
      }
    },
    [data, loadCustomFields]
  )

  return {
    data,
    loadMore,
    loading: loading || isFetchingRef.current === true,
  }
}
