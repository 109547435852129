// @flow strict

import { navigate } from '@reach/router'

import type { NotificationFragment } from '@graphql/server/flow'

import ENTITIES_WITH_NEW_TABLE from 'config/table/entitiesWithNewTable'
import { autonomousNotificationTypes } from 'modules/notifications/constants'
import type { View } from 'modules/notifications/types'
import { parseRoute } from 'utils/entity'
import { encodeId } from 'utils/id'

import type { Entity } from '../types'

export function notificationParseView({
  notification,
}: {
  notification: NotificationFragment,
}): View {
  if (
    autonomousNotificationTypes.includes(notification.type) &&
    notification.reminder?.__typename === 'Reminder'
  ) {
    return notification.reminder.section.view
  }
  return 'Form'
}

export function notificationParseUrl({
  notification,
  view = 'Form',
  next = false,
}: {
  notification: NotificationFragment,
  view?: View,
  next?: boolean,
}): string {
  let entityId = notification.entity.id
  let entityTypeName: Entity['__typename'] | 'Document' = notification.entity.__typename
  const suffix =
    autonomousNotificationTypes.includes(notification.type) || view === 'Table'
      ? `?notificationId=${notification.id}`
      : ''

  if (entityId === undefined) return '.'

  if (
    notification.entity.__typename === 'ProductProvider' &&
    notification.entity.product.__typename === 'Product'
  ) {
    entityTypeName = 'Product'
    entityId = notification.entity.product.id
  } else if (notification.entity.__typename === 'File') {
    entityTypeName = 'Document'
  }

  return `/${next === true ? 'new/shipment' : parseRoute(entityTypeName).toLowerCase()}/${
    view === 'Table' ? view.toLowerCase() : encodeId(entityId)
  }${suffix}`
}

export function notificationRedirect({
  notification,
  view,
}: {
  notification: NotificationFragment,
  view: View,
}) {
  const next = ENTITIES_WITH_NEW_TABLE.includes(notification.entity.__typename) && view === 'Table'
  const url = notificationParseUrl({ notification, view, next })
  if (!next) navigate(url)
  else {
    window.location.assign(url)
  }
}

export default notificationRedirect
