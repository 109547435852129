// @flow
import * as React from 'react'

import SelectOrderItems from 'providers/SelectOrderItems'

import { SelectOrders, SelectProducts, SelectShipments } from './components'

type Props = {
  type: string,
  onCancel: Function,
  onSelect: Function,
  isLoading?: boolean,
}

const ParentSelectList = ({ type, ...rest }: Props): null | React.Node => {
  switch (type) {
    case 'Order':
      return <SelectOrders {...rest} />
    case 'OrderItem':
      return (
        <SelectOrderItems {...rest} saveOnSelect singleSelection isSubContent disableIncrement />
      )
    case 'Shipment':
      return <SelectShipments {...rest} />
    case 'ProductProvider':
      return <SelectProducts {...rest} />
    default:
  }

  return null
}

export default ParentSelectList
