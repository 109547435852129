// @flow
import { css } from 'react-emotion'

export const ParentDialogStyle: string = css`
  padding: 0;
`

export const ParentNavbarLabelStyle: string = css`
  font-weight: bold;
  margin-left: 20px;
`
