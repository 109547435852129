// @flow
import { cloneDeep, set, unset } from 'lodash'
import { Container } from 'unstated'

import type {
  ContainerGroupPayload,
  PartnerPayload,
  TimelineDatePayload,
  VoyagePayload,
  WarehousePayload,
} from '@graphql/server/flow'
import { removeNulls } from '@utils/data'
import { initDatetimeToContainerForShipmentTimeline } from '@utils/shipment'

import emitter from 'utils/emitter'
import { getByPath, isEquals } from 'utils/fp'

type FormState = {|
  cargoReady: TimelineDatePayload,
  cargoReadyWarehouse: WarehousePayload,
  containerGroups: ContainerGroupPayload[],
  voyages: VoyagePayload[],
  hasCalledTimelineApiYet: boolean,
|}

export const initValues: FormState = {
  cargoReady: {},
  cargoReadyWarehouse: null,
  containerGroups: [{}],
  voyages: [{}],
  hasCalledTimelineApiYet: false,
}

const removeOldImporterUser = ({
  entity,
  field,
  partner,
}: {
  entity: TimelineDatePayload,
  field: string,
  partner: PartnerPayload,
}) => {
  if (Object.keys(entity || {}).length < 1) {
    return {}
  }

  return {
    [field]: {
      ...entity,
      approvedAt:
        getByPath('approvedBy.organization.id', entity) === getByPath('id', partner)
          ? null
          : getByPath('approvedAt', entity),
      approvedBy:
        getByPath('approvedBy.organization.id', entity) === getByPath('id', partner)
          ? null
          : getByPath('approvedBy', entity),
    },
  }
}

export default class ShipmentTimelineContainer extends Container<FormState> {
  state: FormState = initValues

  originalValues:
    | any
    | FormState
    | {|
        cargoReady: TimelineDatePayload,
        cargoReadyWarehouse: WarehousePayload,
        containerGroups: ContainerGroupPayload[],
        hasCalledTimelineApiYet: boolean,
        voyages: VoyagePayload[],
      |} = initValues

  isDirty: () => boolean = () => !isEquals(this.state, this.originalValues)

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
    this.setState(this.originalValues)
  }

  setFieldDeepValue: (path: string, value: any) => void = (path: string, value: any) => {
    this.setState((prevState) => {
      const cloneState = cloneDeep(prevState)
      set(cloneState, path, value)
      return cloneState
    })

    if (path.toLowerCase().includes('date')) {
      setTimeout(() => {
        emitter.emit('AUTO_DATE')
      }, 200)
    }
  }

  removeArrayItem: (path: string) => void = (path: string) => {
    this.setState((prevState) => {
      const cloneState = cloneDeep(prevState)
      unset(cloneState, path)
      if (path.toLowerCase().includes('date')) {
        setTimeout(() => {
          emitter.emit('AUTO_DATE')
        }, 200)
      }
      return removeNulls(cloneState)
    })
  }

  initDetailValues: (
    values: {|
      cargoReady: TimelineDatePayload,
      cargoReadyWarehouse: WarehousePayload,
      containerGroups: ContainerGroupPayload[],
      voyages: VoyagePayload[],
    |},
    hasCalledTimelineApiYet?: boolean,
    timezone: string
  ) => void = (
    values: {|
      cargoReady: TimelineDatePayload,
      cargoReadyWarehouse: WarehousePayload,
      containerGroups: ContainerGroupPayload[],
      voyages: VoyagePayload[],
    |},
    hasCalledTimelineApiYet: boolean = false,
    timezone: string
  ) => {
    const { cargoReady, cargoReadyWarehouse, containerGroups, voyages } = values
    const { customClearance, warehouseArrival, deliveryReady } = containerGroups[0]

    const timelineValues = {
      ...initDatetimeToContainerForShipmentTimeline(cargoReady, 'cargoReady', timezone),
      cargoReadyWarehouse,
      containerGroups: [
        {
          ...containerGroups[0],
          ...initDatetimeToContainerForShipmentTimeline(
            customClearance,
            'customClearance',
            timezone
          ),
          ...initDatetimeToContainerForShipmentTimeline(
            warehouseArrival,
            'warehouseArrival',
            timezone
          ),
          ...initDatetimeToContainerForShipmentTimeline(deliveryReady, 'deliveryReady', timezone),
        },
      ],
      voyages:
        voyages?.length > 0
          ? voyages.map((voyage) => ({
              ...voyage,
              ...initDatetimeToContainerForShipmentTimeline(
                voyage.departure,
                'departure',
                timezone
              ),
              ...initDatetimeToContainerForShipmentTimeline(voyage.arrival, 'arrival', timezone),
            }))
          : [{}],
    }

    const parsedValues = { ...initValues, ...timelineValues, hasCalledTimelineApiYet }

    this.setState(parsedValues)
    this.originalValues = { ...parsedValues }
  }

  onChangePartner: (partner: PartnerPayload) => void = (partner: PartnerPayload) => {
    const { cargoReady, containerGroups, voyages } = this.state
    this.setState({
      ...(Object.keys(cargoReady).length > 0
        ? removeOldImporterUser({
            entity: cargoReady,
            field: 'cargoReady',
            partner,
          })
        : { cargoReady }),
      containerGroups: containerGroups.map((group) =>
        Object.keys(group).length > 0
          ? {
              ...group,
              ...removeOldImporterUser({
                entity: getByPath('customClearance', group),
                field: 'customClearance',
                partner,
              }),
              ...removeOldImporterUser({
                entity: getByPath('deliveryReady', group),
                field: 'deliveryReady',
                partner,
              }),
              ...removeOldImporterUser({
                entity: getByPath('warehouseArrival', group),
                field: 'warehouseArrival',
                partner,
              }),
            }
          : group
      ),
      voyages:
        voyages.length === 0
          ? [{}]
          : voyages.map((voyage) =>
              Object.keys(voyage).length > 0
                ? {
                    ...voyage,
                    ...removeOldImporterUser({
                      entity: getByPath('arrival', voyage),
                      field: 'arrival',
                      partner,
                    }),
                    ...removeOldImporterUser({
                      entity: getByPath('departure', voyage),
                      field: 'departure',
                      partner,
                    }),
                  }
                : voyage
            ),
    })
  }
}
