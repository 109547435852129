// @flow

import { useMutation } from '@apollo/client'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import Dropzone from 'react-dropzone'

import fileUploadMutation from '@graphql/client/document/mutation.fileUpload.graphql'
import type {
  FileType,
  MutationFileUploadArgs,
  MessageFragment,
  FileUploadMutation,
  FileUploadMutationVariables,
} from '@graphql/server/flow'

import LoadingIcon from 'components/LoadingIcon'
import useUser from 'hooks/useUser'
import { useChat } from 'modules/chat/hooks/useChat'
import {
  ChatDetailSectionStyle,
  ChatMessagesContainerStyle,
  LoadingIconContainerStyle,
} from 'modules/chat/style'
import type { $ReturnType } from 'types'
import { uuid } from 'utils/id'
import { eventFilesToLocalFiles } from 'utils/typeConversions/eventFilesToLocalFiles'

import type { LocalFile } from '../../../../types/file'
import type { Messages } from '../../hooks/reducer'
import type { FileProgressMessage, FileUnsuccessfulMessage } from '../../type'
import ChatInput from '../ChatInput'
import ChatMessages from '../ChatMessages'
import MessageDeleteDialog from '../MessageDeleteDialog'

import { ChatAreaWrapperStyle } from './style'

const ChatDetailSection = (): React.Node => {
  const {
    state: { messages },
    dispatch,
    entityType,
    entityId,
    selectedGroupId,
    onLoadMore,
    page,
    totalPage,
    isLoadingMessages,
  } = useChat()

  const { user, organization } = useUser()
  const [fileUpload] = useMutation<FileUploadMutation, FileUploadMutationVariables>(
    fileUploadMutation
  )

  const messagesRef = React.useRef<Messages>(messages)
  const messageContainerRef = React.useRef<?HTMLDivElement>(null)
  const heightRef = React.useRef(0)
  const loadingRef = React.useRef(true)
  const [stickToBottom, setStickToBottom] = React.useState(true)
  const hasMore = page < totalPage

  // Reset loading state when switching conversations
  useEffect(() => {
    loadingRef.current = true
  }, [selectedGroupId])

  // for scrolling to the bottom of the messages on load or on message creation
  useEffect(() => {
    if (!messages.length) {
      setStickToBottom(true)
      return
    }

    if (isLoadingMessages) {
      return
    }

    if (messageContainerRef.current) {
      if (stickToBottom) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight

        // if the first message changes then means that new messages are prepended
        // $FlowIssue I don't know why Flow assumes all arrays as populated...
      } else if (messages[0].id !== messagesRef.current[0]?.id) {
        const { scrollHeight, offsetHeight } = messageContainerRef.current

        if (scrollHeight - offsetHeight !== heightRef.current) {
          messageContainerRef.current.scrollTop = scrollHeight - offsetHeight - heightRef.current
        }

        messagesRef.current = messages
      }
    }

    loadingRef.current = false
  }, [messages, stickToBottom, isLoadingMessages])

  const handleScroll: HTMLElement['onscroll'] = (event) => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target

    const threshold = 50

    heightRef.current = scrollHeight - offsetHeight

    if (scrollTop < threshold && hasMore && loadingRef.current === false) {
      loadingRef.current = true
      onLoadMore()
      return
    }

    const shouldStickToBottom = Math.floor(scrollTop) === scrollHeight - offsetHeight

    if (shouldStickToBottom && messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
    }

    setStickToBottom(shouldStickToBottom)
  }

  const [{ isDraggedOver, canDrop }, dropRef] = useDrop({
    accept: [],
    canDrop: () => false,
    drop: (item) => item,
    collect: (monitor) => ({
      isDraggedOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const onUploadFiles = (files: LocalFile[], fileType: ?FileType) => {
    const fileUploadInputs: MutationFileUploadArgs[] = files.map((file) => ({
      file: file.file,
      input: {
        type: fileType,
        messageGroupId: selectedGroupId || undefined, // allPartners case should not send messageGroupId
        entity: {
          [`${entityType.toLowerCase()}Id`]: entityId,
        },
      },
    }))

    fileUploadInputs.forEach((payload) => {
      const placeholderFileProgressMessage: FileProgressMessage = {
        __typename: 'FileProgressMessage',
        id: uuid(),
        createdBy: {
          ...user,
          organization,
        },
        uploading: true,
        progress: 0,
        isNew: true,
      }
      dispatch({
        type: 'SET_MESSAGES',
        payload: {
          setMessages: (oldMessages) => [...oldMessages, placeholderFileProgressMessage],
        },
      })
      fileUpload({
        variables: {
          ...payload,
        },
        context: ({
          fetchOptions: {
            onProgress: (progressEvent: ProgressEvent) => {
              const { lengthComputable, loaded, total } = progressEvent
              if (lengthComputable) {
                dispatch({
                  type: 'SET_MESSAGES',
                  payload: {
                    setMessages: (oldMessages) =>
                      oldMessages.map((oldMessage) =>
                        oldMessage.id === placeholderFileProgressMessage.id &&
                        oldMessage.__typename === 'FileProgressMessage'
                          ? { ...oldMessage, progress: Math.round((loaded / total) * 100) }
                          : oldMessage
                      ),
                  },
                })
              }
            },
          },
        }: any),
      }).then((queryResult) => {
        if (!queryResult.data) return
        const { fileUpload: uploadedFile } = queryResult.data
        if (!uploadedFile) return

        let newMessage: MessageFragment | FileUnsuccessfulMessage
        const newMessageID = uuid()

        if (uploadedFile.__typename === 'BadRequest') {
          newMessage = {
            __typename: 'FileUnsuccessfulMessage',
            id: newMessageID,
            error: uploadedFile.violations[0].message ?? '',
          }
        } else if (uploadedFile.__typename === 'File') {
          newMessage = {
            __typename: 'Message',
            id: newMessageID,
            messageEmoji: [],
            content: '',
            createdAt: uploadedFile.createdAt,
            createdBy: {
              ...(({
                __typename,
                id,
                firstName,
                lastName,
                email,
              }: $ReturnType<typeof useUser>['user']) => ({
                __typename,
                id,
                firstName,
                lastName,
                email,
              }))(user),
              organization: (({
                __typename,
                id,
                name,
              }: $ReturnType<typeof useUser>['organization']) => ({
                __typename,
                id,
                name,
              }))(organization),
            },
            file: uploadedFile,
            updatedAt: uploadedFile.createdAt,
          }
        }
        if (newMessage) {
          dispatch({
            type: 'SET_MESSAGES',
            payload: {
              setMessages: (oldMessages) => [
                ...oldMessages.filter(
                  (oldMessage) => oldMessage.id !== placeholderFileProgressMessage.id
                ),
                newMessage,
              ],
            },
          })
        }
      })
    })
  }

  const [fileType, setFileType] = useState<FileType | void>()
  const [localFiles, setLocalFiles] = useState<LocalFile[]>([])

  const resetFiles = (): void => {
    setFileType()
    setLocalFiles([])
  }

  return (
    <Dropzone
      onDrop={(files) =>
        setLocalFiles((old) => [...old, ...eventFilesToLocalFiles(files, entityType)])
      }
    >
      {({ getRootProps: dropZoneProps, isDragActive: isDraggingFilesOver }) => (
        <div {...dropZoneProps()} style={{ height: '100%' }}>
          <div
            className={ChatAreaWrapperStyle({
              isDraggedOver: (isDraggedOver && canDrop) || isDraggingFilesOver,
            })}
            ref={dropRef}
          >
            <div className={ChatDetailSectionStyle}>
              <div
                ref={messageContainerRef}
                className={ChatMessagesContainerStyle}
                onScroll={handleScroll}
              >
                {page < totalPage && (
                  <div className={LoadingIconContainerStyle}>
                    <LoadingIcon key="loading_icon" size={26} />
                  </div>
                )}
                <ChatMessages messages={messages} />
              </div>
              <div>
                <ChatInput
                  fileType={fileType}
                  localFiles={localFiles}
                  setLocalFiles={setLocalFiles}
                  onUploadFiles={onUploadFiles}
                  resetFiles={resetFiles}
                  setFileType={setFileType}
                />
              </div>
              <MessageDeleteDialog />
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default ChatDetailSection
