// @flow
import * as React from 'react'

import type {
  CommentFragment,
  EventFragment,
  BatchTimelineQuery,
  ContainerTimelineQuery,
  OrderItemTimelineQueryVariables,
  OrderTimelineQuery,
  ProductProviderTimelineQuery,
  ShipmentTimelineQuery,
  ContainerTimelineQueryVariables,
  OrderItemTimelineQuery,
  ShipmentTimelineQueryVariables,
  BatchTimelineQueryVariables,
  OrderTimelineQueryVariables,
  ProductProviderTimelineQueryVariables,
} from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import GridView from 'components/GridView'
import { useViewerHasPermissions } from 'contexts/Permissions'
import getDefaultFormatters, { type LogFormatter } from 'modules/timeline/formatters'
import type { Payload } from 'types'

import DateSeparator from '../DateSeparator'
import Log from '../Log'

import { ROW_HEIGHT } from './constants'
import type { Item } from './helper'
import { decorateEntries, filterEntries, normalizeEntries } from './helper'
import { ListWrapperStyle, TimelineWrapperStyle } from './style'

type Props<TVariable> = {|
  query: GraphQL$DocumentNode,
  queryField: string,
  variables: TVariable,
  formatters?: { [key: string]: LogFormatter },
|}

const Timeline = <
  TData:
    | ShipmentTimelineQuery
    | ContainerTimelineQuery
    | BatchTimelineQuery
    | OrderItemTimelineQuery
    | OrderTimelineQuery
    | ProductProviderTimelineQuery,
  TVariable:
    | $Diff<ShipmentTimelineQueryVariables, { page: number, perPage: number }>
    | $Diff<ContainerTimelineQueryVariables, { page: number, perPage: number }>
    | $Diff<BatchTimelineQueryVariables, { page: number, perPage: number }>
    | $Diff<OrderItemTimelineQueryVariables, { page: number, perPage: number }>
    | $Diff<OrderTimelineQueryVariables, { page: number, perPage: number }>
    | $Diff<ProductProviderTimelineQueryVariables, { page: number, perPage: number }>
>({
  query,
  queryField,
  variables: baseVariables,
  formatters: customFormatters = {},
}: Props<TVariable>): React.Element<'div'> => {
  const ref = React.useRef<?HTMLDivElement>(null)
  const [isReady, setIsReady] = React.useState<boolean>(false)

  const hasPermissions = useViewerHasPermissions()

  const variables = {
    ...baseVariables,
    page: 1,
    perPage: Math.ceil((window.innerHeight - 90) / ROW_HEIGHT),
  }
  // $FlowFixMe
  const formatters = { ...getDefaultFormatters(), ...customFormatters }

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (isReady && ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight
      }
    }, 400)
  }, [isReady])

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    Payload<EventFragment | CommentFragment>,
    TData,
    TVariable
  >(
    query,
    { variables, onCompleted: () => setIsReady(true), fetchPolicy: 'network-only' },
    `${queryField}.timeline.entries`,
    { disableReset: true }
  )

  const entries = decorateEntries(
    normalizeEntries(
      nodes.flatMap((node) =>
        node.__typename === 'TimelineEvent' || node.__typename === 'TimelineComment' ? node : []
      )
    ).reverse()
  )

  return (
    <div className={TimelineWrapperStyle}>
      <div ref={ref} className={ListWrapperStyle}>
        <GridView
          onLoadMore={loadMore}
          hasMore={hasMore}
          isLoading={loading}
          itemWidth="100%"
          isEmpty={entries.length === 0}
          isReverse
          threshold={50}
          rowGap="10px"
          padding="30px 100px"
          emptyMessage="No logs"
        >
          {filterEntries(entries, hasPermissions).map((entry: Item) => {
            switch (entry.__typename) {
              case 'NormalizedLog':
                return <Log key={entry.id} formatters={formatters} log={entry} />
              case 'DateSeparator':
                return <DateSeparator key={entry.id} date={entry} />
              default:
                return null
            }
          })}
        </GridView>
      </div>
    </div>
  )
}

export default Timeline
