// @flow
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import type { File } from '@graphql/server/flow'

import { Display, FieldItem, Label } from 'components/Form'
import {
  computeIcon,
  getFileExtension,
  getFileName,
  getMimeType,
  previewMimeTypes,
} from 'components/Form/DocumentsUpload/helpers'
import FormattedDate from 'components/FormattedDate'
import GridColumn from 'components/GridColumn'
import Icon from 'components/Icon'
import LoadingIcon from 'components/LoadingIcon'
import { Tooltip } from 'components/Tooltip'
import type { LocalFile, UploadingFile } from 'types'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';

import BaseCard from '../BaseCard'

import {
  ButtonLightWrapperStyle,
  CreatedAtStyle,
  DocumentLightCardWrapperStyle,
  DownloadButtonStyle,
  FileExtensionIconStyle,
  FileNameStyle,
  FileNameWrapperStyle,
  FilePreviewStyle,
  OwnedByStyle,
  ThumbnailPreviewStyle,
} from './style'

type Props = {|
  file: File | LocalFile | UploadingFile,
  error?: string,
  actions?: React$Node[],
  hideParentInfo?: boolean,
  downloadable?: boolean,
  navigable?: boolean,
  selected?: boolean,
  onChange?: (field: string, value: mixed) => void,
  onClick?: Function,
  onSelect?: Function,
|}

const getBlobFromUrl = (myImageUrl: string) =>
  new Promise<Blob>((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('GET', myImageUrl, true)
    request.responseType = 'blob'
    request.onload = () => {
      resolve(request.response)
    }
    request.onerror = reject
    request.send()
  })

const DocumentLightCard = ({
  file,
  error,
  hideParentInfo,
  actions,
  downloadable,
  navigable,
  onSelect,
  onChange,
  onClick,
  ...rest
}: Props): React.Node => {
  // pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [imgData, setImgData] = useState<?string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const name = file.name ?? ''
  const fileExtension = getFileExtension(name)
  const mimetype =
    file.__typename === 'File' || file.__typename === 'LocalFile' ? getMimeType(file) : undefined
  const isPreviewable = previewMimeTypes.some((goodMimetype) => goodMimetype === mimetype)
  const isImage = mimetype && mimetype.includes('image')
  const fileName = getFileName(name)
  const fileIcon = computeIcon(fileExtension)
  const createdAt = file.createdAt ?? ''
  const canvasRef = useRef<?HTMLCanvasElement>()

  useEffect(() => {
    if (isPreviewable)
      (async () => {
        const blob = file.path ? await getBlobFromUrl(file.path) : undefined
        if (blob && isImage) {
          setImgData(URL.createObjectURL(blob))
          setIsLoading(false)
        } else {
          const fileReader = new FileReader()
          // $FlowFixMe: Doesn't understand progress event
          fileReader.onload = ({ target: { result } }) => {
            if (!result) return
            if (isImage) {
              setImgData(result)
              setIsLoading(false)
              // return;
            }
            // const pdfData =
            //   typeof result === 'string' && result.startsWith('data')
            //     ? atob(result.replace(/^data:application\/(pdf);base64,/, ''))
            //     : new Uint8Array(result);
            // // Using DocumentInitParameters object to load binary data.
            // const loadingTask = pdfjsLib.getDocument({ data: pdfData });
            // loadingTask.promise.then(pdf => {
            //   // Fetch the first page
            //   const pageNumber = 1;
            //   pdf.getPage(pageNumber).then(page => {
            //     const scale = 1;
            //     const viewport = page.getViewport({ scale });

            //     // Prepare canvas using PDF page dimensions
            //     if (!canvasRef.current) return;
            //     const context = canvasRef.current.getContext('2d');

            //     // Render PDF page into canvas context
            //     const renderContext = {
            //       canvasContext: context,
            //       viewport,
            //     };
            //     const renderTask = page.render(renderContext);
            //     renderTask.promise.then(() => {
            //       setImgData(canvasRef.current?.toDataURL());
            //       setIsLoading(false);
            //     });
            //   });
            // });
          }
          if (blob) {
            fileReader.readAsArrayBuffer(blob)
          } else if (file.__typename === 'LocalFile') {
            fileReader.readAsDataURL(file.file)
          }
        }
      })()
  }, [file, mimetype, isImage, isPreviewable])

  const img = (
    <img
      alt="message_thumbnail_preview"
      className={`${ThumbnailPreviewStyle} asdasd`}
      src={imgData}
    />
  )

  return (
    <BaseCard
      actions={actions}
      error={error}
      showActionsOnHover
      color="DOCUMENT"
      onClick={onClick}
      onSelect={() => {
        if (onSelect) {
          onSelect(file)
        }
      }}
      {...rest}
    >
      <div className={DocumentLightCardWrapperStyle}>
        {isPreviewable ? (
          <div className={`${FilePreviewStyle} FilePreviewStyle`}>
            {isLoading && <LoadingIcon />}
            {imgData && !isLoading && Array(2).fill(img)}
          </div>
        ) : (
          <div className={FileExtensionIconStyle(fileIcon.color, true)}>
            <Icon {...fileIcon} />
          </div>
        )}
        <GridColumn gap="5px">
          <Tooltip message={`${fileName}.${fileExtension}`}>
            <div className={FileNameWrapperStyle(true)}>
              <div className={FileNameStyle(true)}>
                {fileName}.{fileExtension}
              </div>
            </div>
          </Tooltip>
          <FieldItem
            label={
              <Tooltip message={file.ownedBy?.id ? file.ownedBy.name : ''}>
                <span>
                  <Label className={OwnedByStyle}>
                    {file.ownedBy?.id ? file.ownedBy.name : ''}
                  </Label>
                </span>
              </Tooltip>
            }
            input={
              <Display className={CreatedAtStyle(true)}>
                <FormattedDate value={createdAt} mode="datetime" />
              </Display>
            }
          />
        </GridColumn>

        <div className={ButtonLightWrapperStyle}>
          {/* {downloadable && ( */}
          <Tooltip
            message={
              <FormattedMessage
                id="components.documentInput.cantDownload"
                defaultMessage="You do not have the rights to download this document"
              />
            }
            enabled={false}
          >
            <div className={DownloadButtonStyle({ disabled: false })}>
              <Icon
                icon="DOWNLOAD"
                onClick={(e) => {
                  e.stopPropagation()
                  if (file.__typename === 'LocalFile') {
                    const path = URL.createObjectURL(file.file)
                    window.open(path ?? '', '_blank')
                  } else {
                    window.open(file?.path ?? '', '_blank')
                  }
                }}
              />
            </div>
          </Tooltip>
          {/* )} */}
        </div>
      </div>
      {/* Canvas just provides a handy way to render pdfs, then painted to img tag above */}
      <canvas
        alt="never_shown"
        className={ThumbnailPreviewStyle}
        ref={canvasRef}
        style={{ display: 'none' }}
      />
    </BaseCard>
  )
}

export default DocumentLightCard
