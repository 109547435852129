// @flow strict
import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/browser/dist/index'
import * as React from 'react'

import authenticationQuery from '@graphql/client/auth/query.authentication.graphql'
import viewerQuery from '@graphql/client/auth/query.viewer.graphql'
import type {
  UserFragment,
  UsersOrganizationFragment,
  ViewerQuery,
  ViewerQueryVariables,
  AuthenticationQuery,
  AuthenticationQueryVariables,
} from '@graphql/server/flow'
import { isNext } from '@utils/env'

import LoadingIcon from 'components/LoadingIcon'
import type { Payload } from 'types'

type AuthenticatedContext = {
  authenticated: boolean,
  setAuthenticated: (boolean) => void,
}

type ViewerUser = $Diff<
  UserFragment,
  {
    // $FlowIgnore
    organization: *,
  }
>
type ViewerOrganization = UsersOrganizationFragment

export type ViewerContextType = {
  user: ?Payload<ViewerUser>,
  organization: ?Payload<ViewerOrganization>,
  ...AuthenticatedContext,
}

type AuthorizedContext = {
  user: ViewerUser,
  organization: UsersOrganizationFragment,
  ...AuthenticatedContext,
  authenticated: true,
}

const initialState = {
  user: null,
  organization: null,
  authenticated: false,
  setAuthenticated: () => {},
}

export const ViewerContext: React.Context<ViewerContextType> =
  React.createContext<ViewerContextType>({
    ...initialState,
  })

export const useViewer = (): ViewerContextType => React.useContext(ViewerContext)

export const useAuthorizedViewer = (): AuthorizedContext => {
  const { user, organization, authenticated, setAuthenticated } = useViewer()
  if (
    !authenticated ||
    user?.__typename !== 'User' ||
    organization?.__typename !== 'Organization'
  ) {
    throw new Error('ViewerContextType is not authorized!')
  }

  return {
    user,
    organization,
    authenticated,
    setAuthenticated,
  }
}

export const useAuthenticated = (): AuthenticatedContext => {
  const { authenticated, setAuthenticated } = useViewer()

  return { authenticated, setAuthenticated }
}

type Props = {
  children: React.Node,
}

const ViewerProvider = ({ children }: Props): React.Node => {
  const client = useApolloClient()
  const [loadingAuth, setLoadingAuth] = React.useState(true)
  const [loadingAuthChanges, setLoadingAuthChanges] = React.useState(0)
  const [loadingViewer, setLoadingViewer] = React.useState(false)
  const [authenticated, setAuthenticated] = React.useState(false)
  const [viewer, setViewer] = React.useState<ViewerContextType>(initialState)
  const isFirstRender = React.useRef(true)

  React.useEffect(() => {
    client
      .query<AuthenticationQuery, AuthenticationQueryVariables>({
        query: authenticationQuery,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const { authenticatedWithMFA } = data
        const { authenticated: isMfaLoggedIn, authenticatedMfa } = authenticatedWithMFA

        setLoadingViewer(false)

        setAuthenticated(isMfaLoggedIn && authenticatedMfa)
        setLoadingAuthChanges((prev) => prev + 1)
      })
  }, [client, setAuthenticated, setLoadingAuth])

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // toggle flag after first render/mounting
      return
    }
    setLoadingAuth(false)
  }, [loadingAuthChanges])

  React.useEffect(() => {
    if (!authenticated) {
      setViewer(initialState)
      return
    }

    setLoadingViewer(true)

    client
      .query<ViewerQuery, ViewerQueryVariables>({
        query: viewerQuery,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const { user } = data.viewer
        if (user.__typename !== 'User') throw Error
        const { organization, ...userRest } = user
        if (organization?.__typename !== 'Organization') throw Error

        setViewer((prev) => ({
          ...prev,
          user: userRest,
          organization,
        }))
        setLoadingViewer(false)
      })
      .catch(() => {
        setAuthenticated(false)
        setViewer(initialState)
        setLoadingViewer(false)
      })
  }, [client, authenticated, setViewer, setLoadingViewer])

  React.useEffect(() => {
    const { user, organization } = viewer

    if (user?.__typename !== 'User' || organization?.__typename !== 'Organization') {
      if (window && window.smartlook) {
        window.smartlook('identify', 'anonymize')
      }
      return
    }

    const { email, id, firstName, lastName } = user

    Sentry.configureScope((scope) => {
      scope.setUser({ email, id })
    })

    if (isNext) return

    /* eslint-disable */
    // $FlowIgnore (Karte code)
    !(function (n) {
      if (!window[n]) {
        // $FlowIgnore (Karte code)
        var o = (window[n] = function () {
          // $FlowIgnore (Karte code)
          var n = [].slice.call(arguments)
          return o.x ? o.x.apply(0, n) : o.q.push(n)
        })
        ;(o.q = []),
          (o.i = Date.now()),
          (o.allow = function () {
            o.o = 'allow'
          }),
          (o.deny = function () {
            o.o = 'deny'
          })
      }
    })('krt')
    /* eslint-enable */
    window.krt('send', 'identify', {
      user_id: user.id ?? '',
      email: user.email ?? '',
      user_created_date: user.createdAt ?? '',
      user_external_roles:
        user.roles.flatMap((role) => (role.__typename === 'Role' ? role.name : [])) ?? [],
      user_internal_roles:
        user.roles.flatMap((role) => (role.__typename === 'Role' ? role.name : [])) ?? [],
      user_language: user.language ?? '',
      user_disabled: user.disabled ?? false,
      organization_id: organization.id ?? '',
      organization_name: organization.name ?? '',
      organization_type: organization.types ?? [],
      organization_connection_id:
        organization.connections.flatMap((connection) =>
          connection.__typename === 'Connection' ? connection.id : []
        ) ?? [],
      organization_connection_name:
        organization.connections.flatMap((connection) =>
          connection.__typename === 'Connection' ? connection.displayName : []
        ) ?? [],
      organization_disabled: organization.disabled ?? false,
    })

    if (window && window.smartlook) {
      window.smartlook('identify', id, {
        email,
        name: `${lastName ?? ''} ${firstName ?? ''}`,
      })
    }
  }, [viewer])

  return (
    <ViewerContext.Provider
      value={{
        ...viewer,
        authenticated,
        setAuthenticated,
      }}
    >
      {loadingAuth || loadingViewer ? <LoadingIcon /> : children}
    </ViewerContext.Provider>
  )
}

/**
 * @deprecated Use useAuthenticated hook instead
 */
export const AuthenticatedConsumer = ({
  children,
}: {
  children: (AuthenticatedContext) => React.Node,
}): React.Node => {
  return children(useAuthenticated())
}

/**
 * @deprecated Use hook `useAuthorizedViewer` instead.
 */
export const UserConsumer = ({
  children,
}: {
  children: (AuthorizedContext) => React.Node,
}): React.Node => {
  return children(useAuthorizedViewer())
}

export default ViewerProvider
