// @flow strict
import * as React from 'react'

import type {
  UsersOrganizationFragment,
  ConnectionByWithOrganizationsFragment,
} from '@graphql/server/flow'

import ConnectionCard from 'components/Cards/ConnectionCard'
import GridView from 'components/GridView'
import useUser from 'hooks/useUser'
import entityHasConnection from 'utils/entityHasConnection'

import type { MetadataFilterBy } from '../../types'

const renderItem = ({
  item,
  ...rest
}: {|
  item: ConnectionByWithOrganizationsFragment | UsersOrganizationFragment,
  selected: boolean,
  onClick: () => void,
|}) => (
  <ConnectionCard
    key={item.id}
    connection={{
      id: item.id,
      title: item.__typename === 'Connection' ? item.displayName : 'Internal',
      count: item.__typename === 'Connection' ? (item.organizations || []).length : null,
    }}
    selectable
    {...rest}
  />
)

const MaskGridView = ({
  filterBy,
  setFilterBy,
}: {|
  filterBy: $Diff<MetadataFilterBy, { query: ?string }>,
  setFilterBy: (
    ($Diff<MetadataFilterBy, { query: ?string }>) => $Diff<MetadataFilterBy, { query: ?string }>
  ) => void,
|}): React.Node => {
  const { connections, organization } = useUser()

  if (filterBy.entityTypes.length !== 1) {
    throw Error('This component should be run with filterBy.entityTypes.length === 1')
  }

  return (
    <GridView itemWidth="195px">
      {[organization, ...(entityHasConnection(filterBy.entityTypes[0]) ? connections : [])].flatMap(
        (item) =>
          item.__typename === 'Connection' || item.__typename === 'Organization'
            ? renderItem({
                item,
                selected:
                  item.__typename === 'Organization'
                    ? !filterBy.connectionIds || filterBy.connectionIds.length === 0
                    : item.id === filterBy.connectionIds[0],
                onClick: () => {
                  if (
                    item.__typename === 'Organization'
                      ? filterBy.connectionIds.length > 0
                      : filterBy.connectionIds[0] !== item.id
                  )
                    setFilterBy((prev) => ({
                      ...prev,
                      connectionIds: item.__typename === 'Organization' ? [] : [item.id],
                      internalOnly: item.__typename === 'Organization',
                    }))
                },
              })
            : []
      )}
    </GridView>
  )
}

export default MaskGridView
