// @flow
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  differenceInMinutes,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isValid,
  max,
  min,
  startOfDay,
} from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import moment from 'moment'

export {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  differenceInMinutes,
  min as earliest,
  format,
  isAfter,
  isBefore,
  isValid,
  max as latest,
  startOfDay,
}

export const isValidDate = (date: any): boolean => !!date && isValid(new Date(date))

export const isDateObject = (date: any): boolean => date instanceof Date

export const formatDateInputToDateObjectWithTimezone = (
  date: ?string,
  timezone: string
): Date | null =>
  !!date && isValidDate(date)
    ? zonedTimeToUtc(
        new Date(
          parseInt(date.substring(0, 4), 10),
          parseInt(date.substring(5, 7), 10) - 1,
          parseInt(date.substring(8, 10), 10)
        ),
        timezone
      )
    : null

// Return today's date in UTC format
export const newDateUTC = (): string => {
  const date = moment().utc().format('YYYY-MM-DD').concat(`T00:00:00Z`)

  return date
}

// Return today's date in datetime format with timezone
export const newDateTZ = (timezone: string): string => {
  const date = moment().utc().format('YYYY-MM-DD').concat(`T00:00${timezone}`)

  return date
}

// ex. (2020-01-01T11:01:00Z) => 2020-01-01T11:01:00
// When send in UTC datetime,
// it will NOT convert and simply return the datetime with no Z suffix
export const removeZSuffix = (date: string): string => {
  if (date && date.charAt(date.length - 1) === 'Z') {
    return date.substring(0, date.length - 1)
  }
  return date
}

// ex. (+09:00) => -09:00
// When send in timezone,
// it will switch the sign prefix
export const switchTimezoneSign = (timezone: string): string => {
  if (timezone.charAt(0) === '-') {
    return `+${timezone.substring(1)}`
  }

  return `-${timezone.substring(1)}`
}

// ex. (2020-01-01T11:01+09:00) => 2020-01-01T11:01
// When send in datetime with timezone suffix,
// it will NOT convert and simply return the datetime with no timezone suffix
export const removeTimezone = (date: ?string, removeTime?: boolean): string => {
  if (date) {
    const hasTimezone =
      (date.charAt(date.length - 6) === '-' || date.charAt(date.length - 6) === '+') &&
      date.charAt(date.length - 3) === ':'

    if (!hasTimezone) {
      return date
    }

    if (removeTime) {
      const hasTime = date.charAt(date.length - 12) === 'T' && date.charAt(date.length - 9) === ':'

      if (hasTime) {
        const result = date.substring(0, date.length - 12)
        return result
      }
    }

    const result = date.substring(0, date.length - 6)
    return result
  }

  return ''
}

// ex. (2020-01-01T11:01, +09:00) => 2020-01-01T11:01+09:00
// When send in datetime value and timezone,
// it will NOT convert and simply return the datetime with timezone suffix
export const addTimezone = (date: ?string, timezone: string, addTime?: boolean): string => {
  if (date) {
    const result = date.concat(addTime ? 'T00:00' : '').concat(timezone)

    return result
  }

  return ''
}

// ex. (2020-01-01T11:01, +09:00) => 2020-01-01T02:01+09:00
// When send in value from datetime input and user's timezone,
// it will convert the value based on the timezone and return with timezone suffix
export const formatDatetimeInputToDatetimeWithTimezone = (
  date: ?string,
  timezone: string
): string | null => {
  if (date) {
    const dateObj = moment.utc(date.concat(timezone))

    const result = dateObj.format(`YYYY-MM-DDTHH:mm${timezone}`)

    return result
  }

  return null
}

// ex. (2020-01-01T11:01:00Z, +09:00) => 2020-01-01T02:01+09:00
// When send in UTC value and user's timezone,
// it will convert the value based on the timezone and return with timezone suffix
export const formatUTCDatetimeToDatetimeWithTimezone = (
  date: ?string,
  timezone: string
): string | null => {
  if (date) {
    const dateObj = moment.utc(removeZSuffix(date).concat(switchTimezoneSign(timezone)))

    const result = dateObj.format(`YYYY-MM-DDTHH:mm${timezone}`)

    return result
  }

  return null
}

// ex. (2020-01-01T11:01+09:00) => 2020-01-01T02:01:00Z
// When send in datetime with timezone suffix,
// it will convert the value based on the timezone suffix and return in UTC
export const formatDatetimeWithTimezoneToUTCDatetime = (date: ?string): string | null => {
  if (date) {
    const dateObj = moment.utc(date)

    const result = dateObj.format('YYYY-MM-DDTHH:mm:ss').concat('Z')

    return result
  }

  return null
}

// ex. (2020-01-01T14:01+09:00, +03:00) => 2020-01-01T02:01+03:00
// When send in datetime with timezone suffix and timezone,
// it will convert the datetime to the timezone and return the datetime with timezone suffix
export const formatDatetimeWithTimezoneToDatetimeWithTimezone = (
  date: ?string,
  timezone: string
): string | null => {
  if (date) {
    const result = formatUTCDatetimeToDatetimeWithTimezone(
      formatDatetimeWithTimezoneToUTCDatetime(date),
      timezone
    )

    return result
  }

  return null
}

// ex. (2020-01-01T11:01:00Z or 2020-01-01T11:01+09:00, +09:00) => 2020-01-01T02:01+09:00
// When send in either a UTC date or date with timezone suffix,
// it will determine which type was sent and trigger the format function specific to that type
export const formatDatetimeQueryToDatetimeWithTimezone = (
  date: ?string,
  timezone: string
): string | null => {
  if (date) {
    // Date with offset
    if (date.charAt(date.length - 6) === '+' || date.charAt(date.length - 6) === '-') {
      return formatDatetimeWithTimezoneToDatetimeWithTimezone(date, timezone)
    }
    // UTC date with no offset
    return formatUTCDatetimeToDatetimeWithTimezone(date, timezone)
  }

  return null
}

// ex. (2020-01-01T11:01:00Z or 2020-01-01T11:01+09:00) => 2020-01-01T11:01:00Z
// When send in either a UTC date or date with timezone suffix,
// it will determine which type was sent and trigger the format function specific to that type
export const formatDatetimeQueryToUTCDatetime = (date: ?string): string | null => {
  if (date) {
    // Date with offset
    if (date.charAt(date.length - 6) === '+' || date.charAt(date.length - 6) === '-') {
      return formatDatetimeWithTimezoneToUTCDatetime(date)
    }
    // UTC date with no offset
    return date
  }

  return null
}

export type dateEvent =
  | 'deliveredAt'
  | 'desiredAt'
  | 'expiredAt'
  | 'producedAt'
  | 'warehouseArrivalAgreedDate'
  | 'departureDate'
  | 'blDate'
  | 'bookingDate'
  | 'freeTimeStartDate'

// TODO: Fix with syntax from here https://github.com/facebook/flow/issues/6295#issuecomment-1294441861
export const initDatetimeToContainer = (
  date: ?string,
  fieldName: dateEvent,
  timezone: string
):
  | {|
      deliveredAt: ?string,
    |}
  | {|
      desiredAt: ?string,
    |}
  | {|
      expiredAt: ?string,
    |}
  | {|
      producedAt: ?string,
    |}
  | {|
      warehouseArrivalAgreedDate: ?string,
    |}
  | {|
      departureDate: ?string,
    |}
  | {|
      freeTimeStartDate: ?string,
    |}
  | {||} => {
  if (!date) return Object.freeze({})
  const formattedDate = formatDatetimeQueryToDatetimeWithTimezone(date, timezone)
  switch (fieldName) {
    case 'deliveredAt':
      return {
        deliveredAt: formattedDate,
      }
    case 'desiredAt':
      return {
        desiredAt: formattedDate,
      }
    case 'expiredAt':
      return {
        expiredAt: formattedDate,
      }
    case 'producedAt':
      return {
        producedAt: formattedDate,
      }
    case 'warehouseArrivalAgreedDate':
      return {
        warehouseArrivalAgreedDate: formattedDate,
      }
    case 'departureDate':
      return {
        departureDate: formattedDate,
      }
    case 'freeTimeStartDate':
      return {
        freeTimeStartDate: formattedDate,
      }
    default:
  }
  return {
    [(fieldName: string)]: formattedDate,
  }
}

export const formatDateObjectWithTimezoneForMutation = (date: ?Date): string | null =>
  !!date && isValidDate(date) ? format(date, "yyyy-MM-dd'T'HH:mmxxx") : null

export const formatToDateInput = (date: string): string =>
  isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd') : ''

export const formatToDateTimeInput = (date: string): string =>
  isValid(new Date(date))
    ? format(
        new Date(
          parseInt(date.substring(0, 4), 10),
          parseInt(date.substring(5, 7), 10) - 1,
          parseInt(date.substring(8, 10), 10),
          parseInt(date.substring(11, 13), 10),
          parseInt(date.substring(14, 16), 10)
        ),
        "yyyy-MM-dd'T'HH:mm"
      )
    : ''

export const formatDatetimeForMutation = (date: string): string => {
  return format(
    new Date(
      parseInt(date.substring(0, 4), 10),
      parseInt(date.substring(5, 7), 10) - 1,
      parseInt(date.substring(8, 10), 10),
      parseInt(date.substring(11, 13), 10),
      parseInt(date.substring(14, 16), 10)
    ),
    "yyyy-MM-dd'T'HH:mm:'00Z'"
  )
}

export const formatDateToGraphql = (date: Date): string => format(date, "yyyy-MM-dd'T'HH:mm:ssxxx")

export const formatToDateLabel = (date: string): string => format(new Date(date), 'dd/MM/yyyy')
// We need to convert to UTC timezone for backend
const utcTimeZone = 'UTC'
export const formatFromDate = (date: string): Date =>
  zonedTimeToUtc(startOfDay(new Date(date)), utcTimeZone)
export const formatEndDate = (date: string): Date =>
  zonedTimeToUtc(endOfDay(new Date(date)), utcTimeZone)

export const startOfToday = (): Date => zonedTimeToUtc(startOfDay(new Date()), utcTimeZone)

export const todayForDateInput = (): string =>
  formatToDateInput(zonedTimeToUtc(startOfDay(new Date()), utcTimeZone))

export const findDuration = ({
  months,
  weeks,
}: {
  months: number,
  weeks: number,
}): 'days' | 'weeks' | 'months' => {
  let duration = 'days'
  if (Math.abs(months) > 0) {
    duration = 'months'
  } else if (Math.abs(weeks) > 0) {
    duration = 'weeks'
  }
  return duration
}

// freeTimeStartDate = Datetime with timezone format
// returns UTC format with the freeTimeDuration added to it
export const calculateDueDate = (
  freeTimeStartDate: string,
  freeTimeDuration: ?number = 0
): string => {
  const dateObj = moment.utc(freeTimeStartDate).add(freeTimeDuration, 'days')

  const result = dateObj.format('YYYY-MM-DDTHH:mm:ss').concat('Z')

  return result
}

export const calculateDateDifferenceInDaysFromToday = (dueDate: string): number => {
  const dateObj = moment.utc(dueDate)

  const result = dateObj.diff(moment.utc(), 'days')

  return result
}

export const calculateDateDifferenceInDays = (date1: string, date2: string): number => {
  const result = moment.utc(date1).diff(moment.utc(date2), 'days')

  return result
}

// baseDate = Datetime with timezone format
// returns Datetime with timezone format with the dateInterval added to it
export const calculateBindingDate = (
  baseDate: ?string,
  dateInterval: Object,
  timezone: string
): ?string => {
  if (baseDate) {
    const { months, weeks, days } = dateInterval || {}
    const dateObj = moment.utc(baseDate)

    if (months) {
      dateObj.add(months, 'months')
    } else if (weeks) {
      dateObj.add(weeks, 'weeks')
    } else if (days) {
      dateObj.add(days, 'days')
    }

    return formatUTCDatetimeToDatetimeWithTimezone(
      dateObj.format('YYYY-MM-DDTHH:mm:ss').concat('Z'),
      timezone
    )
  }
  return null
}

export const isSameMinute = (date1: string, date2: string): boolean => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  const isSameMin =
    d1.getDate() === d2.getDate() && Math.abs(d1.getTime() - d2.getTime()) < 60 * 1000

  return isSameMin
}

// MIN and MAX possible date values respresentable in JavaScript
export const MIN_DATE_VALUE = -8640000000000000
export const MAX_DATE_VALUE = 8640000000000000
