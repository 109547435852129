// @flow
import { type ApolloClient } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import exportExtensionsQuery from '@graphql/client/tableTemplate/query.exportExtensions.graphql'
import genericExportQuery from '@graphql/client/tableTemplate/query.genericExport.graphql'

import { getByPathWithDefault } from 'utils/fp'

import BaseExportButton from '../BaseExportButton'

type OptionalProps = {
  label: React.Node,
  disabled: boolean,
}

type Props = OptionalProps & {
  columns: string[] | (() => string[]),
  rows: ?(string[][]) | (() => ?(string[][])),
}

const defaultProps = {
  label: (<FormattedMessage id="components.button.export" defaultMessage="EXPORT" />: React.Node),
  disabled: false,
}

function ExportGenericButton({ label, disabled, rows, columns }: Props): React.Node {
  return (
    <BaseExportButton
      label={label}
      disabled={disabled}
      exportQuery={genericExportQuery}
      onLoad={(client: ApolloClient<any>) =>
        client
          .query({
            query: exportExtensionsQuery,
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            const extensions = getByPathWithDefault([], 'exportExtensions', data)

            return extensions.map((extension) => ({
              name: `.${extension.extension}`,
              icon: null,
              variables: {
                extension: extension.extension,
                columns: typeof columns === 'function' ? columns() : columns,
                rows: typeof rows === 'function' ? rows() : rows,
              },
            }))
          })
      }
    />
  )
}

ExportGenericButton.defaultProps = defaultProps

export default ExportGenericButton
