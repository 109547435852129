import { EntityPayload } from '@graphql/server/typescript'

import FILE_ATTACHABLE_ENTITIES from '@constants/file'
import { DocumentedEntity, ExtractFound } from '@types'

type Entity = ExtractFound<EntityPayload>

/**
 * Returns true if the entity can have a file attached to it
 * @param entityTypename
 * @returns boolean
 */
export default function entityHasFile(
  entityTypename: Entity['__typename']
): entityTypename is DocumentedEntity['__typename'] {
  return FILE_ATTACHABLE_ENTITIES.includes(entityTypename as DocumentedEntity['__typename'])
}
