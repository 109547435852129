// @flow strict
import { css } from 'react-emotion'

import { colors } from 'styles/common'

import { type Entities, entities } from './types'

export const EntityLabelStyle = ({ entity }: {| entity: Entities |}): string => css`
  position: absolute;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: -130px;
  top: ${145 + 225 * (Object.keys(entities).indexOf(entity) ?? 1)}px;
   {
    /* Comment out below line to try spacing items out based on their entity type */
  }
  top: 145px;
  background: ${colors[entity]};
  height: 40px;
  width: 220px;
  color: white;
  clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  margin: 0px;
  padding: 0 60px;
  text-align: center;
  line-height: 40px;
`
