// @flow
import Downshift from 'downshift'
import * as React from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { Display } from 'components/Form'
import { getByPath, isEquals } from 'utils/fp'

import { SelectInputRelativeStyle } from './style'
import { type SelectInputProps as Props, defaultSelectInputProps } from './type'

type State = {
  selectedItem: any,
}

class SelectInput extends React.Component<Props, State> {
  downshift = undefined

  static defaultProps = defaultSelectInputProps

  state = {
    selectedItem: null,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { value, items, itemToValue } = props
    const { selectedItem } = state

    const newSelectedItem = value
      ? (items || []).find((item) => isEquals(itemToValue(item), value))
      : null

    if (
      itemToValue &&
      (!isEquals(itemToValue(selectedItem), value) ||
        // Some components rely on referential equality, meaning we must check here as well
        newSelectedItem !== selectedItem)
    ) {
      return {
        selectedItem: value
          ? (items || []).find((item) => isEquals(itemToValue(item), value))
          : null,
      }
    }
    return { selectedItem }
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props
    if (prevProps.value && !value) {
      this.handleChange(null)
    }
  }

  handleChange = (selectedItem: any) => {
    const { onChange } = this.props

    this.setState({ selectedItem }, () => {
      if (onChange) onChange(selectedItem)
    })
  }

  handleBlur = (evt: Object) => {
    const { onBlur } = this.props
    if (onBlur) onBlur(evt)
  }

  handleFocus = (evt: Object) => {
    const { onFocus } = this.props
    if (onFocus) onFocus(evt)
  }

  render() {
    const {
      itemToString,
      itemToValue,
      renderSelect,
      renderOptions,
      items,
      name,
      afterClearSelection,
      readOnly,
      readOnlyWidth,
      readOnlyHeight,
      align,
      downshiftRef,
    } = this.props
    const { selectedItem } = this.state

    return readOnly ? (
      <Display align={align} width={readOnlyWidth} height={readOnlyHeight}>
        {itemToString(selectedItem)}
      </Display>
    ) : (
      <Downshift
        labelId={`${name}SelectInput`}
        onChange={this.handleChange}
        itemToString={itemToString}
        ref={downshiftRef}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          toggleMenu: toggle,
          highlightedIndex,
          clearSelection,
        }) => (
          <div className={SelectInputRelativeStyle}>
            {renderSelect({
              onBlur: this.handleBlur,
              onFocus: this.handleFocus,
              isOpen,
              toggle,
              selectedItem,
              clearSelection: () => clearSelection(afterClearSelection),
              getInputProps,
              itemToString,
              align,
            })}
            {isOpen &&
              renderOptions({
                items,
                highlightedIndex,
                selectedItem,
                getItemProps,
                itemToString,
                itemToValue,
                align,
              })}
          </div>
        )}
      </Downshift>
    )
  }
}

// A wrapper that has access to the DownShift component written above and the items passed to it
// TODO: forwardRef<T,T>
export default (forwardRef<any, any>((props: Props, ref) => {
  const { formPath, items } = props
  const methods = useFormContext()

  const downshiftRef = useRef()
  useImperativeHandle(ref, () =>
    /**
      If this component is within a form, and there are defaultValues
      available for reset, pass a function 'reset' to the parent for handling
    */
    formPath && methods?.defaultValues && downshiftRef.current?.selectItem
      ? {
          reset: () => {
            downshiftRef.current.selectItem(
              items.find((someItem) => someItem.name === getByPath(formPath, methods.defaultValues))
            )
          },
        }
      : undefined
  )
  return <SelectInput downshiftRef={downshiftRef} {...props} />
}): React$AbstractComponent<any, any>)
