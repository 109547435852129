// @flow
import * as React from 'react'
import { Provider } from 'unstated'

import type { FilePayload } from '@graphql/server/flow'

import { SlideViewLayout } from 'components/Layout'
import DocumentForm from 'modules/document/form'
import DocumentFormContainer from 'modules/document/form/container'
import validator from 'modules/document/form/validator'
import { FormContainer } from 'modules/form'
import { encodeId } from 'utils/id'

type Props = {|
  isNew: boolean,
  onSave: (Object) => void,
  file: FilePayload,
|}

const formContainer = new FormContainer()

const DocumentFormImpl = ({
  onSave,
  documentId,
}: {
  onSave: (Object) => void,
  documentId: string,
}) => {
  const { state, isDirty, resetState } = DocumentFormContainer.useContainer()
  return (
    <DocumentForm
      isDirty={isDirty}
      isValidated={formContainer.isReady(state, validator)}
      resetState={resetState}
      isSlideView
      handleSave={() => onSave(state)}
      documentId={documentId}
    />
  )
}

const DocumentFormSlideView = ({ onSave, file, isNew }: Props): React.Node => {
  React.useEffect(() => {
    return () => {
      formContainer.onReset()
    }
  }, [])

  return (
    <Provider inject={[formContainer]}>
      <DocumentFormContainer.Provider initialState={file}>
        <SlideViewLayout>
          <DocumentFormImpl onSave={onSave} documentId={!isNew ? encodeId(file.id) : ''} />
        </SlideViewLayout>
      </DocumentFormContainer.Provider>
    </Provider>
  )
}

export default DocumentFormSlideView
