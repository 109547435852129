// @flow
// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'

export const importEventSubscription: any = gql`
  subscription importEvent($importId: ID!) {
    importEvent(id: $importId) {
      lifecycle
      sheet
      error
      violations {
        path
        message
        error
        parameters {
          key
          value
        }
      }
    }
  }
`

export default importEventSubscription
