// @flow strict
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type {
  ShipmentForm2Fragment,
  UserAvatarFragment,
  VoyageFragment,
  ContainerGroupFragment,
} from '@graphql/server/flow'

import { Tooltip } from 'components/Tooltip'
import {
  TimelineContainerIcon,
  TimelineIcon,
  TimelineLine,
  TimelineTransitIcon,
  TimelineVoyage,
  TimelineWarehouseContainerIcon,
} from 'modules/shipment/form/components/TimelineSection/components/Timeline/components'
import { getTimelineColoring } from 'modules/shipment/form/components/TimelineSection/components/Timeline/helpers'
import { getVoyageIcon } from 'modules/shipment/helpers'
import { encodeId } from 'utils/id'

import WarehouseIconTooltipMessage from './components/WarehouseTooltipMessage'
import {
  BlankSpaceStyle,
  ContainerIconWrapperStyle,
  HorizontalTimelineWrapperStyle,
  TooltipTitleStyle,
  TooltipWrapperStyle,
  WarehouseContainerWrapperStyle,
} from './style'

type Props = {|
  shipment: ShipmentForm2Fragment,
  navigable: {
    form: boolean,
  },
|}

const ApprovedBy = ({ user }: { user: UserAvatarFragment }) => {
  const values = {
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
  }

  return (
    <FormattedMessage
      id="components.Shipments.approvedBy"
      defaultMessage="Approved by {firstName} {lastName}"
      values={values}
    />
  )
}

const HorizontalTimeline = ({ shipment, navigable }: Props): React$Node => {
  const { cargoReady, containers, containerGroups, voyages } = shipment

  const containersFiltered = containers.flatMap((c) => (c.__typename === 'Container' ? c : []))

  const containerGroupsFiltered = containerGroups.flatMap((cgp) =>
    cgp.__typename === 'ContainerGroup' ? cgp : []
  )

  const [firstContainerGroup]: ContainerGroupFragment[] | void[] = [...containerGroupsFiltered]

  const voyagesFiltered = voyages.flatMap((vp) => (vp.__typename === 'Voyage' ? vp : []))
  const [firstVoyage]: VoyageFragment[] | void[] = [...voyagesFiltered]
  const [lastVoyage]: VoyageFragment[] | void[] = [...voyagesFiltered].reverse()

  const coloring =
    cargoReady.__typename === 'TimelineDate' &&
    containerGroupsFiltered.length &&
    voyagesFiltered.length
      ? getTimelineColoring({
          cargoReady,
          voyages: voyagesFiltered,
          containerGroups: containerGroupsFiltered,
        })
      : []
  const cargoReadyColoring = coloring[0]
  const loadPortDepartureColoring = coloring[1]
  const dischargePortArrivalColoring = coloring[coloring.length - 4]
  const customClearanceColoring = coloring[coloring.length - 3]
  const warehouseArrivalColoring = coloring[coloring.length - 2]
  const deliveryReadyColoring = coloring[coloring.length - 1]
  const shipmentId = encodeId(shipment.id)

  return (
    <div className={HorizontalTimelineWrapperStyle}>
      <div className={BlankSpaceStyle} />
      <Tooltip
        message={
          <div>
            <div className={TooltipTitleStyle}>
              <FormattedMessage id="components.Shipments.cargoReady" defaultMessage="CARGO READY" />
            </div>
            {cargoReady.approvedAt ? (
              <ApprovedBy user={cargoReady.approvedAt} />
            ) : (
              <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
            )}
          </div>
        }
      >
        <div>
          <TimelineIcon
            icon="CARGO_READY"
            color={cargoReadyColoring}
            linkPath={navigable.form ? `/shipment/${shipmentId}/cargoReady` : ''}
          />
        </div>
      </Tooltip>

      <TimelineLine color={loadPortDepartureColoring} />

      <Tooltip
        message={
          <div>
            <div className={TooltipTitleStyle}>
              <FormattedMessage
                id="components.Shipments.loadPortDeparture"
                defaultMessage="LOAD PORT DEPARTURE"
              />
            </div>
            {firstVoyage?.departure.approvedBy?.__typename === 'User' ? (
              <ApprovedBy user={firstVoyage.departure.approvedBy} />
            ) : (
              <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
            )}
          </div>
        }
      >
        <div>
          <TimelineIcon
            icon="PORT"
            color={loadPortDepartureColoring}
            linkPath={navigable.form ? `/shipment/${shipmentId}/loadPortDeparture` : ''}
          />
        </div>
      </Tooltip>

      <TimelineVoyage>
        <TimelineLine color={loadPortDepartureColoring} />
        <TimelineLine color={coloring[2]} />
        <TimelineIcon
          icon={
            firstVoyage?.__typename === 'Voyage' &&
            firstVoyage.departurePort &&
            firstVoyage.arrivalPort
              ? getVoyageIcon({
                  voyage: {
                    departurePort: firstVoyage.departurePort,
                    arrivalPort: firstVoyage.arrivalPort,
                  },
                })
              : undefined
          }
          color={loadPortDepartureColoring}
          linkPath={navigable.form ? `/shipment/${shipmentId}/firstVoyage` : ''}
        />
      </TimelineVoyage>

      {voyagesFiltered.length > 1 &&
        voyagesFiltered.slice(1).map((v, index, vs) => {
          const nextVoyage: VoyageFragment | void = vs[index + 1]
          return (
            <React.Fragment key={v.id ?? index}>
              <Tooltip
                message={
                  <div className={TooltipWrapperStyle}>
                    <div className={TooltipTitleStyle}>
                      {index ? (
                        <FormattedMessage
                          id="components.Shipments.secondTransitPortArrival"
                          defaultMessage="SECOND TRANSIT PORT ARRIVAL"
                        />
                      ) : (
                        <FormattedMessage
                          id="components.Shipments.firstTransitPortArrival"
                          defaultMessage="FIRST TRANSIT PORT ARRIVAL"
                        />
                      )}
                    </div>
                    {nextVoyage?.arrival.approvedBy?.__typename === 'User' ? (
                      <ApprovedBy user={nextVoyage.arrival.approvedBy} />
                    ) : (
                      <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
                    )}
                    <div className={TooltipTitleStyle}>
                      <FormattedMessage
                        id="components.Shipments.departure"
                        defaultMessage="DEPARTURE"
                      />
                    </div>
                    {nextVoyage?.departure.approvedBy?.__typename === 'User' ? (
                      <ApprovedBy user={nextVoyage?.departure.approvedBy} />
                    ) : (
                      <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
                    )}
                  </div>
                }
              >
                <div>
                  <TimelineTransitIcon
                    color={coloring[index * 2 + 2]}
                    arrivalLinkPath={
                      navigable.form
                        ? `/shipment/${shipmentId}/${
                            index === 0 ? 'firstTransitPortArrival' : 'secondTransitPortArrival'
                          }`
                        : ''
                    }
                    departureLinkPath={
                      navigable.form
                        ? `/shipment/${shipmentId}/${
                            index === 0 ? 'firstTransitPortDeparture' : 'secondTransitPortDeparture'
                          }`
                        : ''
                    }
                  />
                </div>
              </Tooltip>
              <TimelineVoyage>
                <TimelineLine color={coloring[index * 2 + 3]} />
                <TimelineLine color={coloring[index * 2 + 4]} />
                <TimelineIcon
                  icon={getVoyageIcon({ voyage: v })}
                  color={coloring[index * 2 + 3]}
                  linkPath={
                    navigable.form
                      ? `/shipment/${shipmentId}/${index === 0 ? 'secondVoyage' : 'thirdVoyage'}`
                      : ''
                  }
                />
              </TimelineVoyage>
            </React.Fragment>
          )
        })}

      <Tooltip
        message={
          <div>
            <div className={TooltipTitleStyle}>
              <FormattedMessage
                id="components.Shipments.dischargePortArrival"
                defaultMessage="DISCHARGE PORT ARRIVAL"
              />
            </div>
            {lastVoyage?.arrival.approvedBy?.__typename === 'User' ? (
              <ApprovedBy user={lastVoyage.arrival.approvedBy} />
            ) : (
              <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
            )}
          </div>
        }
      >
        <div>
          <TimelineIcon
            icon="PORT"
            color={dischargePortArrivalColoring}
            linkPath={navigable.form ? `/shipment/${shipmentId}/dischargePortArrival` : ''}
          />
        </div>
      </Tooltip>

      <TimelineLine color={customClearanceColoring} />

      <Tooltip
        message={
          <div>
            <div className={TooltipTitleStyle}>
              <FormattedMessage
                id="components.Shipments.customClearance"
                defaultMessage="CUSTOMS CLEARANCE INITIAL DATE"
              />
            </div>
            {firstContainerGroup?.customClearance.approvedBy?.__typename === 'User' ? (
              <ApprovedBy user={firstContainerGroup.customClearance.approvedBy} />
            ) : (
              <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
            )}
          </div>
        }
      >
        <div>
          <TimelineIcon
            icon="CUSTOMS"
            color={customClearanceColoring}
            linkPath={navigable.form ? `/shipment/${shipmentId}/customClearance` : ''}
          />
        </div>
      </Tooltip>

      {containers && containers.length > 0 ? (
        <>
          <TimelineLine color={warehouseArrivalColoring} flex="1.59" />

          <div className={WarehouseContainerWrapperStyle}>
            <div className={ContainerIconWrapperStyle}>
              <TimelineContainerIcon />
            </div>
            <Tooltip
              maxWidth={800}
              message={<WarehouseIconTooltipMessage containers={containers} />}
            >
              <div>
                <TimelineWarehouseContainerIcon
                  containers={containersFiltered}
                  targetId="containersWarehouseArrival"
                  boundaryId="timelineInfoSection"
                />
              </div>
            </Tooltip>
          </div>

          <TimelineLine color={deliveryReadyColoring} flex="1.59" />
        </>
      ) : (
        <>
          <TimelineLine color={warehouseArrivalColoring} />

          <Tooltip
            message={
              <div>
                <div className={TooltipTitleStyle}>
                  <FormattedMessage
                    id="components.Shipments.warehouseArrival"
                    defaultMessage="WAREHOUSE ARRIVAL INITIAL DATE"
                  />
                </div>
                {firstContainerGroup?.warehouseArrival.approvedBy?.__typename === 'User' ? (
                  <ApprovedBy user={firstContainerGroup.warehouseArrival.approvedBy} />
                ) : (
                  <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
                )}
              </div>
            }
          >
            <div>
              <TimelineIcon
                icon="WAREHOUSE"
                color={warehouseArrivalColoring}
                linkPath={navigable.form ? `/shipment/${shipmentId}/warehouseArrival` : ''}
              />
            </div>
          </Tooltip>

          <TimelineLine color={deliveryReadyColoring} />
        </>
      )}

      <Tooltip
        message={
          <div>
            <div className={TooltipTitleStyle}>
              <FormattedMessage
                id="components.Shipments.deliveryReady"
                defaultMessage="DELIVERY READY"
              />
            </div>
            {firstContainerGroup?.deliveryReady.approvedBy?.__typename === 'User' ? (
              <ApprovedBy user={firstContainerGroup.deliveryReady.approvedBy} />
            ) : (
              <FormattedMessage id="modules.cards.unapproved" defaultMessage="Unapproved" />
            )}
          </div>
        }
      >
        <div>
          <TimelineIcon
            icon="DELIVERY_READY"
            color={deliveryReadyColoring}
            linkPath={navigable.form ? `/shipment/${shipmentId}/deliveryReady` : ''}
          />
        </div>
      </Tooltip>
      <div className={BlankSpaceStyle} />
    </div>
  )
}

export default HorizontalTimeline
