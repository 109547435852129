// @flow
import * as React from 'react'

import { LoadingIconStyle, LoadingWrapperStyle } from './style'

export type Props = {|
  size?: number,
|}

const LoadingIcon = ({ size }: Props): React$Node => (
  <div className={LoadingWrapperStyle} id="loadingIcon">
    <div className={LoadingIconStyle(size || 30)} />
  </div>
)

export default LoadingIcon
