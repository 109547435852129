// @flow
import { css } from 'react-emotion'

export const DialogStyle: string = css`
  padding: 48px;
  height: 480px;
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-direction: column;
  > {
    div:first-child {
      margin-bottom: 1rem;
    }
    div:last-child {
      margin-top: auto;
    }
`

export const ButtonContainerStyle: string = css`
  display: flex;
  justify-content: end;
  width: 100%;
`
