// @flow

import * as React from 'react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Icon'
import OutsideClickHandler from 'components/OutsideClickHandler'
import { useChat } from 'modules/chat/hooks/useChat'
import messages from 'modules/chat/messages'
import { isEnableBetaFeature } from 'utils/env'
import { eventFilesToLocalFiles } from 'utils/typeConversions/eventFilesToLocalFiles'

import { UserMenuDropDownWrapperStyle, UserMenuItemStyle, UserMenuItemWrapperStyle } from './style'

type Props = {
  setLocalFiles: Function,
}

const UserMenuDropdown = ({ setLocalFiles }: Props): React.Node => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const { entityType } = useChat()

  return (
    <OutsideClickHandler onOutsideClick={() => setMenuIsOpen(false)} ignoreClick={false}>
      <Icon icon="UPLOAD" onClick={() => setMenuIsOpen(true)} />
      <div className={UserMenuDropDownWrapperStyle(menuIsOpen)}>
        {isEnableBetaFeature && (
          <button className={UserMenuItemWrapperStyle} type="button">
            <div className={UserMenuItemStyle}>
              <FormattedMessage {...messages.selectDocument} />
            </div>
          </button>
        )}
        <label className={UserMenuItemWrapperStyle}>
          <div className={UserMenuItemStyle}>
            <FormattedMessage {...messages.uploadFromComputer} />
          </div>
          <input
            type="file"
            accept="*"
            hidden
            multiple
            value=""
            onChange={({ target: { files: selectedFiles } }: { target: { files: File[] } }) => {
              setLocalFiles((old) => [...old, ...eventFilesToLocalFiles(selectedFiles, entityType)])
              setMenuIsOpen(false)
            }}
          />
        </label>
      </div>
    </OutsideClickHandler>
  )
}

export default UserMenuDropdown
