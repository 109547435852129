// @flow

// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'

import commentFragment from '@graphql/client/common/fragment.comment.graphql'
import badRequestFragment from '@graphql/client/errors/fragment.badRequest.graphql'
import forbiddenFragment from '@graphql/client/errors/fragment.forbidden.graphql'
import notFoundFragment from '@graphql/client/errors/fragment.notFound.graphql'

export const commentCreateMutation: any = gql`
  mutation commentCreate($input: CommentCreateInput!) {
    commentCreate(input: $input) {
      ...Comment
      ...BadRequest
    }
  }

  ${commentFragment}
  ${badRequestFragment}
`

export const commentUpdateMutation: any = gql`
  mutation commentUpdate($id: ID!, $input: CommentUpdateInput!) {
    commentUpdate(id: $id, input: $input) {
      ...Comment
      ...BadRequest
    }
  }

  ${commentFragment}
  ${badRequestFragment}
`

export const commentDeleteMutation: any = gql`
  mutation commentDelete($id: ID!) {
    commentDelete(id: $id) {
      ...Forbidden
      ...NotFound
      ...BadRequest
    }
  }

  ${badRequestFragment}
  ${forbiddenFragment}
  ${notFoundFragment}
`

export const messagePreferencesMutation: any = gql`
  mutation messagePreferencesUpdate($input: MessagePreferencesInput!) {
    messagePreferencesUpdate(input: $input) {
      ...BadRequest
      ...Forbidden
    }
  }
  ${forbiddenFragment}
  ${badRequestFragment}
`
