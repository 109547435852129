// @flow
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConnectionAvatar from '@components/ConnectionAvatar'
import InternalBadge from '@components/InternalBadge'
import {
  type CustomizableEntityType,
  type DefaultFilterInput,
  type EntityCustomFieldInput,
} from '@graphql/server/flow'

import { BaseButton, ResetButton, SaveButton } from 'components/Buttons'
import Dialog from 'components/Dialog'
import { DefaultOptions, DefaultSelect, Display, Label, SelectInput } from 'components/Form'
import Icon from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import useCustomFieldsLazy from 'hooks/useCustomFieldsLazy'
import type { FieldDefinitionsMemo } from 'hooks/useCustomFieldsLazy'
import { isEquals } from 'utils/fp'

import Archived from './Inputs/Archived'
import {
  Booked,
  CompletelyBatched,
  CompletelyShipped,
  FreeTimeOverdue,
  HasEntity,
  HasShipment,
  HasUnseenNotification,
} from './Inputs/Bool'
import ConnectionIds from './Inputs/ConnectionIds'
import ContainerIds from './Inputs/ContainerIds'
import ContainerOption from './Inputs/ContainerOption'
import ContainerType from './Inputs/ContainerType'
import Country from './Inputs/Country'
import DateRange from './Inputs/DateRange'
import FileType from './Inputs/FileType'
import MaskEditType from './Inputs/MaskEditType'
import { AreaRange, LengthRange, MassRange, VolumeRange } from './Inputs/MetricRange'
import NotificationIds from './Inputs/NotificationIds'
import OrderIds from './Inputs/OrderIds'
import OrganizationIds, {
  ExporterIds,
  ForwarderIds,
  ImporterIds,
  SupplierIds,
  WarehouserIds,
} from './Inputs/OrganizationIds'
import OrganizationTypes from './Inputs/OrganizationTypes'
import Ports from './Inputs/Ports'
import ProductIds from './Inputs/ProductIds'
import ProductProviderIds from './Inputs/ProductProviderIds'
import ShipmentIds from './Inputs/ShipmentIds'
import ShipmentLoadType from './Inputs/ShipmentLoadType'
import {
  BatchTags,
  ContainerTags,
  FileTags,
  OrderItemTags,
  OrderTags,
  ProductTags,
  ShipmentTags,
  UserTags,
} from './Inputs/Tags'
import {
  BatchTagsWithOperator,
  ContainerTagsWithOperator,
  FileTagsWithOperator,
  OrderTagsWithOperator,
  ProductTagsWithOperator,
  ShipmentTagsWithOperator,
  OrderItemTagsWithOperator,
} from './Inputs/TagsWithOperator'
import Users from './Inputs/Users'
import WarehouseIds from './Inputs/WarehouseIds'
import messages from './messages'
import {
  ActionsStyle,
  ActiveStyle,
  AddFilterButtonWrapperStyle,
  ButtonStyle,
  DeleteButtonStyle,
  FiltersListStyle,
  FilterWrapperStyle,
  InputsWrapperStyle,
  ResetActionStyle,
  WrapperStyle,
} from './style'
import type {
  FieldOptionLocal,
  FieldOptionRemote,
  FieldOptionRemoteToFind,
  FilterConfig,
  FilterState,
} from './types'

type Props<F> = {
  config: FilterConfig[],
  rootQueryType: FilterConfig['entity'],
  filterBy: F,
  staticFilters?: string[],
  onChange: (Partial<F>) => void,
}

const inputs = {
  archived: Archived,
  date_range: DateRange,
  custom_field_date_range: DateRange,
  volume_range: VolumeRange,
  area_range: AreaRange,
  length_range: LengthRange,
  mass_range: MassRange,
  order_ids: OrderIds,
  shipment_ids: ShipmentIds,
  warehouse_ids: WarehouseIds,
  product_ids: ProductIds,
  product_provider_ids: ProductProviderIds,
  connection_ids: ConnectionIds,
  container_ids: ContainerIds,
  organization_ids: OrganizationIds,
  importer_ids: ImporterIds,
  exporter_ids: ExporterIds,
  supplier_ids: SupplierIds,
  forwarder_ids: ForwarderIds,
  warehouser_ids: WarehouserIds,
  notification_ids: NotificationIds,
  users: Users,
  product_tags: ProductTags,
  order_tags: OrderTags,
  order_item_tags: OrderItemTags,
  batch_tags: BatchTags,
  shipment_tags: ShipmentTags,
  product_tags_with_operator: ProductTagsWithOperator,
  order_tags_with_operator: OrderTagsWithOperator,
  order_item_tags_with_operator: OrderItemTagsWithOperator,
  batch_tags_with_operator: BatchTagsWithOperator,
  shipment_tags_with_operator: ShipmentTagsWithOperator,
  file_tags_with_operator: FileTagsWithOperator,
  container_tags: ContainerTags,
  container_tags_with_operator: ContainerTagsWithOperator,
  user_tags: UserTags,
  file_tags: FileTags,
  file_type: FileType,
  organization_types: OrganizationTypes,
  mask_edit_type: MaskEditType,
  container_type: ContainerType,
  container_option: ContainerOption,
  country: Country,
  completely_batched: CompletelyBatched,
  completely_shipped: CompletelyShipped,
  has_shipment: HasShipment,
  has_entity: HasEntity,
  has_unseen_notification: HasUnseenNotification,
  free_time_overdue: FreeTimeOverdue,
  booked: Booked,
  ports: Ports,
  shipment_load_type: ShipmentLoadType,
}

const computeFilterStates = <
  F: { ...$Diff<DefaultFilterInput, { query: ?string }>, [key: string]: any }
>(
  config: FilterConfig[],
  filter: F,
  customFieldData?: FieldDefinitionsMemo
): FilterState<F>[] => {
  const newFilterStates = Object.keys(filter).flatMap((field) =>
    field !== 'customFields'
      ? {
          ...config.find((c) => c.field === field),
          key: field,
          value: filter[field],
        }
      : // $FlowIgnore
        ([]: FilterState<F>[])
  )

  if (filter.customFields) {
    const { customFields } = filter
    return [
      ...newFilterStates,
      ...customFields.map((filterByCf) => {
        const foundCustomField =
          customFieldData &&
          customFieldData[filterByCf.entity].fieldDefinitions.find(
            (searchedCf) => searchedCf.id === filterByCf.fieldId
          )
        return {
          entity: filterByCf.entity,
          field: filterByCf.fieldId,
          type: 'custom_field_date_range',
          message: foundCustomField?.name ?? '',
          fieldId: filterByCf.fieldId ?? null,
          key: filterByCf.fieldId ?? null,
          value: filterByCf.date ?? null,
          __typename: !foundCustomField ? 'FieldOptionRemoteToFind' : undefined,
        }
      }),
    ]
  }
  return newFilterStates
}

const cleanFilterStates = <
  F: { ...$Diff<DefaultFilterInput, { query: ?string }>, [key: string]: any }
>(
  filters: FilterState<F>[]
): FilterState<F>[] =>
  filters
    .filter((s) => s.entity !== null && s.field !== null && s.type !== null)
    .map((filter: FilterState<F>) => {
      switch (filter.type) {
        case 'ports':
          return {
            ...filter,
            value: filter.value?.filter(
              (v) => !!v.seaport || !!v.airport || !!v.rail || !!v.road || !!v.dryport
            ),
          }
        default:
          return filter
      }
    })

const Filter = <F: { ...$Diff<DefaultFilterInput, { query: ?string }>, [key: string]: any }>({
  config,
  rootQueryType,
  filterBy,
  staticFilters,
  onChange,
}: Props<F>): React.Node => {
  const intl = useIntl()
  const buttonRef = useRef<null | HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [filterStates, setFilterStates] = useState<FilterState<F>[]>(
    computeFilterStates<F>(config, filterBy)
  )

  useEffect(() => {
    setFilterStates(computeFilterStates<F>(config, filterBy))
  }, [config, filterBy])

  const {
    data: customFieldsData,
    loadMore: loadMoreCustomFields,
    loading: loadingCustomFields,
  } = useCustomFieldsLazy()

  const remoteFieldOptionsToFind: FieldOptionRemoteToFind[] = filterStates.filter(
    (filterState) => filterState.__typename === 'FieldOptionRemoteToFind'
  )

  if (remoteFieldOptionsToFind[0] && !loadingCustomFields) {
    loadMoreCustomFields({
      entityType: remoteFieldOptionsToFind[0].entity,
      onCompleted: (res) => {
        setFilterStates(computeFilterStates<F>(config, filterBy, res))
      },
    })
  }

  const onSave = () => {
    const states = cleanFilterStates(filterStates)

    onChange({
      ...states.reduce(
        (f, state) => ({
          ...f,
          ...(state.type === 'custom_field_date_range'
            ? {
                customFields: ([
                  ...(f.customFields ?? []),
                  {
                    fieldId: state.fieldId,
                    date: state.value,
                    entity: state.entity,
                  },
                  // Do NOT attempt to match custom_field_date_range with a text filter input, this is reserved for text type custom fields
                ]: $Diff<EntityCustomFieldInput, { text?: ?string }>[]),
              }
            : typeof state.field === 'string'
            ? { [(state.field: string)]: state.value }
            : {}),
        }),
        // $FlowExpectedError: Computes as partial F
        ({}: Partial<F>)
      ),
    })

    setOpen(false)
    setFilterStates(states)
  }

  const onReset = useCallback(() => {
    setFilterStates(computeFilterStates(config, filterBy, customFieldsData))
  }, [config, filterBy, customFieldsData])

  useEffect(() => {
    return () => {
      if (!open) {
        onReset()
      }
    }
  }, [onReset, open])

  const onClearAll = () => {
    setFilterStates(filterStates.filter((fs) => (staticFilters || []).includes(fs.field)))
  }
  // We need to filter out the bulk filter here so it doesnt cause the icon to be active.
  const isActive =
    filterStates.filter((b) => b.field !== 'bulkFilter').length > 0 &&
    filterStates.filter((b) => b.field !== 'keywords').length > 0
  const hasWeakFilter = !!filterStates.find(
    (f) => f.entity === null || f.field === null || f.type === null
  )
  const availableConfig = config.filter(
    (c: FilterConfig) =>
      !filterStates.find((f) => f.entity === c.entity && f.field === c.field) && !c.hidden
  )
  const readonlyFilters = filterStates.filter((fs) => (staticFilters || []).includes(fs.field))
  const canAddFilter = availableConfig.length > 0 && !hasWeakFilter
  const isDirty = !isEquals(filterStates, computeFilterStates(config, filterBy, customFieldsData))

  return (
    <>
      <button ref={buttonRef} className={ButtonStyle} type="button" onClick={() => setOpen(!open)}>
        {isActive && <span className={ActiveStyle} />}
        <Icon icon="FILTER" />
      </button>

      <Dialog isOpen={open} onRequestClose={() => setOpen(false)} width="720px">
        <div className={WrapperStyle}>
          <div className={ActionsStyle}>
            <BaseButton
              icon="REMOVE"
              label={<FormattedMessage {...messages.clearAll} />}
              textColor="GRAY_DARK"
              hoverTextColor="WHITE"
              backgroundColor="GRAY_SUPER_LIGHT"
              hoverBackgroundColor="GRAY_LIGHT"
              onClick={onClearAll}
            />
            {isDirty && (
              <>
                <ResetButton className={ResetActionStyle} onClick={onReset} />
                <SaveButton onClick={onSave} id="saveFilterButton" />
              </>
            )}
          </div>

          <div className={FiltersListStyle}>
            {readonlyFilters.map(
              ({
                entity: filterEntity,
                field: filterField,
                type: filterType,
                value: filterValue,
              }) => {
                return (
                  <div
                    key={`${filterEntity || ''}-${filterField || ''}`}
                    className={FilterWrapperStyle}
                  >
                    <div className={InputsWrapperStyle}>
                      <div>
                        <Label height="30px" required>
                          <FormattedMessage {...messages.category} />
                        </Label>
                        <SelectInput
                          value={filterEntity}
                          items={[filterEntity]}
                          name="entity"
                          itemToString={(i) => i?.toUpperCase() ?? ''}
                          itemToValue={(i) => i}
                          readOnly
                          readOnlyWidth="200px"
                          readOnlyHeight="30px"
                        />
                      </div>
                      <div>
                        <Label height="30px" required>
                          <FormattedMessage {...messages.filter} />
                        </Label>
                        <SelectInput
                          value={filterField}
                          items={[filterField]}
                          name="field"
                          itemToString={(i) => {
                            const message = config.find(
                              (c) => c.entity === filterEntity && c.field === i
                            )?.message
                            const value = message ? intl.formatMessage(message) : i

                            return value?.toUpperCase() ?? ''
                          }}
                          itemToValue={(i) => i}
                          readOnly
                          readOnlyWidth="200px"
                          readOnlyHeight="30px"
                        />
                      </div>
                      <div>
                        {filterType !== null &&
                          React.createElement(inputs[filterType], {
                            value: filterValue,
                            onChange: () => {},
                            readonly: true,
                          })}
                      </div>
                    </div>
                  </div>
                )
              }
            )}
            {filterStates.map(
              (
                { field: filterField, entity: filterEntity, type: filterType, value: filterValue },
                index
              ) => {
                if (filterField === 'bulkFilter') {
                  return null
                }
                if (filterField === 'keywords') {
                  return null
                }
                if ((staticFilters || []).includes(filterField)) {
                  return null
                }

                const onEntityChange = (entity: CustomizableEntityType) => {
                  setFilterStates(
                    filterStates.map((fs, i) =>
                      i === index
                        ? {
                            entity,
                            key: null,
                            field: null,
                            type: null,
                            value: null,
                          }
                        : fs
                    )
                  )
                }

                const onFieldChange = (field: FieldOptionLocal | FieldOptionRemote) => {
                  setFilterStates(
                    filterStates.map((fs, i) =>
                      i === index
                        ? field.__typename === 'FieldOptionLocal'
                          ? {
                              key: fs.key,
                              entity: fs.entity,
                              field: field.value,
                              type: config.find((c) => c.field === field.value)?.type ?? null,
                              value:
                                config.find((c) => c.field === field.value)?.defaultValue ?? null,
                            }
                          : {
                              key: fs.key ?? '',
                              entity: fs.entity,
                              field: field.value,
                              fieldId: field.key,
                              type: 'custom_field_date_range',
                              value: { after: null, before: null },
                            }
                        : fs
                    )
                  )
                }

                const onValueChange = (value: any) => {
                  const newFilterStates = filterStates.map((fs, filterIndex) => {
                    return filterIndex === index
                      ? {
                          ...fs,
                          value,
                        }
                      : fs
                  })

                  setFilterStates(newFilterStates)
                }

                const onDelete = () => {
                  const newStates = [...filterStates]
                  newStates.splice(index, 1)
                  setFilterStates(newStates)
                }

                const entities = new Set(
                  config
                    .filter(
                      (c: FilterConfig) =>
                        c.entity === filterEntity ||
                        (!filterStates.find((f) => f.entity === c.entity && f.field === c.field) &&
                          !c.hidden)
                    )
                    .map((c) => c.entity)
                )

                const fields: (FieldOptionLocal | FieldOptionRemote)[] = [
                  // The regular config options
                  ...config
                    .filter(
                      (c: FilterConfig) =>
                        c.entity === filterEntity &&
                        (c.field === filterField ||
                          (!filterStates.find(
                            (f) => f.entity === c.entity && f.field === c.field
                          ) &&
                            !c.hidden))
                    )
                    .map((c) => ({ key: c.field, value: c.field, __typename: 'FieldOptionLocal' })),
                  // Include customFields if this entity is of CustomizableEntityType
                  ...(typeof filterEntity === 'string' &&
                  (filterEntity === 'Container' ||
                    filterEntity === 'Order' ||
                    filterEntity === 'OrderItem' ||
                    filterEntity === 'Shipment' ||
                    filterEntity === 'Batch') &&
                  // filterEntity === 'Product' ||  // ZEN-4247 Backend unavailable
                  // filterEntity === 'ProductProvider' ||  // ZEN-4248 Backend unavailable
                  // filterEntity === 'Warehouse' ||  // ZEN-4256 Backend unavailable
                  (filterEntity === rootQueryType ||
                    // Custom field searchable subentity
                    (filterEntity === 'Container' && rootQueryType === 'Shipment') ||
                    (filterEntity === 'Shipment' && rootQueryType === 'Batch'))
                    ? customFieldsData[(filterEntity: CustomizableEntityType)].fieldDefinitions
                        .filter((fd) => fd.type === 'Date')
                        .map(({ id, name, type, connectionBy }) => ({
                          key: id,
                          value: id,
                          message: name,
                          type,
                          icon:
                            connectionBy?.__typename === 'Connection' ? (
                              <ConnectionAvatar
                                id={connectionBy.id}
                                name={connectionBy.displayName}
                              />
                            ) : connectionBy === null ? (
                              <InternalBadge />
                            ) : (
                              <></>
                            ),
                          __typename: 'FieldOptionRemote',
                        }))
                    : []),
                ]

                return (
                  <div
                    key={`${filterEntity || ''}-${filterField || ''}`}
                    className={FilterWrapperStyle}
                  >
                    <div className={InputsWrapperStyle}>
                      <div>
                        <Label height="30px" required>
                          <FormattedMessage {...messages.category} />
                        </Label>
                        <SelectInput
                          value={filterEntity}
                          onChange={onEntityChange}
                          name="entity"
                          itemToString={(i) => i?.toUpperCase() ?? ''}
                          itemToValue={(i) => i}
                          items={[...entities]}
                          renderSelect={({ ...rest }) => <DefaultSelect hideClearIcon {...rest} />}
                          renderOptions={({ ...rest }) => (
                            <DefaultOptions {...rest} width="200px" />
                          )}
                        />
                      </div>

                      <div>
                        <Label height="30px" required>
                          <FormattedMessage {...messages.filter} />
                        </Label>

                        {filterEntity !== null ? (
                          <SelectInput
                            value={filterField}
                            onChange={onFieldChange}
                            name="field"
                            itemToString={(i: null | FieldOptionLocal | FieldOptionRemote) => {
                              let value
                              if (i?.__typename === 'FieldOptionLocal') {
                                const messageConfig = config.find(
                                  (c) => c.entity === filterEntity && c.field === i.value
                                )?.message
                                if (messageConfig) {
                                  value = intl.formatMessage(messageConfig)
                                }
                              } else if (i?.__typename === 'FieldOptionRemote') {
                                value = i.message
                              }

                              return value?.toUpperCase() ?? ''
                            }}
                            itemToValue={(i: null | FieldOptionLocal | FieldOptionRemote) => {
                              return i?.value
                            }}
                            items={fields}
                            renderSelect={({ ...rest }) => (
                              <DefaultSelect hideClearIcon {...rest} />
                            )}
                            renderOptions={({ ...rest }) => (
                              <DefaultOptions
                                {...rest}
                                width="200px"
                                loadMore={() => {
                                  if (
                                    !loadingCustomFields &&
                                    typeof filterEntity === 'string' &&
                                    (filterEntity === 'Container' ||
                                      filterEntity === 'Order' ||
                                      filterEntity === 'OrderItem' ||
                                      filterEntity === 'Shipment' ||
                                      filterEntity === 'Batch') &&
                                    // filterEntity === 'Product' ||  // ZEN-4247 Backend unavailable
                                    // filterEntity === 'ProductProvider' ||  // ZEN-4248 Backend unavailable
                                    // filterEntity === 'Warehouse' ||  // ZEN-4256 Backend unavailable
                                    (filterEntity === rootQueryType ||
                                      // Custom field searchable subentity
                                      (filterEntity === 'Container' &&
                                        rootQueryType === 'Shipment') ||
                                      (filterEntity === 'Shipment' && rootQueryType === 'Batch'))
                                  ) {
                                    loadMoreCustomFields({
                                      entityType: filterEntity,
                                    })
                                  }
                                }}
                              />
                            )}
                          />
                        ) : (
                          <Display height="30px" color="GRAY_LIGHT">
                            <FormattedMessage {...messages.chooseCategory} />
                          </Display>
                        )}
                      </div>

                      <div>
                        {filterType !== null && filterType !== undefined ? (
                          React.createElement(inputs[filterType], {
                            value: filterValue,
                            onChange: onValueChange,
                            readonly: false,
                          })
                        ) : (
                          <>
                            <Label height="30px" />
                            <Display height="30px" color="GRAY_LIGHT">
                              <FormattedMessage {...messages.chooseFilter} />
                            </Display>
                          </>
                        )}
                      </div>
                    </div>

                    <button type="button" className={DeleteButtonStyle} onClick={onDelete}>
                      <Icon icon="REMOVE" />
                    </button>
                  </div>
                )
              }
            )}

            {canAddFilter ? (
              <BaseButton
                icon="ADD"
                label={<FormattedMessage {...messages.addFilter} />}
                backgroundColor="TEAL"
                hoverBackgroundColor="TEAL_DARK"
                onClick={() =>
                  setFilterStates([
                    ...filterStates,
                    {
                      fieldId: null,
                      key: null,
                      entity: null,
                      field: null,
                      type: null,
                      value: null,
                    },
                  ])
                }
              />
            ) : (
              <Tooltip message={<FormattedMessage {...messages.disabledAddMessage} />}>
                <div className={AddFilterButtonWrapperStyle}>
                  <BaseButton
                    icon="ADD"
                    label={<FormattedMessage {...messages.addFilter} />}
                    backgroundColor="TEAL"
                    hoverBackgroundColor="TEAL_DARK"
                    disabled
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Filter
