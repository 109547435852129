// TODO: Infer "connected" entities from GQL schema with conditional TypeScript generics
// @flow strict

import { AllMainEntityTypeValues } from 'types'

const ENTITIES_WITH_CONNECTION_MAP = Object.freeze({
  Batch: AllMainEntityTypeValues['Batch'],
  Container: AllMainEntityTypeValues['Container'],
  Order: AllMainEntityTypeValues['Order'],
  OrderItem: AllMainEntityTypeValues['OrderItem'],
  ProductProvider: AllMainEntityTypeValues['ProductProvider'],
  Shipment: AllMainEntityTypeValues['Shipment'],
})

type EntityWithConnection = $Keys<typeof ENTITIES_WITH_CONNECTION_MAP>

const ENTITIES_WITH_CONNECTION: EntityWithConnection[] = Object.keys(ENTITIES_WITH_CONNECTION_MAP)

export default ENTITIES_WITH_CONNECTION
