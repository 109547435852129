import React, { createContext, useContext } from 'react'

import useLocalStorage from '@hooks/useLocalStorage'

interface Context {
  isSideBarExpanded: boolean
  toggleSideBarExpansion: () => void
}

const SideBarContext = createContext<Context>({
  isSideBarExpanded: true,
  toggleSideBarExpansion: () => {},
})

export const useSideBar = (): Context => useContext(SideBarContext)

interface Props {
  children: React.ReactNode
}

const isSideBarExpandedStorageKey = 'is-sidebar-expanded'

export const SideBarProvider = ({ children }: Props) => {
  const [localStorageVal, setLocalStorageVal] = useLocalStorage(isSideBarExpandedStorageKey, true)

  const [isSideBarExpanded, setIsSideBarExpanded] = React.useState(localStorageVal)

  React.useEffect(() => {
    setLocalStorageVal(isSideBarExpanded)
  }, [setLocalStorageVal, isSideBarExpanded])

  return (
    <SideBarContext.Provider
      value={{
        isSideBarExpanded,
        toggleSideBarExpansion: () => setIsSideBarExpanded(!isSideBarExpanded),
      }}
    >
      {children}
    </SideBarContext.Provider>
  )
}
