// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { MessageGroup, MessageGroupPayload } from '@graphql/server/flow'

import { BaseButton } from 'components/Buttons'
import messages from 'components/Form/DocumentsUpload/messages'
import { DeliveryBoxSortConfig, Sort } from 'components/NavBar'
import { isEnableBetaFeature } from 'utils/env'
import { downloadAndZip } from 'utils/file'

import { NavbarSecondRowContainerStyle } from '../../style'
import type { DocumentSortBy } from '../../type.js.flow'

type Props = {
  messageGroups: MessageGroupPayload[],
  sortBy: DocumentSortBy,
  setSortBy: Function,
}

const DocumentNavbarSecondRowContent = ({
  messageGroups,
  sortBy,
  setSortBy,
}: Props): React.Element<'div'> => {
  const parsedMessageGroups = ((messageGroups.filter(
    (messageGroup) => messageGroup.__typename === 'MessageGroup'
  ): any[]): MessageGroup[])
  const hasFiles = parsedMessageGroups.some((messageGroup) => messageGroup.id && messageGroup.files)

  return (
    <div className={NavbarSecondRowContainerStyle}>
      <div>
        {isEnableBetaFeature && (
          <Sort config={DeliveryBoxSortConfig} sortBy={sortBy} onChange={setSortBy} />
        )}
      </div>
      <div>
        {hasFiles && (
          <BaseButton
            icon="DOWNLOAD"
            label={<FormattedMessage {...messages.downloadAll} />}
            backgroundColor="GRAY_SUPER_LIGHT"
            hoverBackgroundColor="GRAY_VERY_LIGHT"
            textColor="GRAY_DARK"
            hoverTextColor="GRAY_DARK"
            onClick={(e) => {
              e.stopPropagation()

              const filesToDownload = parsedMessageGroups.reduce((arr, messageGroup) => {
                const newFiles = messageGroup.files.flatMap((file) => {
                  if (file.__typename !== 'File') return []
                  const { path, name } = file
                  return {
                    url: path,
                    name,
                    group: messageGroup.organizations
                      .flatMap((organization) =>
                        organization.__typename === 'Organization' ? organization.name : []
                      )
                      .join(', '),
                  }
                })

                return [...arr, ...newFiles]
              }, [])

              downloadAndZip(filesToDownload)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default DocumentNavbarSecondRowContent
