// @flow strict
import scroll from 'scroll-into-view-if-needed'

import logger from 'utils/logger'

type Props = {
  targetId: string,
  boundaryId?: string,
  scrollMode?: string,
}

const scrollIntoView = ({ targetId, boundaryId, scrollMode = 'if-needed' }: Props) => {
  const node = document.querySelector(`#${targetId}`)
  const boundaryNode =
    typeof boundaryId === 'string' ? document.querySelector(`#${boundaryId}`) : null

  if (node) {
    logger.warn('scroll to element', targetId, boundaryId)
    if (boundaryNode) {
      scroll(node, {
        scrollMode,
        behavior: 'smooth',
        boundary: boundaryNode,
      })
    } else {
      scroll(node, {
        scrollMode,
        behavior: 'smooth',
      })
    }
  } else {
    // wait for the element is rendering on DOM
    const retryFindElement = () => {
      const foundElement = document.querySelector(`#${targetId}`)
      if (!foundElement) {
        requestAnimationFrame(retryFindElement)
      } else {
        // force to scroll to element
        scrollIntoView({ targetId, boundaryId, scrollMode: 'always' })
      }
    }
    requestAnimationFrame(retryFindElement)
  }
}

export default scrollIntoView
