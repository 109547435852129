// @flow strict
import type {
  ContainerFormFragment,
  ContainerGroupFragment,
  ContainerGroupSummaryFragment,
  Scalars,
  TimelineDateFullFragment,
  TimelineDateMinimalFragment,
  VoyageFragment,
  VoyageSummaryFragment,
} from '@graphql/server/flow'

import { colors } from 'styles/common'
import type { Payload } from 'types'
import { isAfter, isBefore } from 'utils/date'
import { getByPath, isNullOrUndefined } from 'utils/fp'

export const getTimelineColoring = ({
  cargoReady,
  voyages,
  containerGroups,
  containers,
}: {
  cargoReady: ?(TimelineDateMinimalFragment | TimelineDateFullFragment),
  voyages: Payload<VoyageSummaryFragment>[] | Payload<VoyageFragment>[] | VoyageFragment[] | {||}[],
  containerGroups:
    | Payload<ContainerGroupSummaryFragment>[]
    | Payload<ContainerGroupFragment>[]
    | ContainerGroupFragment[],
  containers: Payload<ContainerFormFragment>[] | ContainerFormFragment[],
}): $Keys<typeof colors>[] => {
  // Add all boolean approvals of all the dates to an array in order
  const arrayOfApprovals: boolean[] = [!!cargoReady?.approvedAt]
  if (voyages.length) {
    voyages.forEach((voyage) => {
      arrayOfApprovals.push(!!voyage.departure?.approvedAt, !!voyage.arrival?.approvedAt)
    })
  }

  const containersWarehouseApprovedAt =
    containers && containers.length
      ? containers.reduce(
          (accumulator, c) =>
            accumulator &&
            !!c.warehouseArrivalActualDateApprovedAt &&
            !!c.warehouseArrivalAgreedDateApprovedAt,
          true
        )
      : false

  if (containerGroups && containerGroups.length) {
    const { customClearance, deliveryReady, warehouseArrival } = containerGroups[0]
    arrayOfApprovals.push(
      !!(customClearance && customClearance.approvedAt),
      !!((warehouseArrival && warehouseArrival.approvedAt) || containersWarehouseApprovedAt),
      !!(deliveryReady && deliveryReady.approvedAt)
    )
  }

  // Reverse the array in order to traverse it logically
  arrayOfApprovals.reverse()

  // Make new array to store colors by traversing through the arrayOfApprovals
  const arrayOfColors = []
  arrayOfApprovals.forEach((approval) => {
    if (approval === true) {
      arrayOfColors.push('TEAL')
    } else {
      arrayOfColors.push('GRAY_LIGHT')
    }
  })

  return arrayOfColors.reverse()
}

export const getContainerDatesRange = (
  containers: ContainerFormFragment[] = []
): {|
  actualApproved: boolean,
  agreedApproved: boolean,
  maxActualDate: Scalars['DateTime'] | null,
  maxAgreedDate: Scalars['DateTime'] | null,
  minActualDate: Scalars['DateTime'] | null,
  minAgreedDate: Scalars['DateTime'] | null,
|} => {
  let minAgreedDate = null
  let maxAgreedDate = null
  let agreedApproved = false
  let minActualDate = null
  let maxActualDate = null
  let actualApproved = false

  let agreedApprovalCounter = 0
  let actualApprovalCounter = 0

  containers.forEach((container) => {
    const warehouseArrivalAgreedDate = getByPath('warehouseArrivalAgreedDate', container)
    const warehouseArrivalAgreedDateApprovedAt = getByPath(
      'warehouseArrivalAgreedDateApprovedAt',
      container
    )
    const warehouseArrivalAgreedDateApprovedBy = getByPath(
      'warehouseArrivalAgreedDateApprovedBy',
      container
    )
    const warehouseArrivalActualDate = getByPath('warehouseArrivalActualDate', container)
    const warehouseArrivalActualDateApprovedAt = getByPath(
      'warehouseArrivalActualDateApprovedAt',
      container
    )
    const warehouseArrivalActualDateApprovedBy = getByPath(
      'warehouseArrivalActualDateApprovedBy',
      container
    )
    if (!isNullOrUndefined(warehouseArrivalAgreedDate)) {
      if (!isNullOrUndefined(minAgreedDate)) {
        if (isBefore(new Date(warehouseArrivalAgreedDate), new Date(minAgreedDate ?? ''))) {
          minAgreedDate = warehouseArrivalAgreedDate
        }
      } else {
        minAgreedDate = warehouseArrivalAgreedDate
      }

      if (!isNullOrUndefined(maxAgreedDate)) {
        if (isAfter(new Date(warehouseArrivalAgreedDate), new Date(maxAgreedDate ?? ''))) {
          maxAgreedDate = warehouseArrivalAgreedDate
        }
      } else {
        maxAgreedDate = warehouseArrivalAgreedDate
      }
    }

    if (!isNullOrUndefined(warehouseArrivalActualDate)) {
      if (!isNullOrUndefined(minActualDate)) {
        if (isBefore(new Date(warehouseArrivalActualDate), new Date(minActualDate ?? ''))) {
          minActualDate = warehouseArrivalActualDate
        }
      } else {
        minActualDate = warehouseArrivalActualDate
      }

      if (!isNullOrUndefined(maxActualDate)) {
        if (isAfter(new Date(warehouseArrivalActualDate), new Date(maxActualDate ?? ''))) {
          maxActualDate = warehouseArrivalActualDate
        }
      } else {
        maxActualDate = warehouseArrivalActualDate
      }
    }

    if (
      !isNullOrUndefined(warehouseArrivalAgreedDateApprovedAt) ||
      !isNullOrUndefined(warehouseArrivalAgreedDateApprovedBy)
    ) {
      agreedApprovalCounter += 1
    }

    if (
      !isNullOrUndefined(warehouseArrivalActualDateApprovedAt) ||
      !isNullOrUndefined(warehouseArrivalActualDateApprovedBy)
    ) {
      actualApprovalCounter += 1
    }
  })

  if (agreedApprovalCounter === containers.length) {
    agreedApproved = true
  }

  if (actualApprovalCounter === containers.length) {
    actualApproved = true
  }

  return {
    minAgreedDate,
    maxAgreedDate,
    agreedApproved,
    minActualDate,
    maxActualDate,
    actualApproved,
  }
}
