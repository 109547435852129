// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { BaseButton } from 'components/Buttons'

type Props = {|
  disabled: boolean,
  onUpload: Function,
|}

const UploadButton = ({ disabled, onUpload }: Props): React.Node => {
  return (
    <BaseButton
      backgroundColor="TEAL"
      hoverBackgroundColor="TEAL_DARK"
      disabled={disabled}
      icon="CHECKED_REGULAR"
      type="button"
      onClick={onUpload}
      label={
        <div>
          <FormattedMessage
            id="documents.button.uploadDocuments"
            defaultMessage="Upload Documents"
          />
        </div>
      }
    />
  )
}

export default UploadButton
