import { ConnectionColor } from '@types'

export const colors = {
  EXCEL: '#217338',
  PDF: '#AD0B00',
  POWERPOINT: '#CB4424',
  WORD: '#2358C9',
  FILE: '#8697AB',

  WHITE: '#ffffff',
  BLACK: '#555555',

  BLUE: '#0b6ede',
  BLUE_HALF: 'rgba(11, 110, 222, 0.5)',
  BLUE_DARK: '#0756af',
  TEAL: '#11d1a6',
  TEAL_HALF: 'rgba(17, 209, 166, 0.5)',
  TEAL_VERY_DARK: '#08a380',
  TEAL_DARK: '#0bbc94',
  TEAL_QUITE_DARK: '#78c5b4',
  TEAL_LIGHT: '#caeee6',
  TEAL_QUITE_LIGHT: '#12B791',

  GRAY: '#bbbbbb',
  GRAY_DARK: '#aaaaaa',
  GRAY_DARK_1: '#555555',
  GRAY_LIGHT: '#cccccc',
  GRAY_LIGHT_HALF: 'rgba(204, 204, 204, 0.5)',
  GRAY_VERY_LIGHT: '#dddddd',
  GRAY_SUPER_LIGHT: '#eeeeee',

  RED: '#ef4848',
  RED_HALF: 'rgba(239, 72, 72, 0.5)',
  RED_DARK: '#b11717',
  YELLOW: '#ffd400',
  YELLOW_DARK: '#e9c200',
  ORANGE: '#ffa637',
  ORANGE_DARK: '#fc9719',
  PURPLE: '#a34fff',
  PURPLE_DARK: '#802fd9',

  TRANSPARENT: 'rgba(0, 0, 0, 0)',

  PRODUCT: '#F12C2C',
  PRODUCT_VERY_LIGHT: '#fde7e7',
  PRODUCT_PROVIDER: '#CF0000',
  PRODUCT_PROVIDER_VERY_LIGHT: '#ffe6e6',
  PRODUCT_PROVIDER_PACKAGE: '#E01616',
  PRODUCT_PROVIDER_PACKAGE_VERY_LIGHT: '#FFE6E7',
  ORDER: '#ED5724',
  ORDER_DARK: '#B63D1B',
  ORDER_VERY_LIGHT: '#fdede7',
  ORDER_ITEM: '#FBAA1D',
  ORDER_ITEM_DARK: '#C27C16',
  ORDER_ITEM_VERY_DARK: '#EA9500',
  ORDER_ITEM_VERY_LIGHT: '#fff6e6',
  BATCH: '#12B937',
  BATCH_DARK: '#118768',
  BATCH_VERY_LIGHT: '#e8fdec',
  SHIPMENT: '#0756AF',
  SHIPMENT_DARK: '#0A437F',
  SHIPMENT_VERY_LIGHT: '#eaf6fb',
  RELATION_MAP: '#04447E',
  CONTAINER_GROUP: '#2489CD',
  CONTAINER: '#30A8E4',
  CONTAINER_VERY_LIGHT: '#d2ecf9',
  CONTAINER_ITEM: '#8BDDFF',
  WAREHOUSE: '#9D865A',
  USER: '#98A2AC',
  PARTNER: '#474D4D',
  TAG: '#CCCCCC',
  LOGS: '#bbbbbb',
  TARGET: '#555555',
  ENTITY: '#0B6EDE',
  METADATA: '#CCCCCC',
  TEMPLATE: '#CCCCCC',
  EDIT: '#CCCCCC',
  DOCUMENT: '#8FBEB3',
  SHEET: '#5F9286',

  URGENT: '#F12C2C',

  HIGHLIGHT: 'rgba(251,170,29, 0.5)',
  HIGHLIGHT_DARK: '#EF8460',
}

export const connectionColors: Record<ConnectionColor, string> = {
  RED: '#FCECEE',
  RED2: '#F7CFD3',
  PURPLE: '#F1E5F4',
  PURPLE2: '#DBC0E4',
  INDIGO: '#E8EAF5',
  INDIGO2: '#C6CAE6',
  LIGHTBLUE: '#E5F4FD',
  LIGHTBLUE2: '#BCE4F9',
  TEAL: '#E3F1F1',
  TEAL2: '#BBDEDB',
  LIGHTGREEN: '#F2F8EA',
  LIGHTGREEN2: '#DFEBCB',
  YELLOW: '#FFFDE9',
  YELLOW2: '#FEF8CA',
  ORANGE: '#FDF3E2',
  ORANGE2: '#FAE0B7',
  BROWN: '#EEEBE9',
  BROWN2: '#D5CCC9',
  BLUEGREY: '#EDEFF1',
  BLUEGREY2: '#D1D8DB',
}

// background: gradients.BLUE_TEAL_DIAGONAL;
export const gradients = {
  BLUE_TEAL_VERTICAL: `linear-gradient(to bottom, ${colors.BLUE}, ${colors.TEAL})`,
  TEAL_BLUE_VERTICAL: `linear-gradient(to bottom, ${colors.TEAL}, ${colors.BLUE})`,
  BLUE_TEAL_HORIZONTAL: `linear-gradient(to right, ${colors.BLUE}, ${colors.TEAL})`,
  BLUE_TEAL_DIAGONAL: `linear-gradient(to bottom right, ${colors.BLUE}, ${colors.TEAL})`,
  TEAL_HORIZONTAL: `linear-gradient(to right, ${colors.TEAL}, ${colors.TEAL_DARK})`,
  TEAL_DARK_HORIZONTAL: `linear-gradient(to right, ${colors.TEAL_DARK}, ${colors.TEAL_VERY_DARK})`,
}

// font-size: MAIN
export const fontSizes = {
  HUGE: '20px',
  GIANT: '24px',
  LARGE: '16px',
  MAIN: '14px',
  MEDIUM: '13px',
  SMALL: '12px',
  LITTLE: '10px',
  TINY: '9px',
}

// box-shadow: WATERFALL
export const shadows = {
  WATERFALL: '0 10px 30px 0 rgba(0, 0, 0, 0.1)',
  DROPDOWN: '0 5px 20px rgba(0, 0, 0, 0.2)',
  NAV_BUTTON: '0 3px 10px rgba(0, 0, 0, 0.2)',
  TOOLTIP: '0 5px 10px rgba(0, 0, 0, 0.2)',
  INPUT: '0 3px 10px rgba(0, 0, 0, 0.2)',
  HEADER: '0 3px 10px rgba(0, 0, 0, 0.1)',
  HEADER_REVERSE: '0 -3px 10px rgba(0, 0, 0, 0.1)',
  HEADER_RIGHT: '3px 0 10px rgba(0, 0, 0, 0.1)',
  HEADER_LEFT: '-3px 0 10px rgba(0, 0, 0, 0.1)',
  FAINT: '0 0 10px rgba(0, 0, 0, 0.1)',
}

// border-radius: MAIN
export const borderRadiuses = {
  MAIN: '5px',
  MAIN_INSIDE: '4px',
  CIRCLE: '50%',
  BUTTON: '999px',
}

// transition: MAIN
export const transitions = {
  MAIN: 'all 0.1s linear',
  EXPAND: 'all 0.2s ease-out',
}

// MIXINS

export const animations = {
  FADE_OUT: `@keyframes topFadeOut {
    0% {
      position: absolute;
      top: -3rem;
      opacity: 0;
    }

    75% {
      position: absolute;
      top: 25%;
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
  animation: topFadeOut 0.5s forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
  `,
  FADE_IN: `@keyframes  fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
  `,
}

export const scrollbars = {
  MAIN: `
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${colors.GRAY_LIGHT};
      border: 7px solid transparent;
      background-clip: content-box;
      min-height: 40px;
      &:hover {
        background-color: ${colors.GRAY};
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  `,
  SMALL: `
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${colors.GRAY_LIGHT};
      border: 3px solid transparent;
      background-clip: content-box;
      &:hover {
        background-color: ${colors.GRAY};
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  `,
  SMALL_WHITE: `
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fff;
      border: 3px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  `,
}

export const fontSizesWithHeights = {
  MAIN: 'font-size: 14px; min-height: 20px; line-height: 20px',
  HUGE: 'font-size: 20px; min-height: 26px; line-height: 26px',
  GIANT: 'font-size: 24px; min-height: 30px; line-height: 30px',
  LARGE: 'font-size: 16px; min-height: 24px; line-height: 24px',
  SMALL: 'font-size: 12px; min-height: 18px; line-height: 18px',
  MEDIUM: 'font-size: 13px; min-height: 19px; line-height: 19px',
}

export const layout: any = {}
layout.LAYOUT = 'display: flex;'
layout.HORIZONTAL = `
  ${layout.LAYOUT}
  flex-direction: row;
`
layout.VERTICAL = `
  ${layout.LAYOUT}
  flex-direction: column;
`
layout.WRAP = `
  ${layout.LAYOUT}
  flex-wrap: wrap;
`
layout.CENTER = `
  align-items: center;
`
layout.JUSTIFIED_CENTER = `
  justify-content: center;
`
layout.CENTER_CENTER = `
  ${layout.CENTER}
  ${layout.JUSTIFIED_CENTER}
`
layout.FIT = `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
layout.GRID_FORM = `
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: auto;
  min-width: min-content;
`
layout.GRID_VERTICAL = `
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
`
layout.GRID_HORIZONTAL = `
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
`

export const presets = {
  BUTTON: `
    ${layout.HORIZONTAL};
    ${layout.CENTER_CENTER};
    ${transitions.MAIN};
    cursor: pointer;
    user-select: none;
    border: 0;
    &:focus {
      outline: 0;
    }

    &[disabled] {
      cursor: default;
    }
  `,
  ELLIPSIS: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  MULTI_LINE_ELLIPSIS: `
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  `,
  BOX: `
    box-shadow: ${shadows.WATERFALL};
    border-radius: ${borderRadiuses.MAIN};
    background-color: ${colors.WHITE};
  `,
}
