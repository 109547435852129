// @flow strict
import * as React from 'react'
import { cx } from 'react-emotion'

import { GridRowAutoWidthWrapperStyle, GridRowWrapperStyle } from './style'

type Props = {
  children: React.Node,
  width?: string,
  autoWidth?: boolean,
  gap?: string,
  hCentered?: boolean,
  width?: string,
  className?: string,
}

function GridRow({
  autoWidth,
  children,
  gap = '20px',
  width,
  hCentered = false,
  className,
}: Props): React.Element<'div'> {
  return (
    <div
      className={cx(
        autoWidth !== true
          ? GridRowWrapperStyle(gap, width, hCentered)
          : GridRowAutoWidthWrapperStyle(gap, width),
        className
      )}
    >
      {children}
    </div>
  )
}

export default GridRow
