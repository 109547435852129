// @flow
import 'firebase/database'

import firebase from 'firebase/app'

const firebaseApp: any = firebase.initializeApp({
  apiKey: process.env.ZENPORT_FIREBASE_API_KEY || '',
  databaseURL: `https://${process.env.ZENPORT_FIREBASE_PROJECT_ID || ''}.firebaseio.com`,
  projectId: process.env.ZENPORT_FIREBASE_PROJECT_ID || '',
})

firebaseApp.database()

export default firebaseApp
