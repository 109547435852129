// @flow
import React, { useCallback } from 'react'
import { type IntlShape, injectIntl } from 'react-intl'

import { hasZSuffix } from '@utils/date'

import { Display } from 'components/Form'
import messages from 'components/Form/Inputs/messages'
import { type InputProps, defaultInputProps } from 'components/Form/Inputs/type'
import FormattedDateTZ from 'components/FormattedDateTZ'
import useUser from 'hooks/useUser'
import {
  addTimezone,
  formatDatetimeQueryToDatetimeWithTimezone,
  formatDatetimeWithTimezoneToUTCDatetime,
  removeTimezone,
} from 'utils/date'
import { isNullOrUndefined } from 'utils/fp'

type Props = InputProps & {
  intl: IntlShape,
}

const DateTimeInput = ({
  intl,
  value,
  align,
  readOnly,
  readOnlyWidth,
  readOnlyHeight,
  placeholder,
  inputRef,
  onBlur,
  ...rest
}: Props) => {
  const { user } = useUser()

  const handleBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur({
          ...e,
          target: {
            ...e.target,
            value: addTimezone(e.target.value, user.timezone),
          },
        })
      }
    },
    [onBlur, user.timezone]
  )

  return readOnly ? (
    <Display align={align} width={readOnlyWidth} height={readOnlyHeight}>
      <FormattedDateTZ
        value={removeTimezone(formatDatetimeWithTimezoneToUTCDatetime(value))}
        user={user}
        showTime
      />
    </Display>
  ) : (
    <input
      ref={inputRef}
      value={removeTimezone(
        hasZSuffix(value)
          ? // If the value has a z suffix, assume from server, whereby we need to add the user's timezone to
            // the actual time and then remove the timezone suffix
            // ex. user with TMZ +09:00:
            // 2022-01-01T00:00:00Z -> 2022-01-01T09:00+09:00 -> 2022-01-01T09:00
            formatDatetimeQueryToDatetimeWithTimezone(value, user.timezone)
          : // If the value does not have a z suffix, assume from local edit, whereby we need only remove
            // the timezone suffix
            // ex. user with TMZ +09:00:
            // 2022-01-01T09:00:+09:00 -> 2022-01-01T09:00
            value
      )}
      style={{ textAlign: align }}
      placeholder={
        isNullOrUndefined(placeholder)
          ? intl.formatMessage(messages.defaultPlaceholder)
          : placeholder
      }
      onBlur={handleBlur}
      {...rest}
      type="datetime-local"
    />
  )
}

DateTimeInput.defaultProps = defaultInputProps

export default (injectIntl(DateTimeInput): any)
