// @flow
import { css } from 'react-emotion'

import {
  borderRadiuses,
  colors,
  fontSizesWithHeights,
  layout,
  shadows,
  transitions,
} from 'styles/common'

export const MainNameStyle: any = css`
  ${transitions.MAIN};
  ${borderRadiuses.MAIN};
  padding: 0 2px;
  background-color: ${colors.WHITE};
  cursor: default;
  &:hover {
    color: ${colors.WHITE};
    background-color: rgba(17, 209, 166, 0.4);
  }
`

export const UserWrapperStyle: any = css`
  ${shadows.TOOLTIP};
  background-color: ${colors.WHITE};
  padding: 0;

  & > .tippy-roundarrow {
    fill: ${colors.WHITE};
  }
`

export const ContentWrapperStyle: any = css`
  ${layout.HORIZONTAL};
`

export const AvatarWrapperStyle: any = css`
  padding: 10px;
`

export const InfoWrapperStyle: any = css`
  ${layout.VERTICAL};
  align-items: start;
  padding: 5px 10px 10px 0;
`

export const NameStyle: any = css`
  ${fontSizesWithHeights.MAIN};
  font-weight: 600;
  color: ${colors.BLACK};
  padding-bottom: 5px;
`

export const GroupStyle: any = css`
  ${fontSizesWithHeights.SMALL};
  color: ${colors.GRAY_DARK};
`

export const EmailStyle: any = css`
  ${fontSizesWithHeights.SMALL};
  color: ${colors.GRAY_DARK};
`

export const RolesStyle: any = css`
  ${fontSizesWithHeights.SMALL};
  color: ${colors.BLACK};
  & > svg {
    margin: 0 5px 0 0;
    color: ${colors.GRAY_DARK};
  }
`
