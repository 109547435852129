import styled, { css } from 'styled-components'

import { borderRadiuses, colors, fontSizes, presets, transitions } from '@styles/common'

export const SubMenuWrapperStyle = styled.div`
  overflow: hidden;
  min-height: min-content;
  flex-shrink: 0;
`

interface SubItemProps {
  isExpanded: boolean
  hasActiveChild: boolean
}

export const SubMenuItemWrapperStyle = styled.div<SubItemProps>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  color: ${colors.WHITE};
  width: 100%;
  height: 50px;
  font-size: ${fontSizes.MAIN};
  letter-spacing: 1px;
  cursor: pointer;
  transition: ${transitions.MAIN};
  box-shadow: ${({ isExpanded }) => (isExpanded ? '0 3px 5px rgba(0, 0, 0, 0.1)' : 'none')};
  &:focus {
    outline: none;
  }
  & > span {
    min-width: 5px;
    height: ${({ hasActiveChild }) => (hasActiveChild ? '50px' : '0px')};
    background-color: rgba(255, 255, 255, 0.5);
    transition: ${transitions.MAIN};
  }
  &:hover {
    & > span {
      height: 50px;
    }
  }
`

export const SubMenuItemStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: ${fontSizes.SMALL};
  text-transform: uppercase;
`

export const ChevronButtonStyle = styled.div<SubItemProps>`
  ${presets.BUTTON};
  border-radius: ${borderRadiuses.CIRCLE};
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  ${({ isExpanded }) => (isExpanded ? 'transform: rotate(180deg)' : '')};
  margin: 5px;
  ${({ hasActiveChild }) =>
    hasActiveChild
      ? css`
          opacity: 0.25;
        `
      : css`
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        `};
`

interface SubMenuBodyProps {
  isExpanded: boolean
  menuItemCount?: number
}

export const SubMenuBodyStyle = styled.div<SubMenuBodyProps>`
  height: ${({ isExpanded, menuItemCount }) =>
    isExpanded ? `${(menuItemCount || 0) * 50}px` : '0px'};
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
  transition: ${transitions.EXPAND};
  box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2) inset;
`

export const IconStyle = styled.div`
  display: flex;
  width: 40px;
  height: 50px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 5px 0 0;
  & > .fa-angle-right {
    margin: 0 10px 0 auto;
    transition: ${transitions.MAIN};
    &.active {
      transform: rotate(90deg);
    }
  }
`
