// @flow
import * as React from 'react'
import { cx } from 'react-emotion'

import { ContentWrapperStyle, SubNavBarContentWrapperStyle } from './style'

type Props = {
  children: React.Node,
  notCenter?: boolean,
  hasSubNavBar?: boolean,
}

const defaultProps = {
  notCenter: false,
  hasSubNavBar: false,
}

const Content: React$AbstractComponent<Props, HTMLDivElement> = React.forwardRef<
  Props,
  HTMLDivElement
>(({ notCenter, hasSubNavBar = false, children }: Props, ref) => (
  <div
    className={cx(ContentWrapperStyle(notCenter), {
      [SubNavBarContentWrapperStyle]: hasSubNavBar,
    })}
    id="findme"
    ref={ref}
  >
    {children}
  </div>
))

// $FlowFixMe: ignore for now until flow fix this issue with forwardRef
Content.defaultProps = defaultProps

export default Content
