// @flow strict
import { cloneDeep, set, unset } from 'lodash'
import { Container } from 'unstated'

import type { ContainerFormFragment, FieldValuesFragment } from '@graphql/server/flow'
import { cleanFalsyAndTypeName, removeNulls } from '@utils/data'

import type { $PortInput } from 'components/Inputs/PortSelectInput/types'
import type { $Anyify, $Pick } from 'types'
import { isEquals } from 'utils/fp'

const containerInfoFormKeysObj = Object.freeze({
  __typename: '__typename',
  id: 'id',
  connectionBy: 'connectionBy',
  autoCalculatedFreeTimeStartDate: 'autoCalculatedFreeTimeStartDate',
  departureDate: 'departureDate',
  departureDateApprovedAt: 'departureDateApprovedAt',
  departureDateApprovedBy: 'departureDateApprovedBy',
  freeTimeDuration: 'freeTimeDuration',
  freeTimeStartDate: 'freeTimeStartDate',
  memo: 'memo',
  no: 'no:',
  containerType: 'containerType',
  containerOption: 'containerOption',
  customFields: 'customFields',
  shipment: 'shipment',
  tags: 'tags',
  warehouse: 'warehouse',
  terminalArrivalPort: 'terminalArrivalPort',
  terminalArrival: 'terminalArrival',
  vanningDate: 'vanningDate',
  vanningDateApprovedAt: 'vanningDateApprovedAt',
  vanningDateApprovedBy: 'vanningDateApprovedBy',
  vanningActualDate: 'vanningActualDate',
  vanningActualDateApprovedAt: 'vanningActualDateApprovedAt',
  vanningActualDateApprovedBy: 'vanningActualDateApprovedBy',
  warehouseArrivalAgreedDate: 'warehouseArrivalAgreedDate',
  warehouseArrivalAgreedDateApprovedAt: 'warehouseArrivalAgreedDateApprovedAt',
  warehouseArrivalAgreedDateApprovedBy: 'warehouseArrivalAgreedDateApprovedBy',
  warehouseArrivalActualDate: 'warehouseArrivalActualDate',
  warehouseArrivalActualDateApprovedAt: 'warehouseArrivalActualDateApprovedAt',
  warehouseArrivalActualDateApprovedBy: 'warehouseArrivalActualDateApprovedBy',
  yardName: 'yardName',
  archived: 'archived',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy',
  ownedBy: 'ownedBy',
})

export const containerInfoFormKeys: $Keys<typeof containerInfoFormKeysObj>[] =
  Object.keys(containerInfoFormKeysObj)

export type ContainerInfoFormState = {|
  ...{|
    ...$Pick<ContainerFormFragment, $ObjMap<typeof containerInfoFormKeysObj, $Anyify>>,
  |},
  // $FlowIgnore We need a non-mandatory ID. For when the form is new for example.
  id?: *,
  customFields: {|
    ...$ElementType<ContainerFormFragment, 'customFields'>,
    fieldValues: $ReadOnlyArray<FieldValuesFragment>,
  |},
  terminalArrivalPort: ?$PortInput, // due to difference in input type and property type, mapped to this type
  tags: $ReadOnlyArray<ContainerFormFragment['tags'][number]>,
|}

export const initValues: ContainerInfoFormState = {
  __typename: 'Container',
  id: undefined,
  connectionBy: { __typename: 'NotFound' },
  autoCalculatedFreeTimeStartDate: false,
  departureDate: undefined,
  departureDateApprovedAt: undefined,
  departureDateApprovedBy: undefined,
  freeTimeDuration: 14,
  freeTimeStartDate: undefined,
  memo: undefined,
  no: '',
  containerType: undefined,
  containerOption: undefined,
  customFields: {
    __typename: 'CustomFields',
    fieldValues: [],
  },
  shipment: { __typename: 'NotFound' },
  tags: [],
  terminalArrivalPort: undefined,
  terminalArrival: { __typename: 'NotFound' },
  warehouse: undefined,
  vanningDate: undefined,
  vanningDateApprovedAt: undefined,
  vanningDateApprovedBy: undefined,
  vanningActualDate: undefined,
  vanningActualDateApprovedAt: undefined,
  vanningActualDateApprovedBy: undefined,
  warehouseArrivalAgreedDate: undefined,
  warehouseArrivalAgreedDateApprovedAt: undefined,
  warehouseArrivalAgreedDateApprovedBy: undefined,
  warehouseArrivalActualDate: undefined,
  warehouseArrivalActualDateApprovedAt: undefined,
  warehouseArrivalActualDateApprovedBy: undefined,
  yardName: undefined,
  archived: false,
  updatedAt: undefined,
  updatedBy: undefined,
  ownedBy: { __typename: 'NotFound' },
}

export default class ContainerInfoContainer extends Container<ContainerInfoFormState> {
  state: ContainerInfoFormState = initValues

  originalValues: ContainerInfoFormState = initValues

  setFieldValue: <P: $Keys<ContainerInfoFormState>>(
    name: P,
    value: $ElementType<ContainerInfoFormState, P>
  ) => void = <P: $Keys<ContainerInfoFormState>>(
    name: P,
    value: $ElementType<ContainerInfoFormState, P>
  ) => {
    this.setState({
      [(name: string)]: value,
    })
  }

  setDeepFieldValue: <P: $Keys<ContainerInfoFormState>>(
    path: P,
    value: $ElementType<ContainerInfoFormState, P>
  ) => void = <P: $Keys<ContainerInfoFormState>>(
    path: P,
    value: $ElementType<ContainerInfoFormState, P>
  ) => {
    this.setState((prevState: ContainerInfoFormState) => {
      const newState = set(cloneDeep(prevState), path, value)
      return newState
    })
  }

  removeDeepFieldValue: <P: $Keys<ContainerInfoFormState>>(path: P) => void = <
    P: $Keys<ContainerInfoFormState>
  >(
    path: P
  ) => {
    this.setState((prevState: ContainerInfoFormState) => {
      const cloneState = cloneDeep(prevState)
      unset(cloneState, path)
      return removeNulls(cloneState)
    })
  }

  isDirty: () => boolean = () =>
    !isEquals(cleanFalsyAndTypeName(this.state), cleanFalsyAndTypeName(this.originalValues))

  onSuccess: () => void = () => {
    this.originalValues = this.state
    this.setState(this.originalValues)
  }

  initDetailValues: (values: ContainerInfoFormState) => void = (values: ContainerInfoFormState) => {
    const parsedTerminalArrivalPort = {
      __typename: '$PortInput',
      seaport: values.terminalArrivalPort?.seaport,
      rail: values.terminalArrivalPort?.rail,
      road: values.terminalArrivalPort?.road,
      airport: values.terminalArrivalPort?.airport,
      dryport: values.terminalArrivalPort?.dryport,
    }
    const parsedTags = (values.tags || []).flatMap((tag) => (tag.__typename === 'Tag' ? tag : []))
    this.setState(
      ({
        ...values,
        tags: parsedTags,
        terminalArrivalPort: parsedTerminalArrivalPort,
      }: ContainerInfoFormState)
    )
    this.originalValues = ({
      ...initValues,
      ...values,
      tags: parsedTags,
      terminalArrivalPort: parsedTerminalArrivalPort,
    }: ContainerInfoFormState)
  }
}
