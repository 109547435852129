import { DocumentedEntity, arrayOfAll } from '@types'

export const FILE_ATTACHABLE_ENTITIES = arrayOfAll<DocumentedEntity['__typename']>()([
  'Shipment',
  'Order',
  'OrderItem',
  'ProductProvider',
  'Product',
])

export default FILE_ATTACHABLE_ENTITIES
