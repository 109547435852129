// @flow
import { equals } from 'ramda'
import * as React from 'react'
import { ArrayValue, ObjectValue } from 'react-values'

import { sortById } from 'utils/sortById'

type ItemProps = {|
  selectable: boolean,
  selected: boolean,
  onSelect: (Object) => void,
|}

type IncrementProps = {|
  value: number,
  onMinus: () => void,
  onPlus: () => void,
|}

type RenderProps<T> = {|
  value: T,
  dirty: boolean,
  getItemProps: (item: Object, selectable?: boolean) => ItemProps,
|}

type RenderWithIncrementProps<T> = {|
  ...RenderProps<T>,
  isAllSelected: boolean,
  onSelectAll: Function,
  getIncrementProps: (item: Object) => IncrementProps,
|}

type SingleProps<T: any> = {|
  selected: ?T,
  required?: boolean,
  children: (RenderProps<?T>) => React.Node,
|}

/**
 * @param {boolean} dirtyByIds decide dirty state by the IDs of the object only (not deep equals operations involving every key)
 */
type ManyProps = {|
  selected: Object[],
  items?: Object[], // queried items
  dirtyByIds?: boolean,
  max?: number,
  onSelect?: (Object) => void,
  valueToSelected?: (Object) => boolean,
  children: (RenderWithIncrementProps<Object[]>) => React.Node,
|}

const countSelected = (selected: Object[] = [], value: Object) =>
  selected.filter((item) => item.id === value.id).length

const SelectorSingle = <T>({ selected, required, children }: SingleProps<T>): React.Node => (
  <ObjectValue defaultValue={selected || null}>
    {({ value, set }) => {
      return children({
        value,
        dirty:
          (value && !selected) ||
          (typeof selected === 'object' && selected?.id !== value?.id) ||
          (typeof selected === 'object' &&
            typeof selected?.organization === 'object' &&
            selected?.organization?.id !== value?.organization?.id),
        getItemProps: (item, selectable = true) => {
          return {
            selectable,
            selected:
              (!!item.id && value?.id === item.id) ||
              (!!item?.organization && value?.organization?.id === item?.organization?.id),
            onSelect: () => {
              if (value && item.id === value?.id) {
                if (!required) {
                  set(null)
                }
              } else {
                set(item)
              }
            },
          }
        },
      })
    }}
  </ObjectValue>
)

/**
 * Checks if all items in arr1 are in arr2
 */
const isAllSelected = (arr1: Object[], arr2: Object[]) => {
  const arr2ById = arr2.reduce((arr, item) => {
    // eslint-disable-next-line
    arr[item.id] = item
    return arr
  }, {})

  return !arr1.some((item) => !arr2ById[item.id])
}

const defaultArr = []

const SelectorMany = ({
  selected,
  max,
  onSelect,
  items = defaultArr,
  valueToSelected,
  children,
  dirtyByIds,
}: ManyProps): React.Node => {
  const itemsById = React.useMemo(() => {
    return items.reduce((arr, item) => {
      // eslint-disable-next-line
      arr[item.id] = item
      return arr
    }, {})
  }, [items])

  return (
    <ArrayValue defaultValue={selected}>
      {({ value, push, filter, splice }) => {
        return children({
          value,
          dirty: !equals(
            sortById(selected).map((v) => (dirtyByIds ? { id: v.id } : v)),
            sortById(value).map((v) => (dirtyByIds ? { id: v.id } : v))
          ),
          isAllSelected: !!value.length && isAllSelected(items, value),
          onSelectAll: () => {
            if (value.length && isAllSelected(items, value)) {
              filter((i) => !itemsById[i.id])
            } else {
              push(...items.filter((item) => !value.some((val) => item.id === val.id)))
            }
          },
          getItemProps: (item, selectable = true) => {
            const isSelected = valueToSelected
              ? valueToSelected({ value, item })
              : value.some((i) => i.id === item.id)
            return {
              selectable,
              selected: isSelected,
              onSelect: () => {
                if (onSelect) {
                  onSelect({ isSelected, filter, item, max, value, push })
                } else if (isSelected) {
                  filter((i) => i.id !== item.id)
                } else if (!max || value.length < max) {
                  push(item)
                }
              },
            }
          },
          getIncrementProps: (item) => {
            const index = value.map((i) => i.id).indexOf(item.id)

            return {
              value: countSelected(value, item),
              onMinus: () => {
                if (index > -1) {
                  splice(index, 1)
                }
              },
              onPlus: () => push(item),
            }
          },
        })
      }}
    </ArrayValue>
  )
}

export default {
  Single: SelectorSingle,
  Many: SelectorMany,
}
