// @flow
import { isEqual } from 'lodash'
import * as React from 'react'

import type { DefaultFilterInput, DefaultSortInput } from '@graphql/server/flow'

import { useAuthenticated } from 'contexts/Auth'
import { getCache, invalidateCache, setCache } from 'utils/cache'

type FilterSort<F, S> = {
  query: string,
  filterBy: F,
  filterByWithoutQuery: $Diff<F, {| query: ?string |}>,
  setFilterBy: (
    | $Diff<F, {| query: ?string |}>
    | (($Diff<F, {| query: ?string |}>) => $Diff<F, {| query: ?string |}>)
  ) => void,
  sortBy: S,
  setSortBy: (S) => void,
  setQuery: (string) => void,
}

type FilterSortCache<F, S> = {
  filterBy: F,
  sortBy: S,
}

const KEY_PREFIX = 'zenport_filter_sort'

function getFilterSortCache<F, S>(key: string): FilterSortCache<F, S> | null {
  const value = getCache<FilterSortCache<F, S>>(KEY_PREFIX, key)
  if (value) {
    const { filterBy, sortBy } = value
    return { filterBy, sortBy }
  }

  return null
}

function setFilterSortCache<F, S>(key: string, { filterBy, sortBy }: FilterSortCache<F, S>) {
  setCache(KEY_PREFIX, key, { filterBy, sortBy })
}

export function useFilterSortInvalidator() {
  const { authenticated } = useAuthenticated()

  React.useEffect(() => {
    if (authenticated) {
      return
    }

    invalidateCache(KEY_PREFIX)
  }, [authenticated])
}

export default function useFilterSort<
  F: { ...DefaultFilterInput, [key: string]: any } = DefaultFilterInput,
  S: { ...DefaultSortInput, [key: string]: any } = DefaultSortInput
>(
  initialFilterBy: F,
  initialSortBy: S,
  cacheKey: ?string = null,
  disableFilterCache?: boolean
): FilterSort<F, S> {
  const [filterBy, setFilterBy] = React.useState<?F>(null)
  const [sortBy, setSortBy] = React.useState<?S>(null)

  function getFilterBy() {
    if (!filterBy) {
      if (cacheKey !== null && cacheKey !== undefined) {
        const cache = getFilterSortCache<F, S>(cacheKey)
        if (cache) {
          setFilterBy(cache.filterBy)
          return cache.filterBy
        }
      }

      setFilterBy(initialFilterBy)
      return initialFilterBy
    }

    return filterBy
  }

  function getSortBy() {
    if (!sortBy) {
      if (cacheKey !== null && cacheKey !== undefined) {
        const cache = getFilterSortCache<F, S>(cacheKey)
        if (cache?.sortBy) {
          setSortBy(cache.sortBy)
          return cache.sortBy
        }
      }

      setSortBy(initialSortBy)
      return initialSortBy
    }

    return sortBy
  }

  React.useEffect(() => {
    if (cacheKey === null || cacheKey === undefined) {
      return
    }

    const { query, ...cachableFilterBy } = filterBy || {}
    setFilterSortCache(cacheKey, { filterBy: cachableFilterBy, sortBy: sortBy || {} })
  }, [filterBy, sortBy, cacheKey])

  const currentFilterBy =
    disableFilterCache === true && !isEqual(initialFilterBy, filterBy)
      ? initialFilterBy
      : getFilterBy()

  const [query, filterByWithoutQuery] = React.useMemo(() => {
    const { query: q = '', ...rest } = currentFilterBy
    return [q ?? '', rest]
  }, [currentFilterBy])

  const setQuery = React.useCallback(
    (value: string) => {
      setFilterBy((prevFilterBy) =>
        prevFilterBy
          ? {
              ...prevFilterBy,
              query: value,
            }
          : prevFilterBy
      )
    },
    [setFilterBy]
  )

  const setFilterByWithoutQuery = React.useCallback(
    (
      value:
        | $Diff<F, {| query: ?string |}>
        | (($Diff<F, {| query: ?string |}>) => $Diff<F, {| query: ?string |}>)
    ) => {
      if (typeof value !== 'function')
        setFilterBy({
          ...value,
          query,
        })
      else if (typeof value === 'function') {
        setFilterBy((prev) => {
          if (prev) {
            const res = value(prev)
            return { ...res, query }
          }
          return prev
        })
      }
    },
    [query]
  )

  return {
    query,
    filterBy: currentFilterBy,
    filterByWithoutQuery,
    sortBy: getSortBy(),
    setQuery,
    setFilterBy: setFilterByWithoutQuery,
    setSortBy,
  }
}
