export default function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component)
      })
      .catch((error) => {
        if (window.swExpired) {
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
        return error
      })
  })
}
