// @flow strict
import type { Forbidden, MetricValue, OrderItem } from '@graphql/server/flow'

import { findVolume, getBatchLatestQuantity } from 'utils/batch'

export const calculateBatchesFromOrder = ({
  batchCount,
  batchShippedCount,
}: {
  batchCount: number,
  batchShippedCount: number,
}): {| shippedBatches: number, totalBatches: number, unshippedBatches: number |} => {
  return {
    totalBatches: batchCount,
    shippedBatches: batchShippedCount,
    unshippedBatches: batchCount - batchShippedCount,
  }
}

export const getQuantityForOrderSummary = (
  orderItems: OrderItem[]
): {|
  batchedQuantity: number,
  shippedQuantity: number,
  totalBatches: number,
  totalItems: number,
  totalPrice: number | Forbidden,
  totalVolume: MetricValue,
|} => {
  let batchedQuantity = 0
  let shippedQuantity = 0
  let totalPrice: number | Forbidden = orderItems.some(
    (orderItem) => orderItem.price.__typename === 'Forbidden'
  )
    ? { __typename: 'Forbidden' }
    : 0
  let totalItems = 0
  let totalBatches = 0
  const totalVolume: MetricValue = {
    value: 0,
    metric: 'm³',
    __typename: 'MetricValue',
  }

  if (orderItems) {
    totalItems = orderItems.length

    orderItems.forEach((item) => {
      const qty = item.quantity ? item.quantity : 0
      const price = item.price ? item.price.amount : 0
      if (typeof totalPrice === 'number' && typeof price === 'number') {
        totalPrice += price * qty
      }

      if (item.batches) {
        totalBatches += item.batches.length
        item.batches.forEach((batch) => {
          const latestQuantity = getBatchLatestQuantity(batch)
          const volumeValue = findVolume(batch)

          batchedQuantity += latestQuantity
          totalVolume.value += volumeValue

          if (batch.shipment) {
            shippedQuantity += latestQuantity
          }
        })
      }
    })
  }

  return {
    batchedQuantity,
    shippedQuantity,
    totalPrice,
    totalItems,
    totalBatches,
    totalVolume,
  }
}
