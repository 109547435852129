// @flow
import { Location, Redirect } from '@reach/router'
import * as React from 'react'

import { useViewer } from 'contexts/Auth'

type Props = {
  children: React.Node,
}

const Authorized = ({ children }: Props): React$Node | React.Node => {
  const { authenticated, organization, user } = useViewer()

  return authenticated && organization && user ? (
    children
  ) : (
    <Location>
      {({ location }) => <Redirect from={location.pathname} to="login" noThrow />}
    </Location>
  )
}

export default Authorized
