// @flow
import Downshift from 'downshift'
import matchSorter from 'match-sorter'
import * as React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import type { TagFragment } from '@graphql/server/flow'
import { isForbidden, isNotFound } from '@utils/data'

import { DefaultStyle } from 'components/Form'
import TagSelectOptions from 'components/Form/Inputs/Styles/TagSelectOptions'
import Icon from 'components/Icon'
import Tag from 'components/Tag'
import EnumProvider from 'providers/enum'
import { enumsToTags } from 'utils/tags'

import {
  DroppableWrapperStyle,
  InputStyle,
  RemoveStyle,
  SelectionWrapperStyle,
  WrapperStyle,
} from './style'

type OptionalProps = {
  width: string,
  prohibitedValues?: TagFragment[],
  editable: {
    set: boolean,
    remove: boolean,
  },
}

type Props = OptionalProps & {
  color: string,
  ownedBy?: Object,
  enumType: string,
  name: string,
  id?: string,
  values: TagFragment[],
  disabled?: boolean,
  type?: string,
  placeholder?: string,
  onChange?: Function,
  onClickRemove: Function,
  onBlur?: Function,
  onFocus?: Function,
}
type State = {
  focused: boolean,
}

const defaultProps = {
  disabled: false,
  editable: {
    set: false,
    remove: false,
  },
  width: '400px',
}

// A slightly modified copy of TagsInput
export default class TagsInputEnum extends React.Component<Props, State> {
  inputWrapperRef: {| current: null | HTMLDivElement |} = React.createRef<HTMLDivElement>()

  inputRef: {| current: null | HTMLInputElement |} = React.createRef<HTMLInputElement>()

  static defaultProps: {|
    disabled: boolean,
    editable: {| remove: boolean, set: boolean |},
    width: string,
  |} = defaultProps

  state: State = {
    focused: false,
  }

  componentDidUpdate() {
    const { focused } = this.state
    if (focused && this.inputWrapperRef.current) {
      this.inputWrapperRef.current.scrollLeft = this.inputWrapperRef.current.scrollWidth
    }
  }

  handleChange: (tags: TagFragment[]) => void = (tags: TagFragment[]) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(tags)
    }
  }

  handleBlur: () => void = () => {
    const { name, onBlur } = this.props
    if (onBlur) {
      onBlur(name, true)
    }
  }

  handleAdd: (tag: TagFragment) => void = (tag: TagFragment) => {
    const { values } = this.props

    if (values) {
      this.handleChange([...values, tag])
    } else {
      this.handleChange([tag])
    }
  }

  handleRemove: (tag: TagFragment) => void = (tag: TagFragment) => {
    const { values } = this.props

    if (values) this.handleChange(values.filter((t) => t.id !== tag.id))
  }

  handleDownshiftChange: (selectedItem: ?TagFragment) => void = (selectedItem: ?TagFragment) => {
    const { values } = this.props

    if (selectedItem) {
      if (values && values.map((t) => t.id).includes(selectedItem.id)) {
        this.handleRemove(selectedItem)
      } else {
        this.handleAdd(selectedItem)
      }
    }
  }

  handleStateChange: (any) => void = ({ isOpen }: Object) => {
    if (isOpen === false) {
      this.handleBlur()
    }
  }

  stateReducer: (state: any, changes: any) => any = (state: Object, changes: Object) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: true,
          inputValue: '',
        }
      default:
        return changes
    }
  }

  computeFilteredTags: (tags: TagFragment[], input: ?string) => TagFragment[] = (
    tags: TagFragment[],
    input: ?string
  ): TagFragment[] => {
    return matchSorter(tags, input || '', {
      keys: ['name'],
    })
  }

  handleInputFocus: () => void = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }

    this.setState({ focused: true })
  }

  handleInputBlur: () => void = () => {
    this.setState({ focused: false })
    this.handleBlur()
  }

  render(): React.Node {
    const { color, editable, width, disabled, values, name, onClickRemove, prohibitedValues } =
      this.props
    const { focused } = this.state

    return editable.set ? (
      <Downshift
        itemToString={(i) => (i ? i.id : '')}
        selectedItem={values}
        onChange={this.handleDownshiftChange}
        onStateChange={this.handleStateChange}
        stateReducer={this.stateReducer}
        labelId={`${name}TagInputs`}
        onOuterClick={this.handleInputBlur}
      >
        {({ getItemProps, openMenu, isOpen, highlightedIndex }) => (
          <div className={WrapperStyle(focused, !!disabled, !!editable)}>
            <DefaultStyle isFocused={focused} width={width}>
              <div className={SelectionWrapperStyle}>
                <div
                  ref={this.inputWrapperRef}
                  role="presentation"
                  className={InputStyle(width)}
                  onClick={() => {
                    this.handleInputFocus()
                    openMenu()
                  }}
                >
                  <DragDropContext
                    onDragEnd={(result: any) => {
                      if (!result.destination) {
                        return
                      }
                      const sourceIndex = result.source.index
                      const destinationIndex = result.destination.index

                      const reorderedColumns = [...values]
                      const [removed] = reorderedColumns.splice(sourceIndex, 1)
                      reorderedColumns.splice(destinationIndex, 0, removed)

                      this.handleChange(reorderedColumns)
                    }}
                  >
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(dropProvided) => (
                        <div
                          ref={dropProvided.innerRef}
                          {...dropProvided.droppableProps}
                          className={DroppableWrapperStyle}
                        >
                          {(values ?? [])
                            .filter((item) => !isForbidden(item) && !isNotFound(item))
                            .map((tag, index) => (
                              <Draggable key={tag.id} draggableId={tag.id} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Tag
                                      tag={tag}
                                      suffix={
                                        editable.remove && (
                                          <button
                                            type="button"
                                            className={RemoveStyle}
                                            onClick={(event) => {
                                              event.stopPropagation()
                                              onClickRemove(tag)
                                            }}
                                          >
                                            <Icon icon="CLEAR" />
                                          </button>
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {dropProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                {isOpen && (
                  <EnumProvider enumType="ReminderReceiverOrganizationType">
                    {({ data }) => {
                      return (
                        <TagSelectOptions
                          editable={editable}
                          getItemProps={getItemProps}
                          items={enumsToTags({ enums: data, color }).filter((tag) =>
                            prohibitedValues
                              ? !prohibitedValues.some((badTag) => tag.id === badTag.id)
                              : true
                          )}
                          selectedItems={values}
                          highlightedIndex={highlightedIndex}
                          itemToString={(item) => (item ? item.description || item.name : '')}
                          itemToValue={(item) => (item ? item.description : '')}
                          width={width}
                          align="left"
                        />
                      )
                    }}
                  </EnumProvider>
                )}
              </div>
            </DefaultStyle>
          </div>
        )}
      </Downshift>
    ) : (
      <div className={SelectionWrapperStyle}>
        <div className={InputStyle(width)}>
          {values &&
            (values || [])
              .filter((item) => !isForbidden(item) && !isNotFound(item))
              .map((tag) => (
                <Tag
                  key={tag.id}
                  tag={tag}
                  suffix={
                    editable.remove && (
                      <button
                        type="button"
                        className={RemoveStyle}
                        onClick={(event) => {
                          event.stopPropagation()
                          onClickRemove(tag)
                        }}
                      >
                        <Icon icon="CLEAR" />
                      </button>
                    )
                  }
                />
              ))}
        </div>
      </div>
    )
  }
}
