// @flow

import React from 'react'

import type { Warehouse } from '@graphql/server/flow'
import FALLBACK_IMAGE from '@public/media/logo_fallback.png'
import { isSubPath, isNext } from '@utils/env'

import BaseCard from 'components/Cards'
import Icon from 'components/Icon'
import withForbiddenCard from 'hoc/withForbiddenCard'

import {
  OwnedByIconStyle,
  OwnedByStyle,
  OwnedByWrapperStyle,
  ShipmentWarehouseCardImageStyle,
  ShipmentWarehouseCardWrapperStyle,
  ShipmentWarehouseInfoWrapperStyle,
  WarehouseNameStyle,
} from './style'

type Props = {|
  warehouse: Warehouse,
  onClick: Function,
|}

const defaultProps = {
  onClick: () => {},
}

const ShipmentWarehouseCard = ({ warehouse, onClick, ...rest }: Props) => {
  const { name, ownedBy } = warehouse

  const actions = []

  return (
    <BaseCard
      icon="WAREHOUSE"
      color="WAREHOUSE"
      actions={actions}
      showBadge={warehouse?.notificationUnseenCount > 0}
      {...rest}
    >
      <div
        className={ShipmentWarehouseCardWrapperStyle}
        onClick={() => onClick(warehouse)}
        role="presentation"
      >
        <img
          className={ShipmentWarehouseCardImageStyle}
          src={isNext ? `${isSubPath ? '/new' : ''}/media/logo_fallback.png` : FALLBACK_IMAGE}
          alt="warehouse_image"
        />
        <div className={ShipmentWarehouseInfoWrapperStyle}>
          <div className={WarehouseNameStyle}>{name}</div>
          <div className={OwnedByWrapperStyle}>
            <div className={OwnedByIconStyle}>
              <Icon icon="PARTNER" />
            </div>
            <div className={OwnedByStyle}>{ownedBy && ownedBy.name}</div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

ShipmentWarehouseCard.defaultProps = defaultProps

export default (withForbiddenCard(ShipmentWarehouseCard, 'warehouse', {
  width: '195px',
  height: '40px',
  entityIcon: 'WAREHOUSE',
  entityColor: 'WAREHOUSE',
  forceAbleToClick: true,
}): any)
