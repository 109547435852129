// @flow
import { get } from 'lodash/fp'

import logger from './logger'

const loadMore = (
  clientData: { fetchMore: Function, data: ?Object, loading?: boolean },
  filtersAndSort: Object = {},
  selectedField: string = ''
): any | Promise<{ ... }> => {
  const {
    data = {
      [`${selectedField}`]: { page: 1, totalPage: 0 },
    },
    fetchMore,
    loading = false,
  } = clientData
  logger.warn('loadMore', data)
  if (!data || loading) return Promise.resolve({})
  const nextPage = get(`${selectedField}.page`, data) + 1
  const totalPage = get(`${selectedField}.totalPage`, data)
  if (nextPage > totalPage) return Promise.resolve({})
  logger.warn('loadMore nextPage', nextPage)
  return fetchMore({
    variables: {
      ...filtersAndSort,
      ...(filtersAndSort && filtersAndSort.sort
        ? { sort: { [filtersAndSort.sort.field]: filtersAndSort.sort.direction } }
        : {}),
      page: nextPage,
    },
  }).catch(logger.warn)
}

export default loadMore
