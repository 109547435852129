// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { BaseButton } from 'components/Buttons'
import messages from 'components/Form/DocumentsUpload/messages'
import GridRow from 'components/GridRow'

import DeleteSelectedButton from '../DeleteSelectedButton'
import DownloadSelectedButton from '../DownloadSelectedButton'

export type UploadFileState = {
  id: string,
  name: string,
  path: string,
  type: string,
  tags: Object[],
  memo: string,
  entity?: Object,
  createdAt: string,
}

type Props = {
  canDownload: boolean,
  isAllSelected: boolean,
  filesState: {
    ...UploadFileState,
    tags: [],
    uploading: boolean,
    progress: number,
  }[],
  isMultiSelect: boolean,
  onDownloadFinish: Function,
  onSave: Function,
  onSelectAllClick: Function,
  onSelectMultipleClick: Function,
  selectedFiles: Object,
  setSelectedFiles: Function,
}

const DocumentNavbarFileContent = ({
  canDownload,
  isAllSelected,
  filesState,
  isMultiSelect,
  onDownloadFinish,
  onSave,
  onSelectAllClick,
  onSelectMultipleClick,
  selectedFiles,
  setSelectedFiles,
}: Props): React.Node => {
  return (
    <GridRow gap="10px">
      {!!Object.keys(selectedFiles).length && (
        <>
          <DownloadSelectedButton
            selectedFiles={selectedFiles}
            isMultiSelect={isMultiSelect}
            onDownloadFinished={onDownloadFinish}
          />
          <DeleteSelectedButton
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isMultiSelect={isMultiSelect}
            filesState={filesState}
            onSave={onSave}
          />
        </>
      )}
      {canDownload && filesState.length > 0 && (
        <>
          <BaseButton
            icon="CHECKED"
            label={<FormattedMessage {...messages.selectAll} />}
            backgroundColor={isAllSelected ? 'TEAL' : 'GRAY_SUPER_LIGHT'}
            hoverBackgroundColor={isAllSelected ? 'TEAL_DARK' : 'GRAY_VERY_LIGHT'}
            textColor={isAllSelected ? 'WHITE' : 'GRAY_DARK'}
            hoverTextColor={isAllSelected ? 'WHITE' : 'GRAY_DARK'}
            onClick={onSelectAllClick}
          />

          <BaseButton
            icon="CHECKED"
            label={<FormattedMessage {...messages.selectMultiple} />}
            backgroundColor={isMultiSelect ? 'TEAL' : 'GRAY_SUPER_LIGHT'}
            hoverBackgroundColor={isMultiSelect ? 'TEAL_DARK' : 'GRAY_VERY_LIGHT'}
            textColor={isMultiSelect ? 'WHITE' : 'GRAY_DARK'}
            hoverTextColor={isMultiSelect ? 'WHITE' : 'GRAY_DARK'}
            onClick={onSelectMultipleClick}
          />
        </>
      )}
    </GridRow>
  )
}

export default DocumentNavbarFileContent
