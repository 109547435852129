// @flow
import { css } from 'react-emotion'

export const WrapperStyle: any = css`
  display: flex;
`

export const FirstButtonStyle: any = css`
  border-radius: 5px 0 0 5px;
`

export const LastButtonStyle: any = css`
  border-radius: 0 5px 5px 0;
`
