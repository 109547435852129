// @flow strict
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { AgGridReact } from '@ag-grid-community/react'
import { useLazyQuery } from '@apollo/client'
import type { Element } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import activityLogsQuery from '@graphql/client/allLogBoard/query.activityLogs.graphql'
import type {
  ActivityLogSortInput,
  ActivityLogFilterInput,
  ActivityLogsQueryVariables,
  ActivityLogsQuery,
} from '@graphql/server/flow'

import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import { EntityIcon, Search } from 'components/NavBar'
import SlideView from 'components/SlideView'
import useFilterSort from 'hooks/useFilterSort'
import { normalizeParameters, toLowerCamel } from 'modules/timeline/components/Timeline/helper'
import DefaultFormatters from 'modules/timeline/formatters'
import type { LogItem } from 'modules/timeline/types'
import { printValue } from 'utils/printValue'
import { toLowerFirst } from 'utils/string'

import { SideBarWrapperStyle } from './style'

type Props = { isActive: boolean, setActive: (boolean) => void }

type RowItem = {|
  organization: string,
  user: string,
  entity: string,
  id: string,
  field: string,
  action: string,
  oldValue: string,
  newValue: string,
  dateTime: Date,
|}

const blockSize = 20

const formatters = DefaultFormatters({ actionOnly: true })

const AllLogBoard = ({ isActive, setActive }: Props): Element<'div'> => {
  const isOpen = isActive
  const setIsOpen = setActive

  const gridParamsRef = useRef()

  const { query, setQuery } = useFilterSort<ActivityLogFilterInput, ActivityLogSortInput>(
    { query: '' },
    { updatedAt: 'DESCENDING' }
  )

  const [getActivityLogs] = useLazyQuery<ActivityLogsQuery, ActivityLogsQueryVariables>(
    activityLogsQuery
  )

  const [columnDefs] = useState([
    { field: 'organization' },
    { field: 'user' },
    { field: 'entity' },
    { field: 'id' },
    { field: 'field' },
    { field: 'action' },
    { field: 'oldValue' },
    { field: 'newValue' },
    { field: 'dateTime' },
  ])

  const generateRows = useCallback(
    (activityLogPayloads: ActivityLogsQuery['activityLogs']['nodes']): RowItem[] =>
      // Filter out BadRequests etc
      activityLogPayloads
        .flatMap((activityLogPayload) =>
          activityLogPayload.__typename === 'ActivityLog' ? activityLogPayload : []
        )
        .map(({ parameters, ...log }) => {
          // Use legacy method which handily process parameters into more readable objects
          const normalizedParameters = normalizeParameters(parameters)

          const createdBy =
            log.createdBy?.__typename && log.createdBy.__typename === 'User'
              ? log.createdBy
              : undefined

          const parentEntity =
            log.entity.__typename !== 'BadRequest' &&
            log.entity.__typename !== 'Forbidden' &&
            log.entity.__typename !== 'NotFound'
              ? log.entity
              : undefined

          const parentEntityType = parentEntity?.__typename
            ? toLowerFirst(parentEntity.__typename)
            : undefined

          // Match legacy LogItem type used by legacy formatter (with slight changes to it and to LogItem)
          const logForFormatter: LogItem = {
            ...log,
            __typename: 'Log',
            entityType: toLowerCamel(normalizedParameters.entity_type.string),
            parentEntity,
            parentEntityType,
            parameters: normalizedParameters,
            createdAt: new Date(log.createdAt),
            createdBy,
          }

          return {
            organization: logForFormatter.createdBy?.organization?.name || '',
            user:
              logForFormatter.createdBy?.__typename === 'User'
                ? `${logForFormatter.createdBy.firstName} ${logForFormatter.createdBy?.lastName}`
                : '',
            entity: logForFormatter.entityType,
            id: logForFormatter.parameters.entity_identifier?.string,
            field:
              logForFormatter.parameters.field?.string ??
              logForFormatter.parameters.document_type?.string,
            action:
              formatters[logForFormatter.translationKey]?.(logForFormatter) ??
              logForFormatter.translationKey.toUpperCase(),
            oldValue: logForFormatter.parameters.old
              ? printValue(logForFormatter.parameters.old)
              : '',
            newValue: logForFormatter.parameters.new
              ? printValue(logForFormatter.parameters.new)
              : '',
            dateTime: logForFormatter.createdAt,
          }
        }),
    []
  )

  const generateDataSource = useCallback(() => {
    // pseudo-states (saved in component)
    let rowsState: RowItem[] = []

    return {
      rowCount: undefined,
      getRows: async (requestRowsParams) => {
        const result = await getActivityLogs({
          variables: {
            page: requestRowsParams.endRow / blockSize,
            perPage: blockSize,
          },
        })

        if (!result.data) return

        const {
          data: {
            activityLogs: { nodes, page, totalPage },
          },
        } = result

        rowsState = [...rowsState, ...generateRows(nodes)]

        const lastRow = page === totalPage ? rowsState.length : -1

        requestRowsParams.successCallback(
          rowsState.slice(requestRowsParams.startRow, requestRowsParams.endRow),
          lastRow
        )
      },
    }
  }, [generateRows, getActivityLogs])

  const onGridReady = useCallback(
    (gridParams) => {
      gridParamsRef.current = gridParams
      gridParams.api.setDatasource(generateDataSource())
    },
    [generateDataSource]
  )

  // Whenever the query changes, reset the dataSource (as loadMore's filterBy has changed)
  useEffect(() => {
    if (!gridParamsRef.current) return
    gridParamsRef.current.api.setDatasource(generateDataSource())
  }, [query, generateDataSource])

  return (
    <div>
      <SlideView isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <SlideViewLayout>
          <SlideViewNavBar>
            <EntityIcon icon="WAVE_PULSE" color="GRAY" />
            <Search query={query ?? ''} onChange={setQuery} />
          </SlideViewNavBar>
          <Content>
            <div className={`ag-theme-alpine ${SideBarWrapperStyle}`}>
              <AgGridReact
                modules={[InfiniteRowModelModule]}
                columnDefs={columnDefs}
                defaultColDef={{
                  resizable: true,
                }}
                rowModelType="infinite"
                cacheBlockSize={blockSize}
                onGridReady={onGridReady}
              />
            </div>
          </Content>
        </SlideViewLayout>
      </SlideView>
    </div>
  )
}

export default AllLogBoard
