// @flow
import { css } from 'react-emotion'

import { animations, borderRadiuses, colors, fontSizes, presets, transitions } from 'styles/common'

export const DocumentTypeAreaWrapperStyle = ({
  isDraggedOver,
  highlight,
}: {
  isDraggedOver: boolean,
  highlight: boolean,
}): string => css`
  background-color: ${colors.GRAY_SUPER_LIGHT};
  ${borderRadiuses.MAIN};
  border: 5px dashed ${isDraggedOver ? colors.TEAL_HALF : colors.TRANSPARENT};
  ${transitions.MAIN};
  ${highlight
    ? animations.HIGHLIGHT_FLASH_AND_FADE({
        delay: '500ms',
        originalColor: colors.GRAY_SUPER_LIGHT,
      })
    : ''};
`

export const DocumentTypeAreaHeaderStyle = (hasFiles?: boolean): any => css`
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: ${hasFiles ? '20px' : 0};

  @media (max-width: 799px) {
    flex-wrap: wrap;
    > div {
      flex: 1 0 100%;
    }
  }
  @media (max-width: 619px) {
    > div + div {
      grid-auto-flow: row;
    }
  }
`

export const DocumentTypeAreaLabelContainerStyle: string = css`
  align-items: center;
  overflow: unset;
  flex-grow: 1;
  display: flex;
`

export const DocumentTypeAreaLabelStyle: string = css`
  max-width: 660px;
  flex: initial;
`

export const AddDocumentButtonWrapperStyle: string = css`
  ${presets.BUTTON};
  color: ${colors.WHITE};
  background-color: ${colors.TEAL};
  ${borderRadiuses.BUTTON};
  height: 30px;
  padding: 0 10px;
  width: min-content;
  min-width: 75px;
  flex-shrink: 0;
  &:hover,
  :focus {
    background-color: ${colors.TEAL_DARK};
  }
`

export const AddDocumentButtonLabelStyle: string = css`
  ${presets.ELLIPSIS};
  letter-spacing: 2px;
  ${fontSizes.SMALL};
  text-transform: uppercase;
`

export const AddDocumentButtonIconStyle: string = css`
  margin: 0 0 0 5px;
  ${fontSizes.SMALL};
`

export const DocumentTypeAreaBodyStyle: string = css`
  padding: 10px 5px 5px 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

export const CommentStyle = (fontSize: string): any => css`
  grid-column: 1;
  font-size: 20px;
  position: relative;
  margin-top: -1px;
  max-width: 23px;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #fff;
    font-size: ${fontSize};
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg {
    max-width: 30px !important;
  }
  svg path {
    stroke: ${colors.GRAY_SUPER_LIGHT};
    stroke-width: 20px;
    }
  }
`
