// @flow
// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'

import badRequestFragment from '@graphql/client/errors/fragment.badRequest.graphql'
import forbiddenFragment from '@graphql/client/errors/fragment.forbidden.graphql'

export const timelineReadByEntity: any = gql`
  mutation timelineRead($entity: EntityInput!) {
    timelineRead(entity: $entity) {
      ...BadRequest
      ...Forbidden
    }
  }
  ${forbiddenFragment}
  ${badRequestFragment}
`

export default timelineReadByEntity
