// @flow
import { css } from 'react-emotion'

import { layout } from 'styles/common'

export const MessageStyle: string = css`
  ${layout.GRID_VERTICAL};
  grid-gap: 10px;
`

export default MessageStyle
