// @flow
import { css } from 'react-emotion'

export const EntityNameWrapperStyle: string = css`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`
