// @flow
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import type { LocalFile } from 'types/file'

import type { FileType } from '@graphql/server/flow'

import { DocumentCard } from 'components/Cards'
import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import UploadDialog from 'components/Dialog/UploadDialog'
import { SelectInputFactory } from 'components/Form'
import { useChat } from 'modules/chat/hooks/useChat'

type Props = {
  fileType: ?FileType,
  setFileType: Function,
  localFiles: LocalFile[],
  resetFiles: Function,
  onUpload: (any) => void,
}

// Renders when localFiles has item(s)
const DocumentUploadInput = ({
  localFiles,
  fileType,
  setFileType,
  resetFiles,
  onUpload,
}: Props): React.Node => {
  const { entityType, selectedGroupId } = useChat()
  const intl = useIntl()

  const fileTypeOptions = getFileTypesByEntity(entityType, intl)

  return (
    <UploadDialog
      isOpen
      uploadDisabled={!selectedGroupId && !fileType}
      onCancel={resetFiles}
      onRequestClose={resetFiles}
      onUpload={onUpload}
    >
      {!selectedGroupId && (
        <SelectInputFactory
          editable
          required
          vertical
          // $FlowIgnore Very tough covariance problem, 10 points if you can figure it out
          items={fileTypeOptions}
          label={
            <FormattedMessage id="components.Documents.filesType" defaultMessage="Document Type" />
          }
          onChange={({ target: { value } }) => setFileType(value)}
          optionsStyle="right: unset;"
          originalValue={null}
          inputWidth="100%"
          value={fileType}
        />
      )}
      {localFiles.map((file) => (
        <DocumentCard key={file.id} file={file} variant="light" />
      ))}
    </UploadDialog>
  )
}

export default DocumentUploadInput
