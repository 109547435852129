// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'

const validator = ({ no }: { no: string }): Object =>
  Yup.object().shape({
    [no]: Yup.string().required(
      (<FormattedMessage id="validation.no.required" defaultMessage="No is required field" />: any)
    ),
  })

export default validator
