// @flow
import * as React from 'react'
import { cx } from 'react-emotion'

import TooltipIcon from 'components/Form/FormTooltip/TooltipIcon'
import Icon from 'components/Icon'
import OutsideClickHandler from 'components/OutsideClickHandler'
import { Tooltip } from 'components/Tooltip'
import { omit } from 'utils/fp'

import Actions from './Actions'
import CornerIcon from './CornerIcon'
import {
  BadgeContainer,
  BadgeStyle,
  CardStyle,
  CommentStyle,
  CustomDocumentIcon,
  NewBadgeStyle,
  SelectableCardStyle,
} from './style'

export type Props = {|
  icon?: ?string,
  preferIcon?: boolean,
  color?: string,
  actions?: React.Node[],
  error?: ?string,
  showActionsOnHover?: boolean,
  forceShowActions?: boolean,
  selectable?: boolean,
  isArchived?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  selected?: boolean,
  onSelect?: Function,
  invertCornerIcon?: boolean,
  wrapperClassName?: string | Function,
  id?: ?string,
  showBadge?: boolean,
  filesUnreadCount?: number,
  notificationUnseenCount?: number,
  unreadMessageCount?: number,
  flattenCornerIcon?: boolean,
  children: React.Node,
  onClick?: Function,
  notificationPosition?: string,
  onCommentIconClick?: Function,
  onDocumentIconClick?: Function,
  onNotificationIconClick?: Function,
  innerRef?: any,
|}

type State = {
  actionsAreShown: boolean,
}

const defaultProps = {
  icon: null,
  preferIcon: false,
  color: '',
  actions: [],
  showActionsOnHover: false,
  forceShowActions: false,
  selectable: false,
  isArchived: false,
  disabled: false,
  readOnly: false,
  selected: false,
  onSelect: () => {},
  invertCornerIcon: false,
  wrapperClassName: '',
  id: '',
  showBadge: false,
  flattenCornerIcon: false,
  notificationPosition: '18px',
}

class BaseCard extends React.Component<Props, State> {
  cornerIcon: { current: HTMLButtonElement | null } = React.createRef()

  static defaultProps = defaultProps

  state = {
    actionsAreShown: false,
  }

  toggleActions = () => {
    const { actionsAreShown } = this.state

    this.setState({ actionsAreShown: !actionsAreShown })
  }

  openActions = () => {
    this.setState({ actionsAreShown: true })
  }

  closeActions = () => {
    this.setState({ actionsAreShown: false })
  }

  render() {
    const {
      icon,
      preferIcon,
      color,
      actions,
      error,
      showActionsOnHover,
      forceShowActions,
      selectable,
      isArchived = false,
      disabled = false,
      readOnly = false,
      selected,
      onSelect,
      invertCornerIcon: invert,
      wrapperClassName,
      children,
      id,
      showBadge,
      filesUnreadCount,
      notificationUnseenCount,
      unreadMessageCount,
      flattenCornerIcon = false,
      notificationPosition = '18px',
      onCommentIconClick,
      onDocumentIconClick,
      onNotificationIconClick,
      innerRef,
      ...rest
    } = this.props

    const { actionsAreShown } = this.state

    const cardStyle = CardStyle({ disabled, readOnly, isArchived, error: !!error })
    return (
      <div
        id={id}
        ref={innerRef}
        className={cx(cardStyle, wrapperClassName)}
        onMouseOver={() => {
          if (showActionsOnHover) {
            this.openActions()
          }
        }}
        onFocus={() => {
          if (showActionsOnHover) {
            this.openActions()
          }
        }}
        onMouseOut={() => {
          if (showActionsOnHover) {
            this.closeActions()
          }
        }}
        onBlur={() => {
          if (showActionsOnHover) {
            this.closeActions()
          }
        }}
        {...omit(['onRemove', 'onClone'], rest)}
      >
        {!disabled && actions && actions.length > 0 && (
          <OutsideClickHandler
            onOutsideClick={this.closeActions}
            ignoreClick={!forceShowActions && !actionsAreShown}
            ignoreElements={
              this.cornerIcon && this.cornerIcon.current ? [this.cornerIcon.current] : []
            }
          >
            <Actions visible={forceShowActions || actionsAreShown}>
              {React.Children.map(actions, (action) => action)}
            </Actions>
          </OutsideClickHandler>
        )}
        {icon && (
          <CornerIcon
            ref={this.cornerIcon}
            icon={icon}
            preferIcon={preferIcon ?? false}
            color={color}
            disabled={disabled}
            readOnly={readOnly}
            selectable={selectable}
            selected={selected}
            onClick={this.toggleActions}
            invert={invert}
            flatten={flattenCornerIcon}
          />
        )}
        {showBadge && <span className={BadgeStyle} />}
        {error && (
          <Tooltip message={error}>
            <TooltipIcon
              type="error"
              hasInfo={false}
              style={{ position: 'absolute', zIndex: '2' }}
            />
          </Tooltip>
        )}
        <div className={BadgeContainer(notificationPosition)}>
          {unreadMessageCount !== undefined && icon === 'SHIPMENT' && (
            <span
              role="button"
              tabIndex="0"
              onClick={onCommentIconClick}
              onKeyDown={onCommentIconClick}
              className={CommentStyle(unreadMessageCount >= 99 ? '10px' : '12px')}
            >
              <Icon icon="COMMENTS" />
              <span>
                {unreadMessageCount >= 99 ? '99' : unreadMessageCount || ''}
                {unreadMessageCount >= 99 && '+'}
              </span>
            </span>
          )}
          {!!filesUnreadCount && filesUnreadCount > 0 && (
            <span
              role="button"
              tabIndex="0"
              onClick={onDocumentIconClick}
              onKeyDown={onDocumentIconClick}
              className={CustomDocumentIcon(filesUnreadCount >= 99 ? '10px' : '12px')}
            >
              <span>
                {filesUnreadCount >= 99 ? '99' : filesUnreadCount}
                {filesUnreadCount >= 99 && '+'}
              </span>
            </span>
          )}
          {!!notificationUnseenCount && notificationUnseenCount > 0 && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <span
              role="button"
              tabIndex="0"
              onClick={onNotificationIconClick}
              onKeyDown={onNotificationIconClick}
              className={NewBadgeStyle(notificationUnseenCount >= 99 ? '10px' : '12px')}
            />
          )}
        </div>
        {children}
        {!disabled && selectable && (
          <div
            className={SelectableCardStyle(!!selected, flattenCornerIcon)}
            onClick={onSelect}
            role="presentation"
          />
        )}
      </div>
    )
  }
}

export default (React.forwardRef<Props, HTMLDivElement>((props: Props, ref) => (
  <BaseCard innerRef={ref} {...props} />
)): React$AbstractComponent<Props, HTMLDivElement>)
