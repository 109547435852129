import { Tooltip } from 'antd'
import * as React from 'react'

import { Connection, Scalars } from '@graphql/server/typescript'

import { getConnectionAvatarColor, getConnectionAvatarInitials } from './helpers'
import { AvatarStyle } from './style'

interface Props {
  id: Scalars['ID']
  name: Connection['displayName']
}

const ConnectionAvatar = ({ id, name }: Props): React.ReactElement => (
  <Tooltip title={name}>
    <div className={AvatarStyle({ color: getConnectionAvatarColor({ id }) })}>
      {getConnectionAvatarInitials({ name })}
    </div>
  </Tooltip>
)

export default ConnectionAvatar
