// @flow
import { Container } from 'unstated'

import type { Tag } from '@graphql/server/flow'
import { extractForbiddenId } from '@utils/data'

import { isEquals } from 'utils/fp'

type FormState = {
  tags?: Tag[],
}

const initValues = {
  tags: [],
}

export default class ShipmentTagsContainer extends Container<FormState> {
  state: {| tags: any[] |} = initValues

  originalValues: {| tags: any[] |} = initValues

  isDirty: () => boolean = () => !isEquals(this.state, this.originalValues)

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
  }

  setFieldValue: (name: string, value: mixed) => void = (name: string, value: mixed) => {
    this.setState({
      [name]: value,
    })
  }

  initDetailValues: (tags: Tag[]) => void = (tags: Tag[]) => {
    const parsedTags = [...tags.map((tag) => extractForbiddenId(tag))]
    this.setState({ tags: parsedTags })
    this.originalValues = { tags: parsedTags }
  }
}
