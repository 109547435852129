// @flow
import * as React from 'react'

import type { Connection } from '@graphql/server/flow'

import LoadingIcon from 'components/LoadingIcon'
import { usePermissions } from 'contexts/Permissions'
import useUser from 'hooks/useUser'
import entityHasConnection from 'utils/entityHasConnection'

type Props = {
  data: Object,
  entityConnectionId?: $ElementType<Connection, 'id'>,
  children: (permissions: string[], isOwner: boolean) => React$Node,
}

/**
 * @param {string} entityConnectionId For overriding data's connection ID (e.g. connection lives on parent of data)
 */
const PartnerPermissionsWrapper = ({
  data,
  entityConnectionId,
  children,
}: Props): React$Node | React.Node => {
  const { isOwnerBy } = useUser()
  const organizationId = data?.ownedBy?.id
  const connectionId = entityConnectionId || data?.connectionBy?.id || data?.connectionBy // If undefined, toast warning msg will assume you did not query the field at all
  const permissions = usePermissions(
    organizationId,
    connectionId,
    data?.__typename === 'File'
      ? data?.entity?.__typename
        ? !entityHasConnection(data.entity.__typename)
        : true
      : !entityHasConnection(data?.__typename)
  )

  if (!organizationId) {
    return children([], false)
  }

  if (permissions.loading) {
    return <LoadingIcon />
  }

  return children(permissions.permissions, isOwnerBy(organizationId))
}

export default PartnerPermissionsWrapper
