// @flow
import * as React from 'react'

import ConnectionAvatar from '@components/ConnectionAvatar'
import type { Connection, Scalars } from '@graphql/server/flow'

import { DateInput, DefaultStyle, Display, Label, TextInput } from 'components/Form'
import Icon from 'components/Icon'
import { FormField } from 'modules/form'

import {
  CustomFieldIconStyle,
  CustomFieldWrapperStyle,
  DefaultCustomFieldWrapperStyle,
} from './style'

type Props = {
  value?: Object,
  editable?: boolean,
  connectionId?: $ElementType<Scalars, 'ID'>,
  connectionName?: $ElementType<Connection, 'displayName'>,
  fieldName: any,
  fieldType: string,
  targetName: string,
  setFieldValue: Function,
}

const DefaultCustomFieldStyle = ({
  value = ({}: { ... }),
  editable = true,
  connectionId,
  connectionName,
  fieldName,
  fieldType,
  targetName,
  setFieldValue,
}: Props): React.Element<'div'> => (
  <div className={DefaultCustomFieldWrapperStyle}>
    <div className={CustomFieldWrapperStyle}>
      <div className={CustomFieldIconStyle}>
        <Icon icon="METADATA" />
      </div>

      {connectionId !== undefined && connectionName !== undefined ? (
        <ConnectionAvatar id={connectionId} name={connectionName} />
      ) : (
        <div style={{ width: '15px' }} />
      )}

      <Label width="200px">{fieldName}</Label>

      {editable ? (
        <FormField
          name={`${targetName}.value.string`}
          initValue={value?.string}
          setFieldValue={setFieldValue}
        >
          {({ name, ...inputHandlers }) => {
            const { isFocused, isTouched, errorMessage, ...rest } = inputHandlers
            return (
              <DefaultStyle
                width="200px"
                isFocused={isFocused}
                hasError={isTouched && errorMessage}
              >
                {fieldType === 'Date' ? (
                  <DateInput name={name} width="200px" {...rest} />
                ) : (
                  <TextInput name={name} {...rest} />
                )}
              </DefaultStyle>
            )
          }}
        </FormField>
      ) : (
        <Display width="200px" height="30px">
          {value?.string}
        </Display>
      )}
    </div>
  </div>
)

export default DefaultCustomFieldStyle
