import {
  faAngleLeft as ANGLE_LEFT,
  faAngleRight as ANGLE_RIGHT,
  faArchive as ARCHIVE_LIGHT,
  faPrint as PRINT_LIGHT,
  faBox as BATCH_LIGHT,
  faShip as SHIPMENT_LIGHT,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCodeBranch as BRANCH_REGULAR,
  faCalendar as CALENDAR,
  faCheckCircle as CHECKED_REGULAR,
  faFileAlt as DOCUMENT_REGULAR,
  faEye as EYE,
  faIndustryAlt as IMPORTER,
  faList as LIST,
  faMagic as MAGIC_REGULAR,
  faCube as PRODUCT_PROVIDER,
  faArchive as PRODUCT_PROVIDER_PACKAGE,
  faPrint as PRINT_REGULAR,
  faSyncAlt as REFRESH,
  faSquare as SQUARE,
  faStar as STAR_REGULAR,
  faIndustry as SUPPLIER,
  faAngleRight as TARGET,
  faCheckCircle as UNCHECKED,
  faWarehouse as WAREHOUSER,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faWrench as ACTION,
  faFolderOpen as ACTIVE,
  faFolder as FOLDER,
  faPlus as ADD,
  faArchive as ARCHIVE,
  faArrowToBottom as ARRIVAL,
  faArrowToRight as ARRIVAL_HORIZONTAL,
  faArrowsV as ARROWS_V,
  faArrowAltDown as ARROW_ALT_DOWN,
  faArrowAltLeft as ARROW_ALT_LEFT,
  faArrowAltRight as ARROW_ALT_RIGHT,
  faArrowAltUp as ARROW_ALT_UP,
  faArrowDown as ARROW_DOWN,
  faArrowLeft as ARROW_LEFT,
  faArrowRight as ARROW_RIGHT,
  faArrowUp as ARROW_UP,
  faBox as BATCH,
  faLink as BINDED,
  faCodeBranch as BRANCH,
  faBringForward as BRING_FORWARD,
  faCalculator as CALCULATOR,
  faTimesCircle as CANCEL,
  faTh as CARD,
  faThLarge as CARDS,
  faBoxesAlt as CARGO,
  faBoxCheck as CARGO_READY,
  faCheckCircle as CHECKED,
  faCheckSquare as CHECKED_SQUARE,
  faChevronDoubleDown as CHEVRON_DOUBLE_DOWN,
  faChevronDoubleLeft as CHEVRON_DOUBLE_LEFT,
  faChevronDoubleRight as CHEVRON_DOUBLE_RIGHT,
  faChevronDoubleUp as CHEVRON_DOUBLE_UP,
  faChevronDown as CHEVRON_DOWN,
  faChevronLeft as CHEVRON_LEFT,
  faChevronRight as CHEVRON_RIGHT,
  faChevronUp as CHEVRON_UP,
  faCircle as CIRCLE,
  faTimes as CLEAR,
  faClock as CLOCK,
  faClone as CLONE,
  faPalette as COLOR,
  faColumns as COLUMNS,
  faComment as COMMENTS,
  faCompress as COMPRESS,
  faCheck as CONFIRM,
  faContainerStorage as CONTAINER,
  faCrosshairs as CROSSHAIRS,
  faFileCsv as CSV,
  faClipboardList as CUSTOMS,
  faTruckLoading as DELIVERY_READY,
  faArrowFromTop as DEPARTURE,
  faArrowFromLeft as DEPARTURE_HORIZONTAL,
  faBan as DISABLED,
  faFileAlt as DOCUMENT,
  faDownload as DOWNLOAD,
  faGripVertical as DRAG_HANDLE,
  faPencil as EDIT,
  faFeatherAlt as EDITED,
  faTable as EDIT_TABLE,
  faEnvelope as EMAIL,
  faBezierCurve as ENTITY,
  faFileExcel as EXCEL,
  faExchange as EXCHANGE,
  faExpand as EXPAND,
  faIndustryAlt as EXPORTER,
  faExternalLink as EXTERNAL_LINK,
  faFileArchive as FILE_ARCHIVE,
  faFilter as FILTER,
  faRedo as FORGOT,
  faShippingFast as FORWARDER,
  faHome as HOME,
  faEllipsisH as HORIZONTAL_ELLIPSIS,
  faFileImage as IMAGE,
  faFileImport as IMPORT,
  faInfoCircle as INFO,
  faInventory as INVENTORY,
  faEyeSlash as INVISIBLE,
  faLanguage as LANGUAGE,
  faSignInAlt as LOGIN,
  faSignOutAlt as LOGOUT,
  faBook as LOGS,
  faMagic as MAGIC,
  faUserCrown as MANAGER,
  faProjectDiagram as MAP,
  faCommentAltLines as MEMO,
  faCommentAltPlus as MEMO_ADD,
  faEdit as METADATA,
  faMinus as MINUS,
  faAddressBook as NETWORK,
  faBell as NOTIFICATION,
  faFileEdit as ORDER,
  faShoppingCart as ORDER_ITEM,
  faTape as PACKAGING,
  faPaperPlane as PAPER_PLANE,
  faThumbtack as PARENT,
  faHandshake as PARTNER,
  faKey as PASSWORD,
  faPaste as PASTE,
  faFilePdf as PDF,
  faCameraAlt as PHOTO,
  faPlane as PLANE,
  faPrint as PRINT,
  faRoad as ROAD,
  faTrainTrack as RAIL,
  faPalletAlt as DRY,
  faAnchor as PORT,
  faFilePowerpoint as POWER_POINT,
  faCube as PRODUCT,
  faAddressCard as PROFILE,
  faBalanceScale as QUANTITY_ADJUSTMENTS,
  faSitemap as RELATED,
  faMap as RELATION_MAP,
  faRedo as RELOAD,
  faTrashAlt as REMOVE,
  faSearch as SEARCH,
  faShieldAlt as SECURITY,
  faCog as SETTINGS,
  faTh as SHEET,
  faShip as SHIPMENT,
  faForward as SKIPPED,
  faSortAmountUp as SORT_ASC,
  faSortAmountDown as SORT_DESC,
  faRulerTriangle as SPECIFICATIONS,
  faScalpelPath as SPLIT,
  faUsers as USERS,
  faStar as STAR,
  faStopwatch as STOPWATCH,
  faSync as SYNC,
  faTable as TABLE,
  faTag as TAG,
  faFileSpreadsheet as TEMPLATE,
  faThList as TH_LIST,
  faRoute as TIMELINE,
  faToggleOff as TOGGLE_OFF,
  faToggleOn as TOGGLE_ON,
  faLifeRing as TRANSIT,
  faUnlink as UNBINDED,
  faUndo as UNDO,
  faQuestion as UNKNOWN,
  faUpload as UPLOAD,
  faUser as USER,
  faEye as VISIBLE,
  faWarehouse as WAREHOUSE,
  faExclamationCircle as WARNING,
  faExclamationTriangle as WARNING_TRIANGLE,
  faWavePulse as WAVE_PULSE,
  faFileWord as WORD,
} from '@fortawesome/pro-solid-svg-icons'

// Components or Routes

const Icons = {
  ORDER,
  BATCH,
  SHIPMENT,
  CONTAINER,
  PRODUCT,
  WAREHOUSE,
  INVENTORY,
  NETWORK,
  PARTNER,
  USERS,
  SETTINGS,
  TAG,
  ORDER_ITEM,
  NOTIFICATION,
  PROFILE,
  LOGIN,
  LOGOUT,
  IMPORTER,
  EXPORTER,
  FORWARDER,
  SUPPLIER,
  USER,
  MANAGER,
  WAREHOUSER,
  CARGO,
  PACKAGING,
  QUANTITY_ADJUSTMENTS,
  PRODUCT_PROVIDER,
  PRODUCT_PROVIDER_PACKAGE,
  SPECIFICATIONS,
  PHOTO,
  LOGS,
  CALCULATOR,
  DRAG_HANDLE,
  METADATA,
  TEMPLATE,
  STAR,
  STAR_REGULAR,
  STOPWATCH,
  EDIT_TABLE,
  COLUMNS,
  SECURITY,
  PASSWORD,
  FORGOT,
  IMPORT,
  SHEET,
  TH_LIST,
  RELATION_MAP,
  BINDED,
  UNBINDED,
  SPLIT,
  RELATED,
  SKIPPED,
  UNDO,
  BRING_FORWARD,
  CARDS,
  FOLDER,
  PARENT,

  BRANCH,
  ACTIVE,
  ARCHIVE,
  CARD,
  TABLE,
  LIST,
  SORT_DESC,
  SORT_ASC,
  FILTER,
  SEARCH,
  CLEAR,
  CANCEL,
  REMOVE,
  CLONE,
  CHECKED,
  CHECKED_SQUARE,
  UNCHECKED,
  DISABLED,
  EDIT,
  CONFIRM,
  MINUS,
  ADD,
  EYE,
  ARROW_UP,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_ALT_UP,
  ARROW_ALT_DOWN,
  ARROW_ALT_LEFT,
  ARROW_ALT_RIGHT,
  ARROWS_V,
  WARNING_TRIANGLE,
  WARNING,
  CHEVRON_UP,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CROSSHAIRS,
  ANGLE_LEFT,
  ANGLE_RIGHT,
  CHEVRON_DOUBLE_LEFT,
  CHEVRON_DOUBLE_UP,
  CHEVRON_DOUBLE_RIGHT,
  CHEVRON_DOUBLE_DOWN,
  DOCUMENT,
  TOGGLE_ON,
  TOGGLE_OFF,
  INFO,
  MEMO,
  MEMO_ADD,
  COMMENTS,
  DOWNLOAD,
  FILE_ARCHIVE,
  UPLOAD,
  CIRCLE,
  SYNC,
  EXPAND,
  COMPRESS,
  HORIZONTAL_ELLIPSIS,
  EXCHANGE,
  LANGUAGE,
  COLOR,
  EDITED,
  PAPER_PLANE,
  EXTERNAL_LINK,
  VISIBLE,
  INVISIBLE,
  RELOAD,
  CLOCK,
  MAP,
  ACTION,

  TIMELINE,
  CARGO_READY,
  PORT,
  PLANE,
  ROAD,
  RAIL,
  DRY,
  UNKNOWN,
  TRANSIT,
  CUSTOMS,
  DELIVERY_READY,
  ARRIVAL,
  DEPARTURE,
  ARRIVAL_HORIZONTAL,
  DEPARTURE_HORIZONTAL,

  PRINT_LIGHT,
  PRINT_REGULAR,
  PRINT,
  EXCEL,
  CSV,
  IMAGE,
  PASTE,
  PDF,
  POWER_POINT,
  WORD,
  EMAIL,
  WAVE_PULSE,
  SQUARE,
  TARGET,
  ENTITY,
  MAGIC,
  BRANCH_REGULAR,
  CALENDAR,
  MAGIC_REGULAR,
  DOCUMENT_REGULAR,
  CHECKED_REGULAR,
  BATCH_LIGHT,
  SHIPMENT_LIGHT,
  ARCHIVE_LIGHT,

  REFRESH,
  HOME,
}

export type IconEnumTypes = keyof typeof Icons

type IconEnumKeys = {
  [key in keyof typeof Icons]: key
}

/* eslint-disable no-param-reassign */
export const IconEnums: IconEnumKeys = Object.keys(Icons).reduce((arr, iconKey) => {
  arr[iconKey] = iconKey
  return arr
}, {} as IconEnumKeys)
/* eslint-enable no-param-reassign */

export default Icons
