import Icon from 'components/Icon'
import * as React from 'react'

import { IconEnumTypes } from '@components/Icon/enums'

import {
  SubMenuWrapperStyle,
  SubMenuItemWrapperStyle,
  SubMenuItemStyle,
  ChevronButtonStyle,
  SubMenuBodyStyle,
  IconStyle,
} from './styles'

interface Props {
  hasActiveChild: boolean
  icon: IconEnumTypes
  label: string
  children: React.ReactNode
}

const SubMenu = ({ hasActiveChild, icon, label, children }: Props) => {
  const menuItems: any = React.Children.toArray(children)

  const menuItemCount = menuItems.filter((child) => React.isValidElement(child)).length

  let debounceHover: null | NodeJS.Timeout = null
  const hasCursorClickedInTab = React.useRef(false)
  const [isExpanded, setExpanded] = React.useState(false)

  return (
    <SubMenuWrapperStyle>
      <SubMenuItemWrapperStyle
        isExpanded={isExpanded || hasActiveChild}
        hasActiveChild={hasActiveChild}
        onClick={() => {
          hasCursorClickedInTab.current = true
          setExpanded(!isExpanded)
        }}
        role="presentation"
      >
        <span />

        <SubMenuItemStyle
          onMouseEnter={() => {
            if (!isExpanded && !hasActiveChild) {
              debounceHover = setTimeout(() => {
                if (hasCursorClickedInTab.current) return
                setExpanded(true)
              }, 700)
            }
          }}
          onMouseLeave={() => {
            hasCursorClickedInTab.current = false
            if (debounceHover) {
              clearTimeout(debounceHover)
            }
          }}
        >
          <IconStyle>
            <Icon icon={icon} />
          </IconStyle>

          {label}
        </SubMenuItemStyle>

        <ChevronButtonStyle
          isExpanded={isExpanded || hasActiveChild}
          hasActiveChild={hasActiveChild}
          onClick={(evt) => {
            evt.stopPropagation()
            setExpanded(!(isExpanded && !hasActiveChild))
          }}
          role="presentation"
        >
          <Icon icon="CHEVRON_DOWN" />
        </ChevronButtonStyle>
      </SubMenuItemWrapperStyle>

      <SubMenuBodyStyle isExpanded={isExpanded || hasActiveChild} menuItemCount={menuItemCount}>
        {children}
      </SubMenuBodyStyle>
    </SubMenuWrapperStyle>
  )
}

export default SubMenu
