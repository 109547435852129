// @flow
import { css } from 'react-emotion'

export const VerticalTimelineWrapperStyle: string = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`

export default VerticalTimelineWrapperStyle
