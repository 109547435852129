// @flow
import { css } from 'react-emotion'

export const SectionWrapperStyle = (display: boolean): any => css`
  width: 100%;
  max-width: calc(100vw - 220px);
  display: flex;
  ${!display &&
  `
    height: 0;
    overflow: hidden;
  `};
  flex-direction: column;
  align-items: center;
  &[id$='documentsSection'] {
    > div {
      width: 100%;
      max-width: 880px;
      min-width: fit-content;
    }
  }
`

export default SectionWrapperStyle
