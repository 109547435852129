// @flow

import * as React from 'react'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import type { MessageGroup, Partner } from '@graphql/server/flow'

import messages from 'components/Form/DocumentsUpload/messages'
import {
  DocumentsDragAndDropTooltipWrapperStyle,
  NavContentRightContainer,
  NavContentRightContainerButtons,
} from 'components/Form/DocumentsUpload/style'
import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import { TabButtonGroup, EntityLabel, EntityIcon } from 'components/NavBar'
import type { Entities } from 'components/NavBar/components/EntityLabel/types'
import { Tooltip } from 'components/Tooltip'
import AddPartnerGroupButton from 'modules/chat/components/AddPartnerGroupButton'
import type { AllMainEntityType } from 'types'

import DocumentNavbarFileContent from '../DocumentNavbarFileContent'

import { IconWrapper } from './style'

export type UploadFileState = {
  id: string,
  name: string,
  path: string,
  type: string,
  tags: Object[],
  memo: string,
  entity?: Object,
  createdAt: string,
}

export type TabTypes = 'documentTypes' | 'deliveryBoxes'

type Props = {
  entityId: string,
  entityType: 'Order' | 'OrderItem' | 'Shipment' | 'ProductProvider',
  relatedPartners: Partner[],
  canDownload: boolean,
  isAllSelected: boolean,
  filesState: {
    ...UploadFileState,
    tags: [],
    uploading: boolean,
    progress: number,
  }[],
  selectedFiles: { [k: string]: string },
  setSelectedFiles: Function,
  isMultiSelect: boolean,
  onMultiSelectChange: (boolean) => void,
  defaultTab: TabTypes,
  onTabChange: (TabTypes) => void,
  onPartnerGroupAdded: (MessageGroup) => void,
  onSave: Function,
  isInDialog: boolean,
  label?: string,
}

const iconMappings: { [key: AllMainEntityType]: Entities } = {
  Order: 'ORDER',
  OrderItem: 'ORDER_ITEM',
  Shipment: 'SHIPMENT',
  ProductProvider: 'PRODUCT_PROVIDER',
}

const DocumentNavbarContent = ({
  entityId,
  entityType,
  relatedPartners,
  canDownload,
  isAllSelected,
  filesState,
  selectedFiles,
  setSelectedFiles,
  isMultiSelect,
  onMultiSelectChange,
  defaultTab,
  onTabChange,
  onPartnerGroupAdded,
  onSave,
  isInDialog,
  label,
}: Props): React.Node => {
  const intl = useIntl()

  const [tabs] = React.useState<
    {
      id: TabTypes,
      label: string,
      icon?: string,
    }[]
  >([
    {
      id: 'documentTypes',
      label: intl.formatMessage(messages.public),
    },
    {
      id: 'deliveryBoxes',
      label: intl.formatMessage(messages.private),
    },
  ])

  const [selectedTab, setSelectedTab] = React.useState<TabTypes>(defaultTab)

  useEffect(() => setSelectedTab(defaultTab), [defaultTab])

  return (
    <>
      {isInDialog && label && <EntityLabel name={label} entity={iconMappings[entityType]} />}
      <IconWrapper>
        {isInDialog && (
          <div className="icon">
            <EntityIcon icon={iconMappings[entityType]} color={iconMappings[entityType]} />
          </div>
        )}

        {entityType === 'Shipment' && (
          <TabButtonGroup
            items={tabs}
            selectedId={selectedTab}
            onSelect={(newTab) => {
              setSelectedTab((newTab.id: any))
              onTabChange((newTab.id: any))
            }}
          />
        )}
      </IconWrapper>
      <div className={NavContentRightContainer}>
        <div className={NavContentRightContainerButtons}>
          {selectedTab === 'documentTypes' && (
            <DocumentNavbarFileContent
              canDownload={canDownload}
              isAllSelected={isAllSelected}
              filesState={filesState}
              isMultiSelect={isMultiSelect}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              onDownloadFinish={() => setSelectedFiles({})}
              onSave={onSave}
              onSelectAllClick={() => {
                onMultiSelectChange(true)
                // TODO: to continue this
                setSelectedFiles(
                  isAllSelected
                    ? {}
                    : filesState.reduce((arr, file) => {
                        // eslint-disable-next-line
                        arr[file.id] = file
                        return arr
                      }, {})
                )
              }}
              onSelectMultipleClick={() => {
                if (isMultiSelect) setSelectedFiles({})

                onMultiSelectChange(!isMultiSelect)
              }}
            />
          )}
          {selectedTab === 'deliveryBoxes' && entityType === 'Shipment' && entityId && (
            <GridRow>
              <AddPartnerGroupButton
                entityId={entityId}
                onPartnerGroupAdded={(newPartners) => onPartnerGroupAdded(newPartners)}
                relatedPartners={relatedPartners}
              />
            </GridRow>
          )}
        </div>
        <Tooltip message={<FormattedMessage {...messages.dragAndDrop} />}>
          <div className={DocumentsDragAndDropTooltipWrapperStyle}>
            <Icon icon="INFO" />
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default DocumentNavbarContent
