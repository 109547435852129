// @flow strict
import firebase from 'firebase'
import * as React from 'react'

import { unregister } from '../../serviceWorker'

type Props = {
  revision: string,
  revisionKey: string,
}

const DeployHandler = ({ revision, revisionKey }: Props): null => {
  React.useEffect(() => {
    const docRef = firebase.database().ref(`/${revisionKey}`)

    docRef.on('value', (snapshot) => {
      if (!snapshot.exists()) {
        return
      }
      const currentRevision = snapshot.val()

      if (revision !== currentRevision) {
        unregister()
        window.swExpired = true
      }
    })
  }, [revision, revisionKey])

  return null
}

export default DeployHandler
