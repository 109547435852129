// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { CheckboxInput, Label } from 'components/Form'
import shipmentMessages from 'modules/shipment/messages'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

import { CheckboxWrapperStyle } from './style'

const Types = {
  AIR: shipmentMessages.AIR,
  FCL: shipmentMessages.FCL,
  LCL: shipmentMessages.LCL,
}

const ShipmentLoadType = ({
  value,
  onChange,
  readonly,
}: FilterInputProps<$Keys<typeof Types>[]>): React.Node => {
  const handleToggle = (type: $Keys<typeof Types>) => () => {
    if (value.includes(type)) {
      onChange(value.filter((t) => t !== type))
    } else {
      onChange([...value, type])
    }
  }

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.shipmentLoadType} />
      </Label>

      <div>
        {Object.entries(Types).map(([type, message]) => (
          <div key={type} className={CheckboxWrapperStyle}>
            <CheckboxInput
              checked={value.includes(type)}
              onToggle={handleToggle(type)}
              disabled={readonly}
            />
            <span>
              <FormattedMessage id={type} {...message} />
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default ShipmentLoadType
