// @flow strict
import { Link } from '@reach/router'
import * as React from 'react'

import type { ContainerFormFragment } from '@graphql/server/flow'

import Icon from 'components/Icon'
import scrollIntoView from 'utils/scrollIntoView'

import Ring from './Ring'
import { IconWrapperStyle, TimelineIconStyle } from './style'

type Props = {|
  containers: ContainerFormFragment[],
  linkPath?: string,
  targetId?: string,
  boundaryId?: string,
|}

const getApproved = (container: ContainerFormFragment) => {
  const agreedDateApproved = container.warehouseArrivalAgreedDateApprovedAt
  const actualDateApproved = container.warehouseArrivalActualDateApprovedAt
  return { agreedDateApproved, actualDateApproved }
}

const getIconColor = (containers: ContainerFormFragment[]) => {
  let color = 'TEAL'
  const allAgreed = containers.every((container) => !container.warehouseArrivalAgreedDateApprovedAt)
  containers.forEach((container) => {
    const { agreedDateApproved, actualDateApproved } = getApproved(container)
    if (!agreedDateApproved && !actualDateApproved) {
      color = 'GRAY_LIGHT'
    } else if (allAgreed && !actualDateApproved) {
      color = 'BLUE'
    }
  })
  return color
}

const getRingPercent = (containers: ContainerFormFragment[]) => {
  const totalContainer = containers.length
  if (totalContainer === 0) {
    return [0, 0]
  }
  let agreedDates = 0
  let actualDates = 0
  containers.forEach((container) => {
    const { agreedDateApproved, actualDateApproved } = getApproved(container)
    if (agreedDateApproved) {
      agreedDates += 1
    }
    if (actualDateApproved) {
      actualDates += 1
    }
  })
  const actualPercent = (actualDates / totalContainer) * 100
  const agreedPercent = (agreedDates / totalContainer) * 100
  return [actualPercent, agreedPercent]
}

const TimelineWarehouseContainerIcon = ({
  containers,
  linkPath,
  targetId,
  boundaryId,
}: Props): React.Node => {
  const iconColor = getIconColor(containers)
  const [actualPercent, agreedPercent] = getRingPercent(containers)

  if (typeof linkPath === 'string') {
    return (
      // $FlowFixMe Flow typed is not updated yet
      <Link
        className={TimelineIconStyle}
        to={linkPath}
        onClick={(evt: Event) => {
          evt.stopPropagation()
        }}
      >
        <Ring percent={actualPercent} size={30} color="TEAL" />
        <Ring percent={agreedPercent} size={26} color="BLUE" />
        <div className={IconWrapperStyle(iconColor)}>
          <Icon icon="WAREHOUSE" />
        </div>
      </Link>
    )
  }

  if (typeof targetId === 'string') {
    return (
      <button
        className={TimelineIconStyle}
        onClick={() => scrollIntoView({ targetId, boundaryId })}
        type="button"
      >
        <Ring percent={actualPercent} size={30} color="TEAL" />
        <Ring percent={agreedPercent} size={26} color="BLUE" />
        <div className={IconWrapperStyle(iconColor)}>
          <Icon icon="WAREHOUSE" />
        </div>
      </button>
    )
  }

  return (
    <div className={TimelineIconStyle}>
      <Ring percent={actualPercent} size={30} color="TEAL" />
      <Ring percent={agreedPercent} size={26} color="BLUE" />
      <div className={IconWrapperStyle(iconColor)}>
        <Icon icon="WAREHOUSE" />
      </div>
    </div>
  )
}

export default TimelineWarehouseContainerIcon
