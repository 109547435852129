// @flow strict
import type {
  ConnectionByWithOrganizationsFragment,
  UserFragment,
  UsersOrganizationFragment,
} from '@graphql/server/flow'

import { useAuthorizedViewer } from '../contexts/Auth'

const useUser = (): ({|
  user: $Diff<
    UserFragment,
    {
      // $FlowIgnore
      organization: *,
    }
  >,
  organization: UsersOrganizationFragment,
  isExporter: () => boolean,
  isForwarder: () => boolean,
  isImporter: () => boolean,
  isOwnerBy: (ownerId: string) => boolean,
  isSupplier: () => boolean,
  isUsingLegacyFeatures: () => boolean,
  isWarehouser: () => boolean,
  connections: ConnectionByWithOrganizationsFragment[],
|}) => {
  const { organization, user } = useAuthorizedViewer()

  return {
    user,
    organization,
    connections: user.connections
      ? user.connections.flatMap((c) => (c.__typename === 'Connection' ? c : []))
      : [],
    isOwnerBy: (ownerId: string) => !ownerId || ownerId === organization.id,
    isImporter: (): boolean => {
      if (organization) {
        const { types = [] } = organization
        return types.includes('Importer')
      }
      return false
    },
    isForwarder: (): boolean => {
      if (organization) {
        const { types = [] } = organization
        return types.includes('Forwarder')
      }
      return false
    },
    isExporter: (): boolean => {
      if (organization) {
        const { types = [] } = organization
        return types.includes('Exporter')
      }
      return false
    },
    isWarehouser: (): boolean => {
      if (organization) {
        const { types = [] } = organization
        return types.includes('Warehouser')
      }
      return false
    },
    isSupplier: (): boolean => {
      if (organization) {
        const { types = [] } = organization
        return types.includes('Supplier')
      }
      return false
    },
    isUsingLegacyFeatures: (): boolean => {
      // TODO: remove when NRM and GTV are ready
      return ['b9o2ju6rhpo001fg5hi0', 'bhucejdeqsa2ksd16460', 'c7i2flpnn5ipam5k5280'].includes(
        organization.id
      )
    },
  }
}

export default useUser
