import * as React from 'react'

import Icon from '@components/Icon'
import { Tooltip } from '@components/Tooltip'

import FormattedName from '../FormattedName'

import { AvatarWrapperStyle } from './style'

interface Props {
  width?: string
  height?: string
  firstName?: string
  lastName?: string
  showBothInitials?: boolean
  hideTooltip?: boolean
}

function UserAvatar({
  width = '30px',
  height = '30px',
  firstName = '',
  lastName = '',
  showBothInitials = false,
  hideTooltip = false,
  ...rest
}: Props) {
  let content = <></>

  if (firstName === '' || lastName === '') {
    content = <Icon icon="USER" />
  } else
    content = (
      <FormattedName
        firstName={firstName.charAt(0)}
        lastName={lastName.charAt(0)}
        showOnlyOneName={!showBothInitials}
      />
    )

  if (hideTooltip) {
    return (
      <AvatarWrapperStyle width={width} height={height}>
        {content}
      </AvatarWrapperStyle>
    )
  }

  return (
    <Tooltip message={<FormattedName firstName={firstName} lastName={lastName} {...rest} />}>
      <AvatarWrapperStyle width={width} height={height} tabIndex={-1}>
        {content}
      </AvatarWrapperStyle>
    </Tooltip>
  )
}

export default UserAvatar
