// @flow
import { css } from 'react-emotion'

import {
  borderRadiuses,
  colors,
  fontSizes,
  layout,
  presets,
  scrollbars,
  shadows,
  transitions,
} from 'styles/common'

export const InputWrapperStyle: any = css`
  ${layout.VERTICAL};
  padding-bottom: 20px;
`

export const HeaderWrapperStyle: any = css`
  ${layout.HORIZONTAL};
  justify-content: space-between;
  padding: 10px 0 20px;
`

export const TitleStyle: any = css`
  ${fontSizes.SMALL};
  color: ${colors.GRAY_DARK};
  letter-spacing: 0.2em;
`

export const ButtonStyle: any = css`
  ${presets.BUTTON};
  ${fontSizes.SMALL};
  color: ${colors.GRAY_LIGHT};
  &:not([disabled]) {
    cursor: pointer;
    &:hover {
      color: ${colors.TEAL};
    }
  }
`

export const MentionsInputStyle: string = css`
  text-align: left;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 1px 5px;
  ${scrollbars.SMALL};
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  & textarea,
  div {
    border: none;
    ${fontSizes.MAIN} !important;
    font-weight: bold;
    color: ${colors.BLACK};
  }
  & textarea {
    padding: 1px 5px;
    &::placeholder {
      color: ${colors.GRAY_LIGHT};
    }
  }
`

export const MentionStyle: string = css`
  background-color: ${colors.TEAL};
  opacity: 0.25;
  ${borderRadiuses.MAIN};
`

export const MentionAddOnStyle: string = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const SuggestionListStyle: string = css`
  & > div {
    overflow: auto;
    ${shadows.INPUT};
    background: ${colors.WHITE};
    ${borderRadiuses.MAIN};
    max-height: 140px;
    width: 200px;
    ${scrollbars.SMALL};
    cursor: pointer;
  }
`

export const MentionSuggestionStyle = (isHighlighted: boolean): string => css`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  max-width: 100%;
  background-color: ${isHighlighted ? colors.GRAY_SUPER_LIGHT : colors.TRANSPARENT};
  ${transitions.MAIN};
`

export const MentionSuggestionNameWrapperStyle: string = css`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 5px;
`

export const MentionNameStyle: string = css`
  ${fontSizes.MAIN};
  font-weight: bold;
  color: ${colors.BLACK};
  ${presets.ELLIPSIS};
  & b {
    color: ${colors.TEAL};
  }
`

export const MentionCompanyStyle: string = css`
  ${fontSizes.MEDIUM};
  color: ${colors.GRAY_DARK};
  ${presets.ELLIPSIS};
`

export const MessageButtonWrapper: any = css`
  display: flex;
  button {
    margin-left: 10px;
  }
`

export const TippyStyle: any = css`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  .tippy-backdrop {
    background-color: #fff;
  }
  .radio-wrapper {
    margin: 10px 10px 10px 8px;
  }
  .radio-label {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 12px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${colors.GRAY_DARK};
  }
`
