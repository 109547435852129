// @flow
import * as React from 'react'

import FALLBACK_IMAGE from '@public/media/logo_fallback.png'
import { isSubPath, isNext } from '@utils/env'

import Icon from 'components/Icon'
import { FullValueTooltip } from 'components/Tooltip'
import withForbiddenCard from 'hoc/withForbiddenCard'

import BaseCard from '../BaseCard'

import {
  OrganizationCardImageStyle,
  OrganizationCardStyle,
  OrganizationCodeStyle,
  OrganizationInfoWrapperStyle,
  OrganizationNameStyle,
  OrganizationTypeStyle,
  OrganizationTypesWrapperStyle,
} from './style'

type Props = {|
  onSelect: Function,
  size: 'full' | 'half' | 'quarter',
  selectable: boolean,
  readOnly: boolean,
  organization: Object, // Organization,
|}

const defaultProps = {
  onSelect: () => {},
  size: 'full',
  selectable: false,
  readOnly: false,
}

const OrganizationCard = ({ organization, onSelect, size, selectable, ...rest }: Props) => {
  const actions = selectable ? [] : []
  const types = organization?.types
  const partnerName = organization?.name
  const code = organization?.code

  return (
    <BaseCard
      actions={actions}
      icon="PARTNER"
      color="PARTNER"
      selectable={selectable}
      onSelect={onSelect}
      {...rest}
    >
      <div className={OrganizationCardStyle(size)} role="presentation" onClick={onSelect}>
        <img
          className={OrganizationCardImageStyle}
          src={isNext ? `${isSubPath ? '/new' : ''}/media/logo_fallback.png` : FALLBACK_IMAGE}
          alt="exporter_image"
        />
        <div className={OrganizationInfoWrapperStyle(size)}>
          <FullValueTooltip message={partnerName}>
            <div className={OrganizationNameStyle}>{partnerName}</div>
          </FullValueTooltip>
          <div className={OrganizationCodeStyle}>{code}</div>
          <div className={OrganizationTypesWrapperStyle(size)}>
            <div className={OrganizationTypeStyle(types.includes('Importer'))}>
              <Icon icon="IMPORTER" />
            </div>
            <div className={OrganizationTypeStyle(types.includes('Exporter'))}>
              <Icon icon="EXPORTER" />
            </div>
            <div className={OrganizationTypeStyle(types.includes('Supplier'))}>
              <Icon icon="SUPPLIER" />
            </div>
            <div className={OrganizationTypeStyle(types.includes('Forwarder'))}>
              <Icon icon="FORWARDER" />
            </div>
            <div className={OrganizationTypeStyle(types.includes('Warehouser'))}>
              <Icon icon="WAREHOUSER" />
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

OrganizationCard.defaultProps = defaultProps

export default (withForbiddenCard(OrganizationCard, 'organization', {
  width: '195px',
  height: '215px',
  entityIcon: 'PARTNER',
  entityColor: 'PARTNER',
}): any)
