// @flow strict

import { useMutation } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import messageDeleteMutation from '@graphql/client/chat/mutation.messageDelete.graphql'

import ConfirmDialog from 'components/Dialog/ConfirmDialog'
import useUser from 'hooks/useUser'
import { useChat } from 'modules/chat/hooks/useChat'

const MessageDeleteDialog = (): React.Node => {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  const { user } = useUser()
  const { state, dispatch } = useChat()

  const [messageDelete] = useMutation(messageDeleteMutation, {
    onCompleted: (data) => {
      if (data.messageDelete?.violations) {
        // TODO: handle error violations
        return
      }

      dispatch({
        type: 'DELETE_MESSAGE',
        payload: {
          messageId: state.selectedMessageId,
          user: {
            __typename: user.__typename,
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          },
        },
      })
    },
  })

  React.useEffect(() => {
    if (state.mode === 'DELETE_MODE') {
      setOpenConfirmDialog(true)
    }
  }, [state.mode])

  return (
    <ConfirmDialog
      isOpen={openConfirmDialog}
      onRequestClose={() => {
        setOpenConfirmDialog(false)
        dispatch({ type: 'RESET_MODE' })
      }}
      onCancel={() => {
        setOpenConfirmDialog(false)
        dispatch({ type: 'RESET_MODE' })
      }}
      onConfirm={() => {
        setOpenConfirmDialog(false)
        messageDelete({
          variables: {
            id: state.selectedMessageId,
          },
        })
      }}
      message={
        <FormattedMessage
          id="components.Documents.messageDeleteConfirmation"
          defaultMessage="Are you sure you want to delete this message?"
        />
      }
    />
  )
}

export default MessageDeleteDialog
