// @flow strict

import { css } from 'react-emotion'

import { colors, fontSizes, fontSizesWithHeights, presets } from 'styles/common'

export const TemplateCardWrapperStyle: string = css`
  width: 187.67px;
  height: 60px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const TemplateNameStyle: string = css`
  ${fontSizesWithHeights.MAIN};
  color: ${colors.BLACK};
  font-weight: bold;
  ${presets.ELLIPSIS};
  width: 185px;
`

export const TemplateCountWrapperStyle: string = css``

export const TemplateCountStyle: string = css`
  ${fontSizes.SMALL};
  color: ${colors.GRAY_DARK};
  ${presets.ELLIPSIS};
  line-height: 20px;
  padding: 0 5px;
`
