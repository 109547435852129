// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, fontSizes, presets, shadows } from 'styles/common'

export const WrapperStyle: any = css`
  position: relative;
`

export const DropDownStyle: any = css`
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  ${shadows.INPUT};
  background: ${colors.WHITE};
  ${borderRadiuses.MAIN};
  overflow: hidden;
`

export const ItemStyle: any = css`
  ${presets.BUTTON};
  justify-content: left;
  height: 30px;
  padding: 5px;
  ${fontSizes.MEDIUM};
  color: ${colors.GRAY_DARK};
  white-space: nowrap;
  &:hover,
  :focus {
    background-color: ${colors.GRAY_SUPER_LIGHT};
  }
`

export const ItemIconStyle: any = css`
  margin: 0 5px 0 0;
  ${fontSizes.SMALL};
`

export const ButtonStyle: any = css`
  ${borderRadiuses.MAIN};
  border: 1px solid ${colors.GRAY_LIGHT};
`
