// @flow strict
import ContainerBatchesContainer, {
  type ContainerBatchesFormState,
  containerBatchesFormKeys,
} from './batches'
import ContainerInfoContainer, { type ContainerInfoFormState, containerInfoFormKeys } from './info'

export type ContainerFormState = {|
  ...ContainerInfoFormState,
  ...ContainerBatchesFormState,
|}

export const containerFormKeys: string[] = [...containerInfoFormKeys, ...containerBatchesFormKeys]

export { ContainerBatchesContainer, ContainerInfoContainer }
