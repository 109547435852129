import Tippy, { TippyProps } from '@tippyjs/react'
import * as React from 'react'

interface Props extends TippyProps {
  message: JSX.Element | string
  children: JSX.Element
  delay?: number | [number, number]
  maxWidth?: number
  visible?: boolean
}

export default function Tooltip({ message, children, delay, maxWidth, visible, ...rest }: Props) {
  if (!message) {
    return children
  }

  return (
    <Tippy
      content={message}
      arrow
      delay={delay || 200}
      interactive
      ignoreAttributes
      visible={visible}
      {...(maxWidth ? { maxWidth } : {})}
      {...rest}
    >
      {children}
    </Tippy>
  )
}
