// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import documentTimelineQuery from '@graphql/client/document/query.documentTimeline.graphql'

import { SectionWrapper } from 'components/Form'
import JumpToSection from 'components/JumpToSection'
import { Content, FormLayout, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import LoadingIcon from 'components/LoadingIcon'
import { EntityIcon, LogsButton, NavBar } from 'components/NavBar'
import SectionTabs from 'components/NavBar/components/Tabs/SectionTabs'
import ResetFormButton from 'components/ResetFormButton'
import SaveFormButton from 'components/SaveFormButton'
import SlideView from 'components/SlideView'
import Timeline from 'modules/timeline/components/Timeline'
import { decodeId } from 'utils/id'

import DocumentSection from './components/DocumentSection'
import ParentSection from './components/ParentSection'

type Props = {
  documentId?: string,
  isDirty: boolean,
  isValidated: boolean,
  resetState: () => void,
  isSlideView?: boolean,
  isLoading?: boolean,
  isProcessing?: boolean,
  handleSave?: Function,
}

const DocumentForm = ({
  documentId,
  isDirty,
  isValidated,
  resetState,
  isSlideView,
  isLoading,
  isProcessing,
  handleSave,
}: Props): React.Node => {
  const CurrentNavBar = isSlideView ? SlideViewNavBar : NavBar

  return (
    <>
      <CurrentNavBar>
        <EntityIcon icon="DOCUMENT" color="DOCUMENT" />
        <JumpToSection>
          <SectionTabs
            link="document_documentSection"
            label={
              <FormattedMessage id="modules.Documents.documentSection" defaultMessage="Document" />
            }
            icon="DOCUMENT"
          />

          {!isSlideView && (
            <SectionTabs
              link="document_parentSection"
              label={
                <FormattedMessage id="modules.Documents.parentSection" defaultMessage="Parent" />
              }
              icon="PARENT"
            />
          )}
        </JumpToSection>

        {documentId && (
          <BooleanValue>
            {({ value: isOpen, set: toggleLogs }) => (
              <>
                <LogsButton
                  entityType="file"
                  entityId={documentId}
                  onClick={() => toggleLogs(true)}
                />
                <SlideView isOpen={isOpen} onRequestClose={() => toggleLogs(false)}>
                  <SlideViewLayout>
                    <SlideViewNavBar>
                      <EntityIcon icon="LOGS" color="LOGS" />
                    </SlideViewNavBar>

                    <Content>
                      <Timeline
                        query={documentTimelineQuery}
                        queryField="file"
                        variables={{
                          id: decodeId(documentId),
                        }}
                        entity={{
                          fileId: decodeId(documentId),
                        }}
                        users={[]}
                      />
                    </Content>
                  </SlideViewLayout>
                </SlideView>
              </>
            )}
          </BooleanValue>
        )}

        {isDirty && <ResetFormButton onClick={resetState} />}

        {isDirty && (
          <SaveFormButton
            id="document_form_save_button"
            disabled={!isValidated}
            isLoading={isProcessing}
            onClick={handleSave}
          />
        )}
      </CurrentNavBar>

      <Content>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <FormLayout>
            <SectionWrapper id="document_documentSection">
              <DocumentSection />
            </SectionWrapper>

            {!isSlideView && (
              <SectionWrapper id="document_parentSection">
                <ParentSection />
              </SectionWrapper>
            )}
          </FormLayout>
        )}
      </Content>
    </>
  )
}

export default DocumentForm
