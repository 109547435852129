// @flow strict

import * as React from 'react'
import { useRef } from 'react'
import type { Ref } from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import UserAvatar from '@components/UserAvatar'
import type { UserCardFragment } from '@graphql/server/flow'

import { ChatInputStyle } from 'modules/chat/style'
import {
  MentionCompanyStyle,
  MentionNameStyle,
  MentionStyle,
  MentionSuggestionNameWrapperStyle,
  MentionSuggestionStyle,
  SuggestionListStyle,
} from 'modules/timeline/components/CommentInput/style'

type Props = {
  disabled: boolean,
  value: string,
  onKeyDown: (SyntheticKeyboardEvent<EventTarget>) => void,
  onChange: (SyntheticInputEvent<EventTarget>) => void,
  onBlur: (event: SyntheticFocusEvent<HTMLTextAreaElement>) => void,
  onChange: (SyntheticInputEvent<EventTarget>) => void,
  placeholder: string,
  users?: UserCardFragment[],
  textAreaRef: Ref<'div'>,
}

const InputField = ({
  value,
  disabled,
  placeholder,
  onKeyDown,
  onBlur,
  onChange,
  textAreaRef,
  users = [],
}: Props): React.Node => {
  const mentionInputRef = useRef<?HTMLDivElement>()

  return (
    <>
      <div ref={mentionInputRef} className={SuggestionListStyle} />
      <MentionsInput
        className={ChatInputStyle}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        suggestionsPortalHost={mentionInputRef.current}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputRef={textAreaRef}
        allowSpaceInQuery
        allowSuggestionsAboveCursor
      >
        <Mention
          className={MentionStyle}
          data={users
            .filter((user) => user.id)
            .map((user) => ({
              ...user,
              display: `${user.firstName} ${user.lastName}`,
            }))}
          trigger="@"
          markup="@[__display__](__id__)"
          renderSuggestion={(suggestion, search, highlightedDisplay, index, isFocused) => (
            <div className={MentionSuggestionStyle(isFocused)}>
              <UserAvatar
                firstName={suggestion.firstName}
                lastName={suggestion.lastName}
                showBothInitials
                width="25px"
                height="25px"
              />

              <div className={MentionSuggestionNameWrapperStyle}>
                <div className={MentionNameStyle}>{highlightedDisplay}</div>
                <div className={MentionCompanyStyle}>{suggestion.organization?.name}</div>
              </div>
            </div>
          )}
        />
      </MentionsInput>
    </>
  )
}

export default InputField
