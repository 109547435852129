// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { IconButton } from 'components/Buttons'
import ConfirmDialog from 'components/Dialog/ConfirmDialog'
import messages from 'components/Form/DocumentsUpload/messages'
import { Tooltip } from 'components/Tooltip'

type Props = {
  onConfirm: () => void,
  disabled?: boolean,
}

const DeleteAllButton = ({ onConfirm, disabled }: Props): React.Node => {
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = React.useState(false)

  return (
    <>
      <Tooltip message={<FormattedMessage {...messages.deleteAll} />}>
        <IconButton
          icon="CLEAR"
          textColor="WHITE"
          hoverTextColor="WHITE"
          backgroundColor="GRAY_LIGHT"
          hoverBackgroundColor="GRAY_DARK"
          disabled={disabled}
          onClick={() => setConfirmationDialogIsOpen(true)}
        />
      </Tooltip>
      <ConfirmDialog
        isOpen={confirmationDialogIsOpen}
        onRequestClose={() => setConfirmationDialogIsOpen(false)}
        onCancel={() => setConfirmationDialogIsOpen(false)}
        onConfirm={() => {
          onConfirm()
          setConfirmationDialogIsOpen(false)
        }}
        message={<FormattedMessage {...messages.deleteAllFilesConfirmation} />}
      />
    </>
  )
}

export default DeleteAllButton
