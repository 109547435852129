// @flow
import * as React from 'react'

type OptionalProps = {
  value: any,
  onChange: ?Function,
  onBlur: ?Function,
  onFocus: ?Function,
  readOnly: boolean,
  readOnlyWidth?: string,
  readOnlyHeight?: string,
  align: 'left' | 'right' | 'center',
  placeholder: ?string,
  afterClearSelection: Function,
}

export type RenderSelectProps = {
  isOpen: boolean,
  clearSelection: (cb?: Function) => mixed,
  toggle: () => mixed,
  selectedItem: any,
  itemToString: (any) => string,
  getInputProps: Function,
}

/**
 * @param  {() => void} loadMore loadMoreItems
 * @param  {number} loadMoreThreshold the number of offscreen items at which to load more
 */
export type RenderOptionsProps = {
  highlightedIndex: ?number,
  selectedItem: any,
  getItemProps: Function,
  loadMore?: () => void,
  loadMoreThreshold?: number,
  items: any[],
  itemToValue: (any) => any,
  itemToString: (any) => string,
}

export type SelectInputProps = OptionalProps & {
  name: string,
  formPath: string,
  items: any[],
  itemToString: (any) => string,
  // TODO: itemToValue was removed from downshift
  itemToValue: (any) => any,
  renderSelect: (RenderSelectProps) => React.Node,
  renderOptions: (RenderOptionsProps) => React.Node,
  downshiftRef: React.ElementRef<any>,
}

export const defaultSelectInputProps = {
  value: '',
  readOnly: false,
  align: 'left',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  afterClearSelection: () => {},
  placeholder: null,
}

export default defaultSelectInputProps
