// @flow strict

import * as React from 'react'

import relationPartnersQuery from '@graphql/client/partner/query.relationPartners.graphql'
import type {
  OrganizationType,
  Scalars,
  RelationPartnerCardFragment,
  OrganizationFilterInput,
  RelationPartnersQuery,
  RelationPartnersQueryVariables,
} from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton } from 'components/Buttons'
import { OrganizationCard } from 'components/Cards'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  Filter,
  PartnerFilterConfig,
  Search,
  // Sort, //ZEN-3971
} from 'components/NavBar'
import Selector from 'components/Selector'
import useFilterSort from 'hooks/useFilterSort'
import PartnerGridView from 'modules/partner/list/PartnerGridView'
import { isDevEnvironment } from 'utils/env'

type Props = {|
  partners?: RelationPartnerCardFragment[],
  partnerTypes?: OrganizationType[],
  partnerCount?: number,
  informDirty?: (boolean) => void,
  skip?: boolean,
  connectionId?: $ElementType<Scalars, 'ID'>,
  selected: RelationPartnerCardFragment[],
  unselectBlacklist?: $ElementType<Scalars, 'ID'>[],
  alwaysAllowSave?: boolean,
  onCancel: () => void,
  onSelect: (item: RelationPartnerCardFragment[]) => void,
|}

/**
 * A selector component for selecting relation partners
 * @param partners optional options for if you need to pass choices in via props and not via remote
 * @param connectionId required query filter param except for the case where the partners are handed locally as above prop
 * @param selected a list of the selected partner organizations
 * @param unselectBlacklist list of organization ids that are not allowed to be unselected
 */
const SelectRelationPartners = ({
  partners,
  partnerTypes = [],
  partnerCount,
  informDirty,
  skip = false,
  connectionId,
  selected,
  unselectBlacklist = [],
  alwaysAllowSave,
  onCancel,
  onSelect,
}: Props): React.Node => {
  const {
    query,
    setQuery,
    filterBy,
    filterByWithoutQuery,
    setFilterBy,
    //  sortBy, //ZEN-3971
    //  setSortBy //ZEN-3971
  } = useFilterSort<OrganizationFilterInput, _>(
    {
      query: '',
      types: partnerTypes,
      ...(connectionId !== undefined ? { connectionIds: [connectionId] } : null),
    },
    {}
  )

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    RelationPartnerCardFragment,
    RelationPartnersQuery,
    RelationPartnersQueryVariables
  >(
    relationPartnersQuery,
    {
      variables: {
        filterBy,
        // sortBy, //ZEN-3971
        page: 1,
        perPage: 10,
      },
      skip: skip || !!partners,
    },
    'viewer.user.organization.relationPartners'
  )

  const items = React.useMemo(() => [...(partners || nodes)].filter(Boolean), [nodes, partners])

  return (
    <Selector.Many selected={selected} dirtyByIds>
      {({ value, dirty, getItemProps }) => {
        if (informDirty) informDirty(dirty)

        return (
          <SlideViewLayout>
            <SlideViewNavBar>
              <EntityIcon icon="PARTNER" color="PARTNER" />

              <Filter
                config={PartnerFilterConfig}
                rootQueryType="User"
                filterBy={filterByWithoutQuery}
                onChange={setFilterBy}
                staticFilters={isDevEnvironment ? [] : ['types', 'connectionIds']}
              />
              <Search query={query} onChange={setQuery} />
              {/* <Sort config={PartnerSortConfig} sortBy={sortBy} onChange={setSortBy} /> //ZEN-3961 */}

              {partnerCount !== undefined && partnerCount > 0 && (
                <h3>
                  {value.length}/{partnerCount}
                </h3>
              )}
              <CancelButton disabled={false} onClick={onCancel} />
              <SaveButton
                disabled={(alwaysAllowSave === undefined || alwaysAllowSave === false) && !dirty}
                onClick={() => onSelect(value)}
              />
            </SlideViewNavBar>
            <Content>
              {isDevEnvironment && (
                <div style={{ color: 'red', fontWeight: 800 }}>
                  Dev build: You may edit the static filters of this component
                </div>
              )}
              <PartnerGridView
                hasMore={hasMore}
                isLoading={loading}
                onLoadMore={loadMore}
                items={items}
                renderItem={(item) => {
                  const { onSelect: getItemSelect, ...itemProps } = getItemProps(item)

                  return (
                    <OrganizationCard
                      key={item.id}
                      organization={item}
                      onSelect={
                        unselectBlacklist.some((id) => item?.id === id) ? undefined : getItemSelect
                      }
                      {...itemProps}
                    />
                  )
                }}
              />
            </Content>
          </SlideViewLayout>
        )
      }}
    </Selector.Many>
  )
}

export default SelectRelationPartners
