// @flow
/* eslint-disable no-use-before-define */
import type {
  BadRequest,
  BadRequestFragment,
  DefaultFilterInput,
  DefaultSortInput,
  EntityPayload,
  Forbidden,
  ForbiddenFragment,
  NotFound,
  NotFoundFragment,
  Query,
} from '@graphql/server/flow'

// Flow errors on multiple conditional spreads, this is a workaround, see
// https://github.com/facebook/flow/issues/8186#issuecomment-629812957
export const maybeSpread = <T: { ... }>(obj: T | null): $ObjMap<T, <U>(U) => U | void> => {
  return obj === null ? { ...null } : obj
}

export type UniversalBadRequest =
  | BadRequest
  | BadRequestFragment
  | { __typename: $ElementType<BadRequest, '__typename'> }

export type UniversalForbidden =
  | Forbidden
  | ForbiddenFragment
  | { __typename: $ElementType<Forbidden, '__typename'> }

export type UniversalNotFound =
  | NotFound
  | NotFoundFragment
  | { __typename: $ElementType<NotFound, '__typename'> }

// You may receive these responses even when only requesting TNode from GQL.
export type Payload<TNode: *> =
  | TNode
  | { __typename: $ElementType<BadRequest, '__typename'>, ... }
  | { __typename: $ElementType<Forbidden, '__typename'>, ... }
  | { __typename: $ElementType<NotFound, '__typename'>, ... }

// You may receive these responses when calling TNode AND error data from GQL.
export type ExplicitPayload<TNode: *> =
  | TNode
  | BadRequestFragment
  | ForbiddenFragment
  | NotFoundFragment

export type ExtractFound<T1> = $Call<
  <T2>(
    | $Pick<NotFound, { __typename: * }>
    | $Pick<Forbidden, { __typename: * }>
    | $Pick<BadRequest, { __typename: * }>
    | T2
  ) => T2,
  T1
>

export type ExtractBadRequest<T1> = $Call<
  <T2>(
    | { __typename: T1 }
    | $Pick<NotFound, { __typename: * }>
    | $Pick<Forbidden, { __typename: * }>
    | T2
  ) => T2,
  T1
>

export type Entity = ExtractFound<EntityPayload>
export type DocumentedEntity =
  | { __typename: 'Product' }
  | { __typename: 'MessageGroup' }
  | { __typename: 'ProductProvider' }
  | { __typename: 'OrderItem' }
  | { __typename: 'Order' }
  | { __typename: 'Shipment' }
  | { __typename: 'Folder' }

// If the query has nodes, you may use this constructor to type the nodes from a fragment
export type QueryPaginatedSearchConstructor<TNode: any, TKey: string> = {|
  __typename: $ElementType<Query, '__typename'>,
  [TKey]: {
    ...$ElementType<Query, TKey>,
    nodes: TNode | BadRequest | Forbidden | NotFound[],
  },
|}

export type DefaultPaginatedQueryArgs = {
  page: number,
  perPage: number,
  filterBy?: ?$Exact<DefaultFilterInput & Object>,
  sortBy?: ?$Exact<DefaultSortInput & Object>,
  ...
}

export type $Anyify = <V>(V) => {| V: * |}
export type $Pick<Origin: Object, Keys: Object> = $ObjMapi<
  Keys,
  <Key>(k: Key) => $ElementType<Origin, Key>
>
export type $ExtractArray = <T>(T[]) => T
export type $ExtractArrayType<T> = $Call<$ExtractArray, T>
export type $ReturnType<F> = $PropertyType<$ObjMap<{ x: F }, <R>(f: (...any) => R) => R>, 'x'>
export type $Partial<T> = $Rest<T, {}>

export type $DeepShape<O: Object> = $Shape<
  $ObjMap<
    O,
    | (<V: Object>(
        V | BadRequest | Forbidden | NotFound
      ) => $DeepShape<V> | BadRequest | Forbidden | NotFound)
    | (<V: Object>(V) => $DeepShape<V>)
    | (<V>(V) => V)
  >
>

// This is an introspection result which is present in TypeScript but not in Flow, copied from TypeScript and amended to work in Flow
export type __EnumValue = {
  __typename: '__EnumValue',
  name: string,
  description?: ?string,
  isDeprecated: boolean,
  deprecationReason?: ?string,
}

export const AllMainEntityTypeValues = Object.freeze({
  Order: 'Order',
  OrderItem: 'OrderItem',
  Shipment: 'Shipment',
  Batch: 'Batch',
  Container: 'Container',
  Product: 'Product',
  ProductProvider: 'ProductProvider',
  ProductProviderPackage: 'ProductProviderPackage',
  Warehouse: 'Warehouse',
})

export type AllMainEntityType = $Values<typeof AllMainEntityTypeValues>
