// @flow
import * as React from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ArrayValue } from 'react-values'

import orderItemListSelectorQuery from '@graphql/client/orderItem/query.orderItemListSelector.graphql'
import type { ItemInSelectorFragment, QueryOrderItemsArgs } from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton } from 'components/Buttons'
import { ItemCard } from 'components/Cards'
import GridView from 'components/GridView'
import IncrementInput from 'components/IncrementInput'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import { EntityIcon, Search, SortInput } from 'components/NavBar'
import useFilter from 'hooks/useFilter'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'
import messages from 'modules/order/messages'
import { ORDER_ITEMS_GET_PRICE } from 'modules/permission/constants/orderItem'
import type { Payload, QueryPaginatedSearchConstructor } from 'types'

import { ItemWrapperStyle } from './style'

type OptionalProps = {
  cacheKey: string,
  isLoading?: boolean,
  isSubContent?: boolean,
  disableIncrement?: boolean,
  singleSelection?: boolean,
  saveOnSelect?: boolean,
}

type Props = OptionalProps & {
  onCancel: Function,
  onSelect: Function,
  filter: Object,
  intl: IntlShape,
}

function initFilterBy(filter: Object) {
  return {
    perPage: 20,
    page: 1,
    filter: {
      query: '',
      archived: false,
      ...filter,
    },
    sort: { field: 'updatedAt', direction: 'DESCENDING' },
  }
}

function SelectOrderItems({
  intl,
  cacheKey,
  onCancel,
  onSelect,
  filter,
  isSubContent,
  isLoading = false,
  saveOnSelect = false,
  disableIncrement,
  singleSelection,
}: Props) {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)
  const fields = [
    { title: intl.formatMessage(messages.updatedAtSort), value: 'updatedAt' },
    { title: intl.formatMessage(messages.createdAtSort), value: 'createdAt' },
  ]
  const {
    filterAndSort: filtersAndSort,
    queryVariables,
    onChangeFilter: onChange,
  } = useFilter(initFilterBy(filter), cacheKey)

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    Payload<ItemInSelectorFragment>,
    QueryPaginatedSearchConstructor<ItemInSelectorFragmentFragment, 'orderItems'>,
    QueryOrderItemsArgs
  >(
    orderItemListSelectorQuery,
    {
      variables: queryVariables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    'orderItems',
    { disableReset: true }
  )

  return (
    <ArrayValue>
      {({ value: selected, push, set, splice, filter: arrayValueFilter }) => (
        <SlideViewLayout>
          <SlideViewNavBar isSubNavBar={isSubContent}>
            <EntityIcon icon="ORDER_ITEM" color="ORDER_ITEM" />
            <SortInput
              sort={fields.find((item) => item.value === filtersAndSort.sort.field) || fields[0]}
              ascending={filtersAndSort.sort.direction !== 'DESCENDING'}
              fields={fields}
              onChange={({ field: { value }, ascending }) =>
                onChange({
                  ...filtersAndSort,
                  sort: {
                    field: value,
                    direction: ascending ? 'ASCENDING' : 'DESCENDING',
                  },
                })
              }
            />
            <Search
              query={filtersAndSort.filter.query}
              onChange={(newQuery) =>
                onChange({
                  ...filtersAndSort,
                  filter: { ...filtersAndSort.filter, query: newQuery },
                })
              }
            />
            <CancelButton onClick={onCancel} disabled={isLoading} />
            {!saveOnSelect && (
              <SaveButton
                disabled={selected.length === 0 || isLoading}
                isLoading={isLoading}
                onClick={() => {
                  onSelect(singleSelection ? selected[0] : selected)
                }}
              />
            )}
          </SlideViewNavBar>

          <Content hasSubNavBar={isSubContent}>
            <GridView
              onLoadMore={loadMore}
              hasMore={hasMore}
              isLoading={loading}
              itemWidth="195px"
              isEmpty={nodes.length === 0}
              emptyMessage={
                <FormattedMessage
                  id="modules.Batches.noOrderItemsFound"
                  defaultMessage="No orderItems found"
                />
              }
            >
              {nodes.flatMap((item, itemIndex) => {
                if (item.__typename !== 'OrderItem') return []

                const viewable = {
                  price: hasPermission(ORDER_ITEMS_GET_PRICE),
                }

                const config = {
                  hideOrder: false,
                }

                const index: ?Number = item.id
                  ? selected.map(({ id }) => id).indexOf(item.id)
                  : null
                const isSelected = index !== -1

                return (
                  <div key={item.id || itemIndex} className={ItemWrapperStyle}>
                    {!disableIncrement && isSelected && (
                      <IncrementInput
                        value={
                          selected.filter((selectedItem) => selectedItem.id === item.id).length
                        }
                        onMinus={() => splice(index, 1)}
                        onPlus={() => push(item)}
                      />
                    )}
                    <ItemCard
                      orderItem={item.id}
                      productProvider={item.productProvider}
                      product={
                        item.productProvider.__typename === 'ProductProvider'
                          ? item.productProvider.product
                          : null
                      }
                      order={item.order}
                      viewable={viewable}
                      config={config}
                      selectable
                      selected={isSelected}
                      onSelect={() => {
                        if (singleSelection) {
                          set(saveOnSelect || !isSelected ? [item] : [])
                        } else if (isSelected) {
                          arrayValueFilter(({ id }) => id !== item.id)
                        } else {
                          push(item)
                        }

                        if (saveOnSelect) {
                          onSelect(singleSelection ? item : selected)
                        }
                      }}
                    />
                  </div>
                )
              })}
            </GridView>
          </Content>
        </SlideViewLayout>
      )}
    </ArrayValue>
  )
}

const defaultProps = {
  cacheKey: 'SelectOrderItems',
  isSubContent: false,
  disableIncrement: false,
  singleSelection: false,
}

SelectOrderItems.defaultProps = defaultProps

export default (injectIntl(SelectOrderItems): any)
