// @flow
import { useQuery, useSubscription } from '@apollo/client'
import { useState } from 'react'
import * as React from 'react'

import countNotificationQuery from '@graphql/client/notification/query.countNotification.graphql'
import subscribeNewNotification from '@graphql/client/notification/subscription.notificationNew.graphql'
import type {
  CountNotificationQuery,
  CountNotificationQueryVariables,
  NotificationFragment,
  NotificationNewSubscription,
  NotificationNewSubscriptionVariables,
} from '@graphql/server/flow'

import { useAuthenticated } from 'contexts/Auth'

type Context = {|
  notificationNewData: ?NotificationNewSubscription,
  unseen: number,
  setUnseen: (((number) => number) | number) => void,
  setIsReadingNotifications: (((boolean) => boolean) | boolean) => void,
  setOnSubscriptionData: (() => ({ notificationNew: NotificationFragment }) => void) => void,
|}

export const NotificationsContext: React$Context<Context> = React.createContext<Context>({
  notificationNewData: null,
  unseen: 0,
  setUnseen: () => {},
  setIsReadingNotifications: () => {},
  setOnSubscriptionData: () => {},
})

type Props = {|
  children: React.Node,
|}

export const useNotification = (): Context => React.useContext(NotificationsContext)

const NotificationsProvider = ({ children }: Props): React.Node => {
  const { authenticated } = useAuthenticated()
  const [unseen, setUnseen] = useState<number>(0)
  const [isReadingNotifications, setIsReadingNotifications] = useState<boolean>(false)
  const [onSubscriptionData, setOnSubscriptionData] = useState<?Function>(null)

  useQuery<CountNotificationQuery, CountNotificationQueryVariables>(countNotificationQuery, {
    skip: !authenticated,
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (result) {
        setUnseen(result.viewer.notificationUnseenCount)
      }
    },
  })

  const { data: notificationNewData } = useSubscription<
    NotificationNewSubscription,
    NotificationNewSubscriptionVariables
  >(subscribeNewNotification, {
    skip: !authenticated,
    onSubscriptionData({ subscriptionData: { data } }) {
      if (data?.notificationNew) {
        const { notificationNew } = data
        if (!isReadingNotifications) setUnseen((prev) => prev + 1)
        if (onSubscriptionData) onSubscriptionData({ notificationNew })
      }
    },
  })

  return (
    <NotificationsContext.Provider
      value={{
        notificationNewData,
        unseen,
        setUnseen,
        setIsReadingNotifications,
        setOnSubscriptionData,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
