// @flow
import { css } from 'react-emotion'

import { layout } from 'styles/common'

export const GridRowWrapperStyle = (gap: string, width?: string, hCentered: boolean): string => css`
  ${layout.GRID_HORIZONTAL};
  grid-gap: ${gap};
  width: ${width};
  align-items: ${hCentered ? 'center' : 'initial'};
`

export const GridRowAutoWidthWrapperStyle = (gap: string, width?: string): string => css`
  ${layout.GRID_HORIZONTAL_AUTO_WIDTH};
  grid-gap: ${gap};
  width: ${width};
`
