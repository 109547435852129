// @flow strict
import cloneDeep from 'lodash/cloneDeep'
import { Container } from 'unstated'

import type { FieldValuesFragment, Scalars, ShipmentForm1Fragment } from '@graphql/server/flow'
import { cleanFalsyAndTypeName } from '@utils/data'

import type { $Anyify, $Pick } from 'types'
import { initDatetimeToContainer } from 'utils/date'
import { isEquals } from 'utils/fp'
import { defaultVolumeMetric, defaultWeightMetric } from 'utils/metric'

const shipmentFormInfoFormKeysObj = Object.freeze({
  archived: 'archived',
  importer: 'importer',
  exporter: 'exporter',
  no: 'no',
  blNo: 'blNo',
  blDate: 'blDate',
  masterBlNo: 'masterBlNo',
  booked: 'booked',
  bookingNo: 'bookingNo',
  bookingDate: 'bookingDate',
  invoiceNo: 'invoiceNo',
  incoterm: 'incoterm',
  placeOfReceipt: 'placeOfReceipt',
  placeOfDelivery: 'placeOfDelivery',
  autoTrackingBy: 'autoTrackingBy',
  autoTrackingTransportType: 'autoTrackingTransportType',
  carrier: 'carrier',
  followers: 'followers',
  autoTracking: 'autoTracking',
  autoTrackingIncludeContainers: 'autoTrackingIncludeContainers',
  contractNo: 'contractNo',
  loadType: 'loadType',
  memo: 'memo',
  forwarders: 'forwarders',
  totalPackageQuantityOverride: 'totalPackageQuantityOverride',
  totalPackageQuantityOverriding: 'totalPackageQuantityOverriding',
  totalVolumeOverride: 'totalVolumeOverride',
  totalVolumeOverriding: 'totalVolumeOverriding',
  totalWeightOverride: 'totalWeightOverride',
  totalWeightOverriding: 'totalWeightOverriding',
  organizations: 'organizations',
  ownedBy: 'ownedBy',
  connectionBy: 'connectionBy',
  customFields: 'customFields',
})

export const shipmentFormInfoKeys: $Keys<typeof shipmentFormInfoFormKeysObj>[] = Object.keys(
  shipmentFormInfoFormKeysObj
)

export type ShipmentInfoFormState = {|
  ...$Pick<
    $ObjMap<ShipmentForm1Fragment, <T>(T) => $NonMaybeType<T>>,
    $ObjMap<typeof shipmentFormInfoFormKeysObj, $Anyify>
  >,
  id: ?Scalars['ID'],
  connectionId: ?Scalars['ID'],
  customFields: {|
    ...ShipmentForm1Fragment['customFields'],
    fieldValues: $ReadOnlyArray<FieldValuesFragment>,
  |},
|}

export const initValues: ShipmentInfoFormState = {
  id: undefined,
  archived: false,
  importer: undefined,
  exporter: undefined,
  no: '',
  blNo: undefined,
  blDate: undefined,
  masterBlNo: undefined,
  booked: undefined,
  bookingNo: undefined,
  bookingDate: undefined,
  invoiceNo: undefined,
  incoterm: undefined,
  placeOfReceipt: undefined,
  placeOfDelivery: undefined,
  autoTracking: false,
  autoTrackingIncludeContainers: false,
  autoTrackingBy: undefined,
  autoTrackingTransportType: undefined,
  carrier: undefined,
  followers: [],
  contractNo: undefined,
  loadType: undefined,
  memo: undefined,
  forwarders: [],
  totalPackageQuantityOverride: undefined,
  totalPackageQuantityOverriding: false,
  totalVolumeOverride: { __typename: 'MetricValue', value: 0, metric: defaultVolumeMetric },
  totalVolumeOverriding: false,
  totalWeightOverride: { __typename: 'MetricValue', value: 0, metric: defaultWeightMetric },
  totalWeightOverriding: false,
  organizations: [],
  ownedBy: { __typename: 'NotFound' },
  connectionBy: undefined,
  connectionId: undefined,
  customFields: { __typename: 'CustomFields', fieldValues: [] },
}

export default class ShipmentInfoContainer extends Container<ShipmentInfoFormState> {
  state: ShipmentInfoFormState = initValues

  originalValues: ShipmentInfoFormState = initValues

  isDirty: () => boolean = () => {
    /**
      Archived is added to this container for ease of use, but it does not count towards dirty state/mutation
      input etc. as changes to this value are instantaneous.
    */
    const { archived: currentArchived, ...currentStateNoArchived } = cloneDeep({ ...this.state })
    const { archived: originalArchived, ...originalStateNoArchived } = cloneDeep({
      ...this.originalValues,
    })
    return !isEquals(
      cleanFalsyAndTypeName(currentStateNoArchived),
      cleanFalsyAndTypeName(originalStateNoArchived)
    )
  }

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
    this.setState(this.originalValues)
  }

  setFieldValue: <P: $Keys<ShipmentInfoFormState>>(
    name: P,
    value: ShipmentInfoFormState[P]
  ) => void = <P: $Keys<ShipmentInfoFormState>>(name: P, value: ShipmentInfoFormState[P]) => {
    this.setState({
      [(name: string)]: value,
    })
  }

  setFieldValues: (values: ShipmentInfoFormState) => void = (values) => {
    this.setState(values)
  }

  // On change partners, set new partners and clean up Followers
  onChangePartners: ({|
    newPartners: ShipmentInfoFormState['organizations'],
  |}) => void = ({ newPartners }) => {
    this.setState((prev: ShipmentInfoFormState) => {
      const { followers, organizations: oldPartners, forwarders, exporter, importer } = prev

      // Check if also forwarder, exporter, or importer
      const alsoForwarders = oldPartners.flatMap((oldPartner) =>
        oldPartner.__typename === 'Organization' &&
        forwarders.some(
          (forwarder) => forwarder.__typename === 'Organization' && forwarder.id === oldPartner.id
        )
          ? oldPartner
          : []
      )

      const alsoExporters = oldPartners.flatMap((oldPartner) =>
        exporter?.__typename === 'Organization' && exporter.id === oldPartner.id ? oldPartner : []
      )

      const alsoImporters = oldPartners.filter((oldPartner) => importer?.id === oldPartner.id)

      if (alsoForwarders.length > 0 || alsoExporters.length > 0 || alsoImporters.length > 0) {
        const removedPartners = oldPartners.filter(
          (oldPartner) => !newPartners.some((newPartner) => newPartner.id === oldPartner.id)
        )

        if (oldPartners.length > 0 && removedPartners.length > 0) {
          const cleanedFollowers = followers.filter(
            (follower) =>
              !removedPartners.some(
                (removedPartner) => removedPartner.id === follower.organization?.id
              )
          )

          return {
            ...prev,
            organizations: newPartners,
            followers: cleanedFollowers,
          }
        }
      }

      return { ...prev, organizations: newPartners }
    })
  }

  // On change Importer or Exporter, set new partner and clean up Followers
  onChangePartner: <P: 'importer' | 'exporter'>(
    fieldName: P,
    newPartner: ShipmentInfoFormState[P] | void
  ) => void = <P: 'importer' | 'exporter'>(fieldName: P, newPartner: ShipmentInfoFormState[P]) => {
    if (newPartner && newPartner.__typename !== 'Organization') return
    this.setState(({ followers, [fieldName]: prevPartner }: ShipmentInfoFormState) => {
      if (prevPartner) {
        const cleanedFollowers: ShipmentInfoFormState['followers'] = followers.flatMap((follower) =>
          follower.__typename === 'User' && follower.organization?.id !== prevPartner.id
            ? follower
            : []
        )

        return { [(fieldName: string)]: newPartner, followers: cleanedFollowers }
      }

      return { [(fieldName: string)]: newPartner }
    })
  }

  // On change Forwarders, set new Forwarders clean up Followers
  onChangeForwarders: (newForwarders: ShipmentInfoFormState['forwarders']) => void = (
    newForwarders
  ) => {
    this.setState(({ followers, forwarders: prevForwarders, ...rest }: ShipmentInfoFormState) => {
      const removedForwarders = prevForwarders.flatMap((prevForwarder) =>
        !newForwarders.some((newForwarder) => newForwarder.id === prevForwarder.id)
          ? prevForwarder
          : []
      )

      if (prevForwarders.length > 0 && removedForwarders.length > 0) {
        const cleanedFollowers = followers.flatMap((follower) =>
          !removedForwarders.some(
            (removedForwarder) => removedForwarder.id === follower.organization?.id
          )
            ? follower
            : []
        )

        return { ...rest, forwarders: newForwarders, followers: cleanedFollowers }
      }

      return { ...rest, forwarders: newForwarders }
    })
  }

  initDetailValues: (values: Partial<ShipmentForm1Fragment>, timezone: string) => void = (
    values,
    timezone
  ) => {
    const { blDate, bookingDate, customFields, ...rest } = values

    // $FlowIssue: see initDatetimeToContainer implementation
    const info: ShipmentInfoFormState = {
      // $FlowIgnore
      ...initDatetimeToContainer(blDate, 'blDate', timezone),
      // $FlowIgnore
      ...initDatetimeToContainer(bookingDate, 'bookingDate', timezone),
      customFields: {
        ...customFields,
        __typename: 'CustomFields',
        fieldValues: (customFields?.fieldValues ?? []).flatMap((fv) =>
          fv.__typename === 'FieldValue' ? fv : []
        ),
      },
      ...rest,
    }

    const parsedValues = { ...initValues, ...info }

    this.setState(parsedValues)
    this.originalValues = { ...parsedValues }
  }
}
