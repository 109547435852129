// @flow

export const CREATE = 'create'
export const UPDATE_FIELD = 'update_field'
export const ARCHIVED = 'archived'
export const UNARCHIVED = 'unarchived'
export const TAGS = 'tags'
export const DOCUMENTS = 'documents'
export const FORWARDERS = 'forwarders'
export const REVISE_DATE = 'revise_date'
export const UN_REVISE_DATE = 'un_revise_date'
