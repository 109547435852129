// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import usersByIDsMinimalQuery from '@graphql/client/user/query.usersByIDsMinimal.graphql'

import { Label, UserAssignmentInput } from 'components/Form'
import { useAuthorizedViewer } from 'contexts/Auth'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

const Users = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => {
  const { organization } = useAuthorizedViewer()
  const { data } = useQuery(usersByIDsMinimalQuery, {
    variables: { ids: value },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.users} />
      </Label>

      <UserAssignmentInput
        users={data?.usersByIDs ?? []}
        name="users"
        onChange={(name, users) => onChange(users.map((u) => u.id))}
        editable={!readonly}
        groupIds={[organization?.id]}
      />
    </>
  )
}

export default Users
