// @flow
import * as React from 'react'

import type { Port } from '@graphql/server/flow'

import { FullValueTooltip } from 'components/Tooltip'

import { TimelinePortNameWrapperStyle } from './style'

type OptionalProps = {
  vertical: boolean,
}

type Props = OptionalProps & {
  port: ?Port,
}

const defaultProps = {
  vertical: false,
}

const TimelinePortName = ({ port, vertical }: Props): React.Node => {
  const portName = port?.code && port?.name ? `${port.code} - ${port.name}` : null

  return (
    <FullValueTooltip message={portName}>
      <div className={TimelinePortNameWrapperStyle(vertical)}>{portName}</div>
    </FullValueTooltip>
  )
}

TimelinePortName.defaultProps = defaultProps

export default TimelinePortName
