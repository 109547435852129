// @flow
import FILE_ATTACHABLE_ENTITIES from '@constants/file'
import isSameDay from 'date-fns/isSameDay'

import type { CommentFragment, EventFragment } from '@graphql/server/flow'

import { ORDER_ITEMS_GET_PRICE } from 'modules/permission/constants/orderItem'
import { canViewFile } from 'utils/file'
import { uuid } from 'utils/id'
import { toLowerFirst } from 'utils/string'

import type { DateItem, NormalizedLogItem } from '../../types'

export type Item = NormalizedLogItem | DateItem

export const normalizeParameters = (
  parameters: EventFragment['logs'][number]['parameters']
): { [key: string]: any } =>
  parameters.reduce(
    (normalizedParameters, param) => ({
      ...normalizedParameters,
      [param.key]: param.value,
    }),
    {}
  )

export const toLowerCamel = (entityType: string): string =>
  toLowerFirst(entityType.replace(/[-_]([a-z])/g, (m) => m[1].toUpperCase()))

export const normalizeEntries = (
  entries: (EventFragment | CommentFragment)[]
): NormalizedLogItem[] =>
  entries.reduce(
    (
      normalizedEntries: NormalizedLogItem[],
      entry: EventFragment | CommentFragment
    ): NormalizedLogItem[] => {
      switch (entry.__typename) {
        case 'TimelineEvent':
          normalizedEntries.push(
            ...entry.logs.map<NormalizedLogItem, _>(({ parameters, ...log }) => {
              const normalizedParameters = normalizeParameters(parameters)

              return {
                ...log,
                entityType: toLowerCamel(normalizedParameters.entity_type.string),
                // $FlowFixMe
                parentEntity: entry.entity,
                parentEntityType: toLowerFirst(entry.entity.__typename),
                parameters: normalizedParameters,
                createdAt: new Date(entry.createdAt),
                createdBy: entry.createdBy,
                __typename: 'NormalizedLog',
              }
            })
          )
          break
        default:
          break
      }

      return normalizedEntries
    },
    []
  )

export const decorateEntries = (entries: NormalizedLogItem[]): Item[] =>
  entries.reduce((items, entry, index, array) => {
    if (index === 0 || (index > 0 && !isSameDay(entry.createdAt, array[index - 1].createdAt))) {
      items.push({
        __typename: 'DateSeparator',
        id: uuid(),
        date: entry.createdAt,
      })
    }

    items.push(entry)

    return items
  }, [])

export const filterEntries = (entries: Item[], hasPermissions: Function): Item[] => {
  return entries
    .filter(
      (entry) =>
        (entry.entityType === 'file' &&
          FILE_ATTACHABLE_ENTITIES.map((fae) => fae.toLowerCase()).includes(
            entry.parentEntityType
          ) &&
          typeof entry.parentEntityType === 'string' &&
          entry.__typename === 'NormalizedLog' &&
          entry.entity.__typename === 'File' &&
          canViewFile(hasPermissions, entry.entity.type, entry.parentEntityType)) ||
        (entry.parentEntityType === 'orderItem' &&
          entry.parameters?.field?.string === 'price' &&
          hasPermissions(ORDER_ITEMS_GET_PRICE)) ||
        ['timelineDate', 'timelineDateRevision', 'voyage'].includes(entry.entityType) ||
        entry.entityType === entry.parentEntityType
    )
    .reduce((result, entry, counter, arr) => {
      const lastEntry = result[result.length - 1]
      const lastEntryIsSeparator =
        entry.__typename === 'DateSeparator' && counter === arr.length - 1
      const hasTwoEntriesAreSeparator =
        lastEntry &&
        lastEntry.__typename === 'DateSeparator' &&
        entry.__typename === 'DateSeparator'
      if (hasTwoEntriesAreSeparator) {
        return [...result.slice(0, result.length - 1), entry] // Keep the final value as **only the most up-to-date DateSeparator**
      }
      if (lastEntryIsSeparator) {
        return result
      }

      return [...result, entry]
    }, ([]: Item[]))
}
