// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, fontSizes, shadows } from 'styles/common'

export const AvatarStyle = (size: number): string => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.WHITE};
  width: ${size}px;
  height: ${size}px;
  flex-shrink: 0;
  ${borderRadiuses.CIRCLE};
  ${fontSizes.MAIN};
  background-color: ${colors.GRAY_LIGHT};
  ${shadows.FAINT};
  user-select: none;
`
