// @flow
import * as React from 'react'

import {
  SectionNavBarChildrenWrapperStyle,
  SectionNavBarSecondRowWrapperStyle,
  SectionNavBarStyle,
} from './style'

type Props = {
  secondRowcontent?: React$Node,
  children: React$Node,
}

const SectionNavBar = ({ secondRowcontent, children }: Props): React.Element<'div'> => (
  <div className={SectionNavBarStyle(!!secondRowcontent)}>
    <div className={SectionNavBarChildrenWrapperStyle}>{children}</div>
    {secondRowcontent && (
      <div className={SectionNavBarSecondRowWrapperStyle}>{secondRowcontent}</div>
    )}
  </div>
)

export default SectionNavBar
