import { AutoTrackingTransportType, TransportType } from '@graphql/server/typescript'

export const AUTOTRACKING_TRANSPORT_TYPE_PROTO_ARR = [
  null,
  AutoTrackingTransportType.Sea,
  AutoTrackingTransportType.Air,
] as const

export const TRANSPORT_TYPE_PROTO_ARR = [
  null,
  TransportType.Sea,
  TransportType.Air,
  TransportType.Rail,
  TransportType.Road,
  TransportType.Dry,
] as const

export default AUTOTRACKING_TRANSPORT_TYPE_PROTO_ARR
