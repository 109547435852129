// @flow
import * as React from 'react'

import { SectionNavBar } from 'components/NavBar'

import { StickyScrollingSectionWrapperStyle, StickySectionBodyStyle, StickyStyle } from './style'

type Props = {
  sectionHeader: React$Node,
  navbarContent?: React$Node,
  secondRowcontent?: React$Node,
  children: React$Node,
}

const StickyScrollingSection = ({
  sectionHeader,
  navbarContent = null,
  secondRowcontent,
  children,
}: Props): React.Node => (
  <>
    <div className={StickyStyle}>{sectionHeader}</div>

    <div className={StickyScrollingSectionWrapperStyle}>
      <div className={StickyStyle}>
        <SectionNavBar secondRowcontent={secondRowcontent}>{navbarContent}</SectionNavBar>
      </div>

      <div className={StickySectionBodyStyle}>{children}</div>
    </div>
  </>
)

export default StickyScrollingSection
