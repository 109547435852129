// @flow strict

import { useMutation } from '@apollo/client'
import * as React from 'react'
import { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import messageGroupCreateMutation from '@graphql/client/chat/mutation.messageGroupCreate.graphql'
import type { MessageGroup, MinimalOrganizationFragment, Scalars } from '@graphql/server/flow'

import { BaseButton } from 'components/Buttons'
import SelectRelationPartners from 'components/SelectRelationPartners'
import SlideView from 'components/SlideView'
import useUser from 'hooks/useUser'
import { AddGroupButtonStyle } from 'modules/chat/style'

import { getEntityInput } from '../../helpers'
import type { ChatEntity } from '../../type'

type Props = {
  onPartnerGroupAdded: (newPartnerGroup: MessageGroup) => void,
  onDuplicatePartners?: (partnerGroup: string[]) => void,
  entityId: string,
  entityType: ChatEntity,
  relatedPartners: MinimalOrganizationFragment[],
}

const AddPartnerGroupButton = ({
  onPartnerGroupAdded,
  onDuplicatePartners,
  entityId,
  entityType,
  relatedPartners,
}: Props): React.Node => {
  const [isOpen, setOpen] = useState(false)
  const selectedRef = useRef(([]: Scalars['ID'][]))
  const { organization: ownOrg } = useUser()

  const [addmessageGroup, { loading }] = useMutation(messageGroupCreateMutation, {
    onCompleted: ({ messageGroupCreate }) => {
      // there is a duplicate
      if (messageGroupCreate.violations) {
        // eslint-disable-next-line
        onDuplicatePartners?.(selectedRef.current)
        return
      }

      onPartnerGroupAdded(messageGroupCreate)
    },
  })

  return (
    <>
      <BaseButton
        className={AddGroupButtonStyle}
        icon="ADD"
        disabled={loading}
        label={
          <FormattedMessage id="modules.Documents.addPartnerGroup" defaultMessage="ADD GROUP" />
        }
        onClick={() => setOpen(true)}
      />
      <SlideView isOpen={isOpen} onRequestClose={() => setOpen(false)}>
        <SelectRelationPartners
          alwaysAllowSave
          partners={relatedPartners}
          partnerTypes={[]}
          selected={[
            {
              __typename: 'Organization',
              id: ownOrg.id,
              name: ownOrg.name,
              types: ownOrg.types,
            },
          ]}
          unselectBlacklist={[ownOrg.id]}
          onCancel={() => setOpen(false)}
          onSelect={(selected) => {
            const organizationIds = selected.map(({ id }) => id)

            selectedRef.current = organizationIds

            addmessageGroup({
              variables: {
                input: {
                  entity: getEntityInput({ entityId, entityType }),
                  organizationIds,
                },
              },
            })

            setOpen(false)
          }}
        />
      </SlideView>
    </>
  )
}

export default AddPartnerGroupButton
