// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, transitions } from 'styles/common'

export const ChatAreaWrapperStyle = ({ isDraggedOver }: { isDraggedOver: boolean }): string =>
  css`
    height: 100%;
    max-height: 100vh;
    border: 5px dashed ${isDraggedOver ? colors.TEAL_HALF : colors.TRANSPARENT};
    ${borderRadiuses.MAIN};
    ${transitions.MAIN};
  `
