// @flow
import type { DocumentedEntity } from '@types'
import { cx } from 'emotion'
import * as React from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'
import type { LocalFile, UploadingFile } from 'types/file'

import ZenboxLabel from '@components/ZenboxLabel'
import getZenboxName from '@components/ZenboxLabel/helpers'
import type { FilePayload, FileType } from '@graphql/server/flow'
import shipmentMessages from '@modules/shipment/messages'

import { Display, FieldItem, Label } from 'components/Form'
import { computeIcon, getFileExtension, getFileName } from 'components/Form/DocumentsUpload/helpers'
import FormattedDate from 'components/FormattedDate'
import Icon from 'components/Icon'
import RelateEntity from 'components/RelateEntity'
import Tag from 'components/Tag'
import { Tooltip } from 'components/Tooltip'
import withForbiddenCard from 'hoc/withForbiddenCard'
import orderMessages from 'modules/order/messages'
import { getParentInfo } from 'utils/task'

import BaseCard from '../BaseCard'

import DocumentLightCard from './index.light'
import {
  BaseCardStyle,
  ButtonWrapperStyle,
  CreatedAtStyle,
  DocumentCardWrapperStyle,
  DocumentHighlightStyle,
  DocumentParentWrapperStyle,
  DocumentTypeStyle,
  DownloadButtonStyle,
  FileExtensionIconStyle,
  FileNameStyle,
  FileNameWrapperStyle,
  TagsWrapperStyle,
} from './style'

type Props = {|
  file: FilePayload | LocalFile | UploadingFile,
  error?: string,
  variant: 'normal' | 'light',
  actions?: React$Node[],
  hideParentInfo?: boolean,
  downloadable?: boolean,
  navigable?: boolean,
  selected?: boolean,
  highlight?: boolean,
  scrollIntoView?: boolean,
  onChange?: (field: string, value: mixed) => void,
  onClick?: Function,
  onSelect?: Function,
|}

export const getFileTypesByEntity = (
  entity: DocumentedEntity['__typename'],
  intl: IntlShape
): {| +value: FileType, +label: string |}[] => {
  switch (entity) {
    case 'Order':
      return [
        { value: 'OrderPo', label: intl.formatMessage(orderMessages.fileTypeOrderPO) },
        { value: 'OrderPi', label: intl.formatMessage(orderMessages.fileTypeOrderPI) },
        { value: 'Document', label: intl.formatMessage(orderMessages.fileTypeDocument) },
      ]

    case 'OrderItem':
      return [
        {
          value: 'Document',
          label: intl.formatMessage(orderMessages.fileTypeDocument),
        },
      ]

    case 'ProductProvider':
      return [
        {
          value: 'ProductSpec',
          label: intl.formatMessage({
            id: 'modules.ProductProviders.fileType.productSpec',
            defaultMessage: 'Product Specification',
          }),
        },
        {
          value: 'ProductAnalysisCert',
          label: intl.formatMessage({
            id: 'modules.ProductProviders.fileType.productAnalysisCert',
            defaultMessage: 'Product Analysis Certificate',
          }),
        },
        {
          value: 'ProductOriginCert',
          label: intl.formatMessage({
            id: 'modules.ProductProviders.fileType.productOriginCert',
            defaultMessage: 'Product Origin Certificate',
          }),
        },
        {
          value: 'Document',
          label: intl.formatMessage({
            id: 'modules.ProductProviders.fileType.document',
            defaultMessage: 'Miscellaneous',
          }),
        },
      ]

    case 'Shipment':
      return [
        {
          value: 'ShipmentBl',
          label: intl.formatMessage(shipmentMessages.bl),
        },
        {
          value: 'ShipmentInvoice',
          label: intl.formatMessage(shipmentMessages.invoice),
        },
        {
          value: 'ShipmentPackingList',
          label: intl.formatMessage(shipmentMessages.packingList),
        },
        {
          value: 'ShipmentImportDeclaration',
          label: intl.formatMessage(shipmentMessages.importDeclaration),
        },
        {
          value: 'ShipmentExportDeclaration',
          label: intl.formatMessage(shipmentMessages.exportDeclaration),
        },
        {
          value: 'ShipmentInspectionApplication',
          label: intl.formatMessage(shipmentMessages.inspectionApplication),
        },
        {
          value: 'ShipmentShippingAdvice',
          label: intl.formatMessage(shipmentMessages.shippingAdvice),
        },
        {
          value: 'ShipmentWarehouseArrivalReport',
          label: intl.formatMessage(shipmentMessages.warehouseArrivalReport),
        },
        {
          value: 'ShipmentWarehouseInspectionReport',
          label: intl.formatMessage(shipmentMessages.warehouseInspectionReport),
        },
        {
          value: 'ShipmentWarehouseLoadingReport',
          label: intl.formatMessage(shipmentMessages.warehouseLoadingReport),
        },
        {
          value: 'Document',
          label: intl.formatMessage(orderMessages.fileTypeDocument),
        },
      ]

    default:
      return [
        {
          value: 'Document',
          label: intl.formatMessage(orderMessages.fileTypeDocument),
        },
      ]
  }
}

let cardHeight = '184px'

const DocumentCard = ({
  file,
  hideParentInfo,
  actions,
  error,
  downloadable,
  navigable,
  onSelect,
  onChange,
  onClick,
  variant = 'normal',
  highlight,
  scrollIntoView,
  ...rest
}: Props) => {
  const intl = useIntl()
  const cardRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (scrollIntoView && cardRef.current) {
      cardRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }, [scrollIntoView])

  if (!(file.__typename === 'File' || file.__typename === 'LocalFile')) return <></> // Filter bad reqs etc

  cardHeight = hideParentInfo ? '134px' : '184px'
  const name = (file.id && file.name) ?? ''
  const fileExtension = getFileExtension(name)
  const fileName = getFileName(name)
  const fileIcon = computeIcon(fileExtension)
  const { parentIcon, parentData, link } = getParentInfo(file.entity ?? {})
  const fileTypes =
    file.entity &&
    (file.entity.__typename === 'Order' ||
      file.entity.__typename === 'OrderItem' ||
      file.entity.__typename === 'Shipment' ||
      file.entity.__typename === 'ProductProvider')
      ? getFileTypesByEntity(file.entity.__typename, intl)
      : []
  const fileTypeLabel = fileTypes.find((type) => type.value === file.type)?.label ?? ''
  const createdAt = file.createdAt ?? ''

  if (variant === 'light') {
    return (
      <DocumentLightCard
        file={file}
        error={error}
        hideParentInfo={hideParentInfo}
        actions={actions}
        downloadable={downloadable}
        navigable={navigable}
        onSelect={onSelect}
        onChange={onChange}
        onClick={onClick}
        {...rest}
      />
    )
  }

  return (
    <BaseCard
      ref={cardRef}
      actions={actions}
      error={error}
      showActionsOnHover
      icon="DOCUMENT"
      color="DOCUMENT"
      onClick={onClick}
      wrapperClassName={BaseCardStyle}
      onSelect={() => {
        if (onSelect) {
          onSelect(file)
        }
      }}
      {...rest}
    >
      <div className={cx(DocumentCardWrapperStyle, { [DocumentHighlightStyle]: highlight })}>
        <div className={FileExtensionIconStyle(fileIcon.color)}>
          <Icon {...fileIcon} />
        </div>

        <Tooltip message={`${fileName}.${fileExtension}`}>
          <div className={FileNameWrapperStyle(false)}>
            <div className={FileNameStyle()}>
              {fileName}.{fileExtension}
            </div>
          </div>
        </Tooltip>

        <Tooltip message={getZenboxName({ name: `${fileName}.${fileExtension}` })}>
          <div className={FileNameWrapperStyle(false)}>
            <div className={FileNameStyle()}>
              <ZenboxLabel name={`${fileName}.${fileExtension}`} />
            </div>
          </div>
        </Tooltip>

        {!hideParentInfo && (
          <>
            <div className={DocumentTypeStyle}>{fileTypeLabel}</div>
            <div className={DocumentParentWrapperStyle}>
              <RelateEntity link={navigable ? link : ''} entity={parentIcon} value={parentData} />
            </div>
          </>
        )}

        <FieldItem
          label={
            <Label>
              <FormattedMessage id="components.cards.uploadedAt" defaultMessage="UPLOADED" />
            </Label>
          }
          input={
            <Display className={CreatedAtStyle()}>
              <FormattedDate value={createdAt} mode="datetime" />
            </Display>
          }
        />

        <div className={TagsWrapperStyle}>
          {(file?.tags ?? []).flatMap((tag) => (tag.id ? <Tag key={tag.id} tag={tag} /> : []))}
        </div>

        <div className={ButtonWrapperStyle}>
          {downloadable ? (
            <button
              className={DownloadButtonStyle({ disabled: false })}
              onClick={(e) => {
                e.stopPropagation()
                window.open(file?.path ?? '', '_blank')
              }}
              type="button"
            >
              <Icon icon="DOWNLOAD" />
              <Label>
                <FormattedMessage id="modules.Documents.download" defaultMessage="Download" />
              </Label>
            </button>
          ) : (
            <Tooltip
              message={
                <FormattedMessage
                  id="components.documentInput.cantDownload"
                  defaultMessage="You do not have the rights to download this document"
                />
              }
            >
              <div className={DownloadButtonStyle({ disabled: true })}>
                <Icon icon="DOWNLOAD" />
                <Label>
                  <FormattedMessage id="modules.Documents.download" defaultMessage="Download" />
                </Label>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </BaseCard>
  )
}

export default (withForbiddenCard(DocumentCard, 'file', {
  width: '195px',
  height: cardHeight,
  entityIcon: 'DOCUMENT',
  entityColor: 'DOCUMENT',
}): any)
