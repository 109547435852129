// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { DateInput, DefaultStyle, Label } from 'components/Form'
import useUser from 'hooks/useUser'
import { formatDateInputToDateObjectWithTimezone } from 'utils/date'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

const DateRange = ({
  value,
  readonly,
  onChange,
}: FilterInputProps<?{ after?: Date | null, before?: Date | null }>): React.Node => {
  const { user } = useUser()

  const minDate = value?.after ? new Date(value.after) : null
  const maxDate = value?.before ? new Date(value.before) : null

  if (minDate) {
    minDate.setDate(minDate.getDate() + 2)
  }
  if (maxDate) {
    maxDate.setDate(maxDate.getDate())
  }

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.from} />
      </Label>
      <DefaultStyle>
        <DateInput
          value={value?.after}
          onChange={(e) => {
            onChange({
              ...value,
              after: e.target.value
                ? formatDateInputToDateObjectWithTimezone(e.target.value, user?.timezone ?? '')
                : null,
            })
          }}
          readOnly={readonly}
          max={maxDate?.toISOString().split('T')[0]}
        />
      </DefaultStyle>

      <Label height="30px">
        <FormattedMessage {...messages.to} />
      </Label>
      <DefaultStyle>
        <DateInput
          value={value?.before}
          onChange={(e) => {
            onChange({
              ...value,
              before: e.target.value
                ? formatDateInputToDateObjectWithTimezone(e.target.value, user?.timezone ?? '')
                : null,
            })
          }}
          readOnly={readonly}
          min={minDate?.toISOString().split('T')[0]}
        />
      </DefaultStyle>
    </>
  )
}

export default DateRange
