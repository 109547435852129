// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import incomingNotificationListQuery from '@graphql/client/notification/query.incomingNotificationList.graphql'

import BaseCard from 'components/Cards/BaseCard'
import { Display } from 'components/Form'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids from '../Common/Ids'

import { CardStyle } from './style'

const NotificationIds = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => (
  <Ids
    value={value}
    readonly={readonly}
    onChange={onChange}
    query={incomingNotificationListQuery}
    skipQuery
    getItems={(data) => data?.notifications ?? []}
    title={<FormattedMessage {...messages.notifications} />}
    renderItem={(notification) => (
      <BaseCard icon="NOTIFICATION" color="NOTIFICATION" wrapperClassName={CardStyle}>
        <Display height="30px">{notification?.id}</Display>
      </BaseCard>
    )}
  />
)

export default NotificationIds
