// @flow
import { pick, uniqBy } from 'lodash'

import type {
  BatchFormFragment,
  ContainerFormFragment,
  ContainerPayload,
  MetricValue,
  OrderCardFragment,
} from '@graphql/server/flow'

import { findVolume } from 'utils/batch'

import { type ContainerFormState, containerFormKeys } from './form/containers'

export const uniqueOrders = (batches: BatchFormFragment[]): OrderCardFragment[] =>
  uniqBy(
    batches.flatMap((batch) =>
      batch.__typename === 'Batch' &&
      batch.orderItem.__typename === 'OrderItem' &&
      batch.orderItem.order.__typename === 'Order'
        ? batch.orderItem?.order
        : []
    ),
    'id'
  )

export const calculateContainerTotalVolume = (
  container: $Shape<ContainerPayload>
): MetricValue => ({
  __typename: 'MetricValue',
  metric: 'm³',
  value: (container?.batches || []).reduce((result, batch) => result + findVolume(batch), 0),
})

export const generateFormContainer = (container: ContainerFormFragment): ContainerFormState =>
  pick(container, containerFormKeys)

export default uniqueOrders
