// @flow
import { cloneDeep, set } from 'lodash'
import { Container } from 'unstated'

import type { MessageGroupFragment } from '@graphql/server/flow'

type FormState = {|
  messageGroups: MessageGroupFragment[],
|}

const initValues: FormState = {
  messageGroups: [],
}

export default class ShipmentMessageGroupsContainer extends Container<FormState> {
  state: FormState = initValues

  originalValues: FormState = initValues

  setFieldValue: (path: string, value: mixed) => void = (path: string, value: mixed) => {
    this.setState((prevState: FormState): FormState => set(cloneDeep(prevState), path, value))
  }

  initDetailValues: (messageGroups: FormState['messageGroups']) => void = (messageGroups) => {
    const newMessageGroups = [...messageGroups]

    this.setState({
      messageGroups: newMessageGroups,
    })
    this.originalValues = { messageGroups: newMessageGroups }
  }
}
