// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'

import shipmentListQuery from '@graphql/client/shipment/query.shipmentList.graphql'
import type { ShipmentFilterInput, ShipmentSortInput } from '@graphql/server/flow'
import { isForbidden, isNotFound } from '@utils/data'

import { CancelButton } from 'components/Buttons'
import { ShipmentCard } from 'components/Cards'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  Filter,
  Search,
  ShipmentFilterConfig,
  ShipmentSortConfig,
  Sort,
} from 'components/NavBar'
import useFilterSort from 'hooks/useFilterSort'
import ShipmentGridView from 'modules/shipment/list/ShipmentGridView'
import { getByPathWithDefault } from 'utils/fp'
import loadMore from 'utils/loadMore'

type OptionalProps = {
  cacheKey: string,
  isLoading?: boolean,
}

type Props = OptionalProps & {
  onCancel: Function,
  onSelect: Function,
}

function SelectShipments({
  cacheKey,
  isLoading = false,
  onCancel,
  onSelect,
}: Props): React.Node | string {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<ShipmentFilterInput, ShipmentSortInput>(
      { query: '', archived: false },
      { updatedAt: 'DESCENDING' },
      cacheKey
    )

  const variables = {
    filterBy,
    sortBy,
    page: 1,
    perPage: 10,
  }

  const {
    loading: isQuerying,
    data,
    fetchMore,
    error,
  } = useQuery(shipmentListQuery, {
    fetchPolicy: 'network-only',
    variables,
  })

  const [selectedShipment, setSelectedShipment] = React.useState(null)

  const onSelectShipment = React.useCallback(
    (shipment: Object) => {
      setSelectedShipment(shipment)
      onSelect(shipment)
    },
    [onSelect]
  )

  const shipments = React.useMemo(() => {
    return getByPathWithDefault([], 'shipments.nodes', data).filter(
      (shipment) => !isForbidden(shipment) && !isNotFound(shipment)
    )
  }, [data])

  if (error) {
    return error.message
  }

  const nextPage = getByPathWithDefault(1, 'shipments.page', data) + 1
  const totalPage = getByPathWithDefault(1, 'shipments.totalPage', data)
  const hasMore = nextPage <= totalPage

  return (
    <SlideViewLayout>
      <SlideViewNavBar isSubNavBar>
        <EntityIcon icon="SHIPMENT" color="SHIPMENT" subIcon="CARDS" />

        <Filter
          config={ShipmentFilterConfig}
          rootQueryType="Shipment"
          filterBy={filterByWithoutQuery}
          onChange={setFilterBy}
        />
        <Search query={query} onChange={setQuery} />
        <Sort config={ShipmentSortConfig} sortBy={sortBy} onChange={setSortBy} />
        <CancelButton onClick={onCancel} disabled={isLoading} />
      </SlideViewNavBar>

      <Content hasSubNavBar>
        <ShipmentGridView
          items={shipments}
          onLoadMore={() => loadMore({ fetchMore, data }, variables, 'shipments')}
          hasMore={hasMore}
          isLoading={isQuerying}
          renderItem={(item) => {
            return (
              <ShipmentCard
                key={item.id}
                shipment={item}
                selectable
                selected={selectedShipment?.id === item.id}
                onSelect={onSelectShipment}
              />
            )
          }}
        />
      </Content>
    </SlideViewLayout>
  )
}

const defaultProps = {
  cacheKey: 'SelectShipments',
}

SelectShipments.defaultProps = defaultProps

export default SelectShipments
