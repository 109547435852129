// @flow
// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'

export const logOutMutation: any = gql`
  mutation {
    logout
  }
`

export default logOutMutation
