// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  // LOG
  create: {
    id: 'modules.timeline.create',
    defaultMessage: '{user} has created this {entityType}',
    action: 'CREATED',
  },
  createChild: {
    id: 'modules.timeline.createChild',
    defaultMessage: '{user} has created {child}',
    action: 'CREATED CHILD',
  },
  setField: {
    id: 'modules.timeline.setField',
    defaultMessage: '{user} has set {field} to {value}',
    action: 'SET',
  },
  setChildField: {
    id: 'modules.timeline.setChildField',
    defaultMessage: '{user} has set {child} {field} to {value}',
    action: 'SET CHILD',
  },
  clearField: {
    id: 'modules.timeline.clearField',
    defaultMessage: '{user} has cleared {field} (previous value: {value})',
    action: 'CLEARED FIELD',
  },
  clearChildField: {
    id: 'modules.timeline.clearChildField',
    defaultMessage: '{user} has cleared {child} {field} (previous value: {value})',
    action: 'CLEARED CHILD FIELD',
  },
  updateField: {
    id: 'modules.timeline.updateField',
    defaultMessage: '{user} has changed {field} from {oldValue} to {newValue}',
    action: 'CHANGED FIELD',
  },
  updateChildField: {
    id: 'modules.timeline.updateChildField',
    defaultMessage: '{user} has changed {child} {field} from {oldValue} to {newValue}',
    action: 'CHANGED CHILD FIELD',
  },
  archived: {
    id: 'modules.timeline.archived',
    defaultMessage: '{user} has archived this {entityType}',
    action: 'ARCHIVED',
  },
  archivedChild: {
    id: 'modules.timeline.archivedChild',
    defaultMessage: '{user} has archived {child}',
    action: 'ARCHIVED CHILD',
  },
  unarchived: {
    id: 'modules.timeline.unarchived',
    defaultMessage: '{user} has activated this {entityType}',
    action: 'ACTIVATED',
  },
  unarchivedChild: {
    id: 'modules.timeline.unarchivedChild',
    defaultMessage: '{user} has activated {child}',
    action: 'ACTIVATED CHILD',
  },
  addedTags: {
    id: 'modules.timeline.addedTags',
    defaultMessage: `{user} has added {addedCount, plural,
      one {the tag}
      other {tags}
     } {added}`,
    action: 'ADDED TAG',
  },
  addedTagsChild: {
    id: 'modules.timeline.addedTagsChild',
    defaultMessage: `{user} has added {addedCount, plural,
      one {the tag}
      other {tags}
     } {added} to {child}`,
    action: 'ADDED TAG CHILD',
  },
  removedTags: {
    id: 'modules.timeline.removedTags',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the tag}
      other {tags}
     } {removed}`,
    action: 'REMOVED TAG',
  },
  removedTagsChild: {
    id: 'modules.timeline.removedTagsChild',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the tag}
      other {tags}
     } {removed} to {child}`,
    action: 'REMOVED TAG CHILD',
  },
  addedAndRemovedTags: {
    id: 'modules.timeline.addedAndRemovedTags',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the tag}
      other {tags}
     } {removed} and added {addedCount, plural,
      one {the tag}
      other {tags}
     } {added}`,
    action: 'ADDED AND REMOVED TAG',
  },
  addedAndRemovedTagsChild: {
    id: 'modules.timeline.addedAndRemovedTagsChild',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the tag}
      other {tags}
     } {removed} and added {addedCount, plural,
      one {the tag}
      other {tags}
     } {added} to {child}`,
    action: 'ADDED AND REMOVED TAG CHILD',
  },
  addedForwarders: {
    id: 'modules.timeline.addedForwarders',
    defaultMessage: `{user} has added {addedCount, plural,
      one {the forwarder}
      other {forwarders}
     } {added}`,
    action: 'ADDED FORWARDERS',
  },
  removedForwarders: {
    id: 'modules.timeline.removedForwarders',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the forwarder}
      other {forwarders}
     } {removed}`,
    action: 'ADDED FORWARDERS',
  },
  addedAndRemovedForwarders: {
    id: 'modules.timeline.addedAndRemovedForwarders',
    defaultMessage: `{user} has removed {removedCount, plural,
      one {the forwarder}
      other {forwarders}
     } {removed} and added {addedCount, plural,
      one {the forwarder}
      other {forwarders}
     } {added}`,
    action: 'ADDED FORWARDERS',
  },
  addedInCharges: {
    id: 'modules.timeline.addedInCharges',
    defaultMessage: `{user} has assigned {added}`,
    action: 'ADDED IN CHARGE',
  },
  addedInChargesChild: {
    id: 'modules.timeline.addedInChargesChild',
    defaultMessage: `{user} has assigned {added} to {child}`,
    action: 'ADDED IN CHARGE CHILD',
  },
  removedInCharges: {
    id: 'modules.timeline.removedInCharges',
    defaultMessage: `{user} has unassigned {removed}`,
    action: 'REMOVED IN CHARGE',
  },
  removedInChargesChild: {
    id: 'modules.timeline.removedInChargesChild',
    defaultMessage: `{user} has unassigned {removed} to {child}`,
    action: 'REMOVED IN CHARGE CHILD',
  },
  addedAndRemovedInCharges: {
    id: 'modules.timeline.addedAndRemovedInCharges',
    defaultMessage: `{user} has unassigned {removed} and assigned {added}`,
    action: 'ADDED AND REMOVED IN CHARGE',
  },
  addedAndRemovedInChargesChild: {
    id: 'modules.timeline.addedAndRemovedInChargesChild',
    defaultMessage: `{user} has unassigned {removed} and assigned {added} to {child}`,
    action: 'ADDED AND REMOVED IN CHARGE CHILD',
  },
  reviseDateChild: {
    id: 'modules.timeline.reviseDateChild',
    defaultMessage: `{user} has revised date of {child} to {date} because of {reviseType}`,
    action: 'REVISED DATE',
  },
  unReviseDateChild: {
    id: 'modules.timeline.unReviseDateChild',
    defaultMessage: `{user} has un-revised a {reviseType} date of {child}`,
    action: 'UNREVISE DATE CHILD',
  },
  addedDocument: {
    id: 'modules.timeline.addedDocument',
    defaultMessage: `{user} has added {document} to {documentType}.`,
    action: 'ADDED DOCUMENT',
  },
  removedDocument: {
    id: 'modules.timeline.removedDocument',
    defaultMessage: `{user} has removed {document} from {documentType}.`,
    action: 'REMOVED DOCUMENT',
  },
  addedDocumentChild: {
    id: 'modules.timeline.addedDocumentChild',
    defaultMessage: `{user} has added {document} to {documentType} of {child}.`,
    action: 'ADDED DOCUMENT CHILD',
  },
  removedDocumentChild: {
    id: 'modules.timeline.removedDocumentChild',
    defaultMessage: `{user} has removed {document} from {documentType} of {child}.`,
    action: 'REMOVED DOCUMENT CHILD',
  },
  // OTHER
  message: {
    id: 'modules.timeline.message',
    defaultMessage: 'MESSAGE',
  },
  commentEdited: {
    id: 'modules.timeline.commentEdited',
    defaultMessage: '{date} {time} edited',
  },
  order: {
    id: 'modules.timeline.order',
    defaultMessage: 'order',
  },
  orderItem: {
    id: 'modules.timeline.orderItem',
    defaultMessage: 'item',
  },
  batch: {
    id: 'modules.timeline.batch',
    defaultMessage: 'batch',
  },
  shipment: {
    id: 'modules.timeline.shipment',
    defaultMessage: 'shipment',
  },
  product: {
    id: 'modules.timeline.product',
    defaultMessage: 'product',
  },
  productProvider: {
    id: 'modules.timeline.productProvider',
    defaultMessage: 'end product',
  },
  container: {
    id: 'modules.timeline.container',
    defaultMessage: 'container',
  },
  file: {
    id: 'modules.timeline.file',
    defaultMessage: 'file',
  },
  sendMessage: {
    id: 'modules.timeline.sendMessage',
    defaultMessage: 'sends a message',
  },
  newLine: {
    id: 'modules.timeline.newLine',
    defaultMessage: 'adds a new line',
  },
}): any)
