// @flow strict
import type {
  MessageFragment,
  MessageNewSubscription,
  UserAvatarFragment,
} from '@graphql/server/flow'

import type { FileProgressMessage, FileUnsuccessfulMessage } from '../type'

type ChatModes = 'NORMAL_MODE' | 'EDIT_MODE' | 'DELETE_MODE'

export type LocalMessage =
  | MessageFragment
  | $ElementType<MessageNewSubscription, 'messageNew'>
  | FileProgressMessage
  | FileUnsuccessfulMessage
export type Messages = $ReadOnlyLocalMessage[]

export type ChatReducerState = {
  mode: ChatModes,
  messages: Messages,
  selectedMessageId: string,
  selectedGroupId: string,
  messageContent: string,
  draftMessage: ?string,
}

export const initialReducerState: ChatReducerState = {
  mode: 'NORMAL_MODE',
  messages: [],
  selectedMessageId: '',
  selectedGroupId: '',
  messageContent: '',
  draftMessage: null,
}

type ChatActionTypes =
  | 'RESET_MODE'
  | 'SET_EDIT_MODE'
  | 'SET_DELETE_MODE'
  | 'SET_MESSAGES'
  | 'DELETE_MESSAGE'
  | 'UPDATE_MESSAGE'
  | 'SET_MESSAGE_CONTENT'
  | 'SET_DRAFT_MESSAGE'

type ChatPayload = {|
  messageGroupId?: string,
  messageId?: string,
  content?: string,
  user?: UserAvatarFragment,
  messages?: Messages,
  setMessages?: (Messages) => Messages,
  message?: LocalMessage,
  messageContent?: string,
  draftMessage?: ?string,
|}

export const chatReducer = (
  state: ChatReducerState,
  action: { type: ChatActionTypes, payload?: ChatPayload }
): ChatReducerState => {
  let newState = {
    ...state,
  }
  switch (action.type) {
    case 'RESET_MODE':
      newState = {
        ...state,
        mode: 'NORMAL_MODE',
      }
      break
    case 'SET_EDIT_MODE':
      newState = {
        ...state,
        mode: 'EDIT_MODE',
        selectedGroupId: action.payload?.messageGroupId ?? '',
        selectedMessageId: action.payload?.messageId ?? '',
        messageContent: action.payload?.content ?? '',
      }
      break
    case 'SET_DELETE_MODE':
      newState = {
        ...state,
        mode: 'DELETE_MODE',
        selectedGroupId: action.payload?.messageGroupId ?? '',
        selectedMessageId: action.payload?.messageId ?? '',
      }
      break
    case 'DELETE_MESSAGE':
      newState = {
        ...state,
        mode: 'NORMAL_MODE',
        selectedGroupId: '',
        selectedMessageId: '',
        messages: state.messages.map((message) => {
          if (message.__typename === 'Message' && message.id === action.payload?.messageId) {
            return {
              ...message,
              deletedAt: new Date().toISOString(),
              deletedBy: action.payload?.user,
            }
          }

          return message
        }),
      }
      break
    case 'UPDATE_MESSAGE':
      newState = {
        ...state,
        mode: 'NORMAL_MODE',
        selectedGroupId: '',
        selectedMessageId: '',
        messages: state.messages.map((message) => {
          if (message.id === action.payload?.messageId && action.payload?.message) {
            return action.payload.message
          }

          return message
        }),
      }
      break
    case 'SET_MESSAGES':
      newState = {
        ...state,
        messages:
          (action.payload?.setMessages && action.payload.setMessages(state.messages)) ??
          action.payload?.messages ??
          [],
      }
      break
    case 'SET_MESSAGE_CONTENT':
      newState = {
        ...state,
        messageContent: action.payload?.content ?? '',
      }
      break
    case 'SET_DRAFT_MESSAGE':
      newState = {
        ...state,
        draftMessage: action.payload?.draftMessage ?? '',
      }
      break
    default:
      throw new Error(`Action ${action.type} does not exist!`)
  }

  return newState
}
