import Icon from 'components/Icon'
import NavigateLink from 'components/NavigateLink'
import Link from 'next/link'
import * as React from 'react'

import { IconEnumTypes } from '@components/Icon/enums'
import { isNext } from '@utils/env'

import { BetaStyle, IconStyle, MenuItemInternalStyle, MenuItemExternalStyle } from './styles'

interface Props {
  path?: string
  href?: string
  internal?: boolean
  children?: React.ReactNode
  isActive?: boolean
  setActive?: (value: boolean) => void
  isBeta?: boolean
  icon: IconEnumTypes
  label: string
}

const MaybeWrapNextLink = ({ isLink, to, href, children }) => {
  if (isLink) {
    return (
      <NavigateLink to={to} href={href}>
        {children}
      </NavigateLink>
    )
  } else return children
}

const MaybeWrapReachLink = ({ isLink, href, children }) => {
  if (isLink) {
    return <Link href={href}>{children}</Link>
  } else return children
}

const MenuItem = ({
  path,
  href,
  internal = true,
  children,
  isActive = false,
  setActive,
  isBeta = false,
  icon,
  label,
}: Props) => {
  const menuItemType: 'Link' | 'Component' = path ? 'Link' : 'Component'

  const menuIcon = (
    <>
      <span />
      <IconStyle>
        <Icon icon={icon} />
        {children}
      </IconStyle>
      {label}
      {isBeta && <BetaStyle>BETA</BetaStyle>}
    </>
  )

  if (menuItemType === 'Component') {
    return (
      <button
        type="button"
        onClick={() => {
          if (setActive) {
            setActive(true)
          }
        }}
      >
        <MenuItemExternalStyle>{menuIcon}</MenuItemExternalStyle>
      </button>
    )
  }

  if (isNext) {
    if (internal && path) {
      return (
        <MaybeWrapReachLink isLink={!isActive} href={path}>
          <MenuItemInternalStyle isActive={isActive}>{menuIcon}</MenuItemInternalStyle>
        </MaybeWrapReachLink>
      )
    }
    const parsedHref =
      typeof window !== 'undefined'
        ? `${window.location.origin}${path}`
        : `https://staging.zenport.io${path}`

    const menuItemProps = isActive ? { isActive: true } : { href: parsedHref }

    return <MenuItemExternalStyle {...menuItemProps}>{menuIcon}</MenuItemExternalStyle>
  }

  return (
    <MaybeWrapNextLink isLink={!isActive} to={path} href={href}>
      {internal ? (
        <MenuItemInternalStyle isActive={isActive}>{menuIcon}</MenuItemInternalStyle>
      ) : (
        <MenuItemExternalStyle isActive={isActive}>{menuIcon}</MenuItemExternalStyle>
      )}
    </MaybeWrapNextLink>
  )
}

export default MenuItem
