// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  selectMultiple: {
    id: 'components.Documents.selectMultiple',
    defaultMessage: 'SELECT MULTIPLE',
  },
  newDocument: {
    id: 'components.Documents.newDocument',
    defaultMessage: 'UPLOAD DOCUMENT',
  },
  createdAt: {
    id: 'modules.Documents.createdAt',
    defaultMessage: 'Date Created',
  },
  updatedAt: {
    id: 'modules.Documents.updatedAt',
    defaultMessage: 'Last Modified',
  },
  name: {
    id: 'modules.Documents.name',
    defaultMessage: 'Name',
  },
  type: {
    id: 'modules.Documents.type',
    defaultMessage: 'Type',
  },
  order: {
    id: 'modules.Documents.order',
    defaultMessage: 'Order',
  },
  product: {
    id: 'modules.Documents.product',
    defaultMessage: 'Product',
  },
  productProvider: {
    id: 'modules.Documents.productProvider',
    defaultMessage: 'Product Provider',
  },
  shipment: {
    id: 'modules.Documents.shipment',
    defaultMessage: 'Shipment',
  },
  size: {
    id: 'modules.Documents.size',
    defaultMessage: 'Size',
  },
  status: {
    id: 'modules.Documents.status',
    defaultMessage: 'Status',
  },
  hasEntity: {
    id: 'modules.Documents.hasEntity',
    defaultMessage: 'Has Parent',
  },
  tags: {
    id: 'modules.Documents.tags',
    defaultMessage: 'Tags',
  },
  tagsWithout: {
    id: 'modules.Documents.tagsWithout',
    defaultMessage: 'Tags without',
  },
  alphabetical: {
    id: 'modules.Documents.alphabetical',
    defaultMessage: 'Alphabetical',
  },
  documentQuantity: {
    id: 'modules.Documents.documentQuantity',
    defaultMessage: 'Document Quantity',
  },
  uploader: {
    id: 'modules.Documents.uploader',
    defaultMessage: 'Uploader',
  },
  pending: {
    id: 'modules.Documents.pending',
    defaultMessage: 'Pending',
  },
  all: {
    id: 'modules.Documents.all',
    defaultMessage: 'All',
  },
  orderItem: {
    id: 'modules.Documents.orderItem',
    defaultMessage: 'Order Item',
  },
  noDocumentFound: {
    id: 'modules.Documents.noDocumentFound',
    defaultMessage: 'No files found',
  },
  noFolderFound: {
    id: 'modules.Documents.noFolderFound',
    defaultMessage: 'No folder found',
  },
  folderInfo: {
    id: 'modules.Documents.folderInfo',
    defaultMessage: 'Showing {fileCount} files and docs from "{folderName}"',
  },
}): any)
