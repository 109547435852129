// @flow
import { divide, times } from 'number-precision'

import { type Currency, CurrencyValues } from '@graphql/server/flow'

export const distanceMetrics = ['mm', 'cm', 'm']
export const areaMetrics = ['cm²', 'm²']
export const volumeMetrics = ['cm³', 'm³']
export const weightMetrics = ['g', 'kg', 'ton']
export const durationMetrics = ['days', 'weeks', 'months']

export const defaultDistanceMetric: 'cm' = distanceMetrics[1] // cm
export const defaultAreaMetric: 'm²' = areaMetrics[1] // m²
export const defaultVolumeMetric: 'm³' = volumeMetrics[1] // m³
export const defaultWeightMetric: 'kg' = weightMetrics[1] // kg
export const defaultDurationMetric: 'days' = durationMetrics[0] // days
export const defaultCurrencyMetric: Currency['USD'] = CurrencyValues['USD']

export const convertDistance = (value: number, metric: string, newMetric: string): any | number => {
  if (metric === 'm' && newMetric === 'cm') return times(value, 100)
  if (metric === 'm' && newMetric === 'mm') return times(value, 1000)
  if (metric === 'cm' && newMetric === 'm') return divide(value, 100)
  if (metric === 'cm' && newMetric === 'mm') return times(value, 10)
  if (metric === 'mm' && newMetric === 'cm') return divide(value, 10)
  if (metric === 'mm' && newMetric === 'm') return divide(value, 1000)
  return value
}

export const convertArea = (value: number, metric: string, newMetric: string): any | number => {
  if (metric === 'm²' && newMetric === 'cm²') return times(value, 10000)
  if (metric === 'cm²' && newMetric === 'm²') return divide(value, 10000)
  return value
}

export const convertVolume = (value: number, metric: string, newMetric: string): any | number => {
  if (metric === 'm³' && newMetric === 'cm³') return times(value, 1000000)
  if (metric === 'cm³' && newMetric === 'm³') return divide(value, 1000000)
  return value
}

export const convertWeight = (value: number, metric: string, newMetric: string): any | number => {
  if ((metric === 'g' && newMetric === 'kg') || (metric === 'kg' && newMetric === 'ton'))
    return divide(value, 1000)
  if ((metric === 'kg' && newMetric === 'g') || (metric === 'ton' && newMetric === 'kg'))
    return times(value, 1000)
  if (metric === 'g' && newMetric === 'ton') return divide(value, 1000000)
  if (metric === 'ton' && newMetric === 'g') return times(value, 1000000)
  return value
}
