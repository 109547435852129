// @flow

import type { Batch, BatchPayload, OrderItem, OrderItemPayload } from '@graphql/server/flow'
import { isForbidden, isNotFound } from '@utils/data'

import { getBatchLatestQuantity } from './batch'
import { uuid } from './id'

export const getItemQuantityChartData = ({
  orderItem,
  batches,
}: {
  orderItem: OrderItemPayload,
  batches: BatchPayload[],
}): {|
  batched: number,
  batchedQuantity: number,
  orderedQuantity: number,
  shipped: number,
  shippedQuantity: number,
|} => {
  const orderedQuantity = orderItem?.quantity ?? 0
  const totalBatched = orderItem?.totalBatched ?? 0
  const totalShipped = orderItem?.totalShipped ?? 0
  const batchCount = orderItem?.batchCount ?? 0
  const batchShippedCount = orderItem?.batchShippedCount ?? 0
  let batchedQuantity = 0
  let shippedQuantity = 0
  let batched = 0
  let shipped = 0

  if (batches && batches.length > 0) {
    batches.forEach((batch) => {
      const shipment = batch?.shipment
      const latestQuantity = getBatchLatestQuantity(batch)
      batchedQuantity += latestQuantity
      batched += 1
      if (shipment) {
        shippedQuantity += latestQuantity
        shipped += 1
      }
    })
  } else {
    batchedQuantity += totalBatched
    shippedQuantity += totalShipped
    batched += batchCount
    shipped += batchShippedCount
  }

  return {
    orderedQuantity,
    batchedQuantity,
    shippedQuantity,
    batched,
    shipped,
  }
}

// TODO: clean up this way
export const spreadOrderItem = (item: Object): Object => {
  const forbidden = isForbidden(item)
  const notFound = isNotFound(item)

  if (!item || forbidden || notFound) {
    const nullRelated = {
      productProvider: null,
      product: null,
      order: null,
    }

    if (forbidden || notFound) {
      return {
        orderItem: item,
        ...nullRelated,
      }
    }

    return {
      orderItem: null,
      ...nullRelated,
    }
  }

  const {
    id,
    archived,
    no,
    quantity,
    price,
    tags,
    todo,
    totalBatched,
    totalShipped,
    batchCount,
    batchShippedCount,
    productProvider,
    order,
    timeline,
    notificationUnseenCount,
    __typename: __typenameOrderItem,
  } = item
  const compiledOrderItem = {
    id,
    archived,
    no,
    quantity,
    price,
    tags,
    todo,
    totalBatched,
    totalShipped,
    batchCount,
    batchShippedCount,
    timeline,
    notificationUnseenCount,
    __typename: __typenameOrderItem,
  }

  const {
    name: productProviderName,
    product,
    __typename: __typenameProductProvider,
  } = productProvider || {}
  const compiledProductProvider = {
    name: productProviderName,
    __typename: __typenameProductProvider,
  }

  const {
    id: productId,
    name,
    serial,
    tags: productTags,
    files,
    __typename: __typenameProduct,
  } = product || {}
  const compiledProduct = {
    id: productId,
    name,
    serial,
    tags: productTags,
    files,
    __typename: __typenameProduct,
  }

  const { id: orderId, poNo, importer, exporter, __typename: __typenameOrder } = order || {}
  const compiledOrder = {
    id: orderId,
    poNo,
    importer,
    exporter,
    __typename: __typenameOrder,
  }

  return {
    orderItem: compiledOrderItem,
    productProvider: compiledProductProvider,
    product: compiledProduct,
    order: compiledOrder,
  }
}

// TODO: Tech debt. These methods were designed to take Orders or Batches, but they are not typed as such.
// Temporary handling is written here..
export const generateItemForMovedBatch = (
  orderItem:
    | ?OrderItemPayload
    | ?OrderItem
    | $Diff<
        OrderItem,
        {
          id: *,
        }
      >,
  batch: ?BatchPayload | ?Batch
): OrderItemPayload => {
  const item = {
    ...orderItem,
    customFields: {
      mask: null,
      fieldValues: [],
    },
    tags: [],
    files: [],
    memo: '',
    no: `[auto] ${orderItem?.no ?? ''}`,
    quantity: getBatchLatestQuantity(batch),
    isNew: true,
    id: uuid(),
    price: {
      amount:
        orderItem?.__typename === 'OrderItem' &&
        orderItem.price.__typename === 'NewPrice' &&
        orderItem.price.currency === 'USD'
          ? orderItem.price.amount ?? 0
          : 0,
      currency:
        (orderItem?.__typename === 'OrderItem' &&
          orderItem.price.__typename === 'NewPrice' &&
          orderItem.price.currency) ??
        'USD',
    },
  }
  return {
    ...item,
    batches: [{ ...batch, orderItem: item }],
  }
}
