import styled, { css } from 'styled-components'

import { borderRadiuses, colors, fontSizes, transitions } from '@styles/common'

interface MenuItemProps {
  isActive?: boolean
}

export const MenuItemCss = css<MenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${colors.WHITE};
  width: 100%;
  height: 50px;
  font-size: ${fontSizes.SMALL};
  letter-spacing: 2px;
  cursor: pointer;
  & > span {
    min-width: 5px;
    height: ${({ isActive }) => (isActive ? '100%' : '0px')};
    background-color: ${colors.WHITE};
    transition: ${transitions.MAIN};
  }
  &:hover {
    & > span {
      height: 100%;
    }
  }
  &:focus {
    outline: none;
  }
`

export const MenuItemInternalStyle = styled.div<MenuItemProps>`
  ${MenuItemCss}
`

export const MenuItemExternalStyle = styled.a<MenuItemProps>`
  ${MenuItemCss}
  color: ${colors.WHITE};
  &:hover {
    color: ${colors.WHITE};
  }
`

export const IconStyle = styled.div`
  display: flex;
  width: 40px;
  height: 50px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 5px 0 0;
  transition: ${transitions.MAIN};
  & > .fa-angle-right {
    margin: 0 10px 0 auto;
    transition: ${transitions.MAIN};
    &.active {
      transform: rotate(90deg);
    }
  }
`

export const BetaStyle = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${colors.WHITE};
  font-size: ${fontSizes.SMALL};
  border-radius: ${borderRadiuses.BUTTON};
  padding: 0 2px 0 4px;
  letter-spacing: 2px;
  opacity: 0.75;
`
