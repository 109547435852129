import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { navigate } from '@reach/router'
import * as Sentry from '@sentry/browser'
import apolloLogger from 'apollo-link-logger'
import { createUploadLink } from 'apollo-upload-client'
import { getOperationAST } from 'graphql/utilities/getOperationAST'
import Router from 'next/router'
import { toast } from 'react-toastify'

import introspectionQueryResultData from '@graphql/server/fragmentTypes.json'

import { isServerError, isServerParseError } from '@utils/data'
import emitter from '@utils/emitter'
import { isAppInProduction, isDevEnvironment, isNext } from '@utils/env'
import logger from '@utils/logger'

import { SubscriptionSSE } from './SubscriptionSSE'

const SSELink = new ApolloLink((operation: Operation, forward?: NextLink) => {
  const operationAST = getOperationAST(operation.query, operation.operationName)

  if (forward && (!operationAST || operationAST.operation !== 'subscription')) {
    return forward(operation)
  }

  return new Observable((observer) => {
    const subscription = new SubscriptionSSE()

    subscription.subscribe(operation, (data) => observer.next(data))

    return () => subscription.unsubscribe()
  })
})

const snipperLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const isMutate = (operation.operationName || '').includes('Update')
  if (isMutate) {
    emitter.emit('MUTATION')
  }
  return forward(operation).map((result) => {
    if (isMutate) {
      emitter.emit('MUTATION', result)
    }

    return result
  })
})

const errorLogger = (errors) => {
  errors.forEach((error) => {
    const { message } = error
    if (!isDevEnvironment) {
      // ignore error from authentication
      if (
        !(
          message.includes('Unauthorized') ||
          message.includes('Network error with auth') ||
          message.includes('401')
        )
      ) {
        Sentry.withScope((scope) => {
          scope.setExtra('full-error-message', error)
          Sentry.captureException(new Error(message))
        })
      }
    } else {
      // this is toast message only shows on develop environment
      toast.error(error.message)
    }
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    errorLogger(graphQLErrors)
  }

  if (networkError) {
    logger.error(`[Network error]: ${networkError}`)

    if (
      (isServerError(networkError) || isServerParseError(networkError)) &&
      networkError.statusCode === 401
    ) {
      if (isNext) {
        if (typeof window !== 'undefined') {
          if (isAppInProduction) {
            window.location.href = `${window.location.origin}/login`
          } else {
            Router.push('/login?l=1')
          }
        } else {
          Router.push('/login?l=1')
        }
      } else {
        navigate('/login')
      }
    }
  }
})

const parseHeaders = (rawHeaders: string): Headers => {
  const headers = new Headers()
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':')
    const key = parts.shift()?.trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

const customFetch = (url: string, options: any) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    if (options.signal && options.signal.aborted) {
      const err = new Error('Aborted')
      err.name = 'AbortError'
      reject(err)
      return
    }

    xhr.onload = () =>
      resolve(
        new Response(xhr.response, {
          url: 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL'),
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
        } as ResponseInit & { url: string })
      )
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.onabort = () => {
      const err = new Error('Aborted')
      err.name = 'AbortError'
      reject(err)
    }
    xhr.open(options.method || 'get', url, true)
    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key])
    })
    if (options.credentials === 'include') {
      xhr.withCredentials = true
    } else if (options.credentials === 'omit') {
      xhr.withCredentials = false
    }

    if (xhr.upload && options.onProgress) {
      xhr.upload.onprogress = options.onProgress
    }

    if (options.signal) {
      const abortXhr = () => xhr.abort()
      options.signal.addEventListener('abort', abortXhr)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          options.signal.removeEventListener('abort', abortXhr)
        }
      }
    }

    xhr.send(options.body)
  })
}

const httpWithUploadLink = createUploadLink({
  uri: `${process.env.ZENPORT_SERVER_URL || ''}/graphql`,
  credentials: 'include',
  fetch: customFetch,
})

const genericFieldPolicy = {
  keyArgs: ['filterBy', 'sortBy', 'perPage'], // consider it a new list only when these change
  merge(existing, incoming, { args }) {
    if (
      /** merging invalid */
      !args ||
      typeof args.page !== 'number' ||
      typeof args.perPage !== 'number' ||
      /** is new list */
      !existing ||
      existing?.totalPage !== incoming?.totalPage ||
      /** hack for @see {@link https://github.com/apollographql/apollo-client/issues/11630} */
      incoming.page === 1
    ) {
      return incoming
    }

    const { page, perPage } = args
    const merged = {
      ...existing,
      ...incoming,
      ...(existing?.hits && { hits: existing.hits.slice(0) }),
      ...(existing?.nodes && { nodes: existing.nodes.slice(0) }),
      page: Math.max(existing.page, incoming.page),
    }
    if (merged.hits) {
      for (let i = 0; i < incoming.hits?.length; ++i) {
        merged.hits[(page - 1) * perPage + i] = incoming.hits[i]
      }
    }
    if (merged.nodes) {
      for (let i = 0; i < incoming.nodes?.length; ++i) {
        merged.nodes[(page - 1) * perPage + i] = incoming.nodes[i]
      }
    }
    return merged
  },
}

const cache = new InMemoryCache({
  typePolicies: {
    Organization: {
      fields: {
        relationPartners: genericFieldPolicy,
      },
    },
    Timeline: {
      fields: {
        entries: genericFieldPolicy,
      },
    },
    Query: {
      fields: {
        batches: genericFieldPolicy,
        containers: genericFieldPolicy,
        customFields: genericFieldPolicy,
        files: genericFieldPolicy,
        folders: genericFieldPolicy,
        masks: genericFieldPolicy,
        maskEdits: genericFieldPolicy,
        orders: genericFieldPolicy,
        products: genericFieldPolicy,
        productProviders: genericFieldPolicy,
        messages: genericFieldPolicy,
        notifications: genericFieldPolicy,
        orderItems: genericFieldPolicy,
        reminders: genericFieldPolicy,
        shipments: genericFieldPolicy,
        tags: genericFieldPolicy,
        users: genericFieldPolicy,
        warehouses: genericFieldPolicy,
      },
    },
  },
  possibleTypes: introspectionQueryResultData['__schema'].types.reduce(
    (possibleTypes, supertype) => {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name)
      return possibleTypes
    },
    {}
  ),
})

const client = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  assumeImmutableResults: true,
  link: ApolloLink.from(
    isDevEnvironment
      ? [apolloLogger, snipperLink, errorLink, SSELink, httpWithUploadLink]
      : [snipperLink, errorLink, SSELink, httpWithUploadLink]
  ),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
