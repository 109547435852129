// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  followers: {
    id: 'modules.OrderItems.followers',
    defaultMessage: 'Followers',
  },
  save: {
    id: 'modules.OrderItems.save',
    defaultMessage: 'SAVE',
  },
  cancel: {
    id: 'modules.OrderItems.cancel',
    defaultMessage: 'CANCEL',
  },
  active: {
    id: 'modules.OrderItems.active',
    defaultMessage: 'Active',
  },
  status: {
    id: 'modules.OrderItems.status',
    defaultMessage: 'STATUS',
  },
  newOrderItem: {
    id: 'modules.OrderItems.newOrderItem',
    defaultMessage: 'NEW',
  },
  noOrderItems: {
    id: 'modules.OrderItems.noOrderItems',
    defaultMessage: 'No order items found.',
  },
  updatedAt: {
    id: 'modules.OrderItems.list.sort.updatedAt',
    defaultMessage: 'Last Modified',
  },
  createdAt: {
    id: 'modules.OrderItems.list.sort.createdAt',
    defaultMessage: 'Date Created',
  },
  no: {
    id: 'modules.OrderItems.list.sort.no',
    defaultMessage: 'Item No.',
  },
  unitPrice: {
    id: 'modules.orderItems.unitPrice',
    defaultMessage: 'Unit Price',
  },
  tags: {
    id: 'modules.OrderItems.tags',
    defaultMessage: 'TAGS',
  },
  tagsWithout: {
    id: 'components.OrderItems.tagsWithout',
    defaultMessage: 'TAGS WITHOUT',
  },
  currency: {
    id: 'modules.OrderItems.list.sort.currency',
    defaultMessage: 'Currency',
  },
  productName: {
    id: 'modules.OrderItems.list.sort.productName',
    defaultMessage: 'Product Name',
  },
  productSerial: {
    id: 'modules.OrderItems.list.sort.productSerial',
    defaultMessage: 'Product Serial',
  },
  productProviderName: {
    id: 'modules.OrderItems.list.sort.productProviderName',
    defaultMessage: 'End Product Name',
  },
  supplierName: {
    id: 'modules.OrderItems.list.sort.supplierName',
    defaultMessage: 'Supplier',
  },
  supplierCode: {
    id: 'modules.OrderItems.list.sort.supplierCode',
    defaultMessage: 'Supplier Code',
  },
  fileTypeDocument: {
    id: 'modules.OrderItems.fileType.document',
    defaultMessage: 'Miscellaneous',
  },
  deliveryDate: {
    id: 'modules.OrderItems.deliveryDate',
    defaultMessage: 'Delivery Date',
  },
  quantity: {
    id: 'modules.OrderItems.quantity',
    defaultMessage: 'Quantity',
  },
  memo: {
    id: 'modules.OrderItems.memo',
    defaultMessage: 'Memo',
  },
  remainQuantity: {
    id: 'modules.OrderItems.remainQuantity',
    defaultMessage: 'Remain quantity',
  },
  totalBatched: {
    id: 'modules.OrderItems.totalBatched',
    defaultMessage: 'Total batched quantity',
  },
  remainingBatchedQuantity: {
    id: 'modules.OrderItems.remainingBatchedQuantity',
    defaultMessage: 'Remaining batch quantity',
  },
  totalShipped: {
    id: 'modules.OrderItems.totalShipped',
    defaultMessage: 'Total shipped quantity',
  },
  remainingShippedQuantity: {
    id: 'modules.OrderItems.remainingShippedQuantity',
    defaultMessage: 'Remaining shipped quantity',
  },
  totalPrice: {
    id: 'modules.OrderItems.totalPrice',
    defaultMessage: 'Total price',
  },
  sectionDocuments: {
    id: 'modules.OrderItems.sectionDocuments',
    defaultMessage: 'Documents',
  },
  logs: {
    id: 'modules.OrderItems.logs',
    defaultMessage: 'Logs',
  },
  mask: {
    id: 'modules.OrderItems.mask',
    defaultMessage: 'Custom fields template',
  },
}): any)
