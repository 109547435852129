// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  name: {
    id: 'components.FormattedName.name',
    defaultMessage: '{firstName} {lastName}',
  },
  oneName: {
    id: 'components.FormattedName.oneName',
    defaultMessage: '{firstName}',
  },
}): any)
