// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { PortInput as PortInputType } from '@graphql/server/flow'

import { BaseButton } from 'components/Buttons'
import { Label } from 'components/Form'
import Icon from 'components/Icon'
import PortSelect from 'components/Inputs/PortSelectInput'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

import { PortsWrapperStyle, PortWrapperStyle, RemoveButtonStyle } from './style'

const Ports = ({ value, readonly, onChange }: FilterInputProps<?(PortInputType[])>): React.Node => {
  const hasWeakPort = !value.every(
    (v) => !!v?.seaport || !!v?.airport || !!v?.rail || !!v?.road || !!v?.dryport
  )

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.ports} />
      </Label>

      <div className={PortsWrapperStyle}>
        {value.map((port, index) => {
          return (
            <div
              // Need to use key as there is no unique identifier amongst mulitple ports (you can select the same port several times)
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={PortWrapperStyle}
            >
              <PortSelect
                value={port}
                onChange={(newPort) => {
                  onChange(
                    value.map((v, i) =>
                      i === index
                        ? (({ seaport, airport, rail, road, dryport }) => ({
                            seaport,
                            airport,
                            rail,
                            road,
                            dryport,
                          }))(newPort || null)
                        : v
                    )
                  )
                }}
                readonly={readonly}
                eagerSearch
              />
              <button
                type="button"
                className={RemoveButtonStyle}
                onClick={() => onChange(value.filter((v, i) => i !== index))}
              >
                <Icon icon="REMOVE" />
              </button>
            </div>
          )
        })}

        <BaseButton
          icon="ADD"
          label={<FormattedMessage {...messages.addPort} />}
          backgroundColor="TEAL"
          hoverBackgroundColor="TEAL_DARK"
          disabled={hasWeakPort}
          onClick={() => onChange([...value, null])}
        />
      </div>
    </>
  )
}

export default Ports
