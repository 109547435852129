// @flow
import * as React from 'react'

import { ArchiveButton, CancelButton } from 'components/Buttons'
import Dialog from 'components/Dialog'
import type { ConfirmDialogProps } from 'components/Dialog/type'

import { ButtonsStyle, ConfirmMessageStyle, ModalStyle } from '../ActivateDialog/style'

function ArchiveDialog({
  isOpen,
  onRequestClose,
  onCancel,
  onConfirm,
  message,
}: ConfirmDialogProps): React.Node {
  return (
    <Dialog isOpen={isOpen} onRequestClose={onRequestClose} width="400px">
      <div className={ModalStyle}>
        <div className={ConfirmMessageStyle}>{message}</div>

        <div className={ButtonsStyle}>
          <CancelButton onClick={onCancel} />
          <ArchiveButton onClick={onConfirm} />
        </div>
      </div>
    </Dialog>
  )
}

export default ArchiveDialog
