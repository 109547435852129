import styled from 'styled-components'

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  .icon {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 5px;
  }
`

export { IconWrapper }
