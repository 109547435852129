// @flow
import { css } from 'react-emotion'

import { layout } from 'styles/common'

export const SelectionWrapperStyle: any = css`
  ${layout.GRID_VERTICAL};
  grid-template-columns: 200px;
  grid-gap: 10px;
`
