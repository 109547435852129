// @flow
import type { ApolloError } from '@apollo/client'
import { Container } from 'unstated'
import type { ObjectSchema } from 'yup/lib/object'

import type { Violation } from '@graphql/server/flow'

import emitter from 'utils/emitter'
import { yupToFormErrors } from 'utils/errors'
import { isEquals, setIn } from 'utils/fp'

type FormState = {|
  serverErrors: Object,
  errors: Object,
  touched: Object,
  activeField: ?string, // optional for this.rerender() method below
|}

const EmptyValidation = {
  validate: (value: mixed, options: Object): Promise<any> => Promise.resolve({ value, options }),
  // eslint-disable-next-line no-unused-vars
  isValidSync: (value: any): true => true,
}

const initState = {
  errors: {},
  serverErrors: {},
  touched: {},
  activeField: '',
}

export default class FormContainer extends Container<FormState> {
  state: FormState = initState

  // Can be used to rerender the form if there is no activeField selected
  // https://zenport.slack.com/archives/C021VGES4N5/p1666766125610339
  rerender: () => void = () => {
    this.setState({
      activeField: undefined,
    })
  }

  setActiveField: (activeField: string) => void = (activeField: string) => {
    this.setState({ activeField })
  }

  setFieldTouched: (field: string) => void = (field: string) => {
    const { serverErrors, touched } = this.state
    if (serverErrors[field]) {
      delete serverErrors[field]
    }
    this.setState({
      serverErrors,
      touched: {
        ...touched,
        [field]: true,
      },
    })
  }

  onReset: () => void = () => {
    emitter.emit('VALIDATION_ERROR', true)
    this.setState(initState)
    this.rerender()
  }

  // $FlowFixMe: can use ApolloError["graphQLErrors"] after upgrading next.js to support indexed access types
  onErrors: (errors: Violation[] | $ElementType<ApolloError, 'graphQLErrors'>) => void = (
    errors
  ) => {
    let serverErrors = errors.reduce(
      (result, { path, message }) => setIn(path, message, result),
      {}
    )
    // Re-map files errors
    // From: { file[0]: {'ID0': 'message0'}, file[1]: {'ID1': 'message1'} }
    // To: { files: {'ID0': 'message0', 'ID1': 'message1'} }
    serverErrors = Object.entries(serverErrors).reduce((result, [key, value]) => {
      if (key.startsWith('files')) {
        return { ...result, files: { ...result.files, ...value } }
      }

      return { ...result, [key]: value }
    }, {})

    const fieldsTouched = Object.keys(serverErrors).reduce(
      (result, field) => ({ ...result, [field]: true }),
      {}
    )

    this.setState({
      serverErrors,
      errors: serverErrors,
      touched: fieldsTouched,
    })
  }

  isReady: <T: Object>(formData: any, schema: ObjectSchema<T> | typeof EmptyValidation) => boolean =
    <T>(
      formData: Object,
      schema: ObjectSchema<T> | typeof EmptyValidation = EmptyValidation
    ): boolean => schema.isValidSync(formData) && Object.keys(this.state.serverErrors).length < 1

  onValidation: (
    formData: any,
    schema?: {
      isValidSync: (any, any) => boolean,
      validate: (any, any) => Promise<any>,
      ...
    }
  ) => boolean = (
    formData: Object,
    schema: {
      validate: (any, any) => Promise<any>,
      isValidSync: (any, any) => boolean,
    } = EmptyValidation
  ): boolean => {
    const { errors, serverErrors } = this.state
    const isValid = schema?.isValidSync?.(formData)
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const remainErrors: Object = { ...serverErrors }
        Object.keys(errors).forEach((field) => {
          if (typeof formData[field] === 'undefined') {
            remainErrors[field] = errors[field]
          }
        })

        this.setState({
          errors: remainErrors,
        })
      })
      .catch((yupErrors: Object) => {
        const newErrors = yupToFormErrors(yupErrors)
        if (!isEquals(Object.keys(formData), Object.keys(errors))) {
          const remainErrors: Object = { ...serverErrors }
          Object.keys(errors).forEach((field) => {
            if (typeof formData[field] === 'undefined') {
              remainErrors[field] = errors[field]
            }
          })
          this.setState({
            errors: { ...remainErrors, ...newErrors },
          })
        } else if (!isEquals(newErrors, errors)) {
          this.setState({
            errors: newErrors,
          })
        }
      })
    emitter.emit('VALIDATION_ERROR', isValid)
    return isValid
  }
}
