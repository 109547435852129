// @flow

import type { OrderFormFragment } from '@graphql/server/flow'

export const defaultProps = {
  onConfirm: () => {},
}

export type OrderDialogProps = {
  onConfirm: () => void,
  isOpen: boolean,
  onRequestClose: () => void,
  order: OrderFormFragment,
}

export default defaultProps
