// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { UserCard } from 'components/Cards'
import GridView from 'components/GridView'

type Props = {
  items: Object[],
  onLoadMore: Function,
  hasMore: boolean,
  isLoading: boolean,
  renderItem?: (item: Object) => React.Node,
  padding?: string,
}

const defaultRenderItem = (item: Object): React.Node => <UserCard key={item.id} user={item} />

const defaultProps = {
  renderItem: defaultRenderItem,
}

const UserGridView = ({
  items,
  onLoadMore,
  hasMore,
  isLoading,
  renderItem = defaultRenderItem,
  padding,
}: Props): React.Node => {
  return (
    <GridView
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      isLoading={isLoading}
      itemWidth="195px"
      isEmpty={items.length === 0}
      emptyMessage={
        <FormattedMessage id="modules.Users.noUsersFound" defaultMessage="No staff found" />
      }
      padding={padding}
    >
      {items.map((item) => renderItem(item))}
    </GridView>
  )
}

UserGridView.defaultProps = defaultProps

export default UserGridView
