// @flow strict

// regex for format @[Importer Zenport](bl206ruhgr1g0087pnsg)
const regex = /@\[.+?\]\(\w+\)/g

type ParsedMentionType = {
  content: string,
  type?: 'highlight',
}

export const getParsedMentions = (content: string): ParsedMentionType[] => {
  if (!regex.test(content)) {
    return [{ content }]
  }

  const matches = content.match(regex)
  const splitted = content.split(regex)

  const words = splitted.reduce((arr, word, idx) => {
    if (word !== '') {
      arr.push({ content: word })
    }

    if (idx === splitted.length - 1) {
      return arr
    }

    // for finding the text inside the brackets of @[Importer Zenport](bl206ruhgr1g0087pnsg)
    // so 'Importer Zenport' should be the output here
    const match = matches?.shift().match(/\[(.*)\]/) ?? []

    if (match?.[1]) {
      arr.push({
        type: 'highlight',
        content: `@${match[1]}`,
      })
    }

    return arr
  }, ([]: ParsedMentionType[]))

  return words
}

export default getParsedMentions
