// @flow
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import Icon from 'components/Icon'
import { useFocusFallback } from 'contexts/FocusFallback'
import usePortalSlot from 'hooks/usePortalSlot'

import {
  BackdropFadeInStyle,
  BackdropFadeOutStyle,
  CancelButtonStyle,
  DialogFadeInStyle,
  DialogFadeOutStyle,
} from './style'

type Props = {|
  width?: string,
  showCancelButton?: boolean,
  isOpen?: boolean,
  onCancel?: Function,
  onRequestClose?: Function,
  children: React.Node,
|}

const defaultProps = {
  width: 'min-content',
  showCancelButton: false,
  onRequestClose: () => {},
}

export const ANIMATION_FINISHED = 300 // 0.3s

export const DIALOG_PORTAL_NAME = 'dialog'

const DialogRender = (props: Props) => {
  const { isOpen, onRequestClose, onCancel, width, showCancelButton, children } = {
    ...defaultProps,
    ...props,
  }
  const slot = usePortalSlot(DIALOG_PORTAL_NAME)

  const focusFallback = useFocusFallback()
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (isOpen) {
      if (containerRef.current) {
        const elemToFocus =
          containerRef.current.querySelector('[data-focus-first]:not([disabled])') ||
          containerRef.current
        if (elemToFocus) {
          elemToFocus.focus()
        }
      }
    } else if (focusFallback.element.current) {
      focusFallback.element.current.focus()
    }
  }, [isOpen, focusFallback])

  return ReactDOM.createPortal(
    <div
      className={isOpen ? BackdropFadeInStyle : BackdropFadeOutStyle}
      onClick={(event) => {
        event.stopPropagation()
        onRequestClose()
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.stopPropagation()
          onRequestClose()
        }
      }}
      role="presentation"
    >
      <div
        ref={containerRef}
        className={isOpen ? DialogFadeInStyle(width) : DialogFadeOutStyle(width)}
        onClick={(event) => event.stopPropagation()}
        tabIndex="-1"
        role="presentation"
      >
        {showCancelButton && isOpen && (
          <button type="button" onClick={onCancel} className={CancelButtonStyle}>
            <Icon icon="CLEAR" />
          </button>
        )}

        {children}
      </div>
    </div>,
    slot
  )
}

const Dialog = ({
  isOpen,
  onRequestClose,
  onCancel,
  width,
  showCancelButton,
  children,
}: Props): null | React.Node => {
  const [render, setRender] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      setRender(true)
      return () => {}
    }

    const handler = setTimeout(() => {
      setRender(false)
    }, ANIMATION_FINISHED)

    return () => clearTimeout(handler)
  }, [isOpen])

  return render ? (
    <DialogRender
      width={width}
      showCancelButton={showCancelButton}
      onCancel={onCancel}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
    >
      {children}
    </DialogRender>
  ) : null
}

export default Dialog
