// @flow strict
import type { FileAttachableEntity } from 'config/connection/entitiesWithFile'

import type { LocalFile } from 'types'
import { uuid } from 'utils/id'

// Convenience method for converting instances of class File to object
export const eventFilesToLocalFiles = (
  eventFiles: File[],
  entityType: FileAttachableEntity
): LocalFile[] =>
  Array.from(eventFiles).map((eventFile) => ({
    id: uuid(),
    file: eventFile,
    name: eventFile.name,
    entity: { __typename: entityType },
    createdAt: new Date(),
    __typename: 'LocalFile',
  }))

export default eventFilesToLocalFiles
