// @flow
import type { Element } from 'react'
import React from 'react'
import VirtualList from 'react-tiny-virtual-list'

import Icon from 'components/Icon'
import Tag from 'components/Tag'

import { OptionStyle, OptionWrapperStyle, SelectedWrapperStyle } from './style'

type OptionalProps = {
  width: string,
  height: string,
  dropDirection: 'down' | 'up',
}

type Props = OptionalProps & {
  highlightedIndex: ?number,
  getItemProps: Function,
  editable?: { set: boolean, remove: boolean },
  onScroll?: Function,
  items: any[],
  selectedItems: any[],
  itemToValue: (any) => any,
  itemToString: (any) => string,
  align: 'left' | 'right' | 'center',
}

const defaultProps = {
  width: '100%',
  height: '200px',
  dropDirection: 'down',
}

const removePx = (size: string): number => {
  if (size.indexOf('px')) {
    return Number(size.replace('px', ''))
  }
  return 0
}

function TagSelectOptions({
  selectedItems,
  items,
  highlightedIndex,
  getItemProps,
  editable,
  onScroll,
  align,
  width,
  height,
  dropDirection,
}: Props): Element<'div'> {
  const rawHeight = removePx(height)
  const numItemsInAPage = Math.ceil(rawHeight / 30)
  const isPercentWidth = width[width.length - 1] === '%'

  return (
    <div className={OptionWrapperStyle({ width, height, dropDirection, align })}>
      {items.length > 0 ? (
        <VirtualList
          height={items.length < numItemsInAPage ? items.length * 30 : rawHeight}
          width={isPercentWidth ? width : removePx(width)}
          itemCount={items.length}
          itemSize={30}
          overscanCount={numItemsInAPage}
          onScroll={onScroll}
          renderItem={({ index, style }) => {
            const item = items[index]
            if (!item) return null

            const isSelected = selectedItems && selectedItems.some(({ id }) => id === item.id)

            const disabled = isSelected && !editable?.remove

            return (
              <div
                key={item.id}
                className={OptionStyle({
                  onHover: highlightedIndex === index,
                  selected: isSelected,
                  disabled,
                  align,
                })}
                {...getItemProps({
                  index,
                  item,
                  style,
                })}
                {...(disabled && { onClick: undefined, onKeyDown: undefined })}
              >
                <div className={SelectedWrapperStyle}>{isSelected && <Icon icon="CONFIRM" />}</div>
                <Tag tag={item} />
              </div>
            )
          }}
        />
      ) : null}
    </div>
  )
}

TagSelectOptions.defaultProps = defaultProps

export default TagSelectOptions
