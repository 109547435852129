// @flow
import { navigate } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import { CardAction, OrderCard } from 'components/Cards'
import GridView from 'components/GridView'
import PartnerPermissionsWrapper from 'components/PartnerPermissionsWrapper'
import { OrderActivateDialog, OrderArchiveDialog } from 'modules/order/common/Dialog'
import {
  ORDER_CREATE,
  ORDER_FORM,
  ORDER_SET_ARCHIVED,
  ORDER_UPDATE,
} from 'modules/permission/constants/order'
import { encodeId } from 'utils/id'

type Props = {
  items: Object[],
  onLoadMore: Function,
  hasMore: boolean,
  isLoading: boolean,
  renderItem?: (item: Object) => React.Node,
}

const defaultRenderItem = (item: Object): React.Node => (
  <PartnerPermissionsWrapper key={item.id} data={item}>
    {(permissions) => {
      const canCreate = permissions.includes(ORDER_CREATE)
      const canChangeStatus =
        permissions.includes(ORDER_UPDATE) || permissions.includes(ORDER_SET_ARCHIVED)
      const canViewForm = permissions.includes(ORDER_FORM)
      return (
        <BooleanValue>
          {({ value: statusDialogIsOpen, set: dialogToggle }) => (
            <>
              {item.archived ? (
                <OrderActivateDialog
                  onRequestClose={() => dialogToggle(false)}
                  isOpen={statusDialogIsOpen}
                  order={item}
                />
              ) : (
                <OrderArchiveDialog
                  onRequestClose={() => dialogToggle(false)}
                  isOpen={statusDialogIsOpen}
                  order={item}
                />
              )}
              <OrderCard
                order={item}
                actions={[
                  ...(canCreate
                    ? [
                        <CardAction
                          icon="CLONE"
                          onClick={() => navigate(`/order/clone/${encodeId(item.id)}`)}
                        />,
                      ]
                    : []),
                  ...(canChangeStatus
                    ? [
                        <CardAction
                          icon={item.archived ? 'ACTIVE' : 'ARCHIVE'}
                          onClick={() => dialogToggle(true)}
                        />,
                      ]
                    : []),
                ]}
                showActionsOnHover
                onClick={() => {
                  if (canViewForm) {
                    navigate(`/order/${encodeId(item.id)}`)
                  }
                }}
              />
            </>
          )}
        </BooleanValue>
      )
    }}
  </PartnerPermissionsWrapper>
)

const OrderGridView = ({
  items,
  onLoadMore,
  hasMore,
  isLoading,
  renderItem = defaultRenderItem,
}: Props): React.Node => {
  return (
    <GridView
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      isLoading={isLoading}
      itemWidth="195px"
      isEmpty={items.length === 0}
      emptyMessage={
        <FormattedMessage id="modules.Orders.noOrderFound" defaultMessage="No orders found" />
      }
    >
      {items.map(renderItem)}
    </GridView>
  )
}

export default OrderGridView
