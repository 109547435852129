// @flow strict

import { css } from 'react-emotion'

import { borderRadiuses, fontSizes } from 'styles/common'

export const ReactionsHandlerStyle = (
  isMyMessage: boolean,
  numReactedEmojis: number
): string => css`
  position: absolute;
  background-color: inherit;
  margin-top: -0.1rem;
  display: flex;
  gap: 0.2rem;
  overflow-x: hidden;
  flex-direction: row-reverse;
  padding: ${numReactedEmojis < 1 ? '0' : '0.2rem'};
  ${`width:${!numReactedEmojis ? 0 : 0.2 + numReactedEmojis * 1.5 + numReactedEmojis * 0.2}rem`};
  ${isMyMessage ? 'right' : 'left'}: 0;
  ${borderRadiuses.MAIN};
  transition: width 0.2s;
`

export const ReactionHoverStyle: string = css`
  cursor: pointer;
  white-space: nowrap;
  ${fontSizes.SMALL};
  > div {
    width: 1.5rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }
  :hover {
    > div {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
`
