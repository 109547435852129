import { Operation } from '@apollo/client'
import { print } from 'graphql/language/printer'
import { stripIgnoredCharacters } from 'graphql/utilities'

import logger from '@utils/logger'

export class SubscriptionSSE {
  source: EventSource | null = null

  resubscribeTimer?: NodeJS.Timeout = undefined

  subscribe(operation: Operation, handler: (data: any) => void) {
    const { query, variables, operationName } = operation
    const queryString = stripIgnoredCharacters(print(query))

    // resubscribe after 15 minutes
    this.resubscribeTimer = setTimeout(() => {
      this.unsubscribe()
      this.subscribe(operation, handler)
    }, 1000 * 60 * 15)

    this.source = new EventSource(
      encodeURI(
        `${
          process.env.ZENPORT_SERVER_URL || ''
        }/graphql?query=${queryString}&operationName=${operationName}&variables=${JSON.stringify(
          variables
        )}`
      ),
      { withCredentials: true }
    )
    this.source.onmessage = (msg) => {
      try {
        // heartbeat
        if (msg.data === '') {
          return
        }
        handler(JSON.parse(String(msg.data)))
      } catch (e) {
        logger.error(e)
      }
    }
    this.source.onerror = () => {
      this.unsubscribe()
      const retryTimeout = setTimeout(() => {
        this.subscribe(operation, handler)
        clearTimeout(retryTimeout)
      }, 10000)
    }
  }

  unsubscribe() {
    if (this.source) {
      this.source.close()
      this.source = null
    }
    clearTimeout(this.resubscribeTimer)
  }
}

export default SubscriptionSSE
