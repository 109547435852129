// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  inputPlaceholder: {
    id: 'components.Documents.inputPlaceholder',
    defaultMessage: 'Write a message...',
  },
  allPartners: {
    id: 'components.Documents.allPartners',
    defaultMessage: 'All Partners',
  },
  messageDeleted: {
    id: 'components.Documents.messageDeleted',
    defaultMessage: 'The message was deleted',
  },
  fileDeleted: {
    id: 'components.Documents.fileDeleted',
    defaultMessage: 'The file was deleted',
  },
  uploadFromComputer: {
    id: 'components.Documents.uploadFromComputer',
    defaultMessage: 'Upload From Computer',
  },
  selectDocument: {
    id: 'components.Documents.selectDocument',
    defaultMessage: 'Select Document',
  },
}): any)
