// @flow
import { css } from 'react-emotion'

import { colors, transitions } from 'styles/common'

export const FieldStyle: any = css`
  ${transitions.MAIN};
  color: ${colors.BLACK};
  cursor: default;

  &:hover {
    color: ${colors.TEAL};
  }
`

export default FieldStyle
