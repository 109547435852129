// @flow strict
// Components or Routes
import {
  // Common UI
  faAngleLeft as ANGLE_LEFT,
  faAngleRight as ANGLE_RIGHT,
  faArchive as ARCHIVE_LIGHT,
  faBox as BATCH_LIGHT,
  faShip as SHIPMENT_LIGHT,
} from '@fortawesome/pro-light-svg-icons'
import {
  faAngleRight as TARGET,
  // Common UI
  faChartNetwork as CONNECTION,
  faCheckCircle as CHECKED_REGULAR,
  faCheckCircle as UNCHECKED,
  faClone as CLONE_REGULAR,
  faCodeBranch as BRANCH_REGULAR,
  faCube as PRODUCT_PROVIDER,
  faEye as EYE,
  faFileAlt as DOCUMENT_REGULAR,
  faIndustry as SUPPLIER,
  faSyncAlt as REFRESH,
  // Components
  faIndustryAlt as IMPORTER,
  faList as LIST,
  faMagic as MAGIC_REGULAR,
  faSquare as SQUARE,
  faStar as STAR_REGULAR,
  faWarehouse as WAREHOUSER,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAddressBook as NETWORK,
  faAddressCard as PROFILE,
  faAnchor as PORT,
  faArchive as ARCHIVE,
  faFolder as FOLDER,
  faArrowDown as ARROW_DOWN,
  faArrowFromLeft as DEPARTURE_HORIZONTAL,
  faArrowFromTop as DEPARTURE,
  faArrowLeft as ARROW_LEFT,
  faArrowRight as ARROW_RIGHT,
  faArrowToBottom as ARRIVAL,
  faArrowToRight as ARRIVAL_HORIZONTAL,
  faBalanceScale as QUANTITY_ADJUSTMENTS,
  faBan as DISABLED,
  faBell as NOTIFICATION,
  faBezierCurve as ENTITY,
  faBold as BOLD,
  faBook as LOGS,
  faBox as BATCH,
  // Shipment Timeline
  faBoxCheck as CARGO_READY,
  faBoxesAlt as CARGO,
  faBringForward as BRING_FORWARD,
  faCalculator as CALCULATOR,
  faCameraAlt as PHOTO,
  faCheck as CONFIRM,
  faCheckCircle as CHECKED,
  faCheckSquare as CHECKED_SQUARE,
  faChevronDoubleDown as CHEVRON_DOUBLE_DOWN,
  faChevronDoubleLeft as CHEVRON_DOUBLE_LEFT,
  faChevronDoubleRight as CHEVRON_DOUBLE_RIGHT,
  faChevronDoubleUp as CHEVRON_DOUBLE_UP,
  faChevronDown as CHEVRON_DOWN,
  faChevronLeft as CHEVRON_LEFT,
  faChevronRight as CHEVRON_RIGHT,
  faCircle as CIRCLE,
  faClipboardList as CUSTOMS,
  faClock as CLOCK,
  faClone as CLONE,
  faCodeBranch as BRANCH,
  faCog as SETTINGS,
  faHandHoldingBox as COURIER,
  faComment as COMMENTS,
  faCommentAltLines as MEMO,
  faCommentAltPlus as MEMO_ADD,
  faCompress as COMPRESS,
  faContainerStorage as CONTAINER,
  faCube as PRODUCT,
  faDownload as DOWNLOAD,
  faEdit as METADATA,
  faEllipsisH as HORIZONTAL_ELLIPSIS,
  faEnvelope as EMAIL,
  faExchange as EXCHANGE,
  faExclamationCircle as WARNING,
  faExclamationTriangle as WARNING_TRIANGLE,
  faExpand as EXPAND,
  faExternalLink as EXTERNAL_LINK,
  faEye as VISIBLE,
  faEyeSlash as INVISIBLE,
  faFeatherAlt as EDITED,
  faFileAlt as DOCUMENT,
  faFileArchive as FILE_ARCHIVE,
  faFileCsv as CSV,
  // Components
  faFileEdit as ORDER,
  // Document Types
  faFileExcel as EXCEL,
  faFileSignature as FORM,
  faFileImage as IMAGE,
  faFileImport as IMPORT,
  faFilePdf as PDF,
  faFilePlus as DOCUMENT_TYPES,
  faFilePowerpoint as POWER_POINT,
  faFileSpreadsheet as TEMPLATE,
  faFileWord as WORD,
  faFilter as FILTER,
  // Common UI
  faFolderOpen as ACTIVE,
  faForward as SKIPPED,
  faGripVertical as DRAG_HANDLE,
  faHandshake as PARTNER,
  faIndustryAlt as EXPORTER,
  faInfoCircle as INFO,
  faInventory as INVENTORY,
  faItalic as ITALIC,
  faKey as PASSWORD,
  faLanguage as LANGUAGE,
  faLifeRing as TRANSIT,
  faLink as BINDED,
  faLink as LINK,
  faListOl as LIST_OL,
  faLocation as LOCATION,
  faMagic as MAGIC,
  faMap as RELATION_MAP,
  faMinus as MINUS,
  faPalette as COLOR,
  faPalletAlt as DRY,
  faPaperPlane as PAPER_PLANE,
  faPaste as PASTE,
  faPencil as EDIT,
  faPlane as PLANE,
  faPlus as ADD,
  faPrint as PRINT,
  faProjectDiagram as MAP,
  faQuestion as UNKNOWN,
  faRedo as FORGOT,
  faRedo as RELOAD,
  faRoad as ROAD,
  faRoute as TIMELINE,
  faRulerTriangle as SPECIFICATIONS,
  faScalpelPath as SPLIT,
  faSearch as SEARCH,
  faShieldAlt as SECURITY,
  faShip as SHIPMENT,
  faShippingFast as FORWARDER,
  faShoppingCart as ORDER_ITEM,
  faSignInAlt as LOGIN,
  faSignOutAlt as LOGOUT,
  faSitemap as RELATED,
  faSmile as SMILE,
  faSortAmountDown as SORT_DESC,
  faSortAmountUp as SORT_ASC,
  faStar as STAR,
  faStopwatch as STOPWATCH,
  faStrikethrough as STRIK_THROUGH,
  faSync as SYNC,
  faTable as EDIT_TABLE,
  faTable as TABLE,
  faTag as TAG,
  faTape as PACKAGING,
  faTh as CARD,
  faTh as SHEET,
  faThLarge as CARDS,
  faThList as TH_LIST,
  faThumbtack as PARENT,
  faTimes as CLEAR,
  faTimesCircle as CANCEL,
  faToggleOff as TOGGLE_OFF,
  faToggleOn as TOGGLE_ON,
  faTrainTrack as RAIL,
  faTrash as REMOVE,
  faTrashAlt as REMOVE_ALT,
  faTruckLoading as DELIVERY_READY,
  faUndo as UNDO,
  faUnlink as UNBINDED,
  faUpload as UPLOAD,
  faUser as USER,
  faUserCrown as MANAGER,
  faUserFriends as IMPORTERS,
  faUsers as USERS,
  faWarehouse as WAREHOUSE,
  faWavePulse as WAVE_PULSE,
  faWrench as ACTION,
  faHome as HOME,
} from '@fortawesome/pro-solid-svg-icons'

export default {
  ORDER,
  BATCH,
  SHIPMENT,
  CONTAINER,
  COURIER,
  PRODUCT,
  WAREHOUSE,
  INVENTORY,
  NETWORK,
  PARTNER,
  USERS,
  IMPORTERS,
  SETTINGS,
  TAG,
  ORDER_ITEM,
  NOTIFICATION,
  PROFILE,
  LOGIN,
  LOGOUT,
  IMPORTER,
  EXPORTER,
  FORWARDER,
  SUPPLIER,
  USER,
  MANAGER,
  WAREHOUSER,
  CARGO,
  PACKAGING,
  QUANTITY_ADJUSTMENTS,
  PRODUCT_PROVIDER,
  SPECIFICATIONS,
  PHOTO,
  LOGS,
  CALCULATOR,
  DRAG_HANDLE,
  METADATA,
  SMILE,
  BOLD,
  ITALIC,
  STRIK_THROUGH,
  LINK,
  LIST_OL,
  TEMPLATE,
  STAR,
  STAR_REGULAR,
  STOPWATCH,
  EDIT_TABLE,
  SECURITY,
  PASSWORD,
  PASTE,
  FORGOT,
  IMPORT,
  SHEET,
  TH_LIST,
  RELATION_MAP,
  BINDED,
  UNBINDED,
  SPLIT,
  RELATED,
  SKIPPED,
  UNDO,
  BRING_FORWARD,
  CARDS,
  PARENT,
  FOLDER,

  BRANCH,
  ACTIVE,
  ARCHIVE,
  CARD,
  TABLE,
  CONNECTION,
  LIST,
  SORT_DESC,
  SORT_ASC,
  LOCATION,
  FILTER,
  SEARCH,
  CLEAR,
  CANCEL,
  REMOVE,
  REMOVE_ALT,
  CLONE,
  CHECKED,
  CHECKED_SQUARE,
  UNCHECKED,
  DISABLED,
  EDIT,
  CONFIRM,
  MINUS,
  ADD,
  PRINT,
  EYE,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  WARNING_TRIANGLE,
  WARNING,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  ANGLE_LEFT,
  ANGLE_RIGHT,
  CHEVRON_DOUBLE_LEFT,
  CHEVRON_DOUBLE_UP,
  CHEVRON_DOUBLE_RIGHT,
  CHEVRON_DOUBLE_DOWN,
  DOCUMENT,
  TOGGLE_ON,
  TOGGLE_OFF,
  INFO,
  MEMO,
  MEMO_ADD,
  COMMENTS,
  DOWNLOAD,
  FILE_ARCHIVE,
  UPLOAD,
  CIRCLE,
  SYNC,
  EXPAND,
  COMPRESS,
  HORIZONTAL_ELLIPSIS,
  EXCHANGE,
  LANGUAGE,
  COLOR,
  EDITED,
  PAPER_PLANE,
  EXTERNAL_LINK,
  VISIBLE,
  INVISIBLE,
  RELOAD,
  CLOCK,
  MAP,
  ACTION,

  TIMELINE,
  CARGO_READY,
  PORT,
  PLANE,
  ROAD,
  RAIL,
  DRY,
  UNKNOWN,
  TRANSIT,
  CUSTOMS,
  DELIVERY_READY,
  ARRIVAL,
  DEPARTURE,
  ARRIVAL_HORIZONTAL,
  DEPARTURE_HORIZONTAL,

  EXCEL,
  FORM,
  CSV,
  IMAGE,
  PDF,
  POWER_POINT,
  WORD,
  EMAIL,
  WAVE_PULSE,
  SQUARE,
  TARGET,
  ENTITY,
  MAGIC,
  BRANCH_REGULAR,
  MAGIC_REGULAR,
  DOCUMENT_REGULAR,
  CHECKED_REGULAR,
  CLONE_REGULAR,
  BATCH_LIGHT,
  SHIPMENT_LIGHT,
  ARCHIVE_LIGHT,
  DOCUMENT_TYPES,

  REFRESH,
  HOME,
}
