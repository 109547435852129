// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  networkError: {
    id: 'components.InternalError.networkError',
    defaultMessage: 'Network Error',
  },
  message: {
    id: 'components.InternalError.message',
    defaultMessage: 'It looks like there was a problem connecting to our servers.',
  },
  message2: {
    id: 'components.InternalError.message2',
    defaultMessage: 'Please check your internet connection and try refreshing the page.',
  },
  message3: {
    id: 'components.InternalError.message3',
    defaultMessage: 'If the issue persists, please contact an administrator.',
  },
  goHome: {
    id: 'components.InternalError.goHome',
    defaultMessage: 'GO HOME',
  },
  refresh: {
    id: 'components.InternalError.refresh',
    defaultMessage: 'REFRESH',
  },
}): any)
