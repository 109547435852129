// @flow strict
/* eslint-disable no-unused-vars */

import { toast } from 'react-toastify'

import { isDevEnvironment } from 'utils/env'

export const warnMissingConnection = ({
  organizationId,
  connectionId,
  connectionOptional,
}: {
  organizationId: ?string,
  connectionId?: ?string,
  connectionOptional: boolean,
}) => {
  if (isDevEnvironment && connectionId === undefined && !connectionOptional) {
    // toast.error(
    //   `The system has detected that permissions were attempted to be called without a connectionId! \nOrganizationID: ${
    //     organizationId ?? 'undefined'
    //   }\n ConnectionID: ${
    //     connectionId ?? 'undefined'
    //   }\n You can disable this warning by editing the call of useHasPermissions`
    // )
  }
}

export default warnMissingConnection
