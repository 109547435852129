// @flow

import { partition } from 'lodash'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { BaseButton } from 'components/Buttons'

import messages from '../../messages'
import { DownloadWrapperStyle } from '../../style'
import type { UploadFileState } from '../DocumentNavbarFileContent'

type Props = {
  isMultiSelect: boolean,
  selectedFiles: { [k: string]: Object },
  setSelectedFiles: Function,
  filesState: {
    ...UploadFileState,
    tags: [],
    uploading: boolean,
    progress: number,
  }[],
  onSave: Function,
}

const DeleteSelectedButton = ({
  isMultiSelect,
  selectedFiles,
  setSelectedFiles,
  filesState,
  onSave,
}: Props): React.Element<'div'> => {
  return (
    <div className={DownloadWrapperStyle}>
      <BaseButton
        icon="REMOVE"
        label={<FormattedMessage {...messages.deleteSelected} />}
        backgroundColor={isMultiSelect ? 'TEAL' : 'GRAY_SUPER_LIGHT'}
        hoverBackgroundColor={isMultiSelect ? 'TEAL_DARK' : 'GRAY_VERY_LIGHT'}
        textColor={isMultiSelect ? 'WHITE' : 'GRAY_DARK'}
        hoverTextColor={isMultiSelect ? 'WHITE' : 'GRAY_DARK'}
        onClick={(evt) => {
          evt.stopPropagation()
          const [filesToRemove, filesToKeep] = partition(
            filesState,
            (file) =>
              file.id &&
              Object.keys(selectedFiles).some((selectedFileId) => selectedFileId === file.id)
          )
          onSave(filesToKeep)
          setSelectedFiles((prev) => {
            const res = { ...prev }
            filesToRemove.forEach((badFile) => {
              delete res[badFile.id]
            })
            return res
          })
        }}
      />
    </div>
  )
}

export default DeleteSelectedButton
