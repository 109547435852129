import styled from 'styled-components'

import { borderRadiuses, colors, fontSizes, shadows } from '@styles/common'

interface AvatarWrapperProps {
  width: string
  height: string
}

export const AvatarWrapperStyle = styled.div<AvatarWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.WHITE};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  flex-shrink: 0;
  border-radius: ${borderRadiuses.CIRCLE};
  font-size: ${fontSizes.MAIN};
  background-color: ${colors.GRAY_LIGHT};
  box-shadow: ${shadows.FAINT};
  user-select: none;
`

export default AvatarWrapperStyle
