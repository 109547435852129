// @flow strict

import * as React from 'react'
import { useState } from 'react'

import Badge from 'components/Badge'
import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import {
  ChatListItemStyle,
  IconBadgeStyle,
  IconContainerStyle,
  TitleStyle,
} from 'modules/chat/style'

type Props = {
  id: string,
  title: string,
  numUnreadMessages: number,
  hasUnreadFile: boolean,
  isSelected?: boolean,
  onClick: (string) => void,
  onIconClick?: (string) => void,
}

const ChatListItem = ({
  id,
  title,
  numUnreadMessages,
  hasUnreadFile,
  isSelected,
  onClick,
  onIconClick,
}: Props): React.Element<'div'> => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const onListIconClick = (e: Event) => {
    e.stopPropagation()
    if (onIconClick) {
      onIconClick(id)
    }
  }

  return (
    <div
      className={ChatListItemStyle(isSelected)}
      onClick={() => onClick(id)}
      onKeyDown={() => onClick(id)}
      role="button"
      tabIndex="0"
    >
      <GridRow gap="3px" hCentered>
        <Tooltip message={title} visible={tooltipVisible}>
          <span
            className={TitleStyle}
            onMouseEnter={(e) => {
              const isOverflowing = e.target.scrollHeight > e.target.clientHeight
              setTooltipVisible(isOverflowing)
            }}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            {title}
          </span>
        </Tooltip>
        {numUnreadMessages > 0 && <Badge content={`${numUnreadMessages}`} size={20} />}
      </GridRow>
      <div
        className={IconContainerStyle(!!onIconClick)}
        onClick={onListIconClick}
        onKeyDown={onListIconClick}
        role="button"
        tabIndex={0}
      >
        {hasUnreadFile && (
          <div className={IconBadgeStyle}>
            <Badge size={10} />
          </div>
        )}
        <Icon icon="DOCUMENT" />
      </div>
    </div>
  )
}

export default ChatListItem
