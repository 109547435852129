// @flow strict
import update from 'immutability-helper'
import { Container } from 'unstated'

import type {
  BatchPayload,
  ContainerPayload,
  OrganizationPayload,
  BatchFormFragment,
} from '@graphql/server/flow'

import { initDatetimeToContainer } from 'utils/date'
import { isEquals } from 'utils/fp'

const shipmentFormBatchesFormKeysObj = Object.freeze({
  batches: 'batches',
})

export const shipmentFormInfoKeys: $Keys<typeof shipmentFormBatchesFormKeysObj>[] = Object.keys(
  shipmentFormBatchesFormKeysObj
)

export type ShipmentBatchesFormState = {|
  batches: BatchFormFragment[],
  hasCalledBatchesApiYet: boolean,
|}

const initValues: ShipmentBatchesFormState = {
  batches: [],
  hasCalledBatchesApiYet: false,
}

export default class ShipmentBatchesContainer extends Container<ShipmentBatchesFormState> {
  state: ShipmentBatchesFormState = initValues

  originalValues: ShipmentBatchesFormState = initValues

  existingBatches: BatchPayload[] | any | BatchPayload[] = initValues.batches

  addExistingBatches: (batches: BatchPayload[]) => void = (batches: BatchPayload[]) => {
    this.existingBatches = [...this.existingBatches, ...batches]
  }

  removeExistingBatches: (batches: BatchPayload[]) => void = (batches: BatchPayload[]) => {
    this.existingBatches = [
      ...this.existingBatches.filter((existingBatch) =>
        batches.some((batch) => batch?.id === existingBatch?.id)
      ),
    ]
  }

  changeContainerIdToExistingBatches: (
    batches: BatchPayload[],
    container: ContainerPayload
  ) => void = (batches: BatchPayload[], container: ContainerPayload) => {
    this.existingBatches = [
      ...this.existingBatches.map((existingBatch) =>
        batches.some((batch) => batch?.id === existingBatch?.id)
          ? update(existingBatch, { container: { $set: container } })
          : existingBatch
      ),
    ]
  }

  isDirty: () => boolean = () => !isEquals(this.state, this.originalValues)

  onSuccess: () => void = () => {
    this.originalValues = this.state
    this.existingBatches = this.state.batches
    this.setState(this.originalValues)
  }

  setFieldValue: (name: string, value: mixed) => void = (name: string, value: mixed) => {
    this.setState({
      [name]: value,
    })
  }

  setFieldArrayValue: (index: number, value: any) => void = (index: number, value: any) => {
    this.setState((prevState) =>
      update(prevState, {
        batches: {
          [index]: {
            $merge: value,
          },
        },
      })
    )
  }

  initDetailValues: (
    batches: BatchPayload[],
    hasCalledBatchesApiYet?: boolean,
    timezone: string
  ) => void = (
    batches: BatchPayload[],
    hasCalledBatchesApiYet: boolean = false,
    timezone: string
  ) => {
    const parsedBatches: Object[] = batches.map((batch) => ({
      ...batch,
      ...initDatetimeToContainer(batch?.deliveredAt ?? null, 'deliveredAt', timezone),
      ...initDatetimeToContainer(batch?.desiredAt ?? null, 'desiredAt', timezone),
      ...initDatetimeToContainer(batch?.expiredAt ?? null, 'expiredAt', timezone),
      ...initDatetimeToContainer(batch?.producedAt ?? null, 'producedAt', timezone),
    }))

    this.setState({ batches: parsedBatches, hasCalledBatchesApiYet })
    this.originalValues = { batches: parsedBatches, hasCalledBatchesApiYet }
    this.existingBatches = parsedBatches
  }

  changeMainExporter: (prevExporter: ?OrganizationPayload, exporter: ?OrganizationPayload) => void =
    (prevExporter: ?OrganizationPayload, exporter: ?OrganizationPayload) => {
      if (exporter) {
        this.setState((prevState) => {
          return {
            batches: prevState.batches.filter(
              (batch) => batch?.orderItem?.order?.exporter?.id === exporter?.id
            ),
          }
        })
      }
    }

  // On change Importer, clean up followers and batches
  onChangeImporter: (prevImporter: ?OrganizationPayload) => void = (
    prevImporter: ?OrganizationPayload
  ) => {
    if (prevImporter) {
      this.setState({
        batches: [],
      })
    }
  }
}
