// @ts-ignore // TODO
export const isEnableBetaFeature: boolean = parseInt(process.env.ZENPORT_BETA_FEATURE, 10) !== 0
// @ts-ignore // TODO
export const isEnableErrorReport: boolean = parseInt(process.env.ZENPORT_ERROR_TRACKING, 10) !== 0
// @ts-ignore // TODO
export const isEnableStrictMode: boolean = parseInt(process.env.ZENPORT_STRICT_MODE, 10) !== 0
export const isDevEnvironment: boolean = process.env.ZENPORT_NODE_ENV !== 'production'
export const isAppInProduction: boolean = process.env.ZENPORT_NODE_ENV === 'production'
export const isSubPath: boolean = process.env.ZENPORT_SUBPATH === 'true'
export const isNext: boolean = process.env.NEXT_PUBLIC_PKG_ENV === 'next'
export const isClientRendered: boolean = typeof document !== 'undefined'
