// @flow strict
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import IconEnums from './enums'

export type IconEnum = $Keys<typeof IconEnums> | 'LAND'

export type Props = {|
  icon: IconEnum,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  size?: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x',
|}

function Icon({ icon, onClick, ...rest }: Props): React.Node {
  const rotation = null
  if (icon === 'LAND') {
    return (
      <>
        <FontAwesomeIcon size="xs" icon={IconEnums.RAIL} onClick={onClick} />
        <FontAwesomeIcon size="xs" icon={IconEnums.DRY} onClick={onClick} />
      </>
    )
  }
  return (
    <FontAwesomeIcon
      icon={IconEnums[icon]}
      fixedWidth
      onClick={onClick}
      {...(rotation ? { rotation } : {})}
      {...rest}
    />
  )
}

export default Icon
