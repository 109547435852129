// @flow
import * as React from 'react'

import Icon, { type IconEnum } from 'components/Icon'

import { IconStyle, SubIconStyle } from './style'

type Props = {
  icon: IconEnum,
  color: string,
  invert?: boolean,
  subIcon?: IconEnum,
}

const defaultProps = {
  invert: false,
}

function EntityIcon({ icon, color, invert, subIcon }: Props): React.Element<'div'> {
  return (
    <div className={IconStyle(color, invert ?? false)}>
      <Icon icon={icon} />
      {subIcon && (
        <div className={SubIconStyle(color, invert ?? false)}>
          <Icon icon={subIcon} />
        </div>
      )}
    </div>
  )
}

EntityIcon.defaultProps = defaultProps

export default EntityIcon
