// @flow
import { navigate } from '@reach/router'
import * as React from 'react'

import { OrderProductProviderCard } from 'components/Cards'
import { useEntityHasPermissions } from 'contexts/Permissions'
import { PRODUCT_FORM } from 'modules/permission/constants/product'
import { encodeId } from 'utils/id'

type Props = {
  productProvider: Object,
}

const ParentEndProductCard = ({ productProvider }: Props): React.Node => {
  const hasPermissions = useEntityHasPermissions(productProvider?.product)

  return (
    <OrderProductProviderCard
      productProvider={productProvider}
      onClick={() => {
        if (hasPermissions(PRODUCT_FORM) && !!productProvider?.product?.id) {
          navigate(`/product/${encodeId(productProvider?.product?.id)}`)
        }
      }}
    />
  )
}

export default ParentEndProductCard
