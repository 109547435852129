// @flow

import { css } from 'react-emotion'

import { borderRadiuses, colors, fontSizes, fontSizesWithHeights, scrollbars } from 'styles/common'

export const ChatStyle: string = css`
  display: grid;
  grid-template-columns: 270px 1fr;
  background-color: #f3f3f3;
  height: 100%;
`

export const ChatDetailContainerStyle: string = css`
  background-color: #ffffff;
`

export const ChatListSectionStyle: string = css`
  padding: 17px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
`

export const ChatListContainerStyle: string = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto; // set to auto if want to scroll
`

export const ChatListItemStyle = (selected?: boolean): string => css`
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 10px 20px;
  align-items: center;

  /* removes text highlight */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    cursor: pointer;
    ${!selected && `background-color: ${colors.TEAL_LIGHT};`}
  }

  ${selected &&
  `
    color: white;
    background-color: ${colors.TEAL_DARK};
  `}
`

export const TitleStyle: any = css`
  max-width: 130px;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const IconContainerStyle = (changeOnHover: boolean): string => css`
  position: relative;
  color: ${colors.GRAY_LIGHT};
  font-size: 20px;

  ${changeOnHover &&
  css`
    &:hover {
      color: ${colors.WHITE};
    }
  `}
`

export const AddGroupButtonStyle: any = css`
  margin: 10px 20px;
`

export const IconBadgeStyle: any = css`
  position: absolute;
`

export const ChatDetailSectionStyle: any = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
`

export const ChatMessagesContainerStyle: any = css`
  ${scrollbars.MAIN};
  flex-grow: 1;
  padding: 35px 35px 0 35px;
  overflow: auto;
  .ChatMessageStyles {
    .FilePreviewStyle:hover {
      > img:first-child {
        position: absolute;
        display: unset;
      }
    }
    img:first-child {
      display: none;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      transform: scale(3) translate(38px, -65px);
      border-radius: 5px;
    }
  }
  .ChatMessageStyles:first-child img:first-child {
    transform: scale(3) translate(38px, 65px);
    z-index: 1;
  }
`

type ChatMessageStyleType = {
  isMyMessage: boolean,
  isFile: boolean,
  isDeleted: boolean,
}

const getGridTemplateSizes = ({ isMyMessage }: { isMyMessage: boolean }) => {
  return isMyMessage ? '1fr 36px' : '36px 1fr'
}

export const ChatMessageStyles = ({
  isMyMessage,
  isFile,
  isDeleted,
}: ChatMessageStyleType): string => css`
  display: grid;
  grid-template-columns: ${getGridTemplateSizes({ isMyMessage, isFile, isDeleted })};
  gap: 10px;
  margin-bottom: 10px;
  ${fontSizesWithHeights.MAIN};
`

export const MessageBubbleContainerStyle = (isMyMessage: boolean): string => css`
  justify-items: ${isMyMessage ? 'end' : 'inherit'};
  span {
    white-space: nowrap;
  }
`

export const MessageBubbleStyle = (isMyMessage: boolean, numEmojis: number): string => css`
  position: relative;
  padding: 8px;
  background-color: ${isMyMessage ? colors.TEAL_LIGHT : '#F3F3F3'};
  width: max-content;
  max-width: 450px;
  word-wrap: break-word;
  ${borderRadiuses.MAIN};

  span {
    white-space: pre-line !important;
    word-wrap: break-word !important;
  }
  :hover {
    > div {
      padding: 0.2rem;
      width: ${`${0.2 + numEmojis * 1.5 + 10 * 0.2}rem`};
    }
  }
`

export const MessageNoWrapStyle: any = css`
  white-space: nowrap !important;
`

export const MessageTimeStyle: any = css`
  margin-top: 12px; // adjust size accordingly to grid gap of parent
  margin-bottom: 5px;
`

export const MessageGrayStyle: any = css`
  color: ${colors.GRAY};
`

export const ChatInputContainerStyle: any = css`
  border-top: 1px solid ${colors.GRAY_VERY_LIGHT};
`

export const ChatInputStyle: any = css`
  ${scrollbars.SMALL};
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;

  & textarea,
  div {
    border: none;
    ${fontSizes.MAIN};
    font-weight: bold;
    color: ${colors.BLACK};
  }
  & textarea {
    &::placeholder {
      color: ${colors.GRAY_LIGHT};
    }
  }
`

export const ChatSvgStyle: any = css`
  color: ${colors.GRAY_DARK};
  padding: 12px 10px;

  & svg:hover {
    cursor: pointer;
    padding: 1px 5px;
    color: ${colors.GRAY_LIGHT};
  }
`

export const ChatTextInputStyle: any = css`
  padding: 15px 10px;

  input {
    width: 100%;
    height: 25px;
  }
`

export const ChatInputActionsStyle: any = css`
  display: flex;
  justify-content: flex-end;
`

export const DeletedMessageStyle: any = css`
  border: 1px solid ${colors.GRAY_DARK};
  color: ${colors.GRAY_DARK};
  padding: 12px;
`

export const ChatMessageActionStyle: any = css`
  color: ${colors.GRAY};
  ${fontSizes.LARGE};

  > svg:hover {
    cursor: pointer;
    color: ${colors.GRAY_DARK};
  }
`

export const LoadingIconContainerStyle: any = css`
  height: 30px;
`
