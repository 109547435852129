// @flow
import { useApolloClient, useMutation } from '@apollo/client'

import orderItemFilesQuery from '@graphql/client/document/query.orderItemFiles.graphql'
import productFilesQuery from '@graphql/client/document/query.productFiles.graphql'
import UpdateOrderMutation from '@graphql/client/order/mutation.orderUpdate.graphql'
import orderFormFilesQuery from '@graphql/client/order/query.orderFormFiles.graphql'
import UpdateOrderItemMutation from '@graphql/client/orderItem/mutation.orderItemUpdate.graphql'
import UpdateProductMutation from '@graphql/client/product/mutation.productUpdate.graphql'
import UpdateShipmentMutation from '@graphql/client/shipment/mutation.shipmentUpdate.graphql'
import shipmentFormFilesQuery from '@graphql/client/shipment/query.shipmentFormFiles.graphql'
import type { Order, OrderItem, Product, Shipment } from '@graphql/server/flow'
import { isForbidden } from '@utils/data'

import { prepareParsedDocumentParentInput } from 'modules/document/form/mutation'
import { toLowerFirst } from 'utils/string'

/*
 * Update the parent of a document
 */
const useDocumentParentMutation = (): (
  | (({ newState: any, type: string, ... }) => Promise<void> | Promise<{| violations: string |}>)
  | {| loading: boolean |}
)[] => {
  const client = useApolloClient()

  const [shipmentMutate, { loading: isShipmentProcessing }] = useMutation(UpdateShipmentMutation)
  const [orderMutate, { loading: isOrderProcessing }] = useMutation(UpdateOrderMutation)
  const [orderItemMutate, { loading: isOrderItemProcessing }] = useMutation(UpdateOrderItemMutation)
  const [endProductMutate, { loading: isEndProductProcessing }] = useMutation(UpdateProductMutation)

  /*
   * Queries the parent document
   */
  const getEntity = async (type: string, parentId: string) => {
    const queries = {
      Order: orderFormFilesQuery,
      OrderItem: orderItemFilesQuery,
      Shipment: shipmentFormFilesQuery,
      ProductProvider: productFilesQuery,
    }

    const query = queries[type]

    if (!query) {
      return null
    }

    // $FlowFixMe
    return client.query({
      query,
      variables: {
        id: parentId,
      },
      fetchPolicy: 'network-only',
    })
  }

  const updateDocumentParent = async (entity: mixed, state: Object) => {
    const parentInput = prepareParsedDocumentParentInput(state, entity)

    const mutations = {
      Order: orderMutate,
      OrderItem: orderItemMutate,
      Shipment: shipmentMutate,
      ProductProvider: endProductMutate,
    }

    const mutate = mutations[state?.entity?.__typename]

    if (parentInput && mutate) {
      await mutate({
        variables: { id: parentInput.id, input: parentInput.input },
      })
    }
  }

  const getEntityId = (state: Object) => {
    switch (state.entity.__typename) {
      case 'ProductProvider':
        return state?.productProvider?.product?.id
      default:
        return state?.entity?.id
    }
  }

  // for order, orderItem and shipment entities
  const areValidFiles = (entity: Order | OrderItem | Shipment): boolean => {
    return !entity.files.some((file) => isForbidden(file))
  }

  const areValidProductFiles = (entity: Product): boolean => {
    return !entity.productProviders.some((productProvider) => {
      return !!productProvider?.files?.some((file) => isForbidden(file))
    })
  }

  // if one of the files is forbidden then do not continue the save
  // else data loss will occur
  const areEntityFilesValid = (data: any, type: string) => {
    if (!data) {
      return false
    }

    switch (type) {
      case 'Order':
      case 'OrderItem':
      case 'Shipment':
        return areValidFiles(data[toLowerFirst(type)])
      case 'ProductProvider':
        return areValidProductFiles(data.product)
      default:
        return false
    }
  }

  const mutation = async ({ type, newState }: { type: string, newState: Object }) => {
    const entity = await getEntity(type, getEntityId(newState))

    if (!entity || !entity.data) {
      return { violations: 'Entity not found' }
    }

    if (!areEntityFilesValid(entity.data, type)) {
      return { violations: 'You do not have permission to update the files' }
    }

    const updated = await updateDocumentParent(entity.data, newState)

    return updated
  }

  return [
    mutation,
    {
      loading:
        isShipmentProcessing ||
        isOrderProcessing ||
        isOrderItemProcessing ||
        isEndProductProcessing,
    },
  ]
}

export default useDocumentParentMutation
