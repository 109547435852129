// @flow
import * as React from 'react'

import { BaseButton } from 'components/Buttons'

import { FirstButtonStyle, LastButtonStyle, WrapperStyle } from './style'

type Props = {
  items: Object[],
  selectedId?: string,
  disabled?: boolean,
  onSelect: (item: Object) => void,
}

const TabButtonGroup = ({ items, selectedId, disabled, onSelect }: Props): React.Element<'div'> => {
  return (
    <div className={WrapperStyle}>
      {items.map((item, idx) => {
        const { label, id, icon } = item
        const isSelected = id === selectedId
        let radiusStyle

        if (idx === 0) {
          radiusStyle = FirstButtonStyle
        } else if (idx === items.length - 1) {
          radiusStyle = LastButtonStyle
        }

        return (
          <BaseButton
            key={id}
            leftIcon={icon}
            label={label}
            backgroundColor={isSelected ? 'TEAL' : 'GRAY_SUPER_LIGHT'}
            hoverBackgroundColor={isSelected ? 'TEAL_DARK' : 'GRAY_VERY_LIGHT'}
            textColor={isSelected ? 'WHITE' : 'GRAY_DARK'}
            hoverTextColor={isSelected ? 'WHITE' : 'GRAY_DARK'}
            className={radiusStyle}
            disabled={disabled}
            onClick={() => onSelect?.(item)}
          />
        )
      })}
    </div>
  )
}

export default TabButtonGroup
