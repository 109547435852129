// @flow
import * as React from 'react'
import { cx } from 'react-emotion'

import { CancelButton } from 'components/Buttons'
import UploadButton from 'components/Buttons/UploadButton'
import type { UploadDialogProps } from 'components/Dialog/type'
import GridRow from 'components/GridRow'

import Dialog from '../index'

import { ButtonContainerStyle, DialogStyle } from './style'

const UploadDialog = ({
  children,
  isOpen,
  uploadDisabled,
  onCancel,
  onRequestClose,
  onUpload,
}: UploadDialogProps): React.Node => (
  <Dialog isOpen={isOpen} onRequestClose={onRequestClose}>
    <div className={cx(DialogStyle)}>
      {children}
      <div className={ButtonContainerStyle}>
        <GridRow>
          <CancelButton onClick={onCancel} />
          <UploadButton disabled={uploadDisabled} onUpload={onUpload} />
        </GridRow>
      </div>
    </div>
  </Dialog>
)

export default UploadDialog
