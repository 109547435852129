// @flow strict
import { useApolloClient } from '@apollo/client'
import type { SubscriptionResult } from '@apollo/client'
import * as React from 'react'
import { useEffect } from 'react'

import enumQuery from '@graphql/client/common/query.enum.graphql'
import type { EnumQuery, EnumFragment } from '@graphql/server/flow'

export default function useEnum(enumName: ?string): {
  enums: EnumFragment[],
  loading: boolean,
} {
  const client = useApolloClient()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [enums, setEnums] = React.useState<EnumFragment[]>([])

  useEffect(() => {
    setEnums([])
    if (typeof enumName !== 'string') {
      return () => {}
    }

    setLoading(true)

    const watchedQuery = client
      .watchQuery<EnumQuery>({
        query: enumQuery,
        variables: { enum: enumName },
        fetchPolicy: 'cache-first',
      })
      .subscribe(({ data }: SubscriptionResult<EnumQuery>) => {
        setEnums(data?.__type?.enumValues ?? [])
        setLoading(false)
      })

    return () => watchedQuery.unsubscribe()
  }, [client, enumName])

  return {
    loading,
    enums,
  }
}
