// @flow strict
import update from 'immutability-helper'
import { cloneDeep, set, unset } from 'lodash'
import { Container } from 'unstated'

import type { BatchFormFragment, ContainerFormFragment, Scalars } from '@graphql/server/flow'
import { cleanFalsyAndTypeName, removeNulls } from '@utils/data'

import type { $Anyify, $Pick } from 'types'
import { initDatetimeToContainer } from 'utils/date'
import { isEquals } from 'utils/fp'

const containerBatchesFormKeysObj = Object.freeze({
  batches: 'batches',
  representativeBatch: 'representativeBatch',
})

export const containerBatchesFormKeys: string[] = Object.keys(containerBatchesFormKeysObj)

export type ContainerBatchesFormState = {|
  ...$Pick<ContainerFormFragment, $ObjMap<typeof containerBatchesFormKeysObj, $Anyify>>,
|}

const initValues: ContainerBatchesFormState = {
  batches: [],
  representativeBatch: undefined,
}

export default class ContainerBatchesContainer extends Container<ContainerBatchesFormState> {
  state: ContainerBatchesFormState = initValues

  originalValues: ContainerBatchesFormState = initValues

  existingBatches: $ElementType<ContainerBatchesFormState, 'batches'> = initValues.batches

  addExistingBatches: (batches: $ElementType<ContainerBatchesFormState, 'batches'>) => void = (
    batches
  ) => {
    this.existingBatches = [...this.existingBatches, ...batches]
  }

  removeExistingBatch: (batchId: $ElementType<Scalars, 'ID'>) => void = (batchId) => {
    this.existingBatches = [
      ...this.existingBatches.flatMap((batch) =>
        batch.__typename === 'Batch' && batch.id !== batchId ? batch : []
      ),
    ]
  }

  removeExistingBatches: (batches: $ElementType<ContainerBatchesFormState, 'batches'>) => void = (
    batches
  ) => {
    this.existingBatches = [
      ...this.existingBatches.filter((existingBatch) =>
        batches.some(
          (batch) =>
            batch.__typename === 'Batch' &&
            existingBatch.__typename === 'Batch' &&
            batch.id !== existingBatch.id
        )
      ),
    ]
  }

  changeContainerIdToExistingBatches: (
    batches: $ElementType<ContainerBatchesFormState, 'batches'>,
    container: $ElementType<BatchFormFragment, 'container'>
  ) => void = (batches, container) => {
    this.existingBatches = [
      ...this.existingBatches.map((existingBatch) =>
        batches.some(
          (batch) =>
            batch.__typename === 'Batch' &&
            existingBatch.__typename === 'Batch' &&
            batch.id === existingBatch.id
        ) && existingBatch.__typename === 'Batch'
          ? { ...existingBatch, container }
          : existingBatch
      ),
    ]
  }

  isDirty: () => boolean = () =>
    !isEquals(cleanFalsyAndTypeName(this.state), cleanFalsyAndTypeName(this.originalValues))

  onSuccess: () => void = () => {
    this.originalValues = this.state
    this.existingBatches = this.state.batches
    this.setState(this.originalValues)
  }

  setFieldValue: <T>(name: string, value: T) => void = <T>(name, value: T) => {
    this.setState({
      [name]: value,
    })
  }

  setDeepFieldValue: <T>(path: string, value: T) => void = <T>(path, value: T) => {
    this.setState((prevState) => {
      const newState = set(cloneDeep(prevState), path, value)
      return newState
    })
  }

  removeDeepFieldValue: (path: string) => void = (path) => {
    this.setState((prevState) => {
      const cloneState = cloneDeep(prevState)
      unset(cloneState, path)
      return removeNulls(cloneState)
    })
  }

  setFieldArrayValue: <T>(index: number, value: T) => void = <T>(index, value: T) => {
    this.setState((prevState) =>
      update(prevState, {
        batches: {
          [index]: {
            $merge: value,
          },
        },
      })
    )
  }

  initDetailValues: (ContainerBatchesFormState, timezone: string) => void = (
    { batches, representativeBatch },
    timezone
  ) => {
    const parsedBatches = batches.flatMap((batch) => {
      if (batch.__typename !== 'Batch') return []
      let parsedBatch = { ...batch }

      const batchDeliveredAtObj = initDatetimeToContainer(
        batch.deliveredAt,
        'deliveredAt',
        timezone
      )
      const batchDesiredAtObj = initDatetimeToContainer(batch.desiredAt, 'desiredAt', timezone)
      const batchExpiredAtObj = initDatetimeToContainer(batch.expiredAt, 'expiredAt', timezone)
      const batchProducedAtObj = initDatetimeToContainer(batch.producedAt, 'producedAt', timezone)

      if (batchDeliveredAtObj?.deliveredAt !== undefined) {
        parsedBatch = { ...batch, ...batchDeliveredAtObj }
      }
      if (batchDesiredAtObj?.desiredAt !== undefined) {
        parsedBatch = { ...batch, ...batchDesiredAtObj }
      }
      if (batchExpiredAtObj?.expiredAt !== undefined) {
        parsedBatch = { ...batch, ...batchExpiredAtObj }
      }
      if (batchProducedAtObj?.producedAt !== undefined) {
        parsedBatch = { ...batch, ...batchProducedAtObj }
      }

      return parsedBatch
    })

    let parsedRepresentativeBatch = { ...representativeBatch }

    const representativeBatchDeliveredAtObj = initDatetimeToContainer(
      representativeBatch?.deliveredAt,
      'deliveredAt',
      timezone
    )
    const representativeBatchDesiredAtObj = initDatetimeToContainer(
      representativeBatch?.desiredAt,
      'desiredAt',
      timezone
    )
    const representativeBatchExpiredAtObj = initDatetimeToContainer(
      representativeBatch?.expiredAt,
      'expiredAt',
      timezone
    )
    const representativeBatchProducedAtObj = initDatetimeToContainer(
      representativeBatch?.producedAt,
      'producedAt',
      timezone
    )

    if (representativeBatchDeliveredAtObj?.deliveredAt !== undefined) {
      parsedRepresentativeBatch = {
        ...parsedRepresentativeBatch,
        ...representativeBatchDeliveredAtObj,
      }
    }
    if (representativeBatchDesiredAtObj?.desiredAt !== undefined) {
      parsedRepresentativeBatch = {
        ...parsedRepresentativeBatch,
        ...representativeBatchDesiredAtObj,
      }
    }
    if (representativeBatchExpiredAtObj?.expiredAt !== undefined) {
      parsedRepresentativeBatch = {
        ...parsedRepresentativeBatch,
        ...representativeBatchExpiredAtObj,
      }
    }
    if (representativeBatchProducedAtObj?.producedAt !== undefined) {
      parsedRepresentativeBatch = {
        ...parsedRepresentativeBatch,
        ...representativeBatchProducedAtObj,
      }
    }

    this.setState({ batches: parsedBatches, representativeBatch: parsedRepresentativeBatch })
    this.originalValues = {
      batches: parsedBatches,
      representativeBatch: parsedRepresentativeBatch,
    }
    this.existingBatches = parsedBatches
  }
}
