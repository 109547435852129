// @flow

import * as React from 'react'
import { cx } from 'react-emotion'

import { NewBadgeStyle } from './style'

type Props = {
  content?: string,
  size?: number,
  className?: string,
}

const Badge = ({ content, size = 20, className }: Props): React.Element<'div'> => {
  return <div className={cx(NewBadgeStyle(size), className)}>{content}</div>
}

export default Badge
