// @flow strict

import { useMutation } from '@apollo/client'
import { cx } from 'emotion'
import * as React from 'react'
import { useMemo, useState } from 'react'

import messageEmojiUpdateMutation from '@graphql/client/chat/mutation.messageEmojiUpdate.graphql'
import type {
  MessageEmojiUpdateMutation,
  MessageEmojiUpdateMutationVariables,
} from '@graphql/server/flow'

import Avatar from 'components/Avatar'
import { DocumentCard } from 'components/Cards'
import FormattedDate from 'components/FormattedDate'
import GridColumn from 'components/GridColumn'
import GridRow from 'components/GridRow'
import { Tooltip } from 'components/Tooltip'
import useUser from 'hooks/useUser'
import {
  ChatMessageStyles,
  MessageBubbleContainerStyle,
  MessageBubbleStyle,
  MessageGrayStyle,
  MessageNoWrapStyle,
  MessageTimeStyle,
} from 'modules/chat/style'
import { MentionAddOnStyle, MentionStyle } from 'modules/timeline/components/CommentInput/style'
import { colors } from 'styles/common'

import type { LocalMessage } from '../../hooks/reducer'
import { useChat } from '../../hooks/useChat'
import ChatMessageActions from '../ChatMessageActions'
import DeletedMessage from '../DeletedMessage'

import { getParsedMentions } from './helpers'
import { ReactionHoverStyle, ReactionsHandlerStyle } from './style'

type Props = {
  isMyMessage: boolean,
  showAvatar: boolean,
  showTime: boolean,
  onEditClick: (?string, string, string) => void,
  onDeleteClick: (?string, string) => void,
  message: LocalMessage,
}

const ChatMessage = ({
  isMyMessage,
  message,
  showAvatar,
  showTime,
  onEditClick,
  onDeleteClick,
}: Props): React$Node => {
  const { id: messageId, content, createdBy, messageGroup } = message
  const { firstName, lastName, organization } = typeof createdBy?.id === 'string' ? createdBy : {}
  const { emojis, readMessageCache } = useChat()
  const { user } = useUser()

  const [messageEmojiUpdate] = useMutation<
    MessageEmojiUpdateMutation,
    MessageEmojiUpdateMutationVariables
  >(messageEmojiUpdateMutation, {
    onCompleted() {
      readMessageCache()
    },
  })

  const messageGroupId = typeof messageGroup?.id === 'string' ? messageGroup.id : null
  const organizationName = typeof organization?.id === 'string' ? organization.name : ''
  const type = message.file ? 'file' : 'text'
  const senderName = `${firstName ?? ''} ${lastName ?? ''}`
  const isDeleted = !!message.deletedAt
  const [isHovered, setHovered] = useState(false)

  const parsedContent = useMemo(() => {
    if (typeof content !== 'string') {
      return content
    }

    return content.split('\n').map((word, idx, origArr) => {
      const parsed = getParsedMentions(word)

      return (
        // eslint-disable-next-line
        <React.Fragment key={idx}>
          {parsed.map((parsedWord, parsedIdx) => {
            if (parsedWord.type !== 'highlight') {
              // eslint-disable-next-line
              return <span key={parsedIdx}>{parsedWord.content}</span>
            }

            return (
              // eslint-disable-next-line
              <strong key={parsedIdx} style={{ position: 'relative' }}>
                {parsedWord.content}
                <span className={cx(MentionStyle, MentionAddOnStyle)} />
              </strong>
            )
          })}
          {/* eslint-disable-next-line */}
          {idx !== origArr.length - 1 && <br />}
        </React.Fragment>
      )
    })
  }, [content])

  const reactionCounts = (message.messageEmoji ?? []).reduce(
    (memo, { createdBy: rxnCreatedBy, emoji }) => {
      if (emoji?.__typename !== 'Emoji' || !memo[emoji.emoji]) return memo
      // eslint-disable-next-line
      memo[emoji.emoji].count++
      if (rxnCreatedBy?.__typename === 'User') {
        memo[emoji.emoji].users.push({
          name: `${rxnCreatedBy.firstName} ${rxnCreatedBy.lastName}`,
        })
      }
      return memo
    },
    emojis.reduce(
      (memo, { emoji }) => ({ ...memo, [emoji]: { count: 0, users: [] } }),
      ({}: {|
        [string]: {|
          count: number,
          users: {| name: string |}[],
        |},
      |})
    )
  )

  const numReactedEmojis = Object.keys(reactionCounts).reduce(
    (count, emoji) => (reactionCounts[emoji].count > 0 ? count + 1 : count),
    0
  )

  const selectedMessageEmojis = (message.messageEmoji ?? [])
    .flatMap((me) => (me.__typename === 'MessageEmoji' ? me : []))
    .filter((emoji) => emoji.createdBy?.__typename === 'User' && emoji.createdBy.id === user.id)

  return (
    <>
      <div
        className={`${ChatMessageStyles({
          isMyMessage,
          isFile: type === 'file',
          isDeleted,
        })} ChatMessageStyles`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <>
          {!isMyMessage && (showAvatar ? <Avatar name={organizationName} /> : <span />)}
          <GridColumn gap="5px" className={MessageBubbleContainerStyle(isMyMessage)}>
            <>
              {showAvatar && (
                <GridRow gap="10px">
                  <span className={MessageNoWrapStyle}>
                    {senderName}{' '}
                    <span className={cx(MessageGrayStyle, MessageNoWrapStyle)}>
                      [{organizationName}]
                    </span>
                  </span>
                </GridRow>
              )}
              <GridRow gap="10px">
                {isDeleted && <DeletedMessage type={type} />}
                {!isDeleted && (
                  <>
                    {isMyMessage && (
                      <ChatMessageActions
                        hideIcons={!isHovered}
                        onEditClick={
                          type === 'text'
                            ? () => onEditClick(messageGroupId, messageId, content ?? '')
                            : undefined
                        }
                        onDeleteClick={() => onDeleteClick(messageGroupId, messageId)}
                      />
                    )}
                    {type === 'text' && (
                      <p className={MessageBubbleStyle(!isMyMessage, emojis.length)}>
                        {parsedContent}
                        <div className={ReactionsHandlerStyle(isMyMessage, numReactedEmojis)}>
                          {emojis
                            .flatMap((emoji) => (emoji.__typename === 'Emoji' ? emoji : []))
                            .sort((a, b) => {
                              const countDiff =
                                reactionCounts[b.emoji].count - reactionCounts[a.emoji].count
                              return countDiff !== 0 ? countDiff : b.decimals[0] - a.decimals[0]
                            })
                            .map((emoji) => {
                              const { count, users } = reactionCounts[emoji.emoji]
                              const isSelected = selectedMessageEmojis.some(
                                (selectedMessageEmoji) =>
                                  selectedMessageEmoji.emoji.__typename === 'Emoji' &&
                                  emoji.emoji === selectedMessageEmoji.emoji.emoji
                              )
                              return (
                                <Tooltip
                                  message={
                                    <>
                                      {users.map((u) => (
                                        <div>{u.name}</div>
                                      ))}
                                    </>
                                  }
                                  enabled={!!users.length}
                                  placement="bottom"
                                  distance={(users.length + 1) * 20}
                                  // idk why this works but it does (kinda)
                                  popperOptions={{
                                    positionFixed: true,
                                  }}
                                >
                                  <button
                                    type="button"
                                    className={ReactionHoverStyle}
                                    onClick={() => {
                                      messageEmojiUpdate({
                                        variables: {
                                          input: { messageId: message.id, emojiId: emoji.id },
                                        },
                                      })
                                    }}
                                  >
                                    <div {...(isSelected && { style: { color: colors.TEAL } })}>{`${
                                      emoji.emoji
                                    }${count || ''}`}</div>
                                  </button>
                                </Tooltip>
                              )
                            })}
                        </div>
                      </p>
                    )}
                    {type === 'file' && (
                      <DocumentCard file={message.file} downloadable variant="light" actions={[]} />
                    )}
                  </>
                )}
              </GridRow>
              {showTime && (
                <div className={MessageTimeStyle}>
                  <span className={cx(MessageGrayStyle, MessageNoWrapStyle)}>
                    <FormattedDate value={message.createdAt} mode="datetime" />
                  </span>
                </div>
              )}
            </>
          </GridColumn>
          {isMyMessage && (showAvatar ? <Avatar name={organizationName} /> : <span />)}
        </>
      </div>
    </>
  )
}

export default ChatMessage
