// @flow

import * as React from 'react'
import { useCallback, useLayoutEffect, useState } from 'react'

import Tooltip from 'components/Tooltip/Tooltip'
import { getIsOverflowing } from 'utils/getIsOverflowing'

import { EntityNameWrapperStyle } from './style'

type Props = {|
  name: string,
|}

// TODO: Merge overflow design with EntityLabel
const EntityName = ({ name }: Props): React.Node => {
  const [node, setNode] = useState<?HTMLDivElement>()
  const [nameIsOverflowing, setNameIsOverflowing] = useState(false)
  const ref = useCallback<(React.ElementRef<'div'> | null) => void>((_node) => {
    if (_node !== null) {
      setNode(_node)
      setNameIsOverflowing(getIsOverflowing(_node))
    }
  }, [])

  useLayoutEffect(() => {
    const measure = () => {
      if (node) setNameIsOverflowing(getIsOverflowing(node))
    }

    window.addEventListener('resize', measure)

    return () => {
      window.removeEventListener('resize', measure)
    }
  }, [node])

  return (
    <Tooltip enabled={nameIsOverflowing} message={name}>
      <div ref={ref} className={EntityNameWrapperStyle}>
        {name}
      </div>
    </Tooltip>
  )
}

export default EntityName
