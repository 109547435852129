// @flow

// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'
import { upperFirst } from 'lodash'

import MessageGroup from '@graphql/client/chat/fragment.messageGroup.graphql'
import Connection from '@graphql/client/common/fragment.connection.graphql'
import Organization from '@graphql/client/common/fragment.organization.graphql'
import Tag from '@graphql/client/common/fragment.tag.graphql'
import Connected from '@graphql/client/connected/fragment.connected.graphql'
import Document from '@graphql/client/document/fragment.document.graphql'
import BadRequest from '@graphql/client/errors/fragment.badRequest.graphql'
import Forbidden from '@graphql/client/errors/fragment.forbidden.graphql'
import Owned from '@graphql/client/owned/fragment.owned.graphql'

import type { ChatEntity } from './type'

export const entityFormMessageGroups = (entityType: ChatEntity): any => gql`
  query ${upperFirst(entityType)}FormMessageGroups($id: ID!) {
    ${entityType}(id: $id) {
      ... on ${upperFirst(entityType)} {
        id
        messageGroups {
          ...MessageGroup
          ...BadRequest
          ...Forbidden
        }
      }
    }
  }
  ${MessageGroup}
  ${BadRequest}
  ${Forbidden}
  ${Tag}
  ${Connected}
  ${Connection}
  ${Document}
  ${Owned}
  ${Organization}
`

export default entityFormMessageGroups
