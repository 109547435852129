// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import UserAvatar from '@components/UserAvatar'

import { Display, Label } from 'components/Form'
import FormattedDate from 'components/FormattedDate'

import { LastModifiedStyle, LastModifiedWrapperStyle, UserIconStyle } from './style'

type Props = {
  updatedAt: string,
  updatedBy?: {
    firstName: string,
    lastName: string,
  },
}

function SectionHeader({ updatedAt, updatedBy }: Props): React.Element<'div'> {
  return (
    <div className={LastModifiedWrapperStyle}>
      <div className={LastModifiedStyle}>
        <Label width="100%" align="right">
          <FormattedMessage id="components.form.updatedAt" defaultMessage="Last Modified" />
        </Label>
        <Display>
          <FormattedDate value={updatedAt} />
        </Display>
      </div>
      <div className={UserIconStyle}>
        <UserAvatar
          firstName={updatedBy ? updatedBy.firstName : ''}
          lastName={updatedBy ? updatedBy.lastName : ''}
          width="20px"
          height="20px"
        />
      </div>
    </div>
  )
}

export default SectionHeader
