// @flow strict
export const entities = Object.freeze({
  SHIPMENT: 'SHIPMENT',
  CONTAINER: 'CONTAINER',
  BATCH: 'BATCH',
  ORDER: 'ORDER',
  ORDER_ITEM: 'ORDER_ITEM',
  PRODUCT_PROVIDER: 'PRODUCT_PROVIDER',
})

export type Entities = $Values<typeof entities>
