import { takeItems } from 'utils/fp'

import enTranslationMessages from '@web-app/language/translations/en.json'
import jaTranslationMessages from '@web-app/language/translations/ja.json'
import zhTranslationMessages from '@web-app/language/translations/zh.json'

const appLocales = ['en', 'ja', 'zh']

const DEFAULT_LOCALE = 'en'

export const formatTranslationMessages = (locale: string, messages: { [k: string]: any }) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  ja: formatTranslationMessages('ja', jaTranslationMessages),
  zh: formatTranslationMessages('zh', zhTranslationMessages),
}

export const getInitialLocale = (): string => {
  if (typeof navigator === 'undefined') {
    return 'en'
  }

  const browserLocale = navigator.languages ? navigator.languages[0] : navigator.language

  const formattedLocale = takeItems(2, browserLocale || 'en')

  return appLocales.includes(formattedLocale) ? formattedLocale : 'ja'
}
