// @flow
import * as React from 'react'

import FormattedDate from 'components/FormattedDate'
import Icon from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { isDateObject } from 'utils/date'
import { isEquals } from 'utils/fp'

import TooltipIcon from './TooltipIcon'
import {
  ArrowDownStyle,
  DividerStyle,
  InfoMessageStyle,
  NewValueStyle,
  OldValueStyle,
  TooltipRelativeWrapperStyle,
  UpperMessageStyle,
} from './style'

type OptionalProps = {
  isNew: boolean,
  errorMessage: React.Node,
  warningMessage: React.Node,
  infoMessage: React.Node,
  changedValues: {
    oldValue: React.Node,
    newValue: React.Node,
  },
}

type Props = OptionalProps

const defaultProps = {
  isNew: false,
  errorMessage: '',
  warningMessage: '',
  infoMessage: '',
  changedValues: {
    oldValue: '',
    newValue: '',
  },
}

export default function FormTooltip({
  isNew,
  errorMessage,
  warningMessage,
  infoMessage,
  changedValues,
}: Props): null | React.Element<'div'> {
  const { oldValue, newValue } = changedValues

  let type = null
  const showChanged = !isNew && (!!oldValue || !!newValue) && !isEquals(oldValue, newValue)

  if (errorMessage) type = 'error'
  else if (warningMessage) type = 'warning'
  else if (showChanged) type = 'changed'
  else if (infoMessage) type = 'info'

  if (type) {
    return (
      <div className={TooltipRelativeWrapperStyle}>
        <Tooltip
          message={
            <>
              {errorMessage && (
                <>
                  <div className={UpperMessageStyle}>{errorMessage}</div>
                  {(warningMessage || changedValues.oldValue || infoMessage) && (
                    <div className={DividerStyle} />
                  )}
                </>
              )}

              {warningMessage && (
                <>
                  <div className={UpperMessageStyle}>{warningMessage}</div>
                  {(changedValues.oldValue || infoMessage) && <div className={DividerStyle} />}
                </>
              )}

              {showChanged && (
                <>
                  <div className={OldValueStyle}>
                    {isDateObject(changedValues.oldValue) ? (
                      // $FlowIgnore
                      <FormattedDate value={changedValues.oldValue} />
                    ) : (
                      changedValues.oldValue
                    )}
                  </div>
                  <div className={ArrowDownStyle}>
                    <Icon icon="ARROW_DOWN" />
                  </div>
                  <div className={NewValueStyle}>
                    {isDateObject(changedValues.newValue) ? (
                      // $FlowIgnore
                      <FormattedDate value={changedValues.newValue} />
                    ) : (
                      changedValues.newValue
                    )}
                  </div>
                  {infoMessage && <div className={DividerStyle} />}
                </>
              )}

              {infoMessage && <div className={InfoMessageStyle}>{infoMessage}</div>}
            </>
          }
        >
          <TooltipIcon type={type} hasInfo={!!infoMessage} />
        </Tooltip>
      </div>
    )
  }
  return null
}

FormTooltip.defaultProps = defaultProps
