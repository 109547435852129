// @flow strict
import { useApolloClient } from '@apollo/client'
import { intersection } from 'lodash'
import * as React from 'react'
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import permissionsForOrganization from '@graphql/client/auth/query.permissionsForOrganization.graphql'
import type { Scalars } from '@graphql/server/flow'

import { useAuthenticated, useAuthorizedViewer } from 'contexts/Auth'
import entityHasConnection from 'utils/entityHasConnection'
import { getByPathWithDefault } from 'utils/fp'

import { warnMissingConnection } from './utils'

type Permissions = {
  loading: boolean,
  permissions: string[],
}

export type HasPermissions = (permissionKey: string | string[]) => boolean

type Context = {
  getPermissionsByOrganizationByConnection: (
    organizationId: ?string,
    connectionId?: ?string
  ) => Permissions,
  hasPermissionsByOrganizationByConnection: (
    organizationId: ?string,
    connectionId?: ?string
  ) => HasPermissions,
}

export const PermissionsContext: React$Context<Context> = createContext<Context>({
  getPermissionsByOrganizationByConnection: () => ({ loading: false, permissions: [] }),
  hasPermissionsByOrganizationByConnection: () => () => false,
})

const usePermissionContext = (): Context => useContext(PermissionsContext)

export const usePermissions = (
  organizationId: ?string,
  connectionId?: ?string,
  connectionOptional?: boolean = false
): Permissions => {
  warnMissingConnection({ organizationId, connectionId, connectionOptional })

  const { getPermissionsByOrganizationByConnection } = usePermissionContext()

  return getPermissionsByOrganizationByConnection(organizationId, connectionId)
}

export const useHasMyPermissions = (
  organizationId: string
): ((connectionId?: Scalars['ID']) => HasPermissions) => {
  const { hasPermissionsByOrganizationByConnection } = usePermissionContext()

  return (connectionId?: Scalars['ID']) =>
    hasPermissionsByOrganizationByConnection(organizationId, connectionId)
}

export const useHasPermissions = (
  organizationId: ?string,
  connectionId?: ?string,
  connectionOptional?: boolean = false
): HasPermissions => {
  warnMissingConnection({ organizationId, connectionId, connectionOptional })

  const { hasPermissionsByOrganizationByConnection } = usePermissionContext()

  return useCallback(hasPermissionsByOrganizationByConnection(organizationId, connectionId), [
    organizationId,
    connectionId,
    hasPermissionsByOrganizationByConnection,
  ])
}

export const useViewerPermissions = (): Permissions => {
  const { organization } = useAuthorizedViewer()

  return usePermissions(organization?.id, undefined, true)
}

export const useViewerHasPermissions = (): HasPermissions => {
  const { organization } = useAuthorizedViewer()

  return useHasPermissions(organization?.id, undefined, true)
}

export const useEntityPermissions = (
  entity: ?{
    ownedBy?: { id?: string },
    connectionBy?: { id?: string },
  }
): Permissions => {
  return usePermissions(entity?.ownedBy?.id, entity?.connectionBy?.id)
}

export const useEntityHasPermissions = (
  entity: ?{
    ownedBy?: { id?: string },
    connectionBy?: { id?: string },
    type?: string,
    __typename?: string,
  }
): HasPermissions => {
  return useHasPermissions(
    entity?.ownedBy?.id,
    entity?.connectionBy?.id !== undefined ? entity.connectionBy.id : null,
    !entity ||
      (entity && entity.type === undefined) ||
      (entity.type !== undefined && !entityHasConnection(entity.type)) ||
      (entity.__typename !== undefined && !entityHasConnection(entity.__typename))
  )
}

type Props = {
  children: React.Node,
}

type State = {
  [string]: Permissions,
}

const PermissionsProvider = ({ children }: Props): React.Node => {
  const client = useApolloClient()
  const { authenticated } = useAuthenticated()
  const [permissions, setPermissions] = useState<State>({})
  const organizationsConnectionsToSearch = useRef<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (!authenticated) {
      setPermissions({})
    }
  }, [authenticated])

  const getPermissionsByOrganizationByConnection = (
    organizationId: ?string,
    connectionId?: ?string
  ): Permissions => {
    // Combined IDs
    const organizationIdConnectionId = `${organizationId ?? ''}${connectionId ?? ''}`

    if (typeof organizationId !== 'string') {
      return {
        loading: false,
        permissions: [],
      }
    }

    // If already have permission, return it
    if (permissions[organizationIdConnectionId]) {
      return permissions[organizationIdConnectionId]
    }

    // If permission is being searched, return loading
    if (organizationsConnectionsToSearch.current[organizationIdConnectionId]) {
      return {
        loading: true,
        permissions: [],
      }
    }

    // Set to searching
    organizationsConnectionsToSearch.current[organizationIdConnectionId] = true

    client
      .query({
        query: permissionsForOrganization,
        variables: {
          organizationId,
          connectionId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        // Update permissions, but don't update search status. This prevents duplicated calls.
        setPermissions((prev) => ({
          ...prev,
          [organizationIdConnectionId]: {
            loading: false,
            permissions: getByPathWithDefault([], 'viewer.permissionsForOrganization', data),
          },
        }))
      })

    return {
      loading: true,
      permissions: [],
    }
  }

  useEffect(() => {
    Object.keys((permissionKey: string) => {
      delete organizationsConnectionsToSearch.current[permissionKey]
    })
  }, [permissions])

  const hasPermissionsByOrganizationByConnection = (
    organizationId: ?string,
    connectionId?: ?string
  ) => {
    const permissionsByOrganizationByConnection = getPermissionsByOrganizationByConnection(
      organizationId,
      connectionId
    )
    return (permissionKey: string | string[]) => {
      if (permissionsByOrganizationByConnection.loading) {
        return false
      }

      if (Array.isArray(permissionKey)) {
        return (
          intersection(permissionsByOrganizationByConnection.permissions, permissionKey).length > 0
        )
      }

      return permissionsByOrganizationByConnection.permissions.includes(permissionKey)
    }
  }

  return (
    <PermissionsContext.Provider
      value={{ getPermissionsByOrganizationByConnection, hasPermissionsByOrganizationByConnection }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}

export const useAllHasPermission = (ids: [Scalars['ID'], Scalars['ID']][]): HasPermissions => {
  const { hasPermissionsByOrganizationByConnection } = usePermissionContext()

  return useCallback(
    (permissionKey: string | string[] = []): boolean => {
      return ids.every(([ownedById, connectionById]) =>
        hasPermissionsByOrganizationByConnection(ownedById, connectionById)(permissionKey)
      )
    },
    [ids, hasPermissionsByOrganizationByConnection]
  )
}

export default PermissionsProvider
