// @flow
import { css } from 'react-emotion'

import { colors, fontSizes, fontSizesWithHeights, layout } from 'styles/common'

export const LogWrapperStyle: any = css`
  ${layout.HORIZONTAL};
  ${layout.CENTER};
`

export const TimeStyle: any = css`
  ${fontSizes.SMALL};
  font-weight: 600;
  letter-spacing: 0.1em;
  color: ${colors.GRAY_DARK};
  width: 80px;
`

export const LogStyle: any = css`
  ${fontSizesWithHeights.MAIN};
  font-weight: 600;
  color: ${colors.GRAY_DARK};
  flex: 1;
`
