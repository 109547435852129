// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { unreadTimelineByEntity } from '@modules/TableView/CellRenderers/LogsRenderer/query'

import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import emitter from 'utils/emitter'

import { unreadGroupsQuery } from './query'
import { BadgeStyle, LogsButtonWrapperStyle } from './style'

type Props = {|
  onClick: Function,
  entityType:
    | 'order'
    | 'batch'
    | 'orderItem'
    | 'product'
    | 'productProvider'
    | 'shipment'
    | 'container'
    | 'file',
  entityId: string,
  openByDefault?: boolean,
|}

const MessagesButton = ({
  onClick,
  entityType,
  entityId,
  openByDefault,
}: Props): React.Element<'button'> => {
  const { data: allPartnersUnreadData } = useQuery(unreadTimelineByEntity(entityType), {
    variables: {
      id: entityId,
    },
  })

  // $FlowFixMe: Cannot unbind refetch
  const { data, refetch } = useQuery(unreadGroupsQuery(entityType), {
    variables: {
      id: entityId,
    },
    skip: entityType === 'container',
  })

  const isDefaultOpened = useRef(false)

  useEffect(() => {
    if (openByDefault && !isDefaultOpened.current) {
      isDefaultOpened.current = true
      onClick()
    }
  }, [openByDefault, onClick])

  useEffect(() => {
    const subscription = emitter.addListener('MESSAGES_CLOSE', () => {
      refetch()
    })

    return () => {
      subscription.remove()
    }
  }, [refetch])

  const badgeNum = (data?.[entityType]?.messageGroups ?? []).reduce(
    (sum, group) => sum + (group.unreadMessageCount ? 1 : 0),
    allPartnersUnreadData?.[entityType]?.timeline?.unreadMessageCount ? 1 : 0
  )

  return (
    <button type="button" onClick={onClick} className={LogsButtonWrapperStyle}>
      <Icon icon="COMMENTS" />{' '}
      <FormattedMessage id="components.navBar.messages" defaultMessage="MESSAGES" />
      {!!badgeNum && (
        <div className={BadgeStyle}>
          <FormattedNumber value={badgeNum} />
        </div>
      )}
    </button>
  )
}

export default MessagesButton
