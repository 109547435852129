// @flow
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { type IntlShape, injectIntl } from 'react-intl'

import {
  Blackout,
  DefaultOptions,
  DefaultSelect,
  FieldItem,
  FormTooltip,
  Label,
  SelectInput,
} from 'components/Form'
import { convertValueToFormFieldFormat, enumToString } from 'components/Form/Factories/helpers'
import type {
  InputProps,
  InputWrapperProps as StandardInputWrapperProps,
  LabelProps,
  TooltipProps,
} from 'components/Form/Factories/type'
import EnumProvider from 'providers/enum'
import { getByPath } from 'utils/fp'

type InputWrapperProps = StandardInputWrapperProps & {
  type?: 'standard' | 'label',
  dropDirection?: 'down' | 'up',
}

type Props = LabelProps &
  TooltipProps &
  InputWrapperProps &
  InputProps & {
    intl: IntlShape,
    formPath: string,
    vertical: boolean,
    isTouched: boolean,
    label?: React.Node,
    enumType: string,
    hideDropdownArrow: boolean,
    editable: boolean,
    smartLock: boolean,
    blackout: boolean,
  }

const defaultProps = {
  labelWidth: '200px',
  labelHeight: '30px',
  inputWidth: '200px',
  inputHeight: '30px',
  hideTooltip: false,
  isTouched: false,
  hideDropdownArrow: false,
  editable: false,
  smartLock: false,
  blackout: false,
  vertical: false,
}

const EnumSelectInputFactory = ({
  vertical,
  isTouched,
  label,
  enumType,
  formPath,
  required,
  labelAlign,
  labelWidth,
  labelHeight,
  hideTooltip,
  isNew,
  errorMessage,
  warningMessage,
  infoMessage,
  originalValue,
  type,
  dropDirection,
  isFocused,
  disabled,
  forceHoverStyle,
  inputWidth,
  inputHeight,
  value,
  name,
  placeholder,
  prohibitedValues,
  onChange,
  onBlur,
  onFocus,
  inputAlign,
  hideDropdownArrow,
  editable,
  smartLock,
  blackout,
  intl,
}: Props): React.Node => {
  const methods = useFormContext()
  const itemToString = enumToString(enumType, intl)
  let formRefEntry
  if (methods?.refs) {
    formRefEntry = getByPath(formPath, methods.refs)
  }

  return (
    <EnumProvider enumType={enumType}>
      {({ loading, error, data }) => {
        const itemToValue = (item) => (item ? item.name : '')

        const selectedItem = data.find((item) => item.name === value)
        const selectableItems = prohibitedValues
          ? data.filter((item) => !prohibitedValues.some((badValue) => badValue === item.name))
          : data

        const labelConfig = { required, align: labelAlign, width: labelWidth, height: labelHeight }

        const tooltipConfig = {
          isNew,
          infoMessage,
          errorMessage: isTouched && errorMessage,
          warningMessage: isTouched && warningMessage,
          changedValues: {
            oldValue: itemToString(data.find((item) => item.name === originalValue)),
            newValue: itemToString(selectedItem),
          },
        }

        const inputConfig = {
          width: inputWidth,
          height: inputHeight,
          forceHoverStyle,
          placeholder,
          required,
          hideDropdownArrow,
        }

        const optionsConfig = {
          width: inputWidth,
          dropDirection,
          prohibitedValues,
        }

        const selectConfig = {
          type,
          formPath,
          isFocused,
          hasError: !!(isTouched && errorMessage),
          disabled,
          value,
          name,
          onChange: (newValue) => {
            if (onChange) {
              onChange(convertValueToFormFieldFormat(itemToValue(newValue)))
            }
          },
          afterClearSelection: () => {
            if (onChange) {
              onChange(convertValueToFormFieldFormat(null))
            }
            if (onBlur && onFocus) {
              setTimeout(() => {
                onBlur()
                onFocus()
              }, 0)
            }
          },
          onBlur,
          onFocus,
          align: inputAlign,
          readOnly: !editable || (smartLock && selectedItem && selectableItems.length === 1),
          itemToString,
          itemToValue,
          renderSelect: ({ ...rest }) => <DefaultSelect {...rest} {...inputConfig} />,
          renderOptions: ({ ...rest }) => <DefaultOptions {...rest} {...optionsConfig} />,
          readOnlyWidth: inputWidth,
          readOnlyHeight: inputHeight,
        }

        const blackoutConfig = {
          width: inputWidth,
          height: inputHeight,
        }

        let renderedInput = <Blackout {...blackoutConfig} />

        if (!blackout) {
          renderedInput = (
            <SelectInput ref={formRefEntry} items={loading ? [] : data} {...selectConfig} />
          )
        }

        return (
          <FieldItem
            vertical={vertical}
            label={label && <Label {...labelConfig}>{label}</Label>}
            tooltip={!hideTooltip ? <FormTooltip {...tooltipConfig} /> : null}
            input={error ? `Error!: ${error}` : renderedInput}
          />
        )
      }}
    </EnumProvider>
  )
}

EnumSelectInputFactory.defaultProps = defaultProps

export default (injectIntl(EnumSelectInputFactory): any)
