// @flow
import * as React from 'react'

import { SectionNavBar } from 'components/NavBar'

import { SingleCardSectionBodyStyle, SingleCardSectionWrapperStyle } from './style'

type Props = {
  navbarContent?: React$Node,
  children: React$Node,
}

const SingleCardSection = ({ navbarContent = null, children }: Props): React.Element<'div'> => (
  <div className={SingleCardSectionWrapperStyle}>
    <SectionNavBar>{navbarContent}</SectionNavBar>

    <div className={SingleCardSectionBodyStyle}>{children}</div>
  </div>
)

export default SingleCardSection
