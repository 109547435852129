// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  no: {
    id: 'validation.no.required',
    defaultMessage: 'No is required field',
  },
  quantity: {
    id: 'validation.quantity.number',
    defaultMessage: 'Quantity must be a number',
  },
}): any)
