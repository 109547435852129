// ! React MUST BE IN SCOPE to import this file into CRA
// @ts-ignore unused var
// eslint-disable-next-line
import React from 'react'
// ! React MUST BE IN SCOPE to import this file into CRA

import { QueryHookOptions, useQuery } from '@apollo/client'
import { getSomePortCode } from 'modules/shipment/helpers'
import { type Dispatch, type SetStateAction, useState } from 'react'

import portsQuery from '@graphql/client/shipment/query.ports.graphql'
import {
  Port,
  PortInput,
  PortsQueryVariables,
  PortsQuery,
  PortFragment,
} from '@graphql/server/typescript'

import {
  transportTypeFromPortType,
  portTypeFromPort,
  portOptionFromPort,
} from '@modules/shipment/helpers'
import { PortOption } from '@modules/shipment/types'

export default function usePort({
  searchValue,
  canSearch,
  values,
}: {
  searchValue: string
  canSearch: boolean
  values: (PortInput | PortFragment)[]
}): {
  ports: PortOption[]
  setPorts: Dispatch<SetStateAction<PortOption[]>>
} {
  const [ports, setPorts] = useState<PortOption[]>([])

  /*
   We can only query for one value at a time, so run the below query once for every
   unfound value.
   */
  const unfoundValue = values.find(
    (value) =>
      !ports.some(
        (option) =>
          option.value ===
          `${transportTypeFromPortType({
            portType: portTypeFromPort({ port: value }),
          })}-${getSomePortCode({ port: value })}`
      )
  )

  const opts: QueryHookOptions<PortsQuery, PortsQueryVariables> = {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const { ports: newPorts } = data
      setPorts((oldOptions) => [...parseRemotePorts({ oldOptions, newPorts }), ...oldOptions])
    },
  }

  useQuery<PortsQuery, PortsQueryVariables>(portsQuery, {
    variables: {
      keyword: unfoundValue ? getSomePortCode({ port: unfoundValue }) : '',
    },
    skip: !canSearch || !unfoundValue,
    ...opts,
  })

  useQuery<PortsQuery, PortsQueryVariables>(portsQuery, {
    variables: {
      keyword: searchValue,
    },
    skip: !canSearch || searchValue.length < 2,
    ...opts,
  })

  return { ports, setPorts }
}

function parseRemotePorts({
  oldOptions,
  newPorts,
}: {
  oldOptions: PortOption[]
  newPorts: Port[]
}): PortOption[] {
  return newPorts
    .filter(
      (newPort) =>
        !oldOptions.some(
          (oldOption) => `${newPort.transportType}-${newPort.code}` === oldOption.value
        )
    )
    .map(portOptionFromPort)
}
