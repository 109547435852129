// @flow
import * as React from 'react'

import type { ShipmentPayload } from '@graphql/server/flow'

import PartnerPermissionsWrapper from 'components/PartnerPermissionsWrapper'
import { SHIPMENT_GET } from 'modules/permission/constants/shipment'

import HorizontalDates from './HorizontalDates'
import HorizontalPortNames from './HorizontalPortNames'
import HorizontalTimeline from './HorizontalTimeline'
import { HorizontalLayoutWrapperStyle } from './style'

type Props = {|
  shipment: ShipmentPayload,
  navigable: boolean,
|}

const HorizontalLayout = ({ shipment, navigable }: Props): React.Node => {
  return (
    <PartnerPermissionsWrapper data={shipment}>
      {(permissions) => (
        <div className={HorizontalLayoutWrapperStyle}>
          <HorizontalPortNames shipment={shipment} />
          <HorizontalTimeline
            shipment={shipment}
            navigable={{
              form: navigable && permissions.some((p) => SHIPMENT_GET.includes(p)),
            }}
          />
          <HorizontalDates shipment={shipment} />
        </div>
      )}
    </PartnerPermissionsWrapper>
  )
}

export default HorizontalLayout
