// @flow
export const ORDER_ITEMS_FORM = 'order.orderItems.form'
export const ORDER_ITEMS_UPDATE = 'order.orderItems.update'
export const ORDER_ITEMS_DELETE = 'order.orderItems.delete'
export const ORDER_ITEMS_CREATE = 'order.orderItems.create'
export const ORDER_ITEMS_GET_PRICE = 'order.orderItems.getPrice'
export const ORDER_ITEMS_BATCHES_LIST = 'order.orderItemBatches.list'
export const ORDER_ITEMS_SET_NO = 'order.orderItems.setNo'
export const ORDER_ITEMS_SET_QUANTITY = 'order.orderItems.setQuantity'
export const ORDER_ITEMS_SET_PRICE = 'order.orderItems.setPrice'
export const ORDER_ITEMS_SET_DELIVERY_DATE = 'order.orderItems.setDeliveryDate'
export const ORDER_ITEMS_SET_CUSTOM_FIELDS = 'order.orderItems.setCustomFields'
export const ORDER_ITEMS_SET_CUSTOM_FIELDS_MASK = 'order.orderItems.setCustomFieldsMask'
export const ORDER_ITEMS_SET_TAGS = 'order.orderItems.setTags'
export const ORDER_ITEMS_SET_MEMO = 'order.orderItems.setMemo'
export const ORDER_ITEMS_SET_FOLLOWERS = 'order.orderItems.setFollowers'
export const ORDER_ITEMS_DOCUMENT_GET = 'order.orderItemsFiles.get'
export const ORDER_ITEMS_DOCUMENT_FORM = 'order.orderItemsFiles.form'
export const ORDER_ITEMS_DOCUMENT_EDIT = 'order.orderItemsFiles.edit'
export const ORDER_ITEMS_DOCUMENT_DOWNLOAD = 'order.orderItemsFiles.download'
export const ORDER_ITEMS_DOCUMENT_DELETE = 'order.orderItemsFiles.delete'
