// @flow
import { parseEnumField, parseFilesField, parseMemoField, parseTagsField } from '@utils/data'

import { toLowerFirst } from 'utils/string'

export const prepareParsedDocumentInput = (originalValues: ?Object, newValues: Object): Object => {
  // entity id is not used but may need in future
  return {
    ...parseEnumField('type', originalValues?.type, newValues.type),
    // ...parseParentIdField('entityId', originalValues?.entity, newValues.entity),
    ...parseMemoField('memo', originalValues?.memo, newValues.memo),
    ...parseTagsField('tags', originalValues?.tags ?? [], newValues.tags),
  }
}

export const prepareParsedDocumentParentInput = (
  newValues: Object,
  originalEntity: Object // the original unchanged entity
): Object => {
  const loweredTypename = toLowerFirst(newValues?.entity?.__typename)

  const entity = newValues[loweredTypename]

  if (!entity) {
    return null
  }

  let input = {}

  switch (newValues?.entity?.__typename) {
    case 'ProductProvider': {
      const newFiles = newValues.productProvider.files.map((file) => ({
        id: file.id,
        name: file.name,
        type: file.type,
      }))

      input = {
        id: originalEntity.product.id, // product id
        input: {
          productProviders: originalEntity.product.productProviders.map((productProvider) => {
            if (productProvider.id === newValues.productProvider.id) {
              const oldProductProviderFiles = productProvider.files.map((file) => ({
                id: file.id,
              }))

              return {
                id: productProvider.id,
                files: [...oldProductProviderFiles, ...newFiles],
              }
            }

            return {
              id: productProvider.id,
            }
          }),
        },
      }
      break
    }
    case 'Shipment':
      input = {
        id: entity.id,
        input: parseFilesField({
          key: 'files',
          originalFiles: originalEntity[loweredTypename].files,
          newFiles: entity.files,
          isNewFormat: true,
        }),
      }
      break
    default: {
      const oldFiles = originalEntity[loweredTypename].files.map((file) => ({
        id: file.id,
      }))

      const newFiles = entity.files.map((file) => {
        return {
          id: file.id,
          name: file.name,
          type: file.type,
        }
      })

      input = {
        id: entity.id,
        input: {
          files: [...oldFiles, ...newFiles],
        },
      }
    }
  }

  return input
}
