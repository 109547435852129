// @flow
import { lowerFirst } from 'lodash'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Tag from 'components/Tag'
import { getByPath, getByPathWithDefault } from 'utils/fp'

import EntityIdentifier from './components/EntityIdentifier'
import Field from './components/Field'
import User from './components/User'
import Value from './components/Value'
import {
  ARCHIVED,
  CREATE,
  DOCUMENTS,
  FORWARDERS,
  REVISE_DATE,
  TAGS,
  UN_REVISE_DATE,
  UNARCHIVED,
  UPDATE_FIELD,
} from './constants'
import messages from './messages'
import type { LogItem } from './types'

export type LogFormatter = (log: LogItem) => *

type Props = { actionOnly?: boolean }

export const getCreateFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    let message = null
    let values = {
      user: <User user={log.createdBy} />,
    }

    if (log.entityType !== log.parentEntityType) {
      message = messages.createChild
      values = {
        ...values,
        child: <EntityIdentifier log={log} />,
      }
    } else {
      message = messages.create
      values = {
        ...values,
        entityType: <FormattedMessage {...messages[log.entityType]} />,
      }
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getUpdateFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    let message = null
    let values = {
      user: <User user={log.createdBy} />,
      field: (
        // { field: log.parameters.field.string, entityType: log.parameters.entity_type.string }
        <Field field={log.parameters.field.string} entityType={log.parameters.entity_type.string} />
      ),
    }

    if (log.parameters.old === null) {
      message = log.entityType === log.parentEntityType ? messages.setField : messages.setChildField
      values = {
        ...values,
        value: <Value value={log.parameters.new} entityType={log.parameters.entity_type.string} />,
      }
    } else if (log.parameters.new === null) {
      message =
        log.entityType === log.parentEntityType ? messages.clearField : messages.clearChildField
      values = {
        ...values,
        value: <Value value={log.parameters.old} entityType={log.parameters.entity_type.string} />,
      }
    } else {
      message =
        log.entityType === log.parentEntityType ? messages.updateField : messages.updateChildField

      // For shipment auto-calculation
      if (
        [
          'totalWeightOverriding',
          'totalVolumeOverriding',
          'totalPackageQuantityOverriding',
        ].includes(log.parameters.field.string)
      ) {
        const oldTranslationKey = log.parameters.old.boolean ? 'manualInput' : 'autoCalculate'
        const newTranslationKey = log.parameters.new.boolean ? 'manualInput' : 'autoCalculate'
        values = {
          ...values,
          oldValue: (
            <Value
              value={log.parameters.old}
              translationKey={oldTranslationKey}
              entityType={log.parameters.entity_type.string}
            />
          ),
          newValue: (
            <Value
              value={log.parameters.new}
              translationKey={newTranslationKey}
              entityType={log.parameters.entity_type.string}
            />
          ),
        }
      } else {
        values = {
          ...values,
          oldValue: (
            <Value value={log.parameters.old} entityType={log.parameters.entity_type.string} />
          ),
          newValue: (
            <Value value={log.parameters.new} entityType={log.parameters.entity_type.string} />
          ),
        }
      }
    }

    if (log.entityType !== log.parentEntityType) {
      values = {
        ...values,
        child: <EntityIdentifier log={log} />,
      }
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getArchivedFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    let message = null
    let values = {
      user: <User user={log.createdBy} />,
    }

    if (log.entityType !== log.parentEntityType) {
      message = messages.archivedChild
      values = {
        ...values,
        child: <EntityIdentifier log={log} />,
      }
    } else {
      message = messages.archived
      values = {
        ...values,
        entityType: <FormattedMessage {...messages[log.entityType]} />,
      }
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getUnarchivedFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    let message = null
    let values = {
      user: <User user={log.createdBy} />,
    }

    if (log.entityType !== log.parentEntityType) {
      message = messages.unarchivedChild
      values = {
        ...values,
        child: <EntityIdentifier log={log} />,
      }
    } else {
      message = messages.unarchived
      values = {
        ...values,
        entityType: <FormattedMessage {...messages[log.entityType]} />,
      }
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getTagsFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    const added = getByPathWithDefault([], 'parameters.added.values', log)
    const removed = getByPathWithDefault([], 'parameters.removed.values', log)

    let message = null
    let values = {
      user: <User user={log.createdBy} />,
      added: (
        <>
          {added.map((v) => (
            <React.Fragment key={getByPath('entity.id', v)}>
              <Tag tag={v.entity} />{' '}
            </React.Fragment>
          ))}
        </>
      ),
      addedCount: added.length,
      removed: (
        <>
          {removed.map((v) => (
            <React.Fragment key={getByPath('entity.id', v)}>
              <Tag tag={v.entity} />{' '}
            </React.Fragment>
          ))}
        </>
      ),
      removedCount: removed.length,
    }

    if (log.entityType !== log.parentEntityType) {
      values = {
        ...values,
        child: <EntityIdentifier log={log} />,
      }
    }

    if (added.length > 0 && removed.length > 0) {
      message =
        log.entityType !== log.parentEntityType
          ? messages.addedAndRemovedTagsChild
          : messages.addedAndRemovedTags
    } else if (added.length > 0) {
      message =
        log.entityType !== log.parentEntityType ? messages.addedTagsChild : messages.addedTags
    } else {
      message =
        log.entityType !== log.parentEntityType ? messages.removedTagsChild : messages.removedTags
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getForwardersFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    const added = getByPathWithDefault([], 'parameters.added.values', log)
    const removed = getByPathWithDefault([], 'parameters.removed.values', log)

    let message = null
    const values = {
      user: <User user={log.createdBy} />,
      added: (
        <>
          {added.map((v) => (
            <Value value={v.entity?.partner?.name || v.entity?.name} />
          ))}
        </>
      ),
      addedCount: added.length,
      removed: (
        <>
          {removed.map((v) => (
            <Value value={v.entity?.partner?.name || v.entity?.name} />
          ))}
        </>
      ),
      removedCount: removed.length,
      field: <Field field="forwarder" entityType={log.parameters.entity_type.string} />,
    }

    if (added.length > 0 && removed.length > 0) {
      message = messages.addedAndRemovedForwarders
    } else if (added.length > 0) {
      message = messages.addedForwarders
    } else {
      message = messages.removedForwarders
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getDocumentsFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    let message = null
    let values = {
      user: <User user={log.createdBy} />,
    }

    if (log.parameters.old === null) {
      const childEntityType = lowerFirst(log.parameters.new.entity.__typename)
      message =
        log.parentEntityType === childEntityType
          ? messages.addedDocument
          : messages.addedDocumentChild
      values = {
        ...values,
        child: <Value value={log.parameters.new} />,
      }
    } else {
      const childEntityType = lowerFirst(log.parameters.old.entity.__typename)
      message =
        log.parentEntityType === childEntityType
          ? messages.removedDocument
          : messages.removedDocumentChild
      values = {
        ...values,
        child: <Value value={log.parameters.old} />,
      }
    }

    values = {
      ...values,
      document: <EntityIdentifier log={log} />,
      documentType: (
        <Value
          value={log.parameters.document_type}
          entityType={log.parameters.entity_type.string}
        />
      ),
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getReviseDateFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    const message = messages.reviseDateChild
    const values = {
      user: <User user={log.createdBy} />,
      child: <EntityIdentifier log={log} />,
      date: <Value value={log.parameters.date} />,
      reviseType: <Value value={log.parameters.revise_type} />,
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

export const getUnReviseDateFormatter =
  ({ actionOnly }: Props): ((log: LogItem) => any) =>
  (log: LogItem): * => {
    const message = messages.unReviseDateChild
    const values = {
      user: <User user={log.createdBy} />,
      child: <EntityIdentifier log={log} />,
      reviseType: <Value value={log.parameters.revise_type} />,
    }

    return actionOnly ? message.action : <FormattedMessage {...message} values={values} />
  }

const getDefaultFormatters = ({ actionOnly = false }: Props = {}): ({|
  archived: (log: LogItem) => any,
  create: (log: LogItem) => any,
  documents: (log: LogItem) => any,
  forwarders: (log: LogItem) => any,
  revise_date: (log: LogItem) => any,
  tags: (log: LogItem) => any,
  un_revise_date: (log: LogItem) => any,
  unarchived: (log: LogItem) => any,
  update_field: (log: LogItem) => any,
|}) => ({
  [CREATE]: getCreateFormatter({ actionOnly }),
  [UPDATE_FIELD]: getUpdateFormatter({ actionOnly }),
  [ARCHIVED]: getArchivedFormatter({ actionOnly }),
  [UNARCHIVED]: getUnarchivedFormatter({ actionOnly }),
  [TAGS]: getTagsFormatter({ actionOnly }),
  [DOCUMENTS]: getDocumentsFormatter({ actionOnly }),
  [FORWARDERS]: getForwardersFormatter({ actionOnly }),
  [REVISE_DATE]: getReviseDateFormatter({ actionOnly }),
  [UN_REVISE_DATE]: getUnReviseDateFormatter({ actionOnly }),
})

export default getDefaultFormatters
