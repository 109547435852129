// @flow strict
import * as React from 'react'
import { useCallback, useLayoutEffect, useState } from 'react'

import { Tooltip } from 'components/Tooltip'
import { getIsOverflowing } from 'utils/getIsOverflowing'

import { EntityLabelStyle } from './style'
import type { Entities } from './types'

type Props = {|
  name: string,
  entity: Entities,
|}

// TODO: Merge overflow design with EntityName
const EntityLabel = ({ name, entity }: Props): React.Node => {
  const [node, setNode] = useState<?HTMLDivElement>()
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const ref = useCallback<(React.ElementRef<'div'> | null) => void>((_node) => {
    if (_node !== null) {
      setNode(_node)
      setIsOverflowing(getIsOverflowing(_node))
    }
  }, [])

  useLayoutEffect(() => {
    const measure = () => {
      if (node) setIsOverflowing(getIsOverflowing(node))
    }

    window.addEventListener('resize', measure)

    return () => {
      window.removeEventListener('resize', measure)
    }
  }, [node])

  return (
    <Tooltip distance={-60} enabled={isOverflowing} message={name}>
      <div className={EntityLabelStyle({ entity })} ref={ref}>
        {name}
      </div>
    </Tooltip>
  )
}

export default EntityLabel
