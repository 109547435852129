// @flow
import { intersection } from 'lodash'
import { useCallback, useContext } from 'react'

import QueryFormPermissionContext from 'components/common/QueryForm/context'
import { useViewerHasPermissions, type HasPermissions } from 'contexts/Permissions'

/**
 *  Grab the owner permission or partner permission
 *
 * @param {boolean} isOwner
 *
 * @deprecated This hook use a deprecated form permission context, Use instead the hook useHasPermissions directly
 */
const usePermission = (
  isOwner: boolean = true
): ({|
  hasPermission: HasPermissions | ((checkPermission: string | string[]) => boolean),
|}) => {
  const { permissions: partnerPermissions } = useContext(QueryFormPermissionContext)
  const hasPermissionViewer = useViewerHasPermissions()

  const hasPermissionPartner = useCallback(
    (checkPermission: string | string[]) => {
      if (Array.isArray(checkPermission)) {
        return intersection(partnerPermissions, checkPermission).length > 0
      }
      return partnerPermissions.includes(checkPermission)
    },
    [partnerPermissions]
  )

  return {
    hasPermission: isOwner ? hasPermissionViewer : hasPermissionPartner,
  }
}

export default usePermission
