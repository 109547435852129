// @flow
import { Router } from '@reach/router'
import type { ComponentType, StatelessFunctionalComponent } from 'react'
import React, { lazy, Suspense } from 'react'
import { hot } from 'react-hot-loader'

import SideBar from '@modules/SideBar'
import { useSideBar } from '@modules/SideBar/hooks/useSideBar'

import { useAuthenticated, useViewer } from 'contexts/Auth'
import lazyRetry from 'utils/lazyRetry'

import Authorized from './components/Authorized'
import { Layout } from './components/Layout'
import LoadingIcon from './components/LoadingIcon'
import Page from './components/Page'
import PageNotFound from './components/PageNotFound'
import DashBoard from './modules/dashboard'

const AsyncLogin = lazy(() => lazyRetry(() => import('./modules/login')))
const AsyncForgotPassword = lazy(() => lazyRetry(() => import('./modules/forgotPassword')))
const AsyncResetPassword = lazy(() => lazyRetry(() => import('./modules/resetPassword')))
const AsyncOrder = lazy(() => lazyRetry(() => import('./modules/order')))
const AsyncOrderItem = lazy(() => lazyRetry(() => import('./modules/orderItem')))
const AsyncTags = lazy(() => lazyRetry(() => import('./modules/tags')))
const AsyncUser = lazy(() => lazyRetry(() => import('./modules/user')))
const AsyncPartner = lazy(() => lazyRetry(() => import('./modules/partner')))
const AsyncWarehouse = lazy(() => lazyRetry(() => import('./modules/warehouse')))
const AsyncShipment = lazy(() => lazyRetry(() => import('./modules/shipment')))
const AsyncContainer = lazy(() => lazyRetry(() => import('./modules/container')))
const AsyncProduct = lazy(() => lazyRetry(() => import('./modules/product')))
const AsyncBatch = lazy(() => lazyRetry(() => import('./modules/batch')))
const AsyncReminders = lazy(() => lazyRetry(() => import('./modules/reminders')))
const AsyncNotifications = lazy(() => lazyRetry(() => import('./modules/notifications')))
const AsyncMetadata = lazy(() => lazyRetry(() => import('./modules/metadata')))
const AsyncTableTemplate = lazy(() => lazyRetry(() => import('./modules/tableTemplate')))
const AsyncProfile = lazy(() => lazyRetry(() => import('./modules/profile')))
const AsyncDocument = lazy(() => lazyRetry(() => import('./modules/document')))

const Routes: StatelessFunctionalComponent<{}> = () => {
  const { authenticated } = useAuthenticated()
  const { user } = useViewer()

  const { isSideBarExpanded, toggleSideBarExpansion } = useSideBar()

  return (
    <>
      {authenticated && user && (
        <SideBar
          isSideBarExpanded={isSideBarExpanded}
          onSideBarToggleClick={toggleSideBarExpansion}
        />
      )}
      <Suspense fallback={<LoadingIcon />}>
        <Router>
          <Authorized path="/">
            <Layout
              isSideBarExpanded={isSideBarExpanded}
              toggleSideBarExpansion={toggleSideBarExpansion}
              path="/"
            >
              <DashBoard path="/" default />
              <Page Component={AsyncOrder} path="order/*" />
              <Page Component={AsyncOrderItem} path="order-item/*" />
              <Page Component={AsyncBatch} path="batch/*" />
              <Page Component={AsyncShipment} path="shipment/*" />
              <Page Component={AsyncContainer} path="container/*" />
              <Page Component={AsyncProduct} path="product/*" />
              <Page Component={AsyncWarehouse} path="warehouse/*" />
              <Page Component={AsyncPartner} path="partner/*" />
              <Page Component={AsyncTags} path="tags/*" />
              <Page Component={AsyncMetadata} path="templates/metadata/*" />
              <Page Component={AsyncTableTemplate} path="templates/table-template/*" />
              <Page Component={AsyncDocument} path="document/*" />
              <AsyncUser path="staff/*" />
              <AsyncProfile path="profile/*" />
              <AsyncReminders path="reminders/*" />
              <AsyncNotifications path="notifications/*" />
            </Layout>
          </Authorized>
          <AsyncLogin path="/login" />
          <AsyncForgotPassword path="/reset-password" />
          <AsyncResetPassword path="/reset-password/:token" />
          <PageNotFound path="/403" />
        </Router>
      </Suspense>
    </>
  )
}

const HotReloadRoutes: ComponentType<any> = hot(module)(Routes)
export default HotReloadRoutes
