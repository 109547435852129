// @flow
export const BATCH_FORM = 'batch.batches.form'
export const BATCH_CREATE = 'batch.batches.create'
export const BATCH_UPDATE = 'batch.batches.update'
export const BATCH_DELETE = 'batch.batches.delete'
export const BATCH_SET_ARCHIVED = 'batch.batches.setArchived'
export const BATCH_SET_NO = 'batch.batches.setNo'
export const BATCH_SET_QUANTITY = 'batch.batches.setQuantity'
export const BATCH_SET_DELIVERY_DATE = 'batch.batches.setDeliveredAt'
export const BATCH_SET_DESIRED_DATE = 'batch.batches.setDesiredAt'
export const BATCH_SET_EXPIRY = 'batch.batches.setExpiredAt'
export const BATCH_SET_PRODUCTION_DATE = 'batch.batches.setProducedAt'
export const BATCH_SET_CUSTOM_FIELDS = 'batch.batches.setCustomFields'
export const BATCH_SET_CUSTOM_FIELDS_MASK = 'batch.batches.setCustomFieldsMask'
export const BATCH_SET_TAGS = 'batch.batches.setTags'
export const BATCH_SET_MEMO = 'batch.batches.setMemo'
export const BATCH_SET_ORDER_ITEM = 'batch.batches.setOrderItem'
export const BATCH_SET_SHIPMENT = 'batch.batches.setShipment'
export const BATCH_SET_CONTAINER = 'batch.batches.setContainer'
export const BATCH_SET_PACKAGE_NAME = 'batch.batches.setPackageName'
export const BATCH_SET_PACKAGE_CAPACITY = 'batch.batches.setPackageCapacity'
export const BATCH_SET_PACKAGE_QUANTITY = 'batch.batches.setPackageQuantity'
export const BATCH_SET_PACKAGE_WEIGHT = 'batch.batches.setPackageWeight'
export const BATCH_SET_PACKAGE_VOLUME = 'batch.batches.setPackageVolume'
export const BATCH_SET_PACKAGE_SIZE = 'batch.batches.setPackageSize'
export const BATCH_SET_FOLLOWERS = 'batch.batches.setFollowers'
export const BATCH_ORDERS_LIST = 'batch.orders.list'
