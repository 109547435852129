import styled from 'styled-components'

import { colors, gradients, scrollbars, transitions } from '@styles/common'

interface SideBarProps {
  isSideBarExpanded?: boolean
}

export const SideBarWrapper = styled.div<SideBarProps>`
  @keyframes gradient {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  position: fixed;
  top: 0;
  z-index: 99;
  user-select: none;
  overflow: hidden;
  height: 100vh;
  width: ${({ isSideBarExpanded }) => (isSideBarExpanded ? '200px' : '50px')};
  background: ${gradients.BLUE_TEAL_DIAGONAL};
  background-size: 400% 400%;
  animation: gradient 60s ease infinite;
  transition: ${transitions.EXPAND};
  &:hover {
    box-shadow: 5px 0 15px rgba(110, 77, 77, 0.2);
    width: 200px;
  }
`

export const SideBarMenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow-x: hidden;
  overflow-y: overlay;
  ${scrollbars.SMALL_WHITE}

  & > a {
    color: ${colors.WHITE};

    &:hover {
      color: ${colors.YELLOW_DARK};
    }
  }
`
