import styled from 'styled-components'

import { presets, transitions } from '@styles/common'

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`

export const LogoButtonWrapper = styled.div`
  ${presets.BUTTON};
  height: 100%;
`

export const IconImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  width: 50px;
  & > img {
    height: 30px;
  }
`

export const LogoImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 120px;
  & > img {
    height: 18px;
  }
`

export const ToggleButton = styled.button`
  ${presets.BUTTON}
  font-size: 16px;
  margin: 0 10px 0 auto;
  &:hover {
    & > svg {
      color: #ddd;
    }
  }
  & > svg {
    ${transitions.MAIN};
  }
  & > .fa-toggle-off {
    color: #eee;
  }
  & > .fa-toggle-on {
    color: #fff;
  }
`
