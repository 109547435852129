// @flow
import * as React from 'react'

import Icon from 'components/Icon'
import type { LogItem } from 'modules/timeline/types'
import { colors } from 'styles/common'

import { IdentifierStyle } from './style'

type Props = {
  log: LogItem,
}

const EntityIdentifier = ({ log }: Props): React.Element<'span'> => {
  let entityType = log.parameters.entity_type.string.toUpperCase()
  switch (entityType) {
    case 'VOYAGE':
    case 'CONTAINER_GROUP':
    case 'TIMELINE_DATE':
    case 'TIMELINE_DATE_REVISION':
      entityType = 'SHIPMENT'
      break
    case 'FILE':
      entityType = 'DOCUMENT'
      break
    default:
      break
  }

  return (
    // $FlowIssue Flow thinks that entityType could be "VOYAGE"
    <span className={IdentifierStyle(colors[entityType])}>
      <Icon icon={entityType} />
      {log.parameters.entity_identifier.string}
    </span>
  )
}

export default EntityIdentifier
