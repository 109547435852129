// @flow strict

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import BaseCard from 'components/Cards/BaseCard'
import { Display, Label } from 'components/Form'
import useUser from 'hooks/useUser'

import type { FilterInputProps } from '../../types'

import { CardStyle } from './style'

const ConnectionId = ({ value }: FilterInputProps<string[]>): React.Node => {
  const { connections } = useUser()

  const connection = connections.find((c) => value.some((cId) => c.id === cId))

  return (
    <>
      <Label height="30px">
        <FormattedMessage id="components.NavBar.Filter.connections" />
      </Label>
      <BaseCard
        icon="CONNECTION"
        color="CONNECTION"
        wrapperClassName={CardStyle}
        key={connection?.id}
        readOnly
      >
        <Display height="30px">{connection?.displayName}</Display>
      </BaseCard>
    </>
  )
}

export default ConnectionId
