// @flow

import { navigate } from '@reach/router'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import apolloClient from '@apollo-client'

import LogoutDialog from 'components/Dialog/LogoutDialog'
import Icon from 'components/Icon'
import { AuthenticatedConsumer } from 'contexts/Auth'
import Import from 'modules/import'
import messages from 'modules/userNavbar/messages'
import { logOutMutation } from 'modules/userNavbar/mutation'
import emitter from 'utils/emitter'
import { isEnableBetaFeature } from 'utils/env'

import {
  UserMenuDropDownWrapperStyle,
  UserMenuItemIconStyle,
  UserMenuItemStyle,
  UserMenuItemWrapperStyle,
} from './style'

type Props = {
  isOpen: boolean,
  toggleUserMenu: Function,
}

const UserMenuDropdown = ({ isOpen, toggleUserMenu }: Props): React.Element<'div'> => {
  const [importOpen, setImportOpen] = React.useState(false)

  return (
    <div className={UserMenuDropDownWrapperStyle(isOpen)}>
      <BooleanValue>
        {({ value: isLogoutDialogOpen, set: logoutDialogToggle }) => (
          <>
            <button
              className={UserMenuItemWrapperStyle}
              onClick={(evt) => {
                if (!evt.ctrlKey && !evt.shiftKey) {
                  evt.preventDefault()
                  emitter.emit('NAVIGATE_TO', '/profile')
                } else {
                  navigate('/profile')
                }
                toggleUserMenu()
              }}
              type="button"
            >
              <div className={UserMenuItemStyle}>
                <FormattedMessage {...messages.profile} />
              </div>
              <div className={UserMenuItemIconStyle}>
                <Icon icon="PROFILE" />
              </div>
            </button>

            <button
              className={UserMenuItemWrapperStyle}
              onClick={(evt) => {
                if (!evt.ctrlKey && !evt.shiftKey) {
                  evt.preventDefault()
                  emitter.emit('NAVIGATE_TO', '/reminders')
                } else {
                  navigate('/reminders')
                }
                toggleUserMenu()
              }}
              type="button"
            >
              <div className={UserMenuItemStyle}>
                <FormattedMessage {...messages.reminders} />
              </div>
              <div className={UserMenuItemIconStyle}>
                <Icon icon="CLOCK" />
              </div>
            </button>

            {isEnableBetaFeature && (
              <button
                className={UserMenuItemWrapperStyle}
                onClick={() => {
                  toggleUserMenu()
                  setImportOpen(true)
                }}
                type="button"
              >
                <div className={UserMenuItemStyle}>
                  <FormattedMessage {...messages.import} />
                </div>
                <div className={UserMenuItemIconStyle}>
                  <Icon icon="IMPORT" />
                </div>
              </button>
            )}

            <button
              className={UserMenuItemWrapperStyle}
              onClick={() => {
                logoutDialogToggle(true)
                toggleUserMenu()
              }}
              data-testid="logout-button"
              type="button"
            >
              <div className={UserMenuItemStyle}>
                <FormattedMessage {...messages.logout} />
              </div>
              <div className={UserMenuItemIconStyle}>
                <Icon icon="LOGOUT" />
              </div>
            </button>

            <AuthenticatedConsumer>
              {({ setAuthenticated }) => (
                <Mutation
                  mutation={logOutMutation}
                  onCompleted={() => {
                    if (window.localStorage) {
                      window.localStorage.clear()
                    }
                    setAuthenticated(false)
                    // clear all cache after logout, must stop and then clearStore, allow time for clearStore
                    // https://github.com/apollographql/apollo-client/issues/3766#issuecomment-578075556
                    apolloClient.stop()
                    setTimeout(() => apolloClient.clearStore(), 500)
                  }}
                >
                  {(logout) => (
                    <LogoutDialog
                      isOpen={isLogoutDialogOpen}
                      onRequestClose={() => logoutDialogToggle(false)}
                      onCancel={() => logoutDialogToggle(false)}
                      onConfirm={async () => {
                        await logout({})
                        navigate('/login')
                      }}
                    />
                  )}
                </Mutation>
              )}
            </AuthenticatedConsumer>
          </>
        )}
      </BooleanValue>

      <Import open={importOpen} onRequestClose={() => setImportOpen(false)} />
    </div>
  )
}

export default UserMenuDropdown
