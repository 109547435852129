// @flow
import type { ApolloClient } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import type { IntlShape, MessageDescriptor } from 'react-intl'

import apolloClient from '@apollo-client'
import exportTemplatesQuery from '@graphql/client/tableTemplate/query.exportTemplates.graphql'
import type {
  DefaultFilterInput,
  DefaultSortInput,
  Scalars,
  ExportType,
} from '@graphql/server/flow'

import type { Option } from 'components/Buttons/BaseExportButton'
import BaseExportButton from 'components/Buttons/BaseExportButton'
import { getByPathWithDefault } from 'utils/fp'

const extensionIcons = {
  xls: 'EXCEL',
  xlsx: 'EXCEL',
  csv: 'CSV',
}

const exportOptionLabels: MessageDescriptor[] = [
  {
    id: 'components.button.export.all',
    defaultMessage: 'Export all',
  },
  {
    id: 'components.button.export.selected',
    defaultMessage: 'Export selected',
  },
]

export type Variables = {
  filterBy?: { ...DefaultFilterInput, [key: string]: any },
  sortBy?: { ...DefaultSortInput, [key: string]: any },
  [key: string]: any,
}

type Props = {
  label?: React.Node,
  disabled?: boolean,
  selectedIds?: Scalars['ID'][],
  variables?: Variables,
  type: ExportType,
  exportQuery: GraphQL$DocumentNode,
  intl: IntlShape,
}

const defaultProps = {
  label: (<FormattedMessage id="components.button.export" defaultMessage="EXPORT" />: React.Node),
  disabled: false,
  selectedIds: ([]: Scalars['ID'][]),
  variables: ({}: Variables),
}

function ExportButton({
  label,
  disabled,
  type,
  exportQuery,
  selectedIds,
  variables,
  intl,
}: Props): React.Node {
  const getVariables = React.useCallback(
    (isExportAll: boolean): Variables => {
      // Export whatever is shown
      if (isExportAll) {
        return { ...variables }
      }

      // Export based on selected Ids
      return {
        ...variables,
        filterBy: {
          ...variables?.filterBy,
          ids: selectedIds, // Overwrites 'ids' filter with selected ids
        },
      }
    },
    [variables, selectedIds]
  )

  return (
    <BaseExportButton
      client={apolloClient}
      label={label}
      disabled={disabled}
      exportQuery={exportQuery}
      selectedIds={selectedIds}
      getVariables={getVariables}
      onLoad={(client: ApolloClient<any>) =>
        client
          // $FlowFixMe: For flow, variables in not valid
          .query({
            query: exportTemplatesQuery,
            variables: {
              filterBy: {
                type,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            const templates = getByPathWithDefault([], 'exportTemplates', data)

            if (type === 'Batches') {
              return templates.reduce((accumulator, template) => {
                // Generate multiple export options
                const exportOptions = exportOptionLabels.map((exportOptionLabel): Option => {
                  const exportOptionIntl = intl.formatMessage(exportOptionLabel)
                  const isExportAll = exportOptionLabel.id === exportOptionLabels[0].id

                  return {
                    name: `${exportOptionIntl} ${template.name}`,
                    type: isExportAll ? 'all' : 'selected',
                    icon: extensionIcons[template.extension] || 'FILE',
                    variables: {
                      templateId: template.id,
                    },
                  }
                })

                return [...accumulator, ...exportOptions]
              }, [])
            }

            return templates.map((template) => ({
              name: `${template.name}`,
              type: 'all',
              icon: extensionIcons[template.extension] || 'FILE',
              variables: {
                templateId: template.id,
              },
            }))
          })
      }
    />
  )
}

ExportButton.defaultProps = defaultProps

export default (injectIntl(ExportButton): React.ComponentType<$Diff<Props, { intl: * }>>)
