// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  newDocument: {
    id: 'components.Form.DocumentsUpload.newDocument',
    defaultMessage: 'Upload Documents',
  },
  dragAndDrop: {
    id: 'components.Form.DocumentsUpload.dragAndDrop',
    defaultMessage: 'You can also upload files by dragging and dropping them inside',
  },
  noDocuments: {
    id: 'components.Form.DocumentsUpload.noDocuments',
    defaultMessage: 'No documents found',
  },
  noMessageGroups: {
    id: 'components.Form.DocumentsUpload.noMessageGroups',
    defaultMessage: 'No message groups',
  },
  selectAll: {
    id: 'components.Documents.selectAll',
    defaultMessage: 'SELECT ALL',
  },
  selectMultiple: {
    id: 'components.Documents.selectMultiple',
    defaultMessage: 'SELECT MULTIPLE',
  },
  deleteSelected: {
    id: 'components.Documents.deleteSelected',
    defaultMessage: 'DELETE SELECTED',
  },
  downloadSelected: {
    id: 'components.Documents.downloadSelected',
    defaultMessage: 'DOWNLOAD SELECTED',
  },
  downloadZip: {
    id: 'components.Documents.downloadZip',
    defaultMessage: 'DOWNLOAD ZIP',
  },
  downloadAll: {
    id: 'components.Documents.downloadAll',
    defaultMessage: 'DOWNLOAD ALL',
  },
  deleteAll: {
    id: 'components.Documents.deleteAll',
    defaultMessage: 'DELETE ALL',
  },
  addGroup: {
    id: 'components.Documents.addGroup',
    defaultMessage: 'ADD GROUP',
  },
  addPartnerGroup: {
    id: 'components.Documents.addPartnerGroup',
    defaultMessage: 'ADD PARTNER GROUP',
  },
  deleteAllFilesConfirmation: {
    id: 'components.Documents.deleteAllFilesConfirmation',
    defaultMessage: 'Do you want to delete all the files?',
  },
  public: {
    id: 'components.Documents.public',
    defaultMessage: 'Public',
  },
  private: {
    id: 'components.Documents.private',
    defaultMessage: 'Private',
  },
  uploadFile: {
    id: 'components.Documents.uploadFile',
    defaultMessage: 'Upload file',
  },
}): any)
