import styled from 'styled-components'

import { borderRadiuses, colors, fontSizes } from '@styles/common'

export const BadgeWrapperStyle = styled.div`
  color: ${colors.BLACK};
  border-radius: ${borderRadiuses.MAIN_INSIDE};
  background-color: ${colors.GRAY_SUPER_LIGHT};
  font-size: ${fontSizes.TINY};
  padding: 2px 4px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

export default BadgeWrapperStyle
