// @flow
import type { Value } from '@graphql/server/flow'

export const printValue = (value: Value): string => {
  if (value.__typename === 'StringValue') return value.string
  if (value.__typename === 'IntValue') return value.int.toString()
  if (value.__typename === 'FloatValue') return value.float.toString()
  if (value.__typename === 'BooleanValue') return value.boolean.toString()
  if (value.__typename === 'DateTimeValue') return new Date(value.datetime).toLocaleDateString()
  if (value.__typename === 'IntervalValue') return JSON.stringify(value.interval)
  if (value.__typename === 'DurationValue') return `${value.duration.seconds}s`
  if (value.__typename === 'MetricValueValue')
    return `${value.metricValue.value} ${value.metricValue.metric}`
  if (value.__typename === 'SizeValue') return JSON.stringify(value.size)
  if (value.__typename === 'EntityValue') return value.entity?.__typename || ''
  if (value.__typename === 'StringValue') return value.string
  if (value.__typename === 'Values') return JSON.stringify(value.values)
  return ''
}

export default printValue
