// @flow

import * as React from 'react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import type { MessageGroup } from '@graphql/server/flow'

import { useChat } from 'modules/chat/hooks/useChat'
import messages from 'modules/chat/messages'
import { ChatListContainerStyle } from 'modules/chat/style'

import AddPartnerGroupButton from '../AddPartnerGroupButton'
import ChatListItem from '../ChatListItem'

type Props = {
  items: MessageGroup[],
  allPartnerUnreadCount: number,
  onListItemClick: (string) => void,
  onListItemIconClick?: (string) => void,
}

const ChatList = ({
  items,
  allPartnerUnreadCount,
  onListItemClick,
  onListItemIconClick,
}: Props): React.Node => {
  const { entityId, entityType, selectedGroupId, relatedPartners } = useChat()
  const intl = useIntl()

  const [chatGroups, setChatGroups] = React.useState(items)

  useEffect(() => {
    setChatGroups(items)
  }, [items, allPartnerUnreadCount])

  return (
    <>
      <div className={ChatListContainerStyle}>
        <ChatListItem
          id=""
          title={intl.formatMessage(messages.allPartners)}
          numUnreadMessages={allPartnerUnreadCount}
          hasUnreadFile={!!allPartnerUnreadCount}
          onClick={onListItemClick}
          onIconClick={onListItemIconClick}
          isSelected={selectedGroupId === ''}
        />
        {chatGroups.map(({ id, unreadMessageCount, organizations, filesUnreadCount }) => {
          return (
            <ChatListItem
              key={id}
              id={id}
              title={organizations.map(({ name = '' }) => name).join(', ')}
              numUnreadMessages={unreadMessageCount}
              hasUnreadFile={!!filesUnreadCount}
              isSelected={selectedGroupId === id}
              onIconClick={onListItemIconClick}
              onClick={onListItemClick}
            />
          )
        })}
      </div>

      <AddPartnerGroupButton
        entityId={entityId}
        entityType={entityType}
        relatedPartners={relatedPartners}
        onPartnerGroupAdded={(newPartnerGroup) => {
          setChatGroups((oldChatGroups) => [newPartnerGroup, ...oldChatGroups])
          onListItemClick(newPartnerGroup.id)
        }}
        onDuplicatePartners={(duplicateGroupPartners: string[]) => {
          // find existing group
          const foundChatGroup = chatGroups.find((chatGroup) => {
            return chatGroup.organizations.every(
              (organization) => organization.id && duplicateGroupPartners.includes(organization.id)
            )
          })

          onListItemClick(foundChatGroup?.id ?? '')
        }}
      />
    </>
  )
}

export default ChatList
