// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'

import orderListQuery from '@graphql/client/order/query.orderList.graphql'
import type { OrderFilterInput, OrderSortInput } from '@graphql/server/flow'
import { isForbidden, isNotFound } from '@utils/data'

import { CancelButton } from 'components/Buttons'
import { OrderCard } from 'components/Cards'
import { Content, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  Filter,
  OrderFilterConfig,
  OrderSortConfig,
  Search,
  Sort,
} from 'components/NavBar'
import useFilterSort from 'hooks/useFilterSort'
import OrderGridView from 'modules/order/list/OrderGridView'
import { getByPathWithDefault } from 'utils/fp'
import loadMore from 'utils/loadMore'

type OptionalProps = {
  cacheKey: string,
  isLoading?: boolean,
}

type Props = OptionalProps & {
  onCancel: Function,
  onSelect: Function,
}

function SelectOrders({
  cacheKey,
  isLoading = false,
  onCancel,
  onSelect,
}: Props): React.Node | string {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<OrderFilterInput, OrderSortInput>(
      { query: '', archived: false },
      { updatedAt: 'DESCENDING' },
      cacheKey
    )

  const variables = {
    filterBy,
    sortBy,
    page: 1,
    perPage: 10,
  }

  const { loading, data, fetchMore, error } = useQuery(orderListQuery, {
    fetchPolicy: 'network-only',
    variables,
  })

  const [selectedOrder, setSelectedOrder] = React.useState(null)

  const onSelectOrder = React.useCallback(
    (order: Object) => {
      setSelectedOrder(order)
      if (onSelect) {
        onSelect(order)
      }
    },
    [onSelect]
  )

  const orders = React.useMemo(() => {
    return getByPathWithDefault([], 'orders.nodes', data).filter(
      (order) => !isForbidden(order) && !isNotFound(order)
    )
  }, [data])

  if (error) {
    return error.message
  }

  const nextPage = getByPathWithDefault(1, 'orders.page', data) + 1
  const totalPage = getByPathWithDefault(1, 'orders.totalPage', data)
  const hasMore = nextPage <= totalPage

  return (
    <>
      <SlideViewNavBar isSubNavBar>
        <EntityIcon icon="ORDER" color="ORDER" subIcon="CARDS" />

        <Filter
          config={OrderFilterConfig}
          rootQueryType="Order"
          filterBy={filterByWithoutQuery}
          onChange={setFilterBy}
        />
        <Search query={query} onChange={setQuery} />
        <Sort config={OrderSortConfig} sortBy={sortBy} onChange={setSortBy} />

        <CancelButton disabled={isLoading} onClick={onCancel} />
      </SlideViewNavBar>

      <Content hasSubNavBar>
        <OrderGridView
          items={orders}
          onLoadMore={() => loadMore({ fetchMore, data }, variables, 'orders')}
          hasMore={hasMore}
          isLoading={loading}
          renderItem={(item) => {
            return (
              <OrderCard
                key={item.id}
                order={item}
                selectable
                selected={selectedOrder?.id === item.id}
                onSelect={onSelectOrder}
              />
            )
          }}
        />
      </Content>
    </>
  )
}

const defaultProps = {
  cacheKey: 'SelectOrders',
}

SelectOrders.defaultProps = defaultProps

export default SelectOrders
