// @flow

import * as React from 'react'
import { useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

import type { FilePayload } from '@graphql/server/flow'
import { isForbidden } from '@utils/data'

import { IconButton } from 'components/Buttons'
import { CardAction, DocumentCard } from 'components/Cards'
import { Label } from 'components/Form'
import messages from 'components/Form/DocumentsUpload/messages'
import FormattedNumber from 'components/FormattedNumber'
import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import { BadgeStyle } from 'components/NavBar/components/LogsButton/style'
import { Tooltip } from 'components/Tooltip'
import type { UploadingFile } from 'types'
import { downloadAndZip } from 'utils/file'

import DeleteAllButton from './DeleteAllButton'
import FileDeleteDialog from './FileDeleteDialog'
import {
  CommentStyle,
  DocumentTypeAreaBodyStyle,
  DocumentTypeAreaHeaderStyle,
  DocumentTypeAreaLabelContainerStyle,
  DocumentTypeAreaLabelStyle,
  DocumentTypeAreaWrapperStyle,
} from './style'

type Props = {|
  groupId: string,
  entityType: string,
  label: string,
  files: FilePayload | UploadingFile[],
  onUpload: Function,
  canDownload: boolean,
  canDelete: boolean,
  isMultiSelect: boolean,
  selectedFiles: Object,
  onDocumentClick: (file: Object) => void,
  onBubbleClick: (id: string) => void,
  onDeleteAllClick: () => void,
  onDeleteClick: (string) => void,
  unreadMessageCount?: number,
  hideMessageBubble?: boolean,
|}

const DocumentTypeAreaLight = ({
  groupId,
  label,
  files,
  onUpload,
  unreadMessageCount = 0,
  canDownload,
  canDelete,
  isMultiSelect,
  selectedFiles,
  hideMessageBubble,
  onDocumentClick,
  onBubbleClick,
  onDeleteAllClick,
  onDeleteClick,
}: Props): React.Node => {
  const [collapsed, setCollapsed] = useState(false)
  const [fileIdToDelete, setFileIdToDelete] = useState('')
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const uploadInputRef = useRef(null)

  const onBubbleIconClick = () => {
    onBubbleClick(groupId)
  }

  const [{ isDraggedOver, canDrop }, dropRef] = useDrop({
    accept: [],
    canDrop: () => {
      return false
    },
    drop: (item) => item,
    collect: (monitor) => ({
      isDraggedOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  return (
    <>
      <Dropzone onDrop={onUpload}>
        {({ getRootProps: dropZoneProps, isDragActive: isDraggingFilesOver }) => (
          <div {...dropZoneProps()}>
            <div
              className={DocumentTypeAreaWrapperStyle({
                isDraggedOver: (isDraggedOver && canDrop) || isDraggingFilesOver,
                highlight: false,
              })}
              ref={dropRef}
            >
              <div className={DocumentTypeAreaHeaderStyle(files.length > 0)}>
                <div className={DocumentTypeAreaLabelContainerStyle}>
                  <Label width="min-content" className={DocumentTypeAreaLabelStyle}>
                    <Tooltip message={label} visible={tooltipVisible}>
                      <div
                        onMouseEnter={(e) => {
                          const isOverflowing = e.target.scrollWidth > e.target.clientWidth
                          setTooltipVisible(isOverflowing)
                        }}
                        onMouseLeave={() => setTooltipVisible(false)}
                      >
                        {label}
                        {' ('}
                        <FormattedNumber value={files.length} />)
                      </div>
                    </Tooltip>
                  </Label>
                  {!hideMessageBubble && (
                    <span
                      role="button"
                      tabIndex="0"
                      onClick={onBubbleIconClick}
                      onKeyDown={onBubbleIconClick}
                      className={CommentStyle(unreadMessageCount >= 99 ? '10px' : '12px')}
                    >
                      <Icon icon="COMMENTS" />
                      {unreadMessageCount > 0 && (
                        <div className={BadgeStyle}>
                          <FormattedNumber value={unreadMessageCount} />
                        </div>
                      )}
                    </span>
                  )}
                </div>
                <GridRow gap="10px">
                  <Tooltip message={<FormattedMessage {...messages.downloadAll} />}>
                    <IconButton
                      icon="DOWNLOAD"
                      textColor="WHITE"
                      hoverTextColor="WHITE"
                      backgroundColor="GRAY_LIGHT"
                      hoverBackgroundColor="GRAY_DARK"
                      disabled={files.length === 0}
                      onClick={() => {
                        const filesToDownload = files
                          .map((file) => {
                            if (file.__typename !== 'File') {
                              return null
                            }

                            return {
                              url: file.path,
                              name: file.name,
                            }
                          })
                          .filter(Boolean)
                        downloadAndZip(filesToDownload)
                      }}
                    />
                  </Tooltip>
                  <Tooltip message={<FormattedMessage {...messages.uploadFile} />}>
                    <IconButton
                      icon="UPLOAD"
                      textColor="WHITE"
                      hoverTextColor="WHITE"
                      backgroundColor="GRAY_LIGHT"
                      hoverBackgroundColor="GRAY_DARK"
                      onClick={() => {
                        if (uploadInputRef.current) {
                          uploadInputRef.current.click()
                        }
                      }}
                    />
                  </Tooltip>
                  <input
                    ref={uploadInputRef}
                    type="file"
                    accept="*"
                    hidden
                    multiple
                    value=""
                    onChange={onUpload}
                  />
                  <DeleteAllButton onConfirm={onDeleteAllClick} />
                  <IconButton
                    textColor="GRAY_DARK"
                    hoverTextColor="GRAY_DARK"
                    backgroundColor="TRANSPARENT"
                    hoverBackgroundColor="TRANSPARENT"
                    icon={collapsed ? 'CHEVRON_RIGHT' : 'CHEVRON_DOWN'}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                </GridRow>
              </div>

              {!collapsed && files.length > 0 && (
                <div className={DocumentTypeAreaBodyStyle}>
                  {files
                    .filter((file) => !isForbidden(file))
                    .map((file, idx) => (
                      <DocumentCard
                        key={file?.id ? file.id : idx}
                        file={file}
                        hideParentInfo
                        downloadable={canDownload}
                        variant="light"
                        onClick={(evt) => {
                          evt.stopPropagation()
                          if (isMultiSelect) {
                            onDocumentClick(file)
                          }
                        }}
                        selectable={isMultiSelect}
                        selected={file.id ? selectedFiles[file.id] : false}
                        showActionsOnHover
                        actions={[
                          canDelete && (
                            <CardAction
                              icon="REMOVE_ALT"
                              hoverColor="RED"
                              onClick={(evt) => {
                                evt.stopPropagation()
                                setFileIdToDelete(file.id ? file.id : '')
                              }}
                            />
                          ),
                        ].filter(Boolean)}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      <FileDeleteDialog
        onConfirm={() => {
          onDeleteClick(fileIdToDelete)
          setFileIdToDelete('')
        }}
        onClose={() => setFileIdToDelete('')}
        open={!!fileIdToDelete}
      />
    </>
  )
}

export default DocumentTypeAreaLight
