// @flow
import * as React from 'react'

import TabItem from './components/TabItem'
import { WrapperStyle } from './style'

type OptionalProps = {
  activeIndex: number,
}
type Props = OptionalProps & {
  tabs: {
    id: string,
    icon: 'ACTIVE' | 'ARCHIVE',
    label: string | React.Node,
    disabled?: boolean,
  }[],
  disabled?: boolean,
  onChange?: (number) => void,
}

type State = {
  activeIndex: number,
}

class Tabs extends React.Component<Props, State> {
  static defaultProps: {| activeIndex: number, disabled: boolean |} = {
    disabled: false,
    activeIndex: 0,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      activeIndex: props.activeIndex,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State): null | {| activeIndex: number |} {
    if (props.activeIndex !== state.activeIndex) {
      return { activeIndex: props.activeIndex }
    }
    return null
  }

  handleChange: (index: number) => void = (index: number) => {
    this.setState({ activeIndex: index })
    const { onChange } = this.props
    if (onChange) onChange(index)
  }

  render(): React.Element<'div'> {
    const { tabs, ...rest } = this.props
    const { activeIndex } = this.state
    return (
      <div className={WrapperStyle}>
        {tabs.map((tab, index) => (
          <TabItem
            key={tab.id}
            active={index === activeIndex}
            onClick={() => this.handleChange(index)}
            /* $FlowFixMe This comment suppresses an error found when upgrading
             * Flow to v0.112.0. To view the error, delete this comment and run
             * Flow. */
            {...tab}
            {...rest}
            {...(tab.disabled ? { disabled: tab.disabled } : {})}
          />
        ))}
      </div>
    )
  }
}

export default Tabs
