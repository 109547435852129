// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  defaultPlaceholder: {
    id: 'components.Inputs.defaultPlaceholder',
    defaultMessage: 'Please enter a value',
  },
  defaultSelectPlaceholder: {
    id: 'components.Inputs.defaultSelectPlaceholder',
    defaultMessage: 'Please select a value',
  },
}): any)
