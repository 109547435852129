// @flow strict
import { partition } from 'lodash'
import React, { type Element, useEffect, useMemo } from 'react'
import VirtualList from 'react-tiny-virtual-list'

import { type RenderOptionsProps } from 'components/Form/Inputs/SelectInput/type'

import { OptionStyle, OptionWrapperStyle } from './style'

type OptionalProps = {
  customHeights: boolean,
  type: 'standard' | 'label',
  align: 'left' | 'right' | 'center',
  width: string,
  height: string,
  dropDirection: 'down' | 'up',
  prohibitedValues: string[],
  optionsStyle: string,
}

type Props = OptionalProps & RenderOptionsProps

const defaultProps = {
  type: 'standard',
  width: '100%',
  height: '200px',
  dropDirection: 'down',
  align: 'left',
}

const removePx = (size: string): number => {
  if (size.indexOf('px')) {
    return Number(size.replace('px', ''))
  }
  return 0
}

const ITEM_SIZE = 30

function DefaultOptions({
  customHeights,
  type,
  items,
  itemToValue,
  itemToString,
  highlightedIndex,
  selectedItem,
  getItemProps,
  loadMore,
  loadMoreThreshold = 4,
  align,
  width,
  height,
  dropDirection,
  prohibitedValues,
  optionsStyle,
}: Props): Element<'div'> {
  const renderItems = useMemo(
    () =>
      partition(
        items,
        (
          item // Sort good values to top
        ) =>
          prohibitedValues
            ? !prohibitedValues.some((badValue) => item.name === badValue || item === badValue)
            : true
      ).flat(),
    [items, prohibitedValues]
  )
  const rawHeight = removePx(height)
  const numItemsInAPage = Math.ceil(rawHeight / 30)
  const isPercentWidth = width[width.length - 1] === '%'

  useEffect(() => {
    if (loadMore && items.length < numItemsInAPage) {
      loadMore()
    }
  }, [loadMore, items.length, numItemsInAPage])

  return (
    <div
      className={OptionWrapperStyle({ customHeights, optionsStyle, width, height, dropDirection })}
    >
      {items.length > 0 ? (
        <VirtualList
          height={items.length < numItemsInAPage ? items.length * 30 : rawHeight}
          width={isPercentWidth ? width : removePx(width)}
          itemCount={items.length}
          itemSize={ITEM_SIZE}
          overscanCount={numItemsInAPage}
          onScroll={(scrollTop, e) => {
            // Request more items when scroll position reaches end of list minus
            if (
              loadMore &&
              e.target.scrollHeight - scrollTop - loadMoreThreshold * ITEM_SIZE <=
                e.target.offsetHeight
            ) {
              loadMore()
            }
          }}
          renderItem={({ index, style }) => {
            const item = renderItems[index]
            if (!item) return null
            const unselectable =
              item.props?.disabled ||
              prohibitedValues?.some(
                (restrictedValue) => item.name === restrictedValue || item === restrictedValue
              )
            return (
              <div
                disabled={unselectable}
                key={item.key || itemToValue(item)}
                className={OptionStyle({
                  unselectable,
                  customHeights,
                  onHover: highlightedIndex === index,
                  selected: selectedItem === item,
                  align,
                  type,
                })}
                {...getItemProps({
                  index,
                  item,
                  style,
                })}
              >
                {itemToString(item)}
                {item.icon}
              </div>
            )
          }}
        />
      ) : null}
    </div>
  )
}

DefaultOptions.defaultProps = defaultProps

export default DefaultOptions
