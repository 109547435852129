// @flow
import { getByPath } from './fp'
import { encodeId } from './id'

export const getParentInfo = (
  parent: ?Object
): {
  parentType: string,
  // prettier-ignore
  parentIcon: | 'ORDER'
    | 'BATCH'
    | 'SHIPMENT'
    | 'CONTAINER'
    | 'ORDER_ITEM'
    | 'PRODUCT'
    | 'PRODUCT_PROVIDER'
    | 'CLEAR',
  parentData: React$Node,
  link: string,
} => {
  switch (parent?.__typename) {
    case 'Order':
      return {
        parentType: 'order',
        parentIcon: 'ORDER',
        parentData: parent.poNo,
        link: `/order/${encodeId(parent.id)}`,
      }
    case 'OrderItem':
      return {
        parentType: 'orderItem',
        parentIcon: 'ORDER_ITEM',
        parentData: parent.no,
        link: `/order-item/${encodeId(parent.id)}`,
      }
    case 'Batch':
      return {
        parentType: 'batch',
        parentIcon: 'BATCH',
        parentData: parent.no,
        link: `/batch/${encodeId(parent.id)}`,
      }
    case 'Shipment':
      return {
        parentType: 'shipment',
        parentIcon: 'SHIPMENT',
        parentData: parent.no,
        link: `/shipment/${encodeId(parent.id)}`,
      }
    case 'Product':
      return {
        parentType: 'product',
        parentIcon: 'PRODUCT',
        parentData: parent.name,
        link: `/product/${encodeId(parent.id)}`,
      }
    case 'ProductProvider':
      return {
        parentType: 'product',
        parentIcon: 'PRODUCT_PROVIDER',
        parentData: parent.name,
        link: `/product/${encodeId(getByPath('product.id', parent))}`,
      }
    default:
      return {
        parentType: '',
        parentIcon: 'CLEAR',
        parentData: null,
        link: '',
      }
  }
}

export default getParentInfo
