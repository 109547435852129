// @flow strict
import * as React from 'react'

import usersQuery from '@graphql/client/user/query.users.graphql'
import type {
  UserAvatarFragment,
  UserFilterInput,
  UserSortInput,
  UsersQuery,
  UsersQueryVariables,
} from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton } from 'components/Buttons'
import { UserCard } from 'components/Cards'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  Filter,
  Search,
  Sort,
  UserFilterConfig,
  UserSortConfig,
} from 'components/NavBar'
import Selector from 'components/Selector'
import useFilterSort from 'hooks/useFilterSort'
import UserGridView from 'modules/user/list/UserGridView'

type OptionalProps = {
  selected: UserAvatarFragment[],
  organizationIds: string[],
}

type Props = OptionalProps & {
  onSelect: (values: UserAvatarFragment[]) => void,
  onCancel: () => void,
}

const defaultProps = {
  selected: ([]: any[]),
  organizationIds: ([]: any[]),
}

const MAX_SELECTIONS = 5

const AssignUsers = ({ selected, onCancel, onSelect, organizationIds }: Props): React.Node => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<UserFilterInput, UserSortInput>(
      { query: '', organizationIds },
      { updatedAt: 'DESCENDING' }
    )

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    UsersQuery['users']['nodes'][number],
    UsersQuery,
    UsersQueryVariables
  >(
    usersQuery,
    {
      variables: { filterBy, sortBy, page: 1, perPage: 10 },
      fetchPolicy: 'network-only',
    },
    'users'
  )

  return (
    <Selector.Many selected={selected} max={MAX_SELECTIONS}>
      {({ value, dirty, getItemProps }) => (
        <SlideViewLayout>
          <SlideViewNavBar>
            <EntityIcon icon="USER" color="USERS" />

            <Filter
              config={UserFilterConfig}
              rootQueryType="User"
              filterBy={filterByWithoutQuery}
              onChange={setFilterBy}
              staticFilters={['organizationIds']}
            />
            <Search query={query} onChange={setQuery} />
            <Sort config={UserSortConfig} sortBy={sortBy} onChange={setSortBy} />

            <h3>
              {value.length}/{MAX_SELECTIONS}
            </h3>

            <CancelButton onClick={onCancel} />
            <SaveButton
              data-testid="saveButtonOnAssignUsers"
              disabled={!dirty}
              onClick={() => onSelect(value)}
            />
          </SlideViewNavBar>

          <Content>
            <UserGridView
              hasMore={hasMore}
              isLoading={loading}
              onLoadMore={loadMore}
              items={nodes}
              renderItem={(item) => <UserCard key={item.id} user={item} {...getItemProps(item)} />}
            />
          </Content>
        </SlideViewLayout>
      )}
    </Selector.Many>
  )
}

AssignUsers.defaultProps = defaultProps

export default AssignUsers
