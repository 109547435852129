// @flow strict
import type { NotificationType } from '@graphql/server/flow'

/**
 * Notifications which decide their own route for redirection. With
 * special handling provided at each route.
 * @type {NotificationType[]}
 */
export const autonomousNotificationTypes: NotificationType[] = ['reminder_trigger']

/**
 * Notifications which apply templates to table views.
 * @type {NotificationType[]}
 */
export const tableTemplateNotificationTypes: NotificationType[] = ['reminder_trigger']
