// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  active: {
    id: 'components.NavBar.active',
    defaultMessage: 'ACTIVE',
  },
  archived: {
    id: 'components.NavBar.archived',
    defaultMessage: 'ARCHIVED',
  },
}): any)
