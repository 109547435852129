// @flow
import * as React from 'react'

import Icon from 'components/Icon'

import { IconStyle, SectionHeaderWrapperStyle, TitleStyle, TitleWrapperStyle } from './style'

type Props = {
  icon: string,
  title: string | React.Node,
  children?: React.Node,
}

function SectionHeader({ icon, title, children }: Props): React.Element<'div'> {
  return (
    <div className={SectionHeaderWrapperStyle}>
      <div className={TitleWrapperStyle}>
        <div className={IconStyle}>
          <Icon icon={icon} />
        </div>
        <div className={TitleStyle}>{title}</div>
      </div>
      {children}
    </div>
  )
}

export default SectionHeader
