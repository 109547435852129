// @flow strict
import * as React from 'react'
import DebounceInput from 'react-debounce-input'
import { FormattedMessage, useIntl } from 'react-intl'

import { type FileType as FileTypeType, FileTypeValues } from '@graphql/server/flow'

import { Label } from 'components/Form'
import Icon from 'components/Icon'
import SelectInput from 'components/Inputs/SelectInput'
import type { RenderInputProps } from 'components/Inputs/SelectInput'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

import { ArrowDownStyle, SelectInputStyle } from './style'

const FileType = ({ value, onChange, readonly }: FilterInputProps<FileTypeType[]>): React.Node => {
  const intl = useIntl()

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.fileType} />
      </Label>

      <SelectInput
        value={value}
        onChange={onChange}
        disabled={readonly}
        items={Object.keys(FileTypeValues)
          .filter((ft) => ft !== 'Document' && ft !== 'File' && ft !== 'Image')
          .map((ft) => ({
            name: ft,
            description: intl.formatMessage(messages[ft.slice(0, 1).toLowerCase() + ft.slice(1)]),
          }))}
        itemToString={(item: { name: string, description: string } | null) =>
          item?.description ?? ''
        }
        itemToValue={(item: { name: string, description: string } | null) => item?.name ?? null}
        renderInput={({ getInputProps, getToggleButtonProps, isOpen }: RenderInputProps) => {
          const { ref, ...inputProps } = getInputProps({
            spellCheck: false,
            placeholder: intl.formatMessage(messages.fileTypePlaceholder),
          })

          return (
            <div className={SelectInputStyle(isOpen)}>
              <DebounceInput debounceTimeout={500} inputRef={ref} {...inputProps} />

              <button className={ArrowDownStyle(isOpen)} type="button" {...getToggleButtonProps()}>
                <Icon icon="CHEVRON_DOWN" />
              </button>
            </div>
          )
        }}
        renderOption={SelectInput.DefaultRenderSelectOption}
      />
    </>
  )
}

export default FileType
