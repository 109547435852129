// @flow
// $FlowIgnore: flow-typed not updated
import { gql } from '@apollo/client'
import { upperFirst } from 'lodash'

export const unreadGroupsQuery = (entityType: string): any => gql`
  query unreadGroupsQuery($id: ID!) {
    ${entityType}(id: $id) {
      ... on ${upperFirst(entityType)} {
        id
        messageGroups {
          ... on MessageGroup {
            id
            unreadMessageCount
            __typename
          }
        }
      }
    }
  }
`

export default unreadGroupsQuery
