// @flow
import * as Sentry from '@sentry/browser'
import * as React from 'react'

import ConfirmBeforeLeave from 'components/ConfirmBeforeLeave'
import InternalError from 'components/InternalError'
import UserNavbar from 'modules/userNavbar'
import { DesktopWrapperStyle } from 'styles/main'
import { isDevEnvironment } from 'utils/env'
import logger from 'utils/logger'

import { LayoutWrapperStyle, NavBarStyle, NavBarWrapperStyle } from './style'

type Props = {
  isSideBarExpanded?: boolean,
  children: React.Node,
}

type State = {
  hasError: boolean,
}

export default class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Object): {| hasError: boolean |} {
    if (!isDevEnvironment) {
      Sentry.captureException(error)
    }
    return { hasError: true }
  }

  componentDidCatch(error: Object) {
    logger.warn({
      error,
    })
    this.setState({ hasError: true })
    if (!isDevEnvironment) {
      Sentry.captureException(error)
    }
  }

  onReportError: () => void = () => {
    if (Sentry.lastEventId() && !isDevEnvironment) {
      // $FlowFixMe
      Sentry.showReportDialog()
    }
  }

  render(): React.Node {
    const { isSideBarExpanded, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <InternalError onReportError={this.onReportError} />
    }

    return (
      <div className={DesktopWrapperStyle(isSideBarExpanded)}>
        <div className={LayoutWrapperStyle}>
          <div className={NavBarWrapperStyle}>
            <div id="navbar-root" className={NavBarStyle} />
            <UserNavbar />
          </div>

          <div>{children}</div>
        </div>
        <ConfirmBeforeLeave />
      </div>
    )
  }
}
