// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, shadows, transitions } from 'styles/common'

export const SectionNavBarStyle = (hasSecondRowContent: boolean): string => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${hasSecondRowContent ? '90px' : '50px'};
  background: ${colors.WHITE};
  z-index: 1;
  ${shadows.HEADER};
  ${transitions.EXPAND};
  ${borderRadiuses.MAIN};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

// eslint-disable-next-line
export const SectionNavBarChildrenWrapperStyle: string = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 50px;
`

export const SectionNavBarSecondRowWrapperStyle: string = css`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  background-color: #f3f3f3;
`
