// Field component has unstable keys to allow resetting without digging into DownShift.
// This component is largely the same except with stable keys.

// @flow
import * as React from 'react'
import { Subscribe } from 'unstated'

import withCache from 'hoc/withCache'
import { getByPath, isNullOrUndefined } from 'utils/fp'

import FormContainer from './container'

type OptionalProps = {
  validationOnChange: boolean,
  validationOnBlur: boolean,
  saveOnChange: boolean,
  isTouched: boolean,
  errorMessage: string,
  activeField: string,
  onValidate: (value: any) => void,
  setActiveField: (field: string) => void,
  setFieldTouched: (field: string, isTouched: boolean) => void,
}

type Props = OptionalProps & {
  initValue: any,
  name: string,
  children: ({
    name: string,
    value: any,
    isTouched: boolean,
    errorMessage: string,
    isFocused: boolean,
    onChange: Function,
    onFocus: Function,
    onBlur: Function,
  }) => React.Node,
  setFieldValue: (field: string, value: any) => void,
}

type State = {
  value: any,
}

const defaultProps = {
  activeField: '',
  validationOnChange: false,
  validationOnBlur: true,
  saveOnChange: false,
  isTouched: false,
  errorMessage: '',
  onValidate: () => {},
  setActiveField: () => {},
  setFieldTouched: () => {},
}
class BaseFormField extends React.Component<Props, State> {
  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)

    const { initValue: initialValue } = props
    this.state = {
      value: isNullOrUndefined(initialValue) ? '' : initialValue,
    }
  }

  onFocus = (event: ?SyntheticFocusEvent<*>) => {
    if (event && event.persist) {
      event.persist()
    }

    const { name, setActiveField } = this.props
    setActiveField(name)
  }

  /**
   * Save local state on change, it could run validation
   */
  onChange = (event: SyntheticInputEvent<*>) => {
    if (event.persist) {
      event.persist()
    }

    // If you need to override the field's name, pass a name to the target manually.
    const { value, name: eventName } = event.target
    const {
      setActiveField,
      validationOnChange,
      onValidate,
      saveOnChange,
      setFieldValue,
      name,
    }: Props = this.props

    this.setState({ value })

    if (validationOnChange && onValidate) {
      onValidate({ [eventName || name]: value })
    }

    if (saveOnChange) {
      setFieldValue(eventName || name, value)
    }

    setActiveField('')
  }

  /**
   * Send the value to container/context when finish editing
   */
  onBlur = (event: any) => {
    if (event && event.persist) {
      event.persist()
    }

    let { value } = this.state
    if (getByPath('currentTarget.type', event) === 'number') {
      value = getByPath('target.value', event)
      this.setState({
        value,
      })
    }

    if (event?.currentTarget?.type === 'datetime-local' || event?.currentTarget?.type === 'date') {
      value = event?.target?.value
      this.setState({
        value,
      })
    }

    const { name, validationOnBlur, onValidate, setFieldTouched, setActiveField, setFieldValue } =
      this.props
    if (validationOnBlur && onValidate) {
      onValidate({ [name]: value })
    }
    setFieldTouched(name, true)
    setActiveField('')
    setFieldValue(name, value)
  }

  render() {
    const { children, name, activeField, isTouched, errorMessage } = this.props
    const { value } = this.state

    return children({
      name,
      value,
      isTouched,
      errorMessage,
      isFocused: name === activeField,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    })
  }
}

const CachedField = withCache(BaseFormField, [
  'activeField',
  'isTouched',
  'errorMessage',
  'initValue',
  'values',
])

const FormField = (props: {
  initValue: any,
  name: string,
  setFieldValue?: (field: string, value: any) => void,
  values?: any,
  validator?: Object,
}): React.Node => {
  const { values, validator, setFieldValue, ...rest } = props
  return (
    <Subscribe to={[FormContainer]}>
      {({
        state: { activeField, touched, errors },
        setFieldTouched,
        setActiveField,
        onValidation,
      }) => {
        return (
          <CachedField
            key={`field-${props.name}`}
            values={values}
            activeField={activeField}
            isTouched={touched[props.name]}
            errorMessage={errors[props.name]}
            setFieldTouched={setFieldTouched}
            setActiveField={setActiveField}
            setFieldValue={setFieldValue}
            onValidate={(newValue) => onValidation({ ...values, ...newValue }, validator)}
            /* $FlowFixMe This comment suppresses an error found when upgrading
             * Flow to v0.112.0. To view the error, delete this comment and run
             * Flow. */
            {...rest}
          />
        )
      }}
    </Subscribe>
  )
}

FormField.defaultProps = {
  validator: {
    validate: (value, options): Promise<{| options: any, value: any |}> =>
      Promise.resolve({ value, options }),
  },
  setFieldValue: () => {},
  values: ({}: { ... }),
}

export default FormField
