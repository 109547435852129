// @flow
import { Link } from '@reach/router'
import type { Element } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import loginIcon from '@public/media/icon_white.png'

import Icon from 'components/Icon'
import { isDevEnvironment } from 'utils/env'

import messages from './messages'
import {
  InternalErrorContainerStyle,
  InternalErrorGifStyle,
  InternalErrorH1Style,
  InternalErrorH3Style,
  InternalErrorLinkContainerStyle,
  InternalErrorLinkStyle,
  InternalErrorTitleContainerStyle,
} from './style'

type Props = {
  onReportError: Function,
}

export default function InternalError({ onReportError }: Props): Element<'div'> {
  const handleRefresh = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  return (
    <div className={InternalErrorContainerStyle}>
      <div role="presentation" onClick={onReportError} className={InternalErrorTitleContainerStyle}>
        <h1 className={InternalErrorH1Style}>
          {isDevEnvironment ? (
            <img
              className={InternalErrorGifStyle}
              src="https://media.giphy.com/media/5kzB8SARBWCmQ/giphy.gif"
              alt="lost"
            />
          ) : (
            <img src={loginIcon} alt="logo" />
          )}
        </h1>
        <h2>
          <FormattedMessage {...messages.networkError} />
        </h2>
        <h3 className={InternalErrorH3Style}>
          <FormattedMessage {...messages.message} />
          <br />
          <FormattedMessage {...messages.message2} />
        </h3>
        <h3 className={InternalErrorH3Style}>
          <FormattedMessage {...messages.message3} />
        </h3>
      </div>
      <div className={InternalErrorLinkContainerStyle}>
        <button type="button" onClick={handleRefresh} className={InternalErrorLinkStyle}>
          <Icon icon="REFRESH" size="3x" />
          <FormattedMessage {...messages.refresh} />
        </button>
        {/* $FlowFixMe Flow typed is not updated yet */}
        <Link to="/" className={InternalErrorLinkStyle}>
          <Icon icon="HOME" size="3x" />
          <FormattedMessage {...messages.goHome} />
        </Link>
      </div>
    </div>
  )
}
