import seedrandom from 'seedrandom'

import { Connection, Scalars } from '@graphql/server/typescript'

import CONNECTION_COLORS from '@constants/connection'
import type { ConnectionColor } from '@types'

export const getConnectionAvatarColor = ({ id }: { id: Scalars['ID'] }): ConnectionColor => {
  const rng = seedrandom(id)
  const value = rng()
  const index = Math.floor(value * CONNECTION_COLORS.length)

  // @ts-expect-error: Impossible for index to be out of range
  return CONNECTION_COLORS[index]
}

export const getConnectionAvatarInitials = ({
  name,
}: {
  name: Connection['displayName']
}): string => {
  const hasSpace = name.includes(' ')
  const hasUnderscore = name.includes('_')
  let res = ''

  if (!hasSpace && !hasUnderscore) {
    res = name.substring(0, 2)
  } else if (hasSpace) {
    const words = name.split(' ')
    // @ts-expect-error: Impossible for index to be out of range
    res = words[0][0] + words[1][0]
  } else if (hasUnderscore) {
    const words = name.split('_')
    // @ts-expect-error: Impossible for index to be out of range
    res = words[0][0] + words[1][0]
  }

  return res.toUpperCase()
}

export default getConnectionAvatarColor
