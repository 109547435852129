export const CONNECTION_COLORS = [
  'RED',
  'RED2',
  'PURPLE',
  'PURPLE2',
  'INDIGO',
  'INDIGO2',
  'LIGHTBLUE',
  'LIGHTBLUE2',
  'TEAL',
  'TEAL2',
  'LIGHTGREEN',
  'LIGHTGREEN2',
  'YELLOW',
  'YELLOW2',
  'ORANGE',
  'ORANGE2',
  'BROWN',
  'BROWN2',
  'BLUEGREY',
  'BLUEGREY2',
] as const

export default CONNECTION_COLORS
