import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import BadgeWrapperStyle from './style'

const InternalBadge = (): React.ReactElement => (
  <BadgeWrapperStyle>
    <FormattedMessage id="modules.Connection.internal" defaultMessage="Internal" />
  </BadgeWrapperStyle>
)

export default InternalBadge
