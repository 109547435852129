// @flow
import { Link } from '@reach/router'
import * as React from 'react'

import emitter from 'utils/emitter'

type Props = {|
  to: string,
  href?: string,
  className?: string,
  children: React$Node,
  onClick?: (Event) => void,
|}

function NavigateLink({ to, className, children, href, onClick }: Props): React.Node {
  const handleClick = (evt) => {
    if (!evt.ctrlKey && !evt.shiftKey) {
      evt.preventDefault()
      emitter.emit('NAVIGATE_TO', to)
    }
  }

  if (href) {
    return (
      <a href={href} className={className}>
        {children}
      </a>
    )
  }

  return (
    // $FlowFixMe Flow typed is not updated yet
    <Link
      to={to}
      href={href}
      className={className}
      onClick={(evt) => {
        if (window.swExpired === true) {
          evt.preventDefault()
          window.location.href = to ?? href
        }
        handleClick(evt)
        if (onClick) onClick(evt)
      }}
    >
      {children}
    </Link>
  )
}

export default NavigateLink
