// @flow
import * as React from 'react'

import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import withForbiddenCard from 'hoc/withForbiddenCard'

import BaseCard from '../BaseCard'

import {
  TemplateCardWrapperStyle,
  TemplateCountIconStyle,
  TemplateCountStyle,
  TemplateCountWrapperStyle,
  TemplateDescriptionFadeStyle,
  TemplateDescriptionStyle,
  TemplateNameStyle,
} from './style'

type OptionalProps = {
  onClick: Function,
  actions: React.Node[],
}

type Props = OptionalProps & {
  template: {
    id: string,
    title: string,
    description: ?string,
    count: number,
  },
  type: 'METADATA' | 'EDIT_TABLE',
}

const defaultProps = {
  onClick: () => {},
  actions: [],
}

const TemplateCard = ({ template, type, onClick, actions, ...rest }: Props) => {
  const { title, description, count } = template

  return (
    <BaseCard icon="TEMPLATE" color="TEMPLATE" actions={actions} {...rest}>
      <div className={TemplateCardWrapperStyle} onClick={onClick} role="presentation">
        <Tooltip message={title}>
          <div className={TemplateNameStyle}>{title}</div>
        </Tooltip>
        <Tooltip message={description}>
          <div className={TemplateDescriptionStyle}>
            {description}
            <div className={TemplateDescriptionFadeStyle} />
          </div>
        </Tooltip>
        <div className={TemplateCountWrapperStyle}>
          <div className={TemplateCountIconStyle}>
            <Icon icon={type} />
          </div>
          <div className={TemplateCountStyle}>
            <FormattedNumber value={count} />
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

TemplateCard.defaultProps = defaultProps

export default (withForbiddenCard(TemplateCard, 'template', {
  width: '195px',
  height: '125px',
  entityIcon: 'TEMPLATE',
  entityColor: 'TEMPLATE',
}): any)
