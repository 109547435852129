// @flow
import * as React from 'react'
import { type IntlShape, injectIntl } from 'react-intl'

import messages from 'components/Form/Inputs/messages'
import { type InputProps, defaultInputProps } from 'components/Form/Inputs/type'
import { isNullOrUndefined } from 'utils/fp'

import { TextAreaReadOnlyStyle } from './style'

type Props = InputProps & {
  intl: IntlShape,
}

const TextAreaInput = ({
  intl,
  value,
  align,
  readOnly,
  readOnlyWidth,
  readOnlyHeight,
  placeholder,
  ...rest
}: Props) =>
  readOnly ? (
    <div className={TextAreaReadOnlyStyle({ align, readOnlyWidth, readOnlyHeight })}>{value}</div>
  ) : (
    <textarea
      style={{ textAlign: align }}
      value={value || ''}
      placeholder={
        isNullOrUndefined(placeholder)
          ? intl.formatMessage(messages.defaultPlaceholder)
          : placeholder
      }
      {...rest}
      spellCheck={false}
    />
  )

TextAreaInput.defaultProps = defaultInputProps

export default (injectIntl(TextAreaInput): any)
