// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import ConfirmDialog from 'components/Dialog/ConfirmDialog'

type Props = {
  open: boolean,
  onConfirm: () => void,
  onClose: () => void,
}

const FileDeleteDialog = ({ open, onConfirm, onClose }: Props): React.Node => {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  React.useEffect(() => {
    if (open) {
      setOpenConfirmDialog(true)
    }
  }, [open])

  return (
    <ConfirmDialog
      isOpen={openConfirmDialog}
      onRequestClose={() => {
        setOpenConfirmDialog(false)
        onClose()
      }}
      onCancel={() => {
        setOpenConfirmDialog(false)
        onClose()
      }}
      onConfirm={() => {
        setOpenConfirmDialog(false)
        onConfirm()
      }}
      message={
        <FormattedMessage
          id="components.Documents.fileDeleteConfirmation"
          defaultMessage="Are you sure you want to delete this file?"
        />
      }
    />
  )
}

export default FileDeleteDialog
