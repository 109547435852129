// @flow

export const NAVIGATION_ADMIN_ACTIVITYLOG = 'navigation.admin.activityLog'

export const NAVIGATION_ORDERS_MAP = 'navigation.orders.map'
export const NAVIGATION_ORDERS_CARD = 'navigation.orders.card'
export const NAVIGATION_ORDERS_LIST = [NAVIGATION_ORDERS_MAP, NAVIGATION_ORDERS_CARD]

export const NAVIGATION_ORDER_ITEMS_CARD = 'navigation.orderItems.card'
export const NAVIGATION_ORDER_ITEMS_LIST = [NAVIGATION_ORDER_ITEMS_CARD]

export const NAVIGATION_BATCH_CARD = 'navigation.batch.card'
export const NAVIGATION_BATCH_LIST = [NAVIGATION_BATCH_CARD]

export const NAVIGATION_SHIPMENTS_MAP = 'navigation.shipments.map'
export const NAVIGATION_SHIPMENTS_TABLE_BETA = 'navigation.shipments.tableβ'
export const NAVIGATION_SHIPMENTS_CARD = 'navigation.shipments.card'
export const NAVIGATION_SHIPMENTS_LIST = [
  NAVIGATION_SHIPMENTS_MAP,
  NAVIGATION_SHIPMENTS_TABLE_BETA,
  NAVIGATION_SHIPMENTS_CARD,
]

export const NAVIGATION_CONTAINERS_CARD = 'navigation.containers.card'
export const NAVIGATION_CONTAINERS_LIST = [NAVIGATION_CONTAINERS_CARD]

export const NAVIGATION_PRODUCTS_CARD = 'navigation.products.card'
export const NAVIGATION_PRODUCTS_LIST = [NAVIGATION_PRODUCTS_CARD]

export const NAVIGATION_DOCUMENTS_CARD = 'navigation.documents.card'
export const NAVIGATION_DOCUMENTS_LIST = [NAVIGATION_DOCUMENTS_CARD]

export const NAVIGATION_NETWORK_WAREHOUSES = 'navigation.network.warehouses'
export const NAVIGATION_NETWORK_PARTNERS = 'navigation.network.partners'
export const NAVIGATION_NETWORK_USERS = 'navigation.network.users'

export const NAVIGATION_TEMPLATES_CUSTOM_FILEDS = 'navigation.templates.customFileds'
export const NAVIGATION_TEMPLATES_TABLE = 'navigation.templates.table'

export const NAVIGATION_TAGS_CARD = 'navigation.tags.card'
export const NAVIGATION_TAGS_LIST = [NAVIGATION_TAGS_CARD]
