// @flow strict

import { AllMainEntityTypeValues } from 'types'

export const ENTITIES_WITH_NEW_TABLE_MAP = Object.freeze({
  Shipment: AllMainEntityTypeValues['Shipment'],
})

export type EntityWithNewTable = $Keys<typeof ENTITIES_WITH_NEW_TABLE_MAP>

const ENTITIES_WITH_NEW_TABLE: EntityWithNewTable[] = Object.keys(ENTITIES_WITH_NEW_TABLE_MAP)

export default ENTITIES_WITH_NEW_TABLE
