// @flow
import * as React from 'react'
import { BooleanValue } from 'react-values'

import UserAvatar from '@components/UserAvatar'

import Icon from 'components/Icon'
import SlideView from 'components/SlideView'
import { type UserAvatarType } from 'types'

import AssignUsers from './components/AssignUsers'
import { MAX_USERS_ALLOWED } from './constants'
import {
  AddAssignmentButtonStyle,
  AssignmentStyle,
  AssignmentWrapperStyle,
  RemoveAssignmentButtonStyle,
} from './style'

type OptionalProps = {
  users: UserAvatarType[],
  name: string,
  onChange: (name: string, users: UserAvatarType[]) => void,
  editable: boolean,
  inputRef: Object,
  size: number,
}

type Props = OptionalProps & {
  groupIds: string[],
}

const defaultProps = {
  users: ([]: any[]),
  name: '',
  onChange: () => {},
  editable: false,
  size: 30,
}

const UserAssignmentInput = ({
  users,
  name,
  groupIds,
  onChange,
  editable,
  inputRef,
  size,
}: Props): React.Element<'div'> => (
  <div className={AssignmentWrapperStyle}>
    {users.map(({ id, firstName, lastName }) => (
      <div className={AssignmentStyle} key={id}>
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          width={`${size}px`}
          height={`${size}px`}
        />
        {editable && (
          <button
            className={RemoveAssignmentButtonStyle(size)}
            onClick={() =>
              onChange(
                name,
                users.filter(({ id: userId }) => id !== userId)
              )
            }
            type="button"
          >
            <Icon icon="REMOVE_ALT" />
          </button>
        )}
      </div>
    ))}
    {editable && users.length < MAX_USERS_ALLOWED && (
      <BooleanValue>
        {({ value: isOpen, set: toggleSlide }) => (
          <>
            <button
              ref={inputRef}
              data-testid="addAssignerButton"
              className={AddAssignmentButtonStyle(size)}
              type="button"
              onClick={() => toggleSlide(true)}
            >
              <Icon icon="ADD" />
            </button>
            <SlideView isOpen={isOpen} onRequestClose={() => toggleSlide(false)}>
              {isOpen && (
                <AssignUsers
                  organizationIds={groupIds}
                  selected={users}
                  onSelect={(selected) => {
                    toggleSlide(false)
                    onChange(name, selected)
                  }}
                  onCancel={() => toggleSlide(false)}
                />
              )}
            </SlideView>
          </>
        )}
      </BooleanValue>
    )}
  </div>
)

UserAssignmentInput.defaultProps = defaultProps

export default UserAssignmentInput
