// @flow
import type { EntityInput, Scalars } from '@graphql/server/flow'

import type { ChatEntity } from './type'

type Props = {|
  entityId: $ElementType<Scalars, 'ID'>,
  entityType: ChatEntity,
|}

export const getEntityInput = ({ entityId, entityType }: Props): EntityInput => {
  switch (entityType) {
    case 'Shipment':
      return { shipmentId: entityId }
    case 'Order':
      return { orderId: entityId }
    case 'Container':
      return { containerId: entityId }
    // case 'OrderItem':
    //   return { orderItemId: entityId }
    // case 'ProductProvider':
    //   return { productProviderId: entityId }
    default:
      return { shipmentId: entityId }
  }
}

export default getEntityInput
