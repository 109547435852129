// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { Label, MetricInput } from 'components/Form'
import { areaMetrics, distanceMetrics, volumeMetrics, weightMetrics } from 'utils/metric'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

const MetricRange =
  (
    metrics: string[]
  ): ((
    FilterInputProps<{ max: number | null, metric: string, min: number | null, ... }>
  ) => React.Node) =>
  ({
    value,
    readonly,
    onChange,
  }: FilterInputProps<{ min: number | null, max: number | null, metric: string }>) =>
    (
      <>
        <Label height="30px">
          <FormattedMessage {...messages.from} />
        </Label>
        <MetricInput
          value={{ value: value.min, metric: value.metric }}
          metrics={metrics}
          onChange={(e) => {
            onChange({
              ...value,
              min: e.target?.value.value ?? null,
              metric: e.target?.value.metric ?? value.metric,
            })
          }}
          valueReadOnly={readonly}
          metricReadOnly={readonly}
        />

        <Label height="30px">
          <FormattedMessage {...messages.to} />
        </Label>
        <MetricInput
          value={{ value: value.max, metric: value.metric }}
          metrics={metrics}
          onChange={(e) => {
            onChange({
              ...value,
              max: e.target?.value.value ?? null,
              metric: e.target?.value.metric ?? value.metric,
            })
          }}
          valueReadOnly={readonly}
          metricReadOnly={readonly}
        />
      </>
    )

export const VolumeRange: (
  FilterInputProps<{ max: number | null, metric: string, min: number | null, ... }>
) => React.Node = MetricRange(volumeMetrics)
export const AreaRange: (
  FilterInputProps<{ max: number | null, metric: string, min: number | null, ... }>
) => React.Node = MetricRange(areaMetrics)
export const LengthRange: (
  FilterInputProps<{ max: number | null, metric: string, min: number | null, ... }>
) => React.Node = MetricRange(distanceMetrics)
export const MassRange: (
  FilterInputProps<{ max: number | null, metric: string, min: number | null, ... }>
) => React.Node = MetricRange(weightMetrics)

export default MetricRange
