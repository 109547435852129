// @flow
import { css } from 'react-emotion'

import { animations, borderRadiuses, colors, fontSizes, layout, presets } from 'styles/common'

export const DocumentCardWrapperStyle: string = css`
  ${layout.GRID_VERTICAL};
  position: relative;
  grid-gap: 5px;
  width: 195px;
  min-height: 184px;
  padding: 10px 5px;
`

export const DocumentLightCardWrapperStyle: string = css`
  display: grid;
  grid-template-columns: 124px 1fr 30px;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 404.5px;
  height: 90px;
`

export const DocumentHighlightStyle: string = css`
  ${animations.FLASH({
    originalColor: colors.GRAY_SUPER_LIGHT,
  })};
`

export const BaseCardStyle: string = css`
  scroll-margin-top: 100px;
`

export const FileExtensionIconStyle = (color: string, isLight?: boolean): string => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-size: 36px;
  ${isLight && 'width: 100%;'}
  color: ${colors[color]};
`

export const FileNameWrapperStyle = (isLight?: boolean): string => css`
  display: flex;
  overflow: hidden;
  ${fontSizes.MAIN};
  color: ${colors.BLACK};
  font-weight: bold;
  align-items: center;
  flex: 1;
  padding: 0 5px;
  margin: 5px 0 0 0;
  height: ${isLight ? '20px' : '40px'};
  line-height: 20px;
`

export const FileNameStyle = (isLight?: boolean): string => css`
  ${presets.MULTI_LINE_ELLIPSIS};
  -webkit-line-clamp: ${isLight ? 1 : 2};
  max-height: 40px;
  word-wrap: break-word;
`

export const FilePreviewStyle: string = css`
  display: flex;
  position: relative;
  justify-content: flex-start;
  height: 90px;
  img:first-child {
    display: none;
  }
`

export const ThumbnailPreviewStyle: any = css`
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const DocumentTypeStyle: string = css`
  padding: 0 5px;
  ${borderRadiuses.MAIN};
  background: ${colors.GRAY_SUPER_LIGHT};
  ${fontSizes.MAIN};
  color: ${colors.BLACK};
  font-weight: bold;
  ${presets.ELLIPSIS};
  margin: 0 5px;
  height: 20px;
  line-height: 20px;
`

export const DocumentParentWrapperStyle: string = css`
  padding: 0 5px;
`

export const CreatedAtStyle = (isLight?: boolean): string => css`
  color: ${colors.BLACK};
  ${!isLight && 'margin-bottom: 14px;'}
  ${fontSizes.SMALL};
  line-height: 20px;
  padding: 0 5px 0 0;
`

export const OwnedByStyle: string = css`
  ${presets.ELLIPSIS};
  width: 130px;
`

export const ButtonWrapperStyle: string = css`
  position: absolute;
  right: 5px;
  bottom: 10px;
`

export const ButtonLightWrapperStyle: string = css`
  flex-grow: 1;
`

export const TagsWrapperStyle: string = css`
  ${layout.GRID_HORIZONTAL};
  grid-gap: 5px;
  overflow: hidden;
  width: 180px;
  ${borderRadiuses.MAIN};
  padding-left: 5px;
`

export const DownloadButtonStyle = ({ disabled }: { disabled: boolean }): string => css`
  ${presets.BUTTON};
  padding-top: 5px;
  height: 18px;
  ${fontSizes.MAIN};
  ${disabled
    ? `
    color: ${colors.GRAY_SUPER_LIGHT};
    cursor: default;
  `
    : `
    color: ${colors.GRAY_LIGHT};
    &:hover {
      color: ${colors.TEAL};
      div {
        color: ${colors.TEAL};
      }
    }
  `};
`
