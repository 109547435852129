// @flow
import moment from 'moment'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { UserPayload, UserFragment } from '@graphql/server/flow'

import { formatDatetimeQueryToUTCDatetime, removeZSuffix, switchTimezoneSign } from 'utils/date'

function getDateFormat(language: string, showTime: boolean) {
  if (showTime) {
    switch (language) {
      case 'en':
        return 'MM/DD/YYYY h:mm A'
      case 'jp':
        return 'YYYY/M/D H:mm'
      default:
        return 'YYYY/M/D H:mm'
    }
  }

  switch (language) {
    case 'en':
      return 'MM/DD/YYYY'
    case 'jp':
      return 'YYYY/M/D'
    default:
      return 'YYYY/M/D'
  }
}

type Props = {
  value: ?string,
  user: UserPayload | $Diff<UserFragment, {| organization: * |}>,
  showTime?: boolean,
}

// Expects value in UTC pattern
export default function FormattedDateTZ({
  value,
  user,
  showTime = false,
}: Props): React.Node | string {
  return !value || user.__typename !== 'User' ? (
    <FormattedMessage id="components.cards.na" defaultMessage="N/A" />
  ) : (
    moment
      .utc(
        // $FlowIgnore Flow thinks formatDatetimeQueryToUTCDatetime could return null, but it can't because we already do check for it earlier
        removeZSuffix(formatDatetimeQueryToUTCDatetime(value)).concat(
          switchTimezoneSign(user?.timezone ?? '')
        )
      )
      .format(getDateFormat(user.language, showTime))
  )
}
