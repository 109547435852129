// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'

import type { Scalars } from '@graphql/server/flow'

import { DashedPlusButton, Label } from 'components/Form'
import LoadingIcon from 'components/LoadingIcon'

import { SelectionWrapperStyle } from './style'

export type SelectorProps = {
  open: boolean,
  onClose: () => void,
  selected: string[],
  setSelected: (string[]) => void,
}

type Props = {
  value: string[],
  readonly: boolean,
  onChange: (string[]) => void,
  title: any,
  selector?: (SelectorProps) => React.Node,
  query: GraphQL$DocumentNode,
  skipQuery?: boolean,
  getItems: (Object) => Object[],
  renderItem: (Object) => React.Node,
}

const DEFAULT_IDS: Scalars['ID'][] = []

const Ids = ({
  value,
  readonly,
  onChange,
  title,
  selector,
  query,
  skipQuery,
  getItems,
  renderItem,
}: Props): React.Node => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { data, loading } = useQuery(query, {
    variables: { ids: value || DEFAULT_IDS },
    fetchPolicy: 'cache-and-network',
    skip: skipQuery,
  })

  return (
    <>
      <Label height="30px">{title}</Label>

      <div
        className={SelectionWrapperStyle}
        role="presentation"
        onClick={() => {
          if (!readonly) {
            setOpen(true)
          }
        }}
      >
        {(() => {
          if (loading) {
            return <LoadingIcon size={20} />
          }

          const items = loading || !getItems ? [] : getItems(data)

          if (items.length === 0 && !readonly) {
            return <DashedPlusButton width="200px" height="30px" onClick={() => setOpen(true)} />
          }

          return items.map((item) => <div key={item?.id}>{renderItem(item)}</div>)
        })()}
      </div>

      {selector &&
        selector({
          open,
          onClose: () => setOpen(false),
          selected: value,
          setSelected: (newValue) => {
            onChange(newValue.filter(Boolean))
            setOpen(false)
          },
        })}
    </>
  )
}

export default Ids
