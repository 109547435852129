// @flow
import { useMutation, useQuery } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { unreadTimelineByEntity } from '@modules/TableView/CellRenderers/LogsRenderer/query'

import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import { getByPathWithDefault } from 'utils/fp'
import { decodeId } from 'utils/id'

import { timelineReadByEntity } from './mutation'
import { BadgeStyle, LogsButtonWrapperStyle } from './style'

type Props = {|
  onClick: Function,
  // prettier-ignore
  entityType: | 'order'
    | 'batch'
    | 'orderItem'
    | 'product'
    | 'productProvider'
    | 'shipment'
    | 'container'
    | 'file',
  entityId: string,
  openByDefault?: boolean,
|}

const LogsButton = ({
  onClick,
  entityType,
  entityId,
  openByDefault,
}: Props): React.Element<'button'> => {
  const requestEntityId = entityType === 'productProvider' ? entityId : decodeId(entityId)
  const { loading, data } = useQuery(unreadTimelineByEntity(entityType), {
    variables: {
      id: requestEntityId,
    },
  })
  const isDefaultOpened = React.useRef(false)

  const [timelineRead] = useMutation(timelineReadByEntity, {
    variables: {
      entity: {
        [`${entityType}Id`]: requestEntityId,
      },
    },
    refetchQueries: [
      // $FlowFixMe
      {
        query: unreadTimelineByEntity(entityType),
        variables: {
          id: requestEntityId,
        },
      },
    ],
  })

  React.useEffect(() => {
    if (openByDefault && !isDefaultOpened.current) {
      isDefaultOpened.current = true
      timelineRead()
      onClick()
    }
  }, [openByDefault, timelineRead, onClick])

  const badge = loading ? 0 : getByPathWithDefault(0, `${entityType}.timeline.unreadCount`, data)
  return (
    <button
      type="button"
      onClick={() => {
        if (badge) {
          timelineRead()
        }
        onClick()
      }}
      className={LogsButtonWrapperStyle}
    >
      <Icon icon="LOGS" />{' '}
      <FormattedMessage id="components.navBar.logsButton.logs" defaultMessage="LOGS" />
      {!!badge && badge > 0 && (
        <div className={BadgeStyle}>
          <FormattedNumber value={badge} />
        </div>
      )}
    </button>
  )
}

export default LogsButton
