// @flow

export const HIDE = 'hide'
export const NAVIGABLE = 'navigable'
export const READONLY = 'readOnly'
export const INITIAL_QUANTITY = 'quantity'
export const PRODUCED_QUANTITY = 'producedQuantity'
export const PRE_SHIPPED_QUANTITY = 'preShippedQuantity'
export const SHIPPED_QUANTITY = 'shippedQuantity'
export const POST_SHIPPED_QUANTITY = 'postShippedQuantity'
export const DELIVERED_QUANTITY = 'deliveredQuantity'
