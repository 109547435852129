// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, fontSizes, presets, scrollbars, shadows } from 'styles/common'

type OptionWrapperType = {
  customHeights: boolean,
  optionsStyle: string,
  width: string,
  height: string,
  dropDirection: 'down' | 'up',
}

export const OptionWrapperStyle = ({
  customHeights,
  optionsStyle,
  width,
  height,
  dropDirection,
}: OptionWrapperType): string => css`
  & > div {
    ${customHeights && 'height: unset !important;'}
    list-style-type: none;
    position: absolute;
    ${dropDirection === 'down'
      ? `
      top: calc(100% + 5px)
    `
      : `
      bottom: calc(100% + 5px)
    `};
    right: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 2;
    ${shadows.INPUT};
    min-width: ${width};
    max-width: ${width};
    background: ${colors.WHITE};
    ${borderRadiuses.MAIN};
    max-height: ${height};
    ${scrollbars.SMALL};
    cursor: pointer;
    ${optionsStyle || ''}
    > div {
      ${customHeights && 'height: unset !important'}
    }
  }
`

type OptionalProps = {
  customHeights: boolean,
  unselectable: boolean,
  onHover: boolean,
  selected: boolean,
  align: 'left' | 'right' | 'center',
  type: 'standard' | 'label',
}

export const OptionStyle = ({
  customHeights,
  unselectable,
  onHover,
  selected,
  align,
  type,
}: OptionalProps): string => css`
  ${
    customHeights &&
    'position: unset !important;' /* Remove absolute position so that parent can respond */
  }
  ${customHeights && 'top: unset !important;' /* Realign to top */}
  ${customHeights && 'height: unset !important;' /* Make height dynamic */}
  ${unselectable && 'pointer-events: none;'}
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${onHover ? colors.GRAY_SUPER_LIGHT : colors.WHITE};
  text-align: ${align};
  line-height: 20px;
  padding: 5px;
  ${presets.ELLIPSIS};
  flex-shrink: 0;
  ${type === 'label'
    ? `
    color: ${selected ? colors.TEAL : colors.GRAY_DARK};
    ${unselectable ? `color: ${colors.GRAY_LIGHT_HALF};` : ''}
    ${fontSizes.SMALL};
    letter-spacing: 2px;
    text-transform: uppercase;
  `
    : `
    color: ${selected ? colors.TEAL : colors.BLACK};
    ${unselectable ? `color: ${colors.GRAY_LIGHT_HALF};` : ''}
    ${fontSizes.MAIN};
    font-weight: bold;
  `};
`
