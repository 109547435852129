// @flow strict
import { navigate } from '@reach/router'
import * as React from 'react'
import { useRef, useState } from 'react'

import UserAvatar from '@components/UserAvatar'

import Icon from 'components/Icon'
import OutsideClickHandler from 'components/OutsideClickHandler'
import { UserConsumer } from 'contexts/Auth'
import { useNotification } from 'contexts/Notifications'

import { UserMenuDropdown } from './components'
import {
  NotificationBadgeStyle,
  NotificationsButtonStyle,
  NotificationsWrapperStyle,
  SettingsWrapperStyle,
  UserMenuButtonStyle,
  UserMenuWrapperStyle,
} from './style'

const UserNavBar = (): React.Element<'div'> => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false)
  const toggleUserMenu = (): void => setIsUserMenuOpen((prev) => !prev)
  const { unseen } = useNotification()

  const notificationRef = useRef<?HTMLButtonElement>()
  const userMenuRef = useRef<?HTMLButtonElement>()

  const handleClickOutside = () => {
    if (isUserMenuOpen) {
      toggleUserMenu()
    }
  }

  return (
    <div className={SettingsWrapperStyle}>
      <div className={NotificationsWrapperStyle}>
        <button
          className={NotificationsButtonStyle}
          onClick={async () => {
            navigate('/notifications/incoming')
          }}
          type="button"
          ref={notificationRef}
        >
          {unseen > 0 && (
            <div className={NotificationBadgeStyle}>{unseen > 99 ? '99+' : unseen}</div>
          )}
          <Icon icon="NOTIFICATION" />
        </button>
      </div>

      <div className={UserMenuWrapperStyle}>
        <button
          className={UserMenuButtonStyle}
          onClick={toggleUserMenu}
          type="button"
          data-testid="setting-button"
          ref={userMenuRef}
        >
          <UserConsumer>
            {({ user }) => (
              <UserAvatar
                firstName={user?.firstName ?? ''}
                lastName={user?.lastName ?? ''}
                hideTooltip
              />
            )}
          </UserConsumer>
        </button>

        <OutsideClickHandler
          onOutsideClick={handleClickOutside}
          ignoreClick={!isUserMenuOpen}
          ignoreElements={userMenuRef && userMenuRef.current ? [userMenuRef.current] : []}
        >
          <UserMenuDropdown isOpen={isUserMenuOpen} toggleUserMenu={toggleUserMenu} />
        </OutsideClickHandler>
      </div>
    </div>
  )
}

export default UserNavBar
