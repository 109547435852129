// @flow
import { either, is, map, pipe, prop, reject, when } from 'ramda'

import type { Tag } from '@graphql/server/flow'
import { isNotFound } from '@utils/data'

import type { __EnumValue, $Pick } from 'types'

export const enumsToTags = ({
  enums,
  color,
}: {
  enums: $Pick<__EnumValue, { __typename: *, name: *, description?: * }[]>,
  color: string,
}): $Pick<Tag, {| __typename: *, id: *, name: *, color: *, description: *, entityTypes: * |}[]> =>
  enums.flatMap((item) => ({
    __typename: 'Tag',
    id: item.name,
    name: item.name,
    color,
    description: item.name,
    entityTypes: [],
  }))

export const removeNotFound: Function = when(is(Array), reject(isNotFound))

export const removeNotFoundTag: Function = when(
  either(is(Array), is(Object)),
  pipe(
    (x) =>
      is(Object, x) && !is(Array, x) && prop('tags', x)
        ? { ...x, tags: removeNotFound(x.tags) }
        : x,
    map((a) => removeNotFoundTag(a))
  )
)

export const cleanTagsData = (entity: Object): any => {
  return removeNotFoundTag(entity)
}

export const reduceTagsByName = (tags: Object[]): { ... } => {
  return tags.reduce((arr, tag) => {
    if (!tag?.name) {
      return arr
    }

    if (!arr[tag.name]) {
      // eslint-disable-next-line
      arr[tag.name] = {
        ...tag,
      }
    }

    return arr
  }, {})
}

export default cleanTagsData
