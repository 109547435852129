// @flow strict
import { Link } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { BatchCardWithOwnedFragment, UserPayload } from '@graphql/server/flow'
import { isForbidden } from '@utils/data'

import { Display, FieldItem, Label } from 'components/Form'
import FormattedDateTZ from 'components/FormattedDateTZ'
import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import ProductImage from 'components/ProductImage'
import RelateEntity from 'components/RelateEntity'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'
import {
  DELIVERED_QUANTITY,
  POST_SHIPPED_QUANTITY,
  PRE_SHIPPED_QUANTITY,
  PRODUCED_QUANTITY,
  SHIPPED_QUANTITY,
} from 'modules/batch/constants'
import messages from 'modules/batch/messages'
import { ORDER_ITEMS_GET_PRICE } from 'modules/permission/constants/orderItem'
import { findActiveQuantityField } from 'utils/batch'
import { getByPathWithDefault } from 'utils/fp'
import { encodeId } from 'utils/id'
import { defaultVolumeMetric } from 'utils/metric'

import BaseCard from '../BaseCard'

import {
  BatchCardWrapperStyle,
  BatchInfoWrapperStyle,
  BatchNoWrapperStyle,
  BatchTagsWrapperStyle,
  ContainerWrapperStyle,
  DividerStyle,
  ImporterWrapperStyle,
  OrderWrapperStyle,
  ProductIconLinkStyle,
  ProductImageStyle,
  ProductInfoWrapperStyle,
  ProductNameStyle,
  ProductNameWrapperStyle,
  ProductProviderNameStyle,
  ProductSerialStyle,
  ProductWrapperStyle,
  QuantityWrapperStyle,
  ShipmentWrapperStyle,
  TagsAndTaskWrapperStyle,
} from './style'

type Props = {|
  batch: BatchCardWithOwnedFragment,
  onClick?: () => void,
  user: UserPayload,
|}

const defaultProps = {
  onClick: () => {},
}

const BatchCard = ({ batch, onClick, user, ...rest }: Props) => {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)

  const {
    no,
    archived,
    latestQuantity = 0,
    deliveredAt,
    desiredAt,
    packageQuantity = 0,
    tags = [],
    shipment,
    container,
  } = batch

  const packageVolume = batch.packageVolume || {
    metric: defaultVolumeMetric,
    value: 0,
  }

  let order = {}
  let price = null
  let currency = null
  let product = null
  let productProviderName = null
  let importer = null
  let exporter = null

  if (batch.orderItem.__typename === 'OrderItem') {
    order = batch.orderItem.order ?? order
    price = batch.orderItem.price ?? price
    currency = batch.orderItem.price.currency ?? currency
    if (batch.orderItem.productProvider.__typename === 'ProductProvider') {
      product = batch.orderItem.productProvider.product ?? product
      productProviderName = batch.orderItem.productProvider.name ?? productProviderName
    }
    if (batch.orderItem.order.__typename === 'Order') {
      importer =
        batch.orderItem.order.importer.__typename === 'Organization'
          ? batch.orderItem.order.importer ?? importer
          : importer
      exporter =
        batch.orderItem.order.importer.__typename === 'Organization'
          ? batch.orderItem.order.exporter ?? exporter
          : exporter
    }
  }

  return (
    <BaseCard icon="BATCH" color="BATCH" isArchived={archived} {...rest}>
      <div className={BatchCardWrapperStyle} onClick={onClick} role="presentation">
        <div className={ProductWrapperStyle}>
          <ProductImage
            className={ProductImageStyle}
            file={getByPathWithDefault(null, 'files.0', product)}
            height="70px"
          />

          <div className={ProductInfoWrapperStyle}>
            {product?.__typename === 'Product' && (product.id ?? '') && (
              <>
                <div className={ProductNameWrapperStyle}>
                  {/* $FlowFixMe Flow typed is not updated yet */}
                  <Link
                    className={ProductIconLinkStyle}
                    to={`/product/${encodeId(product.id)}`}
                    onClick={(evt: Event) => {
                      evt.stopPropagation()
                    }}
                  >
                    <Icon icon="PRODUCT" />
                  </Link>
                  <div className={ProductNameStyle}>{product.name}</div>
                </div>
                <div className={ProductSerialStyle}>{product.serial}</div>
                <div className={ProductProviderNameStyle}>
                  <Icon icon="PRODUCT_PROVIDER" />
                  {productProviderName}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={BatchInfoWrapperStyle}>
          <div className={BatchNoWrapperStyle}>
            <Display align="left">{no}</Display>
          </div>

          <div className={ImporterWrapperStyle}>
            <Icon icon="IMPORTER" />
            {importer?.__typename === 'Organization' && importer.name}
          </div>
          <div className={ImporterWrapperStyle}>
            <Icon icon="EXPORTER" />
            {exporter?.__typename === 'Organization' && exporter.name}
          </div>

          <div className={QuantityWrapperStyle}>
            <Label>
              <FormattedMessage
                {...messages[
                  findActiveQuantityField({
                    [PRODUCED_QUANTITY]: batch?.[PRODUCED_QUANTITY],
                    [PRE_SHIPPED_QUANTITY]: batch?.[PRE_SHIPPED_QUANTITY],
                    [SHIPPED_QUANTITY]: batch?.[SHIPPED_QUANTITY],
                    [POST_SHIPPED_QUANTITY]: batch?.[POST_SHIPPED_QUANTITY],
                    [DELIVERED_QUANTITY]: batch?.[DELIVERED_QUANTITY],
                  })
                ]}
              />
            </Label>
            <Display>
              <FormattedNumber value={latestQuantity} />
            </Display>
          </div>

          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.delivery" defaultMessage="DELIVERY" />
              </Label>
            }
            input={
              <Display>
                <FormattedDateTZ value={deliveredAt} user={user} />
              </Display>
            }
          />

          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.desiredAt" defaultMessage="DESIRED" />
              </Label>
            }
            input={
              <Display>
                <FormattedDateTZ value={desiredAt} user={user} />
              </Display>
            }
          />

          <div className={DividerStyle} />

          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.unitPrice" defaultMessage="UNIT PRICE" />
              </Label>
            }
            input={
              <Display blackout={!hasPermission(ORDER_ITEMS_GET_PRICE)}>
                <FormattedNumber
                  value={price && price.amount ? price.amount : 0}
                  suffix={order.currency || currency}
                />
              </Display>
            }
          />

          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.ttlPrice" defaultMessage="TTL PRICE" />
              </Label>
            }
            input={
              <Display
                blackout={
                  !hasPermission(ORDER_ITEMS_GET_PRICE) ||
                  batch.totalPrice?.__typename !== 'NewPrice'
                }
              >
                <FormattedNumber
                  value={batch.totalPrice?.__typename === 'NewPrice' ? batch.totalPrice.amount : 0}
                  suffix={
                    batch.totalPrice?.__typename === 'NewPrice' ? batch.totalPrice.currency : ''
                  }
                />
              </Display>
            }
          />

          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.ttlVol" defaultMessage="TTL VOL" />
              </Label>
            }
            input={
              <Display>
                {packageVolume && packageQuantity != null && (
                  <FormattedNumber
                    value={batch.totalVolume.value}
                    suffix={batch.totalVolume.metric}
                  />
                )}
              </Display>
            }
          />

          <div className={OrderWrapperStyle}>
            <RelateEntity
              link={order.__typename === 'Order' && order.id ? `/order/${encodeId(order.id)}` : ''}
              entity="ORDER"
              value={order.__typename === 'Order' && order.poNo}
            />
          </div>

          <div className={ShipmentWrapperStyle}>
            <RelateEntity
              link={
                shipment?.__typename === 'Shipment' && shipment.id
                  ? `/shipment/${encodeId(shipment.id)}`
                  : ''
              }
              blackout={isForbidden(shipment)}
              entity="SHIPMENT"
              value={shipment?.__typename === 'Shipment' && shipment && shipment.no}
            />
          </div>

          <div className={ContainerWrapperStyle}>
            <RelateEntity
              link={
                container?.__typename === 'Container' && container.id
                  ? `/container/${encodeId(container.id)}`
                  : ''
              }
              blackout={isForbidden(container)}
              entity="CONTAINER"
              value={container?.__typename === 'Container' && container.no}
            />
          </div>
          <div className={TagsAndTaskWrapperStyle}>
            <div className={BatchTagsWrapperStyle}>
              {tags.length > 0 &&
                tags.flatMap((tag) =>
                  tag.__typename === 'Tag' ? <Tag key={tag.id} tag={tag} /> : []
                )}
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

BatchCard.defaultProps = defaultProps

export default (withForbiddenCard(BatchCard, 'batch', {
  width: '195px',
  height: '426px',
  entityIcon: 'BATCH',
  entityColor: 'BATCH',
}): $FlowFixMe)
