import logger from 'loglevel'

import { isAppInProduction } from './env'

logger.setLevel('info')

if (isAppInProduction) {
  logger.setLevel('error')
}

export default logger
