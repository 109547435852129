// @flow
import { css } from 'react-emotion'

import { colors, fontSizes, presets } from 'styles/common'

export const RadiosWrapperStyle: any = css`
  ${fontSizes.MAIN};
  ${presets.ELLIPSIS};
  color: ${colors.BLACK};
`
