// @flow
import { navigate } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { Order } from '@graphql/server/flow'

import { Display, FieldItem, Label } from 'components/Form'
import FormattedDate from 'components/FormattedDate'
import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import QuantityChart from 'components/QuantityChart'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'
import { ORDER_ITEMS_GET_PRICE } from 'modules/permission/constants/orderItem'
import { encodeId } from 'utils/id'

import BaseCard from '../BaseCard'

import {
  ChartWrapperStyle,
  DividerStyle,
  ImporterWrapperStyle,
  OrderCardWrapperStyle,
  OrderInfoWrapperStyle,
  PONoWrapperStyle,
  TagsAndTaskWrapperStyle,
  TagsWrapperStyle,
} from './style'

type OptionalProps = {
  actions: React.Node[],
  onClick: Function,
  onSelect: Function,
}

type Props = OptionalProps & {
  order: Order,
}

const defaultProps = {
  actions: [],
}

const OrderCard = ({ order, actions, onClick, onSelect, ...rest }: Props) => {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)

  let archived = null
  let poNo = null
  let issuedAt = null
  let deliveryDate = null
  let totalPrice = null
  let totalOrdered = 0
  let totalBatched = 0
  let totalShipped = 0
  let batchCount = 0
  let batchShippedCount = 0
  let orderItemCount = null
  let importer = null
  let exporter = null

  if (order.__typename === 'Order') {
    archived = order.archived
    poNo = order.poNo
    issuedAt = order.issuedAt
    deliveryDate = order.deliveryDate
    totalPrice = order.totalPrice
    totalOrdered = order.totalOrdered
    totalBatched = order.totalBatched
    totalShipped = order.totalShipped
    batchCount = order.batchCount
    batchShippedCount = order.batchShippedCount
    orderItemCount = order.orderItemCount
    importer = order.importer
    exporter = order.exporter
  }

  return (
    <BaseCard
      // showBadge={order?.notificationUnseenCount > 0}
      filesUnreadCount={order?.filesUnreadCount}
      unreadMessageCount={order?.timeline?.unreadMessageCount}
      notificationUnseenCount={order?.notificationUnseenCount}
      icon="ORDER"
      color="ORDER"
      actions={actions}
      isArchived={archived}
      notificationPosition="46px"
      onSelect={() => {
        if (onSelect) {
          onSelect(order)
        }
      }}
      onCommentIconClick={() => {
        navigate(`/order/${encodeId(order.id)}?default=logs`)
      }}
      onDocumentIconClick={() => {
        navigate(`/order/${encodeId(order.id)}?default=documents`)
      }}
      onNotificationIconClick={() => {
        navigate(`/order/${encodeId(order.id)}`)
      }}
      {...rest}
    >
      <div className={OrderCardWrapperStyle} onClick={onClick} role="presentation">
        <div className={OrderInfoWrapperStyle}>
          <div className={PONoWrapperStyle}>
            <Display align="left">{poNo}</Display>
          </div>
          <div className={ImporterWrapperStyle}>
            <Icon icon="IMPORTER" />
            {importer?.__typename === 'Organization' && importer.name}
          </div>
          <div className={ImporterWrapperStyle}>
            <Icon icon="EXPORTER" />
            {exporter?.__typename === 'Organization' && exporter.name}
          </div>
          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.ttlPrice" defaultMessage="TTL PRICE" />
              </Label>
            }
            input={
              <Display blackout={!hasPermission(ORDER_ITEMS_GET_PRICE)}>
                {totalPrice && (
                  <FormattedNumber value={totalPrice.amount} suffix={totalPrice.currency} />
                )}
              </Display>
            }
          />
          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.ttlItems" defaultMessage="TTL ITEMS" />
              </Label>
            }
            input={
              <Display>
                <FormattedNumber value={orderItemCount} />
              </Display>
            }
          />
          <FieldItem
            label={
              <Label>
                <FormattedMessage id="components.cards.poDate" defaultMessage="PO DATE" />
              </Label>
            }
            input={
              <Display>
                <FormattedDate value={issuedAt} />
              </Display>
            }
          />
          <FieldItem
            label={
              <Label>
                <FormattedMessage
                  id="components.cards.contractDate"
                  defaultMessage="CONTRACT DATE"
                />
              </Label>
            }
            input={
              <Display>
                <FormattedDate value={deliveryDate} />
              </Display>
            }
          />
          <div className={DividerStyle} />
          <div className={ChartWrapperStyle}>
            <QuantityChart
              hasLabel={false}
              orderedQuantity={totalOrdered}
              batchedQuantity={totalBatched}
              shippedQuantity={totalShipped}
              batched={batchCount}
              shipped={batchShippedCount}
            />
          </div>
          <div className={TagsAndTaskWrapperStyle}>
            <div className={TagsWrapperStyle}>
              {((order && order.tags) || []).flatMap((tag) =>
                tag.id ? <Tag key={tag.id} tag={tag} /> : []
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

OrderCard.defaultProps = defaultProps

export default (withForbiddenCard(React.memo(OrderCard), 'order', {
  width: '195px',
  height: '268px',
  entityIcon: 'ORDER',
  entityColor: 'ORDER',
}): any)
