// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors } from 'styles/common'

export const ProgressBarStyle: string = css`
  display: flex;
  position: relative;
  background-color: ${colors.GRAY_SUPER_LIGHT};
  width: 100%;
  ${borderRadiuses.BUTTON};
  margin: 55px 0;
`

export const BarStyle = (percent: number): string => css`
  background-color: ${percent ? colors.TEAL : colors.GRAY_SUPER_LIGHT};
  height: 10px;
  min-width: 10px;
  width: ${percent}%;
  ${borderRadiuses.BUTTON};
`
