// @flow
import { cloneDeep, set, unset } from 'lodash'
import { Container } from 'unstated'

import { cleanFalsyAndTypeName, cleanUpData, removeNulls } from '@utils/data'

import { isEquals } from 'utils/fp'

type FormState = {
  mask: Object,
  fieldValues: Object[],
  fieldDefinitions: Object[],
}

const initValues = {
  mask: null,
  fieldValues: [],
  fieldDefinitions: [],
}

export default class CustomFieldsContainer extends Container<FormState> {
  state: {| fieldDefinitions: any[], fieldValues: any[], mask: null |} = initValues

  originalValues: any | {| fieldDefinitions: any[], fieldValues: any[], mask: null |} = initValues

  isDirty: () => boolean = () =>
    !isEquals(cleanFalsyAndTypeName(this.state), cleanFalsyAndTypeName(this.originalValues))

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
    this.setState(this.originalValues)
  }

  initDetailValues: (values: any) => void = (values: Object) => {
    const parsedValues: Object = { ...initValues, ...cleanUpData(values) }
    this.setState(parsedValues)
    this.originalValues = { ...parsedValues }
  }

  setFieldValue: (path: string, value: any) => void = (path: string, value: any) => {
    this.setState((prevState) => {
      return set(cloneDeep(prevState), path, value)
    })
  }

  removeArrayItem: (path: string) => void = (path: string) => {
    this.setState((prevState) => {
      const cloneState = cloneDeep(prevState)
      unset(cloneState, path)
      return removeNulls(cloneState)
    })
  }
}
