// @flow

import { useLazyQuery } from '@apollo/client'
import * as React from 'react'

import type { Scalars, TagEntityType } from '@graphql/server/flow'

import useDebounce from 'hooks/useDebounce'

type Props = {
  tagType: TagEntityType[] | TagEntityType,
  connectionIds?: $ElementType<Scalars, 'ID'[]>,
  organizationIds?: $ElementType<Scalars, 'ID'[]>,
  queryString?: string,
  query?: any,
}

const requeryThreshold = 100

const useTagList = ({
  tagType,
  connectionIds,
  organizationIds,
  queryString,
  query,
}: Props): any => {
  const isMounted = React.useRef(true)
  const debouncedQueryString = useDebounce(queryString, 100)

  const [pageSettings, setPageSettings] = React.useState({
    page: 1,
    perPage: 100,
  })

  const queryStringRef = React.useRef(queryString)
  const tagDataRef = React.useRef({})

  const [totalPages, setTotalPages] = React.useState(0)

  const variables = {
    ...pageSettings,
    sortBy: {
      name: 'ASCENDING',
    },
    filterBy: {
      query: debouncedQueryString || '',
      entityTypes: Array.isArray(tagType) ? tagType : [tagType],
      connectionIds,
      organizationIds: organizationIds ?? [],
    },
  }

  const [getTags, { data, loading }] = useLazyQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (newData) => {
      const value = newData?.tags
      setTotalPages(value.totalPage)
    },
  })

  const [tagData, setTagData] = React.useState([])

  // empty tag data if query string changes
  React.useEffect(() => {
    if (queryStringRef.current !== queryString) {
      setTagData([])
      queryStringRef.current = queryString
      tagDataRef.current = {}
    }
  }, [queryString])

  React.useEffect(() => {
    if (!data || loading) {
      return
    }

    const newTagsById = (data?.tags?.nodes ?? []).reduce((memo, tag) => {
      if (!tag?.id) {
        return memo
      }

      // eslint-disable-next-line
      memo[tag.id] = tag

      return memo
    }, {})

    tagDataRef.current = {
      ...tagDataRef.current,
      ...newTagsById,
    }

    setTagData(Object.values(tagDataRef.current))
  }, [data, loading])

  React.useEffect(() => {
    if (
      (isMounted.current && totalPages === 0) ||
      (pageSettings.page !== 1 && totalPages > 0 && pageSettings.page <= totalPages)
    ) {
      getTags()
    }

    return () => {
      isMounted.current = false
    }
  }, [debouncedQueryString, getTags, totalPages, pageSettings, connectionIds])

  const onScroll = React.useCallback(
    ({ event, scrollHeight, scrollTop, clientHeight }: Object) => {
      if (
        !event?.target &&
        (Number.isNaN(scrollHeight) || Number.isNaN(scrollTop) || Number.isNaN(clientHeight))
      ) {
        return
      }

      let nearTheBottom = false

      if (event) {
        nearTheBottom =
          event.target.scrollHeight - event.target.scrollTop <=
          event.target.clientHeight + requeryThreshold
      } else {
        nearTheBottom = scrollHeight - scrollTop <= clientHeight + requeryThreshold
      }

      if (!loading && pageSettings.page < totalPages && nearTheBottom) {
        setPageSettings((oldSettings) => {
          return {
            ...oldSettings,
            page: oldSettings.page + 1,
          }
        })
      }
    },
    [loading, pageSettings.page, totalPages]
  )

  return {
    tags: tagData,
    loading,
    onScroll,
  }
}

export default useTagList
