// @flow strict
import { defineMessages } from 'react-intl'

export default (defineMessages({
  AIR: {
    id: 'modules.Shipment.LoadType.AIR',
    defaultMessage: 'AIR',
  },
  FCL: {
    id: 'modules.Shipment.LoadType.FCL',
    defaultMessage: 'FCL',
  },
  LCL: {
    id: 'modules.Shipment.LoadType.LCL',
    defaultMessage: 'LCL',
  },
}): $FlowFixMe)
