// @flow

import tagsQuery from '@graphql/client/tag/query.tags.graphql'
import type { Scalars, TagEntityType } from '@graphql/server/flow'

import useTagList from 'hooks/useTagList'

type Props = {
  children: any,
  tagType: TagEntityType,
  connectionIds: $ElementType<Scalars, 'ID'[]>,
  organizationIds?: $ElementType<Scalars, 'ID'[]>,
  queryString?: string,
}

const TagListProvider = ({
  children,
  tagType,
  connectionIds,
  organizationIds,
  queryString,
}: Props): any => {
  const { tags, loading, onScroll } = useTagList({
    tagType,
    connectionIds,
    organizationIds,
    query: tagsQuery,
    queryString,
  })

  return children({
    data: tags,
    loading,
    onScroll,
  })
}

export default TagListProvider
