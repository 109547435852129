// @flow
import { navigate } from '@reach/router'
import * as React from 'react'

import { OrderCard } from 'components/Cards'
import { useEntityHasPermissions } from 'contexts/Permissions'
import { ORDER_FORM } from 'modules/permission/constants/order'
import { encodeId } from 'utils/id'

type Props = {
  order: Object,
}

const ParentOrderCard = ({ order }: Props): React.Node => {
  const hasPermissions = useEntityHasPermissions(order)

  return (
    <OrderCard
      order={order}
      onClick={() => {
        if (hasPermissions(ORDER_FORM) && !!order?.id) {
          navigate(`/order/${encodeId(order?.id)}`)
        }
      }}
    />
  )
}

export default ParentOrderCard
