// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { Props as BaseCardProps } from 'components/Cards/BaseCard/BaseCard'

import BaseCard from '../BaseCard'

import {
  TemplateCardWrapperStyle,
  TemplateCountStyle,
  TemplateCountWrapperStyle,
  TemplateNameStyle,
} from './style'

type Props = {|
  connection: {
    id: string,
    title: string,
    count: ?number,
  },
  ...$Diff<BaseCardProps, { children: React.Node }>,
|}

const ConnectionCard = ({ connection, ...rest }: Props): React.Node => {
  const { title, count } = connection

  return (
    <BaseCard icon="CONNECTION" color="CONNECTION" preferIcon {...rest}>
      <div className={TemplateCardWrapperStyle} role="presentation">
        <div className={TemplateNameStyle}>{title}</div>
        <div className={TemplateCountWrapperStyle}>
          <div className={TemplateCountStyle}>
            {typeof count === 'number' ? (
              <FormattedMessage
                id="modules.Connection.countedOrganizations"
                defaultMessage="{count} Organizations"
                values={{
                  count,
                }}
              />
            ) : (
              <FormattedMessage
                id="modules.Users.ownOrganization"
                defaultMessage="Your Organization"
              />
            )}
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

export default ConnectionCard
