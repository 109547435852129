// @flow
import { css } from 'react-emotion'

import { colors, fontSizes, layout, presets } from 'styles/common'

export const CheckboxWrapperStyle: any = css`
  ${layout.HORIZONTAL};

  & > span {
    margin-left: 10px;
    ${fontSizes.MAIN};
    ${presets.ELLIPSIS};
    color: ${colors.BLACK};
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`
