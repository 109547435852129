// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  createdAtSort: {
    id: 'modules.TableTemplates.list.sort.createdAt',
    defaultMessage: 'Created At',
  },
  updatedAtSort: {
    id: 'modules.TableTemplates.list.sort.updatedAt',
    defaultMessage: 'Updated At',
  },
  name: {
    id: 'modules.TableTemplates.name',
    defaultMessage: 'Name',
  },
  createdAt: {
    id: 'modules.TableTemplates.createdAt',
    defaultMessage: 'Created At',
  },
  updatedAt: {
    id: 'modules.TableTemplates.updatedAt',
    defaultMessage: 'Updated At',
  },
  type: {
    id: 'modules.TableTemplates.type',
    defaultMessage: 'Type',
  },
  order: {
    id: 'modules.TableTemplates.order',
    defaultMessage: 'Order Edit',
  },
  orderSheet: {
    id: 'modules.TableTemplates.orderSheet',
    defaultMessage: 'Order Table',
  },
  batchSheet: {
    id: 'modules.TableTemplates.batchSheet',
    defaultMessage: 'Batch Table',
  },
  shipmentSheet: {
    id: 'modules.TableTemplates.shipmentSheet',
    defaultMessage: 'Shipment Table',
  },
}): any)
