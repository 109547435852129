// @flow strict
import { defineMessages } from 'react-intl'

export default (defineMessages({
  category: {
    id: 'components.NavBar.Filter.category',
    defaultMessage: 'category',
  },
  filter: {
    id: 'components.NavBar.Filter.filter',
    defaultMessage: 'filter',
  },
  from: {
    id: 'components.NavBar.Filter.from',
    defaultMessage: 'from',
  },
  chooseCategory: {
    id: 'components.NavBar.Filter.chooseCategory',
    defaultMessage: 'Please choose a category first',
  },
  chooseFilter: {
    id: 'components.NavBar.Filter.chooseFilter',
    defaultMessage: 'Please choose a filter first',
  },
  clearAll: {
    id: 'components.NavBar.Filter.clearAll',
    defaultMessage: 'clear all',
  },
  addFilter: {
    id: 'components.NavBar.Filter.addFilter',
    defaultMessage: 'add filter',
  },
  disabledAddMessage: {
    id: 'components.NavBar.Filter.disabledAddMessage',
    defaultMessage: 'Please fill out the previous filter',
  },
  to: {
    id: 'components.NavBar.Filter.to',
    defaultMessage: 'to',
  },
  connections: {
    id: 'components.NavBar.Filter.connections',
    defaultMessage: 'Connections',
  },
  status: {
    id: 'components.NavBar.Filter.status',
    defaultMessage: 'STATUS',
  },
  active: {
    id: 'components.NavBar.Filter.active',
    defaultMessage: 'Active',
  },
  archived: {
    id: 'components.NavBar.Filter.archived',
    defaultMessage: 'Archived',
  },
  booked: {
    id: 'components.NavBar.Filter.booked',
    defaultMessage: 'Booked',
  },
  notBooked: {
    id: 'components.NavBar.Filter.notBooked',
    defaultMessage: 'Not booked',
  },
  tags: {
    id: 'components.NavBar.Filter.tags',
    defaultMessage: 'Tags',
  },
  organizations: {
    id: 'components.NavBar.Filter.organizations',
    defaultMessage: 'Organizations',
  },
  importers: {
    id: 'components.NavBar.Filter.importers',
    defaultMessage: 'Importers',
  },
  exporters: {
    id: 'components.NavBar.Filter.exporters',
    defaultMessage: 'Exporters',
  },
  suppliers: {
    id: 'components.NavBar.Filter.suppliers',
    defaultMessage: 'Suppliers',
  },
  forwarders: {
    id: 'components.NavBar.Filter.forwarders',
    defaultMessage: 'Forwarders',
  },
  warehousers: {
    id: 'components.NavBar.Filter.warehousers',
    defaultMessage: 'Warehousers',
  },
  notifications: {
    id: 'components.NavBar.Filter.notifications',
    defaultMessage: 'Notifications',
  },
  organization: {
    id: 'components.NavBar.Filter.organization',
    defaultMessage: 'Organization',
  },
  importer: {
    id: 'components.NavBar.Filter.importer',
    defaultMessage: 'Importer',
  },
  exporter: {
    id: 'components.NavBar.Filter.exporter',
    defaultMessage: 'Exporter',
  },
  supplier: {
    id: 'components.NavBar.Filter.supplier',
    defaultMessage: 'Supplier',
  },
  forwarder: {
    id: 'components.NavBar.Filter.forwarder',
    defaultMessage: 'Forwarder',
  },
  warehouser: {
    id: 'components.NavBar.Filter.warehouser',
    defaultMessage: 'Warehouser',
  },
  orders: {
    id: 'components.NavBar.Filter.orders',
    defaultMessage: 'Orders',
  },
  shipments: {
    id: 'components.NavBar.Filter.shipments',
    defaultMessage: 'Shipments',
  },
  warehouses: {
    id: 'components.NavBar.Filter.warehouses',
    defaultMessage: 'Warehouses',
  },
  products: {
    id: 'components.NavBar.Filter.products',
    defaultMessage: 'Products',
  },
  productProviders: {
    id: 'components.NavBar.Filter.productProviders',
    defaultMessage: 'End Products',
  },
  containers: {
    id: 'components.NavBar.Filter.containers',
    defaultMessage: 'Containers',
  },
  users: {
    id: 'components.NavBar.Filter.users',
    defaultMessage: 'Users',
  },
  ports: {
    id: 'components.NavBar.Filter.ports',
    defaultMessage: 'Ports',
  },
  addPort: {
    id: 'components.NavBar.Filter.addPort',
    defaultMessage: 'Add Port',
  },
  portPlaceholder: {
    id: 'components.NavBar.Filter.portPlaceholder',
    defaultMessage: 'Please start typing a port',
  },
  organizationTypes: {
    id: 'components.NavBar.Filter.organizationTypes',
    defaultMessage: 'Types',
  },
  maskEditTypes: {
    id: 'components.NavBar.Filter.maskEditTypes',
    defaultMessage: 'Types',
  },
  containerType: {
    id: 'components.NavBar.Filter.containerType',
    defaultMessage: 'Container Type',
  },
  containerTypePlaceholder: {
    id: 'components.NavBar.Filter.containerTypePlaceholder',
    defaultMessage: 'Please choose a type',
  },
  containerOption: {
    id: 'components.NavBar.Filter.containerOption',
    defaultMessage: 'Container Option',
  },
  containerOptionPlaceholder: {
    id: 'components.NavBar.Filter.containerOptionPlaceholder',
    defaultMessage: 'Please choose an option',
  },
  country: {
    id: 'components.NavBar.Filter.country',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'components.NavBar.Filter.countryPlaceholder',
    defaultMessage: 'Please choose a country',
  },
  completelyBatched: {
    id: 'components.NavBar.Filter.completelyBatched',
    defaultMessage: 'Fully Batched',
  },
  notCompletelyBatched: {
    id: 'components.NavBar.Filter.notCompletelyBatched',
    defaultMessage: 'Not Fully Batched',
  },
  completelyShipped: {
    id: 'components.NavBar.Filter.completelyShipped',
    defaultMessage: 'Fully Shipped',
  },
  notCompletelyShipped: {
    id: 'components.NavBar.Filter.notCompletelyShipped',
    defaultMessage: 'Not Fully Shipped',
  },
  hasShipment: {
    id: 'components.NavBar.Filter.hasShipment',
    defaultMessage: 'Has Shipment',
  },
  hasNotShipment: {
    id: 'components.NavBar.Filter.hasNotShipment',
    defaultMessage: `Doesn't Have Shipment`,
  },
  hasEntity: {
    id: 'components.NavBar.Filter.hasEntity',
    defaultMessage: 'Has Parent',
  },
  hasNotEntity: {
    id: 'components.NavBar.Filter.hasNotEntity',
    defaultMessage: `Doesn't Have Parent`,
  },
  hasUnseenNotification: {
    id: 'components.NavBar.Filter.hasUnseenNotification',
    defaultMessage: 'Has Update',
  },
  hasNotUnseenNotification: {
    id: 'components.NavBar.Filter.hasNotUnseenNotification',
    defaultMessage: `No Updates`,
  },
  freeTimeOverdue: {
    id: 'components.NavBar.Filter.freeTimeOverdue',
    defaultMessage: 'Free Time Overdue',
  },
  freeTimeNotOverdue: {
    id: 'components.NavBar.Filter.freeTimeNotOverdue',
    defaultMessage: 'Free Time Not Overdue',
  },
  shipmentLoadType: {
    id: 'components.NavBar.Filter.shipmentLoadType',
    defaultMessage: 'Load Type',
  },
  fileType: {
    id: 'components.NavBar.Filter.fileType',
    defaultMessage: 'File Type',
  },
  fileTypePlaceholder: {
    id: 'components.NavBar.Filter.fileTypePlaceholder',
    defaultMessage: 'Please choose a file type',
  },
  orderPo: {
    id: 'components.NavBar.Filter.orderPo',
    defaultMessage: 'Order PO',
  },
  orderPi: {
    id: 'components.NavBar.Filter.orderPi',
    defaultMessage: 'Order PI',
  },
  shipmentBl: {
    id: 'components.NavBar.Filter.shipmentBl',
    defaultMessage: 'Shipment BL',
  },
  shipmentInvoice: {
    id: 'components.NavBar.Filter.shipmentInvoice',
    defaultMessage: 'Shipment Invoice',
  },
  shipmentPackingList: {
    id: 'components.NavBar.Filter.shipmentPackingList',
    defaultMessage: 'Shipment Packing List',
  },
  shipmentImportDeclaration: {
    id: 'components.NavBar.Filter.shipmentImportDeclaration',
    defaultMessage: 'Shipment Import Declaration',
  },
  shipmentInspectionApplication: {
    id: 'components.NavBar.Filter.shipmentInspectionApplication',
    defaultMessage: 'Shipment Inspection Application',
  },
  shipmentWarehouseArrivalReport: {
    id: 'components.NavBar.Filter.shipmentWarehouseArrivalReport',
    defaultMessage: 'Shipment Warehouse Arrival Report',
  },
  shipmentWarehouseInspectionReport: {
    id: 'components.NavBar.Filter.shipmentWarehouseInspectionReport',
    defaultMessage: 'Shipment Warehouse Inspection Report',
  },
  shipmentExportDeclaration: {
    id: 'components.NavBar.Filter.shipmentExportDeclaration',
    defaultMessage: 'Shipment Export Declaration',
  },
  shipmentShippingAdvice: {
    id: 'components.NavBar.Filter.shipmentShippingAdvice',
    defaultMessage: 'Shipment Shipping Advice',
  },
  shipmentWarehouseLoadingReport: {
    id: 'components.NavBar.Filter.shipmentWarehouseLoadingReport',
    defaultMessage: 'Shipment Warehouse Loading Report',
  },
  productSpec: {
    id: 'components.NavBar.Filter.productSpec',
    defaultMessage: 'Product Spec',
  },
  productAnalysisCert: {
    id: 'components.NavBar.Filter.productAnalysisCert',
    defaultMessage: 'Product Analysis Cert',
  },
  productOriginCert: {
    id: 'components.NavBar.Filter.productOriginCert',
    defaultMessage: 'Product Origin Cert',
  },
}): $FlowFixMe)
