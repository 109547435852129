// @flow
import { css } from 'react-emotion'

export const NumberInputsWrapperStyle: string = css`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 20px;
  width: 100%;
`

export default NumberInputsWrapperStyle
