// @flow
import noop from 'lodash/noop'
import * as React from 'react'

import {
  RadioButtonStyle,
  RadioInputActionsWrapperStyle,
  RadioInputFilterFormWrapperStyle,
  RadioInputLabelStyle,
} from './style'

type OptionalProps = {
  readOnly: boolean,
  disabled: boolean,
  actions: ?(React.Node[]),
}

type Props = OptionalProps & {
  selected: boolean,
  onToggle: Function,
  children: React.Node,
}

const defaultProps = {
  readOnly: true,
  disabled: false,
  actions: [],
}

class RadioInputFilterForm extends React.PureComponent<Props> {
  static defaultProps: {| actions: any[], disabled: boolean, readOnly: boolean |} = defaultProps

  render(): React.Element<'div'> {
    const { selected, onToggle, children, disabled, readOnly, actions, ...rest } = this.props

    return (
      <div className={RadioInputFilterFormWrapperStyle}>
        <div
          className={RadioInputLabelStyle(selected, disabled)}
          onClick={!disabled ? onToggle : noop}
          role="presentation"
        >
          <button className={RadioButtonStyle(selected, disabled)} type="button" {...rest} />
          {children}
        </div>
        {!readOnly ? <div className={RadioInputActionsWrapperStyle}>{actions}</div> : null}
      </div>
    )
  }
}

export default RadioInputFilterForm
