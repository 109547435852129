// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import tagsByIDsMinimalQuery from '@graphql/client/tag/query.tagsByIDsMinimal.graphql'

import { Label, RadioInput, TagsInput } from 'components/Form'
import { useViewerHasPermissions } from 'contexts/Permissions'
import { TAG_GET } from 'modules/permission/constants/tag'
import { reduceTagsByName } from 'utils/tags'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

import { OperatorWrapperStyle } from './style'

type ImplProps = {
  ...FilterInputProps<{
    operator: string,
    ids: string[],
  }>,
  tagType: string,
}

const TagsImpl = ({ value, readonly, onChange, tagType }: ImplProps) => {
  const hasPermissions = useViewerHasPermissions()
  const { data } = useQuery(tagsByIDsMinimalQuery, {
    variables: { ids: [...new Set([...value.ids])] },
    fetchPolicy: 'cache-and-network',
  })

  const tags = React.useMemo(() => {
    return Object.values(reduceTagsByName(data?.tagsByIDs ?? []))
  }, [data])

  return (
    <>
      <Label height="30px">
        <div className={OperatorWrapperStyle}>
          <FormattedMessage {...messages.tags} />
          <RadioInput
            onToggle={() => onChange({ ...value, operator: 'AND' })}
            editable
            selected={value.operator === 'AND'}
          >
            AND
          </RadioInput>
          <RadioInput
            onToggle={() => onChange({ ...value, operator: 'OR' })}
            editable
            selected={value.operator === 'OR'}
          >
            OR
          </RadioInput>
        </div>
      </Label>

      <TagsInput
        name="tags"
        width="200px"
        tagType={tagType}
        disabled={readonly}
        values={tags}
        onChange={(newTags) => {
          const { newIds } = newTags.reduce(
            (arr, newTag) => {
              arr.newIds.push(newTag.id)
              return arr
            },
            {
              newIds: [],
            }
          )
          onChange({ ...value, ids: newIds })
        }}
        onClickRemove={(removedTag) => {
          let newIds = [...value.ids]
          newIds = newIds.filter((id) => id !== removedTag.id)
          onChange({ ...value, ids: newIds })
        }}
        editable={{
          set: hasPermissions(TAG_GET),
          remove: true,
        }}
      />
    </>
  )
}

const TagsWithOperator =
  (
    tagType: string
  ): ((
    FilterInputProps<{
      ids: string[],
      operator: string,
      ...
    }>
  ) => React.Node) =>
  ({
    value,
    onChange,
    readonly,
  }: FilterInputProps<{
    operator: string,
    ids: string[],
  }>) =>
    <TagsImpl value={value} readonly={readonly} onChange={onChange} tagType={tagType} />

export const ProductTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('Product')
export const OrderTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('Order')
export const OrderItemTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('OrderItem')
export const BatchTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('Batch')
export const ContainerTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('Container')
export const ShipmentTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('Shipment')
export const FileTagsWithOperator: (
  FilterInputProps<{
    ids: string[],
    operator: string,
    ...
  }>
) => React.Node = TagsWithOperator('File')

export default TagsWithOperator
