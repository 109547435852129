// @flow
import * as React from 'react'

import documentListQuery from '@graphql/client/document/query.documentList.graphql'
import type { FilePayload, FileFilterInput, FileSortInput } from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'
import { isForbidden } from '@utils/data'

import { CancelButton, SaveButton } from 'components/Buttons'
import { DocumentCard } from 'components/Cards'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  FileFilterConfig,
  FileSortConfig,
  Filter,
  Search,
  Sort,
} from 'components/NavBar'
import Selector from 'components/Selector'
import { useViewerHasPermissions } from 'contexts/Permissions'
import useFilterSort from 'hooks/useFilterSort'
import DocumentGridView from 'modules/document/list/DocumentGridView'
import { canDownloadFile, canViewFile } from 'utils/file'
import { uuid } from 'utils/id'
import { getParentInfo } from 'utils/task'

type Props = {
  onCancel: Function,
  onSelect: Function,
  alreadyAddedDocuments: Object[],
}

const RenderItem = ({ file, selectItemProps }: {| file: FilePayload, selectItemProps: any |}) => {
  const { parentType } = getParentInfo(file.entity ? file.entity : {})
  const hasPermissions = useViewerHasPermissions()
  const canView = canViewFile(hasPermissions, file.type ? file?.type : ('': any), parentType)
  const canDownload = canDownloadFile(hasPermissions, parentType)

  if (!canView) return null

  return (
    <DocumentCard
      key={file.id ? file.id : uuid()}
      file={file}
      downloadable={canDownload}
      hideParentInfo
      {...selectItemProps}
    />
  )
}

const DocumentsSelector = ({ onCancel, onSelect, alreadyAddedDocuments }: Props): React.Node => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<FileFilterInput, FileSortInput>(
      { query: '', hasEntity: false },
      { updatedAt: 'DESCENDING' }
    )

  const { nodes, loading, hasMore, loadMore } = useQueryList(
    documentListQuery,
    {
      variables: {
        filterBy: { query, excludeIds: alreadyAddedDocuments.map((file) => file.id), ...filterBy },
        sortBy,
        page: 1,
        perPage: 20,
      },
      fetchPolicy: 'network-only',
    },
    'files',
    { disableReset: true }
  )

  return (
    <Selector.Many selected={[]}>
      {({ value, dirty, getItemProps }) => (
        <SlideViewLayout>
          <SlideViewNavBar>
            <EntityIcon icon="DOCUMENT" color="DOCUMENT" />

            <Filter
              config={FileFilterConfig}
              rootQueryType="File"
              filterBy={filterByWithoutQuery}
              onChange={setFilterBy}
              staticFilters={['hasEntity']}
            />
            <Search query={query} onChange={setQuery} />
            <Sort config={FileSortConfig} sortBy={sortBy} onChange={setSortBy} />

            <CancelButton onClick={onCancel} />
            <SaveButton
              data-testid="saveButtonOnSelectDocuments"
              disabled={!dirty}
              onClick={() => onSelect(value)}
            />
          </SlideViewNavBar>

          <Content>
            <DocumentGridView
              files={nodes.filter((file) => !isForbidden(file))}
              onLoadMore={loadMore}
              hasMore={hasMore}
              isLoading={loading}
              renderItem={({ file }) => (
                <RenderItem file={file} selectItemProps={getItemProps(file)} />
              )}
            />
          </Content>
        </SlideViewLayout>
      )}
    </Selector.Many>
  )
}

export default DocumentsSelector
