// @flow
import { css } from 'react-emotion'

import { borderRadiuses, colors, fontSizes } from 'styles/common'

export const NewBadgeStyle = (size: number): any => css`
  width: ${size}px;
  height: ${size}px;
  background-color: ${colors.RED};
  ${borderRadiuses.CIRCLE};
  border: 1.5px solid ${colors.GRAY_SUPER_LIGHT};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  ${fontSizes.SMALL};
`
