// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import messages from 'modules/chat/messages'
import { DeletedMessageStyle } from 'modules/chat/style'

type Props = {
  type?: 'file' | 'text',
}

const DeletedMessage = ({ type = 'text' }: Props): React.Element<'div'> => {
  return (
    <div className={DeletedMessageStyle}>
      {type === 'text' && (
        <span>
          <FormattedMessage {...messages.messageDeleted} />
        </span>
      )}
      {type === 'file' && (
        <GridRow gap="5px">
          <span>
            <Icon icon="REMOVE_ALT" />
          </span>
          <span>
            <FormattedMessage {...messages.fileDeleted} />
          </span>
        </GridRow>
      )}
    </div>
  )
}

export default DeletedMessage
