import styled from 'styled-components'

import { fontSizes } from '@styles/common'

export const ZenboxLabelWrapperStyle = styled.div<{ width?: number }>`
  display: flex;
  font-size: ${fontSizes.SMALL};
  opacity: 0.8;
  word-break: break-word;
  ${({ width }) => (width ? `width: ${width}px` : '')};
`

export default ZenboxLabelWrapperStyle
