// @flow strict
import * as React from 'react'

import ConnectionAvatar from '@components/ConnectionAvatar'
import type { TagFragment } from '@graphql/server/flow'

import { PrefixStyle, SuffixStyle, TagStyle } from './style'

type Props = {
  prefix?: React.Node,
  suffix?: React.Node,
  tag: TagFragment,
}

const Tag = ({ tag, prefix = null, suffix = null }: Props): React.Element<'div'> => {
  const defaultTag = {
    name: '',
    color: '#ffffff',
  }
  const mergedTag = { ...defaultTag, ...tag }
  const { color, name } = mergedTag

  return (
    <div className={TagStyle(color)}>
      <span style={{ marginRight: '3px' }}>
        {tag.connectionBy?.__typename === 'Connection' && (
          <ConnectionAvatar id={tag.connectionBy.id} name={tag.connectionBy.displayName} />
        )}
      </span>
      {prefix !== undefined && prefix !== null && (
        <div className={PrefixStyle(color)}>{prefix}</div>
      )}
      {name}
      {suffix !== undefined && suffix !== null && (
        <div className={SuffixStyle(color)}>{suffix}</div>
      )}
    </div>
  )
}

export default Tag
