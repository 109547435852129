// @flow
import { css } from 'react-emotion'

export const ButtonStyle: any = css`
  min-width: 75px;
`

export const ButtonLoadingStyle: string = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
`

export const ButtonIconStyle: string = css`
  margin: 0 0 0 5px;
`

export const LeftButtonIconStyle: string = css`
  margin: 0 5px 0 0;
`
