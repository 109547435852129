export const LBS_CONVERSION_COEFFICIENT = 453.59237

/**
 * When the metric is one of these values, the value is invalid. In place of the value,
 * display the metric instead.
 * e.g. { value: 0, metric: "mix units" } -> "mix units  mix units"
 */
export const METRIC_INVALIDS = ['mix units'] as const
export const METRIC_NULLABLES = [''] as const

export const METRIC_WEIGHTS = ['g', 'lb', 'kg', 'ton'] as const
export const METRIC_LENGTHS = ['mm', 'cm', 'm'] as const
export const METRIC_VOLUMES = ['cm³', 'm³'] as const

/**
 * Weights including invalid and null values
 */
export const METRIC_WEIGHTS_COMP = [
  ...METRIC_WEIGHTS,
  ...METRIC_INVALIDS,
  ...METRIC_NULLABLES,
] as const
