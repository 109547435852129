// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import containersQuery from '@graphql/client/container/query.containers.graphql'
import containersByIDsQuery from '@graphql/client/container/query.containersByIDs.graphql'
import type { ContainerFilterInput, ContainerSortInput } from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton, SelectAllButton } from 'components/Buttons'
import { ContainerCard } from 'components/Cards'
import BaseCard from 'components/Cards/BaseCard'
import { Display } from 'components/Form'
import GridView from 'components/GridView'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  BulkHeaderFilter,
  ContainerFilterConfig,
  ContainerSortConfig,
  EntityIcon,
  Filter,
  Search,
  Sort,
} from 'components/NavBar'
import Selector from 'components/Selector'
import SlideView from 'components/SlideView'
import { UserConsumer } from 'contexts/Auth'
import useFilterSort from 'hooks/useFilterSort'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids, { type SelectorProps } from '../Common/Ids'

import { CardStyle } from './style'

const ContainerSelector = ({ open, onClose, selected, setSelected }: SelectorProps) => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<ContainerFilterInput, ContainerSortInput>(
      { query: '', archived: false },
      { updatedAt: 'DESCENDING' }
    )

  const { nodes, loading, hasMore, loadMore } = useQueryList(
    containersQuery,
    {
      variables: { filterBy, sortBy, page: 1, perPage: 20 },
      fetchPolicy: 'network-only',
    },
    'containers'
  )

  return (
    <SlideView isOpen={open} onRequestClose={onClose}>
      <Selector.Many items={nodes} selected={selected.map((id) => ({ id }))}>
        {({ value, dirty, getItemProps, isAllSelected, onSelectAll }) => (
          <SlideViewLayout>
            <SlideViewNavBar>
              <EntityIcon icon="CONTAINER" color="CONTAINER" />
              <Filter
                config={ContainerFilterConfig}
                rootQueryType="Container"
                filterBy={filterByWithoutQuery}
                onChange={setFilterBy}
              />
              <Search query={query} onChange={setQuery} />
              <BulkHeaderFilter
                filterBy={filterByWithoutQuery}
                setFilterBy={setFilterBy}
                type="CONTAINER"
              />
              <Sort config={ContainerSortConfig} sortBy={sortBy} onChange={setSortBy} />
              <CancelButton onClick={onClose} />
              <SaveButton
                disabled={!dirty}
                onClick={() => {
                  const newSelected = value.map((container) => container.id)
                  setSelected([...new Set(newSelected)])
                }}
              />
              <SelectAllButton
                right={15}
                disabled={loading}
                isAllSelected={isAllSelected}
                onClick={() => {
                  onSelectAll(nodes)
                }}
                labelSuffix={value.length ? ` (${value.length.toString()})` : ''}
              />
            </SlideViewNavBar>

            <Content>
              <UserConsumer>
                {({ user }) => (
                  <GridView
                    onLoadMore={loadMore}
                    hasMore={hasMore}
                    isLoading={loading}
                    isEmpty={nodes.length === 0}
                    emptyMessage={null}
                    itemWidth="195px"
                  >
                    {nodes.map((container) => (
                      <ContainerCard
                        key={container?.id}
                        container={container}
                        user={user}
                        {...getItemProps(container)}
                      />
                    ))}
                  </GridView>
                )}
              </UserConsumer>
            </Content>
          </SlideViewLayout>
        )}
      </Selector.Many>
    </SlideView>
  )
}

const ContainerIds = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => (
  <Ids
    value={value}
    readonly={readonly}
    onChange={onChange}
    title={<FormattedMessage {...messages.containers} />}
    selector={ContainerSelector}
    query={containersByIDsQuery}
    getItems={(data) => data?.containersByIDs ?? []}
    renderItem={(container) => (
      <BaseCard icon="CONTAINER" color="CONTAINER" wrapperClassName={CardStyle}>
        <Display height="30px">{container?.no}</Display>
      </BaseCard>
    )}
  />
)

export default ContainerIds
