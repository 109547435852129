// @flow
import { navigate } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { PartnerCard } from 'components/Cards'
import GridView from 'components/GridView'
import usePermission from 'hooks/usePermission'
import { PARTNER_FORM } from 'modules/permission/constants/partner'
import { encodeId } from 'utils/id'

type Props = {
  items: Object[],
  onLoadMore: Function,
  hasMore: boolean,
  isLoading: boolean,
  renderItem?: (item: Object, allowViewForm: boolean) => React.Node,
}

const defaultRenderItem = (item: Object, allowViewForm: boolean): React.Node => {
  return (
    <PartnerCard
      key={item.id}
      partner={item}
      onClick={allowViewForm ? () => navigate(`/partner/${encodeId(item.id)}`) : null}
    />
  )
}

const defaultProps = {
  renderItem: defaultRenderItem,
}

const PartnerGridView = (props: Props): React.Node => {
  const { items, onLoadMore, hasMore, isLoading, renderItem = defaultRenderItem } = props
  const { hasPermission } = usePermission()
  const allowViewForm = hasPermission(PARTNER_FORM)

  return (
    <GridView
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      isLoading={isLoading}
      itemWidth="195px"
      isEmpty={items.length === 0}
      emptyMessage={
        <FormattedMessage
          id="modules.Partners.noPartnersFound"
          defaultMessage="No partners found"
        />
      }
    >
      {items.map((item) => renderItem(item, allowViewForm))}
    </GridView>
  )
}

PartnerGridView.defaultProps = defaultProps

export default PartnerGridView
