// @flow
import type { FieldValuesFragment } from '@graphql/server/flow'

export const prepareCustomFieldsData = (
  customFields: Object
): null | {| fieldValues: any, maskId: any | null |} =>
  customFields
    ? {
        maskId: customFields.mask ? customFields.mask.id : null,
        fieldValues: customFields.fieldValues.map((fieldValue) => ({
          value: { string: fieldValue.value.string },
          fieldDefinitionId: fieldValue.fieldDefinition.id,
        })),
      }
    : null

export const list2Map = (
  list: FieldValuesFragment[]
): Map<string, { ...FieldValuesFragment, index: number }> => {
  const map = new Map()
  list.forEach(({ fieldDefinition, ...rest }, index) => {
    if (fieldDefinition.id) map.set(fieldDefinition.id, { fieldDefinition, ...rest, index })
  })
  return map
}

export const countMaskFieldDefinitions = ({
  fieldDefinitions = [],
}: {
  fieldDefinitions: Object[],
}): number => fieldDefinitions.filter(({ id }) => id).length
