// @flow strict
import * as React from 'react'

import Blackout from '../Blackout'

import { DisplayWrapperStyle } from './style'

type Props = {
  children: React.Node,
  align?: 'left' | 'right' | 'center',
  width?: string,
  height?: string,
  color?: string,
  fontSize?: string,
  blackout?: boolean,
  lines?: number,
}

const Display = ({
  align = 'left',
  width = '100%',
  height = '20px',
  color = 'BLACK',
  fontSize = 'MAIN',
  blackout = false,
  lines = 0,
  children,
  ...rest
}: Props): React.Node =>
  blackout ? (
    <Blackout width={width} height={height} />
  ) : (
    <div
      className={DisplayWrapperStyle({
        align,
        width,
        height,
        color,
        fontSize,
        lines,
      })}
      {...rest}
    >
      {children}
    </div>
  )

export default Display
