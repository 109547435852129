// @flow strict
import * as React from 'react'

import type { Scalars } from '@graphql/server/flow'

import { SectionWrapperStyle } from './style'

type Props = {
  display?: boolean,
  id: $ElementType<Scalars, 'ID'>,
  children: React.Node,
}

const SectionWrapper = ({ children, id, display }: Props): React.Element<'div'> => (
  <div className={SectionWrapperStyle(display ?? false)} id={id}>
    {children}
  </div>
)

SectionWrapper.defaultProps = {
  display: true,
}

export default SectionWrapper
