// @flow strict
import type { MessageDescriptor } from 'react-intl'
import { defineMessages } from 'react-intl'

export default (defineMessages({
  followers: {
    id: 'modules.container.followers',
    defaultMessage: 'Followers',
  },
  container: {
    id: 'modules.container.container',
    defaultMessage: 'CONTAINER',
  },
  containerNo: {
    id: 'modules.container.containerNo',
    defaultMessage: 'CONTAINER NO',
  },
  containerType: {
    id: 'modules.container.containerType',
    defaultMessage: 'CONTAINER TYPE',
  },
  containerOption: {
    id: 'modules.container.containerOption',
    defaultMessage: 'CONTAINER OPTION',
  },
  warehouseName: {
    id: 'modules.container.warehouseName',
    defaultMessage: 'Warehouse Name',
  },
  warehouse: {
    id: 'modules.container.warehouse',
    defaultMessage: 'WAREHOUSE',
  },
  warehouseArrivalAgreedDateApproved: {
    id: 'modules.container.warehouseArrivalAgreedDateApproved',
    defaultMessage: 'Warehouse Arrival Agree Date Approved',
  },
  warehouseArrivalAgreedDate: {
    id: 'modules.container.warehouseArrivalAgreedDate',
    defaultMessage: 'Agreed Arrival Date',
  },
  warehouseArrivalActualDate: {
    id: 'modules.container.warehouseArrivalActualDate',
    defaultMessage: 'Actual Arrival Date',
  },
  warehouseArrivalActualDateApproved: {
    id: 'modules.container.warehouseArrivalActualDateApproved',
    defaultMessage: 'WAREHOUSE ARRIVAL ACTUAL APPROVED',
  },
  departureDate: {
    id: 'modules.container.departureDate',
    defaultMessage: 'Departure Date',
  },
  departureDateApproved: {
    id: 'modules.container.departureDateApproved',
    defaultMessage: 'Departure Date Approved',
  },
  yardName: {
    id: 'modules.container.yardName',
    defaultMessage: 'Yard',
  },
  dueDate: {
    id: 'modules.container.dueDate',
    defaultMessage: 'Due date',
  },
  memo: {
    id: 'modules.container.memo',
    defaultMessage: 'Memo',
  },
  logs: {
    id: 'modules.container.logs',
    defaultMessage: 'Logs',
  },
  mask: {
    id: 'modules.container.mask',
    defaultMessage: 'Custom fields template',
  },
  createdAt: {
    id: 'modules.container.createdAt',
    defaultMessage: 'Date Created',
  },
  updatedAt: {
    id: 'modules.container.updatedAt',
    defaultMessage: 'Last Modified',
  },
  duration: {
    id: 'modules.container.duration',
    defaultMessage: 'DURATION',
  },
  startDate: {
    id: 'modules.container.startDate',
    defaultMessage: 'START DATE',
  },
  noContainerFound: {
    id: 'modules.container.noContainerFound',
    defaultMessage: 'NO CONTAINERS FOUND',
  },
  tags: {
    id: 'modules.container.tags',
    defaultMessage: 'TAGS',
  },
  tagsWithout: {
    id: 'components.container.tagsWithout',
    defaultMessage: 'TAGS WITHOUT',
  },
  totalPackages: {
    id: 'modules.container.totalPackages',
    defaultMessage: 'TOTAL PACKAGES',
  },
  totalBatchQuantity: {
    id: 'modules.container.batchQuantity',
    defaultMessage: 'TOTAL BATCHED QUANTITY',
  },
  totalUniqueItems: {
    id: 'modules.container.totalUniqueItems',
    defaultMessage: 'TOTAL UNIQUE ITEMS',
  },
  totalVolume: {
    id: 'modules.container.totalVolume',
    defaultMessage: 'TOTAL VOLUME',
  },
  totalWeight: {
    id: 'modules.container.totalWeight',
    defaultMessage: 'TOTAL WEIGHT',
  },
  totalPrice: {
    id: 'modules.container.totalPrice',
    defaultMessage: 'TOTAL PRICE',
  },
  status: {
    id: 'modules.container.status',
    defaultMessage: 'STATUS',
  },
  freeTime: {
    id: 'modules.container.freeTime',
    defaultMessage: 'FREE TIME',
  },
  freeTimeOverdue: {
    id: 'modules.container.freeTimeOverdue',
    defaultMessage: 'Free time overdue',
  },
  maxVolume: {
    id: 'modules.container.maxVolume',
    defaultMessage: 'Max Volume',
  },
  loadingRate: {
    id: 'modules.container.loadingRate',
    defaultMessage: 'Loading Rate',
  },
}): { [key: string]: MessageDescriptor })
