import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { isNext } from '@utils/env'

import Icons from './enums'
import { Icon as IconType } from './types'

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  icon: IconType
}

const Icon = React.forwardRef<React.ForwardedRef<Props>, Props>(({ icon, ...rest }, ref) => {
  const rotation: number | null = null
  if (icon === 'LAND') {
    return (
      <>
        <FontAwesomeIcon size="xs" icon="train" {...rest} />
        <FontAwesomeIcon size="xs" icon="pallet-alt" {...rest} />
      </>
    )
  }

  if (icon === 'IATA') {
    return (
      <span
        className="fa-stack"
        style={{ width: isNext ? 17.5 : 30, ...(isNext && { height: 14 }) }}
      >
        <Icon icon="PLANE" className="fa-stack-1x" {...(isNext && { style: { margin: 0 } })} />
        <Icon
          icon="INFO"
          className="fa-stack-1x"
          style={{
            marginLeft: isNext ? 6 : 14,
            marginBottom: isNext ? 4 : 16,
            transform: `scale(0.${isNext ? 4 : 6})`,
            ...(isNext && { position: 'absolute' }),
          }}
        />
      </span>
    )
  }

  return (
    <FontAwesomeIcon
      forwardedRef={ref}
      // @ts-ignore // TODO
      icon={Icons[icon]}
      fixedWidth
      {...(rotation ? { rotation } : {})}
      {...rest}
    />
  )
})

Icon.displayName = 'Icon'

export default Icon
