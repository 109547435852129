// @flow

import * as React from 'react'

import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import { ChatMessageActionStyle } from 'modules/chat/style'

type Props = {
  hideIcons: boolean,
  onEditClick?: () => void,
  onDeleteClick?: () => void,
}

const ChatMessageActions = ({ hideIcons, onEditClick, onDeleteClick }: Props): React.Node => {
  return (
    <GridRow gap="10px" hCentered className={ChatMessageActionStyle}>
      {!hideIcons && (
        <>
          {onEditClick && <Icon icon="EDIT" onClick={onEditClick} />}
          {onDeleteClick && <Icon icon="REMOVE_ALT" onClick={onDeleteClick} />}
        </>
      )}
    </GridRow>
  )
}

export default ChatMessageActions
