// @flow
import { cloneDeep, set } from 'lodash'
import { Container } from 'unstated'

import { cleanFalsyAndTypeName } from '@utils/data'

import { isEquals } from 'utils/fp'

type FormState = {
  containers: Object[],
}

const initValues = {
  containers: [],
}

export default class ContainersInSlideViewContainer extends Container<FormState> {
  state: {| containers: any[] |} = initValues

  originalValues: {| containers: any[] |} = initValues

  setFieldValue: (name: string, value: mixed) => void = (name: string, value: mixed) => {
    this.setState({
      [name]: value,
    })
  }

  setDeepFieldValue: (path: string, value: any) => void = (path: string, value: any) => {
    this.setState((prevState) => {
      const newState = set(cloneDeep(prevState), path, value)
      return newState
    })
  }

  isDirty: () => boolean = () =>
    !isEquals(cleanFalsyAndTypeName(this.state), cleanFalsyAndTypeName(this.originalValues))

  onSuccess: () => void = () => {
    this.originalValues = { ...this.state }
    this.setState(this.originalValues)
  }

  initDetailValues: (containers: any[]) => void = (containers: Object[]) => {
    this.setState({ containers })
    this.originalValues = { containers }
  }
}
