// @flow
import * as React from 'react'
import { cx } from 'react-emotion'

import { CancelButton, SaveButton } from 'components/Buttons'
import type { FormDialogProps } from 'components/Dialog/type'
import GridRow from 'components/GridRow'

import Dialog from '../index'

import { ButtonContainerStyle, DialogStyle, NoPaddingStyle } from './style'

function FormDialog({
  isOpen,
  isLoading,
  onRequestClose,
  onCancel,
  onSave,
  noPadding,
  width = '968px',
  children,
}: FormDialogProps): React.Node {
  return (
    <Dialog isOpen={isOpen} onRequestClose={onRequestClose} width={width}>
      <div className={cx(DialogStyle, { [NoPaddingStyle]: noPadding })}>
        <div className={ButtonContainerStyle}>
          <GridRow>
            <CancelButton disabled={isLoading} onClick={onCancel} />
            <SaveButton disabled={isLoading} isLoading={isLoading} onClick={onSave} />
          </GridRow>
        </div>
        {children}
      </div>
    </Dialog>
  )
}

export default FormDialog
