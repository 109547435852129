// @flow strict
import * as React from 'react'

import { TimelineVoyageWrapperStyle } from './style'

type Props = {
  vertical?: boolean,
  children: React.Node,
}

const TimelineVoyage = ({ vertical = false, children }: Props): React.Element<'div'> => (
  <div className={TimelineVoyageWrapperStyle(vertical)}>{children}</div>
)

export default TimelineVoyage
